import React from 'react'
import {createBrowserRouter} from 'react-router-dom'
import {createRoot} from 'react-dom/client'

import reportWebVitals from 'reportWebVitals'
import {routes} from 'routes'
import {AppRoot} from 'AppRoot'

const router = createBrowserRouter(routes)

const root = createRoot(document.getElementById('root'))
root.render(<AppRoot router={router}/>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
