import {v4 as uuid} from 'uuid'
import {
  applySnapshot,
  cast,
  flow,
  getRoot,
  getSnapshot,
  Instance,
  SnapshotIn,
  SnapshotOut,
  types
} from 'mobx-state-tree'
import toast from 'react-hot-toast'

import {AskedQuestion} from 'common/types/question/question'
import {AllowedAnswers, SubmittedAnswerMap} from 'common/types/question/answer/answer'
import {ICheckAnswer} from 'util/api'
import {ApiError} from 'types/ApiError'
import {Opponent} from 'types/game/opponent'
import {GAME_TYPE} from 'common/types/game'
import {Selected_subjects} from 'types/game/selected_subjects'
import {getGameHighScore} from 'util/api/game'
import {Store} from 'types/store'
import {GameCompetitionsStore} from 'types/game/competitions'
import {logError} from 'util/helpers/logError'
import {Difficulty, DifficultyEnum} from 'common/types/question/difficulty'
import {ask} from 'util/api/ask/ask'
import {checkAnswer} from 'util/api/question/checkAnswer'

type PreQuestion = {
  question: SnapshotIn<AskedQuestion>
} | {
  error: any
}

export const SettingEnum = types.enumeration('SettingEnum', [
  'Default',
  'Space',
  'Forest',
  'City',
  'Tropical',
  'Halloween',
  'Christmas',
  'Chinese New Year',
  'Summer',
])
export type SettingEnum = Instance<typeof SettingEnum>

export const GameModel = types.model({
  _id: types.maybe(types.string),
  selected_subjects: Selected_subjects,
  difficulty: Difficulty,
  question: types.maybe(AskedQuestion),
  student_answer: SubmittedAnswerMap,
  correct_answers: AllowedAnswers,
  score: 0,
  game_id: types.maybe(types.string),
  gameInPlay: false,
  game_start_time: types.maybeNull(types.Date),
  game_duration: types.number,
  question_asked: types.boolean,
  total_questions: 0,
  questions_correct: 0,
  answer_decision: types.maybe(types.string),
  error: types.maybe(types.string),
  //used by time-based speed game
  consecutiveCorrectAnswers: 0,
  streakPoints: 0,
  highestStreak: 0,
  bonusPoints: 0,
  lastQuestionStartTime: Date.now(),
  opponents: types.array(Opponent),
  game_time_seconds: 0,
  question_time_seconds: 0,
  loading: false,
  paused: false,
  over: false,
  highscore: 0,
  is_muted: types.boolean,
  setting: types.maybe(SettingEnum),
  competition_store: GameCompetitionsStore,
  is_duplicate_answer: types.boolean,
})
  .volatile(_ => ({
    next_medium_promise: undefined as Promise<PreQuestion> | undefined,
    next_hard_promise: undefined as Promise<PreQuestion> | undefined,
    next_super_hard_promise: undefined as Promise<PreQuestion> | undefined,
    game_timer: undefined as NodeJS.Timeout | undefined
  }))

  .actions(self => {
    const end_game = () => {
      self.gameInPlay = false
      self.over = true
    }
    return ({
      end_game,
      heartbeat() {
        if (self.game_time_seconds === 0) {
          end_game()
        } else {
          self.game_time_seconds -= 1
          if (self.competition_store.selected_competition && (self.game_time_seconds % 5 === 0)) {
            self.competition_store.get_details(self.competition_store.selected_competition)
          }
        }
      },
      set_difficulty(difficulty: SnapshotOut<typeof Difficulty>) {
        self.difficulty = difficulty
      },
      ask_question: flow(function* (student_id: string) {
        console.log('asking next question')

        self.loading = true
        self.question = yield ask({
          body: {
            student: student_id,
            difficulty: self.difficulty,
            focus: self.selected_subjects.subjects.map(s => s._id),
            endcodes_to_exclude: [self.question ? self.question._id : undefined].filter(Boolean)
          }
        })
        self.lastQuestionStartTime = Date.now()
        self.question_asked = true
        self.total_questions++
        self.error = undefined
        self.student_answer.clear()
        self.correct_answers.clear()
        self.answer_decision = undefined
        self.loading = false
      }),
      update_answer(line_element: string, ref: string, value: string, selected: boolean) {
        if (value === '' || selected) {
          self.student_answer.delete(line_element)
        } else {
          self.student_answer.set(line_element, { ref, value })
        }
      },
      submit_answer: flow(function* (student_id: string) {
        if (self.loading || !self.question_asked) {
          console.log('Answer submission ignored for loading, potentially double submitted')
          return
        }
        self.loading = true
        const answer = getSnapshot(self.student_answer)
        try {
          const endTime = Date.now()
          const time = Math.round((endTime - self.lastQuestionStartTime) / 1000)
          const { result, answers, is_duplicate_answer }: ICheckAnswer = yield checkAnswer({
            params: {
              question_id: self.question._id
            },
            body: {
              student_id,
              time,
              difficulty: self.difficulty,
              answer: answer as unknown as SubmittedAnswerMap,
              game_summary_id: self._id,
              game_id: self.game_id
            }
          })

          self.is_duplicate_answer = is_duplicate_answer

          if (answers.length > 0) {
            applySnapshot(self.correct_answers, answers)
          } else {
            self.error = 'Oh no! Sorry, I can`t show the correct answer'
          }
          self.answer_decision = result
          self.question_asked = false
          const {
            student_tokens: {award_tokens, fetch_tokens},
            actions_store: {record_action}
          } = getRoot<Instance<typeof Store>>(self)

          if (result === 'CORRECT') {
            if (is_duplicate_answer) {
              toast('You have already mastered this question - try another question!', {
                icon: '💪',
                duration: 5000
              })
              self.loading = false
            } else {
              const standard_time = self.question.standard_time
              const ratio = (standard_time - time) / standard_time
              const calc_score = Math.round((((1 + ratio) * time)))
              const score = Math.max(calc_score, 1)//if you answer very fast you should get a point

              self.questions_correct++
              self.consecutiveCorrectAnswers++
              self.score += score
              self.bonusPoints += difficulty_points(self.difficulty)
              if (self.consecutiveCorrectAnswers > self.highestStreak) {
                self.highestStreak = self.consecutiveCorrectAnswers
              }
              //every 5 streak, increase score by 20 and reset rubies
              if (self.consecutiveCorrectAnswers > 0 && self.consecutiveCorrectAnswers % 5 === 0) {
                self.score += 20
                self.streakPoints++

                yield award_tokens(student_id, 20)
              }
            }
          } else {
            self.consecutiveCorrectAnswers = 0
          }

          yield fetch_tokens(student_id)
          if (self.competition_store.selected_competition){
            yield self.competition_store.get_details(self.competition_store.selected_competition)
          }
          yield record_action('submit_answer', result)

        } catch (e) {
          logError(e)
          if (e instanceof ApiError) {
            self.error = e.errors?.join(',\n')
          } else {
            console.dir(e)
            self.error = e.errors?.join(',\n') || e.message || 'Oops!'
          }
        } finally {
          self.loading = false
          self.difficulty = DifficultyEnum.MEDIUM
        }
      }),
      update_highscore: flow(function* () {
        const highScore = yield getGameHighScore({
          query: {
            game_type: GAME_TYPE.SPEED_STANDARD,
            duration: self.game_duration
          }
        })
        self.highscore = highScore.score
      }),
    })
  })
  .actions(self => {
    return ({
      start_game: flow(function*(game_id: string) {
        self.question = undefined
        self.paused = false
        self.over = false
        self.score = 0
        self.game_start_time = undefined
        self.gameInPlay = false
        self.question_asked = false
        self.total_questions = 0
        self.questions_correct = 0
        self.answer_decision = undefined
        self.error = undefined
        self.consecutiveCorrectAnswers = 0
        self.streakPoints = 0
        self.highestStreak = 0
        self.bonusPoints = 0
        self.lastQuestionStartTime = Date.now()
        self.opponents = cast([])
        self._id = uuid()
        self.game_id = game_id
        self.gameInPlay = true
        self.game_time_seconds = self.game_duration
        self.game_start_time = new Date()
        if (self.game_timer !== undefined) {
          clearInterval(self.game_timer)
          self.game_timer = undefined
        }

        self.game_timer = setInterval(
          () => self.heartbeat(),
          1000
        )
        self.update_highscore()


        if (!self.competition_store.selected_competition) {
          const {student_tokens} = getRoot<Instance<typeof Store>>(self)
          console.log('student_tokens.total_token_count', student_tokens.total_token_count)
          self.competition_store.set_original_tokens(student_tokens.total_token_count)
          return
        }

        yield self.competition_store.get_details(self.competition_store.selected_competition)
        const current_position = self.competition_store.current_position(self.competition_store.selected_competition)
        self.competition_store.set_original_position(current_position?.position)
        self.competition_store.set_original_tokens(current_position?.total_tokens)
      }),
      terminate_game() {
        if (self.game_timer !== undefined) {
          clearInterval(self.game_timer)
          self.game_timer = undefined
        }
        self.end_game()
      },
      stop_heartbeat() {
        if (self.over && self.game_timer !== undefined) {
          clearInterval(self.game_timer)
          self.game_timer = undefined
        }
      },
      pause_game() {
        self.paused = true
        if (self.game_timer !== undefined) {
          clearInterval(self.game_timer)
          self.game_timer = undefined
        }
      },
      unpause_game() {
        self.paused = false
        if (self.game_timer !== undefined) {
          clearInterval(self.game_timer)
          self.game_timer = undefined
        }
        self.game_timer = setInterval(
          () => self.heartbeat(),
          1000
        )
      },
      toggle_mute() {
        self.is_muted = !self.is_muted
      },
      clear_answer() {
        self.student_answer.clear()
      },
      set_setting(setting) {
        self.setting = setting
      },
      set_game_duration(duration) {
        self.game_duration = duration
      },
    })
  })

export type GameModel = Instance<typeof GameModel>

function difficulty_points(difficulty: DifficultyEnum) {
  switch (difficulty) {
    case DifficultyEnum.EASY:
      return 0
    case DifficultyEnum.MEDIUM:
      return 1
    case DifficultyEnum.HARD:
      return 2
    case DifficultyEnum.SUPER_HARD:
      return 3
  }
}
