import {ReactNode} from 'react'
import classNames from 'classnames'

interface ISubElementProps {
  children: ReactNode|ReactNode[]
}

export const Container = (props) => (<div className="flex items-center" {...props}/>)
export const ColumnContainer = (props) => (<div className="m-6 flex flex-col items-center" {...props}/>)

export const SubElement = ({ children }: ISubElementProps) => (
  <span className="mx-2 text-center text-[32px]">{children}</span>
)

export const Input = (props) => {
  return (
    <input
      {...props}
      data-testid={props.id}
      className={
        classNames(
          'arrow-hide appearance-none min-w-[150px] rounded shadow text-center p-4 mx-6 my-2 border-solid border-4 font-bold text-lg focus-visible:outline-none focus-visible:border-blue-500',
          getBorderColour(props.answer_decision),
          props.answer_decision && 'focus:outline-0',
          props.className
        )
      }
    />
  )
}

export const NoBorderInput = (props) => (
  <input
    className={
      classNames('rounded min-w-[150px] border-0 mx-4 text-center focus-visible:outline-none focus-visible:border-blue-500', props.answer_decision && 'outline-0')
    }
    {...props}
    data-testid={props.id}
  />
)

export const BorderWrapper = (props) => (
  <div
    {...props}
    className={
      classNames(
        'min-w-[150px] rounded shadow text-center p-4 mx-6 my-2 border-solid border-4 font-bold text-lg focus-visible:outline-none focus-visible:border-blue-500',
        getBorderColour(props.answer_decision)
      )
    }
    data-testid={props.id}
  />
)

const getBorderColour = (answer_decision) => {
  if (!answer_decision) {
    return 'border-gray-500'
  }

  if (answer_decision === 'CORRECT') {
    return 'border-green-500'
  }

  return 'border-red-500'
}
