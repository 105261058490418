import {destroy, getParent, Instance,} from 'mobx-state-tree'

import {AdminQuestion, Element, Lines} from 'common/types/question/question'
import {Status} from 'common/types/question/status'

export const Question = AdminQuestion
  .named('Question')
  .volatile(() => ({ has_changed: false }))
  .actions((self) => ({
    set_has_changed: (has_changed: boolean) => {
      self.has_changed = has_changed
    },
    set_score(score: number) {
      self.has_changed = true
      self.score = score
    },
    set_max_time(seconds: number) {
      self.has_changed = true
      self.maximum_time = seconds
    },
    set_std_time(seconds: number) {
      self.has_changed = true
      self.standard_time = seconds
    },
    set_status(status: Status) {
      self.has_changed = true
      self.status = status
    },
    remove_element(element: Instance<Element>) {
      self.has_changed = true
      const line = getParent<Instance<Lines>>(element)
      if( line.length === 1 ) {
        destroy(line)
      } else {
        destroy(element)
      }
    },
    replace_element(line_index: number, element_index: number, element: Element) {
      self.has_changed = true
      self.lines[line_index].splice(element_index, 1, element)
    },
    insert_element(line_index: number, element_index: number, element: Element) {
      self.has_changed = true
      self.lines[line_index].splice(element_index, 0, element)
    },
    insert_new_line_element(line: number, element: Element) {
      self.has_changed = true
      self.lines.splice(line, 0, [element])
    },
    add_answer() {
      self.has_changed = true
      self.answers.push([{ ref: '', values: [''] }])
    },
    add_answer_part(answer: number) {
      self.has_changed = true
      self.answers[answer].push({ ref: '', values: ['']})
    },
    add_answer_value(answer: number, part: number) {
      self.has_changed = true
      self.answers[answer][part].values.push('')
    },
    remove_answer(answer:number) {
      self.has_changed = true
      if( self.answers.length < 2 ) {
        throw new Error('Cannot remove the last answer')
      }
      destroy(self.answers[answer])
    },
    remove_answer_part(answer:number, part: number) {
      self.has_changed = true
      if( self.answers[answer].length < 2 ) {
        throw new Error('Cannot remove the last answer part')
      }
      destroy(self.answers[answer][part])
    },
    update_answer_ref(answer:number, part:number, ref:string){
      self.has_changed = true
      self.answers[answer][part].ref = ref
    },
    update_answer_value(answer:number, part:number, index:number, value:string){
      self.has_changed = true
      if( value === '' && self.answers[answer][part].values.length > 1 ) {
        self.answers[answer][part].values.splice(index,1)
      } else {
        self.answers[answer][part].values.splice(index,1,value)
      }
    },
    delete() {
      self.status = 'archived'
    }
  }))
export type Question = Instance<typeof Question>;
