import classNames from 'classnames'

export interface IIconProps {
    icon: any,
    alt?: any,
    className?: any,
    onClick?: any,
    color?: any,
    style?: any,
    label?: string,
}

export const Icon = ({ icon, className, onClick, label }: IIconProps) =>
  (
    <i
      className={classNames('fill-current', className, icon)}
      onClick={onClick}
      aria-label={label}
    />
  )
