import { Instance, types } from 'mobx-state-tree'

export const GetPromoCodeRequest = types.model({
  params: types.model({
    promo_code: types.string,
  })
})
export type GetPromoCodeRequest = Instance<typeof GetPromoCodeRequest>

export const GetPromoCodeResponse = types.model({
  promo_id: types.maybeNull(types.string),
  amount_off: types.maybeNull(types.number),
  percent_off: types.maybeNull(types.number),
  is_valid: types.boolean,
})
export type GetPromoCodeResponse = Instance<typeof GetPromoCodeResponse>
