import {Instance, types} from 'mobx-state-tree'

import {NonEmptyString} from 'common/types/basic/non_empty_string'
import {Class} from 'common/types/class'
import {Subscription} from 'common/types/subscription'

export const CreateTeacher = types.model({
  email: NonEmptyString,
  name: NonEmptyString,
}).named('CreateTeacher')
export type CreateTeacher = Instance<typeof CreateTeacher>

export const AddTeacherPostRequest = types.model({
  body: CreateTeacher
}).named('AddTeacherPostRequest')
export type AddTeacherPostRequest = Instance<typeof AddTeacherPostRequest>

export const AddTeacherPostResponse = types.model({ success: types.boolean }).named('AddTeacherPostResponse')
export type AddTeacherPostResponse = Instance<typeof AddTeacherPostResponse>

export const Teacher = CreateTeacher.props({
  _id: types.identifier,
  classes: types.array(types.late(() => Class)),
}).named('Teacher')
export type Teacher = Instance<typeof Teacher>

export const GetTeacherGetRequest = types.model({
  params: types.model({
    teacher_id: types.string
  })
}).named('GetTeacherGetRequest')
export type GetTeacherGetRequest = Instance<typeof GetTeacherGetRequest>

export const GetTeacherGetResponse = types.model({
  success: types.boolean,
  teacher: Teacher
}).named('GetTeacherGetResponse')
export type GetTeacherGetResponse = Instance<typeof GetTeacherGetResponse>


export const GetTeacherSubscriptionsResponse = types.model({
  success: types.boolean,
  subscriptions: types.array(types.late(() => Subscription))
}).named('GetTeacherSubscriptionsResponse')
export type GetTeacherSubscriptionsResponse = Instance<typeof GetTeacherSubscriptionsResponse>
