import React, {FC, useMemo, useState} from 'react'
import {useExpanded, useFilters, useSortBy, useTable} from 'react-table'
import {observer} from 'mobx-react-lite'

import {Modal} from 'components/modal/modal'
import {Icon} from 'generic_components/Icon'

interface PopoverProps {
  onHide: () => void,
  options: string[],
  filterValue: string | undefined,
  setFilter: (string) => void
}

const PopoverList: FC<PopoverProps> = ({ onHide, options, filterValue, setFilter }) => {
  return (
    <div className="flex max-h-[40vh] flex-col overflow-y-auto p-6">
      {
        ['All', ...options].map(option => {
          const allToUndefined = (option === 'All' ? undefined : option)
          return (
            <div
              className="px-6 py-2"
              key={option}
            >
              <input
                className="size-4 cursor-pointer border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
                name="popover-list-filters"
                type="radio"
                checked={filterValue === allToUndefined}
                onChange={() => {
                  setFilter(allToUndefined)
                  onHide()
                }}
              />
              <label
                className="pl-2 text-lg font-normal"
                htmlFor={option}
              >
                {option}
              </label>
            </div>
          )
        })
      }
    </div>
  )
}

function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)

  const options = useMemo(() => {
    const options = new Set()
    preFilteredRows.forEach(row => {
      options.add(row.values[id])
    })
    const values: any = options.values()
    return [...values]
  }, [id, preFilteredRows])

  const popover = (
    <Modal
      title="Update Filters"
      onClose={() => setIsPopoverVisible(false)}
      content={(
        <PopoverList
          onHide={() => setIsPopoverVisible(false)}
          options={options}
          filterValue={filterValue}
          setFilter={setFilter}
        />
      )}
    />
  )

  return (
    <div
      onClick={() => setIsPopoverVisible(true)}
      className="cursor-pointer text-xs"
    >
      {
        isPopoverVisible ?
          popover :
          null
      }
      {
        filterValue ?
          filterValue.length <= 10 ?
            filterValue :
            filterValue.substr(0, 9) + '...' :
          null
      }
      <Icon icon="fa-filter fa-solid" />
    </div>

  )
}

export const Table = observer(({ columns, data, RowSubComponent, update, initialFilters = [] }: any) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: SelectColumnFilter,
      filter: 'exactText'
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    visibleColumns,
  } = useTable(
    {
      columns,
      data: data.slice(),
      defaultColumn,
      autoResetExpanded: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      initialState: {
        filters: initialFilters,
      },
    }, useFilters, useSortBy, useExpanded)

  return (
    <div className="overflow-x-auto overflow-y-hidden rounded">
      <table {...getTableProps()} className="mx-auto bg-gray-100">
        <thead className="rounded bg-gray-200">
          {headerGroups.map(headerGroup => (
            <>
              <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.getHeaderGroupProps().key}>
                {headerGroup.headers.map(column => (
                  <th key={column.getHeaderProps().key} {...column.getHeaderProps()} >
                    <div className='p-4'>
                      {column.render('Header')}
                    </div>
                  </th>
                ))}
              </tr>
              <tr {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.getHeaderGroupProps().key}_filter`}>
                {headerGroup.headers.map(column => (
                  <th key={column.getHeaderProps().key} {...column.getHeaderProps()} >
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
              <tr {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.getHeaderGroupProps().key}_sort`}>
                {headerGroup.headers.map(column => (
                  <th key={column.getHeaderProps().key} {...column.getHeaderProps()} {...column.getSortByToggleProps()}>
                    {column.canSort && <Icon icon={
                      column.isSorted ?
                        column.isSortedDesc ?
                          'fa-arrow-down fa-solid' :
                          'fa-arrow-up fa-solid' :
                        'fa-ellipsis fa-solid'
                    } />}
                  </th>
                ))}
              </tr>
            </>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {
            rows.map(row => {
              prepareRow(row)
              return (
                <>
                  <tr {...row.getRowProps()} {...row.getToggleRowExpandedProps()} className="even:bg-gray-300">
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()} key={cell.getCellProps().key} className="px-4">
                        {cell.render('Cell')}
                      </td>
                    )
                    )}
                  </tr>
                  {row.isExpanded &&
                    <tr {...row.getRowProps()} key={`${row.getRowProps().key}_detail`}>
                      <td colSpan={visibleColumns.length}>
                        <RowSubComponent row={row} update={update} />
                      </td>
                    </tr>
                  }
                </>
              )
            })
          }
        </tbody>
      </table>
    </div>
  )
})
