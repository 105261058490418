import {FC} from 'react'
import {observer} from 'mobx-react-lite'

import {ColumnContainer, Container, Input, SubElement} from 'generic_components/AnswerInputContainers'
import {useStore} from 'types/store'
import {InputProps} from 'components/question/inputProps'
import {get_formatted_correct_answer} from 'components/question/input'

const calculateLength = (x: string): number => x.length <= 3 ? 3 : x.length + 1

const parseNumber = (value: string): string => value.replaceAll(/[^0-9.-]/g, '')

const parseRatioXYZ = (ratio: string): [string, string, string] => {
  const [x,y,z] = (ratio !== '') ? ratio.replaceAll(/[ ]/g, '').split(':') : ['','','']
  return [x,y,z]
}

export const RatioXYZInputComponent: FC<InputProps> = observer(({answerIndex, element, handleChange, answer_decision, correct_answers, value, element_id}) => {
  const {game, auth: { user }} = useStore()

  const [x_value, y_value, z_value] = parseRatioXYZ(value)
  const [sizeX, sizeY, sizeZ] = [calculateLength(x_value), calculateLength(y_value), calculateLength(z_value)]

  const [x_answer, y_answer, z_answer] = (correct_answers.length > 0 && answer_decision !== 'CORRECT') ?
    parseRatioXYZ(get_formatted_correct_answer(correct_answers, answerIndex, element.ref)) :
    ['','','']

  const inputChangeX = (e) => {
    e.target.value = `${parseNumber(e.target.value)}:${y_value}:${z_value}`
    handleChange(e)
  }

  const inputChangeY = (e) => {
    e.target.value = `${x_value}:${parseNumber(e.target.value)}:${z_value}`
    handleChange(e)
  }

  const inputChangeZ = (e) => {
    e.target.value = `${x_value}:${y_value}:${parseNumber(e.target.value)}`
    handleChange(e)
  }

  return (
    <ColumnContainer>
      <Container>
        <Input
          autoFocus
          id={`${element_id}_x`}
          onChange={(e) =>inputChangeX(e) }
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          value={x_value}
          size={sizeX}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              if(x_value !== '' && y_value !== '' && z_value !== '') {
                game.submit_answer(user._id)
              }
            }
          }}
        />
        <SubElement>:</SubElement>
        <Input
          id={`${element_id}_y`}
          onChange={(e) => inputChangeY(e)}
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          value={y_value}
          size={sizeY}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              if(x_value !== '' && y_value !== '' && z_value !== '') {
                game.submit_answer(user._id)
              }
            }
          }}
        />
        <SubElement>:</SubElement>
        <Input
          id={`${element_id}_z`}
          onChange={(e) => inputChangeZ(e)}
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          value={z_value}
          size={sizeZ}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              if(x_value !== '' && y_value !== '' && z_value !== '') {
                game.submit_answer(user._id)
              }
            }
          }}
        />
      </Container>
      {answer_decision !== 'CORRECT' && correct_answers.length > 0 && (
        <Container>
          <Input
            answer_decision={'CORRECT'}
            readOnly={true}
            value={x_answer}
            size={calculateLength(x_answer)}
          />
          <SubElement>:</SubElement>
          <Input
            answer_decision={'CORRECT'}
            readOnly={true}
            value={y_answer}
            size={calculateLength(y_answer)}
          />
          <SubElement>:</SubElement>
          <Input
            answer_decision={'CORRECT'}
            readOnly={true}
            value={z_answer}
            size={calculateLength(z_answer)}
          />
        </Container>
      )}
    </ColumnContainer>
  )

})
