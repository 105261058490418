import {SnapshotOut} from 'mobx-state-tree'

import {api} from 'util/api'
import {GetNotesRequest, GetNotesResponse, Note, PostNoteRequest} from 'common/types/notes'
import {StudentId} from 'common/types/student/id'
import {SubjectId} from 'common/types/subject/id'
import {DateTimeString} from 'common/types/basic/date_time_string'

const _send_note = api(PostNoteRequest, Note, 'POST', 'note/student/subject')
export const send_note = async (subject:SnapshotOut<SubjectId>, student:SnapshotOut<StudentId>, message:string) => {
  const data = await _send_note({
    params: {
      student,
      subject
    },
    body: {
      message
    }
  })
  return data
}
const _list_notes = api(GetNotesRequest, GetNotesResponse, 'GET', 'note/student/subject')
export const list_notes = async (subject:SnapshotOut<SubjectId>, student:SnapshotOut<StudentId>, before?:SnapshotOut<DateTimeString>) => {
  const data = await _list_notes({
    params: {
      student,
      subject
    },
    query: {
      before
    }
  })
  return data
}
