import {Instance, SnapshotOut, types} from 'mobx-state-tree'

import {NonEmptyString} from 'common/types/basic/non_empty_string'
import {Email} from 'common/types/basic/email'

export const DbUser = types.model('UserData', {
  _id: NonEmptyString,
  email: types.maybe(Email),
  username: types.string,
  display_name: types.maybe(types.string),
  profile_picture: types.maybe(types.string),
  student_has_parent: types.maybe(types.boolean)
})
export type DbUser = Instance<typeof DbUser>

export const PlytimeUser = types.model('PlytimeUser', {
  uid: NonEmptyString,
  username: NonEmptyString,
  role: NonEmptyString,
  user_data: types.maybe(DbUser)
})
export type PlytimeUser = Instance<typeof PlytimeUser>

export const MeRequest = types.model({
})
export type MeRequest = SnapshotOut<typeof MeRequest>


