import {ChangeEvent, FC} from 'react'
import {observer} from 'mobx-react-lite'
import {Instance} from 'mobx-state-tree'

import {QuestionText} from 'components/question/text'
import {is_latex_included, is_maths_only, is_readable_string} from 'util/helpers/stringFormatting'
import {QuestionImage} from 'components/question/image'
import {InputComponent} from 'components/question/input'
import {McTick} from 'components/question/mctick'
import {DisplayType, Element, InputType, Lines} from 'common/types/question/question'
import {AllowedAnswers, SubmittedAnswerMap} from 'common/types/question/answer/answer'
import {CoordinateInputComponent} from 'components/question/coordinate'
import {RatioXYInputComponent} from 'components/question/ratioXY'
import {RatioXYZInputComponent} from 'components/question/ratioXYZ'
import {FractionInputComponent} from 'components/question/fraction'
import {MixedFractionInputComponent} from 'components/question/mixed_fraction'
import {TimeInputComponent} from 'components/question/time'
import {NumberComponent} from 'components/question/number'
import {AdvancedMathsInputComponent} from 'components/question/advanced_maths'
import {TextWithMaths} from 'components/text_with_maths'
import TextToSpeechButton from 'generic_components/TextToSpeechButton'
import {InputProps} from 'components/question/inputProps'
import {GBPComponent} from 'components/question/gbp'

const mc_result = (answers) => (ref, value) => {
  if (answers.length === 0) return undefined

  const correct_answer = answers.find(acceptable_answer =>
    acceptable_answer.find(answer_part =>
      answer_part.ref === ref && answer_part.values.includes(value.toLowerCase())
    ) !== undefined
  )

  return correct_answer !== undefined
}

interface Props {
    lines: Lines,
    line_index: number
    element_index: number,
    element: Element,
    handleInputChange: (answer_key: string, ref: string, selected?: boolean) => (e: ChangeEvent) => void,
    student_answer: Instance<SubmittedAnswerMap>,
    correct_answers: Instance<AllowedAnswers>,
    answer_decision: string,
    auto_focus_element?: Element,
}

const get_component_for = (elementType: InputType): (props: InputProps) => JSX.Element => {
  switch (elementType) {
    case InputType.LaTeX:
    case InputType.LaTeX_exact:
      return AdvancedMathsInputComponent
    case InputType.RatioXYZ:
      return RatioXYZInputComponent
    case InputType.RatioXY:
      return RatioXYInputComponent
    case InputType.Coordinate:
      return CoordinateInputComponent
    case InputType.Fraction:
      return FractionInputComponent
    case InputType.MixedFraction:
      return MixedFractionInputComponent
    case InputType.Time:
      return TimeInputComponent
    case InputType.Whole_Number:
      return NumberComponent
    case InputType.GBP:
      return GBPComponent
    case InputType.Text_input_exact:
    case InputType.Text_input:
    case InputType.Textcs:
    case InputType.OneDP:
    case InputType.TwoDP:
    case InputType.ThreeDP:
    case InputType.FourDP:
    case InputType.Range:
      return InputComponent
    case InputType.RangeCoordinateXY:
      return CoordinateInputComponent
    default:
      return InputComponent
  }
}

export const ElementComponent: FC<Props> = observer(({
  lines,
  line_index,
  element_index,
  element,
  handleInputChange,
  correct_answers,
  student_answer,
  answer_decision
}) => {
  const answer_key = `${line_index}-${element_index}`
  const element_id = `question_${line_index}_${element_index}`

  const answer_part = student_answer.size > 0 ? student_answer.get(answer_key) : null
  const value = answer_part?.value || ''

  const selected = student_answer.has(answer_key)

  switch (element.type) {
    case DisplayType.Text:
      if (is_latex_included(element?.text as string)) {
        return (
          <>
            <TextWithMaths text={element.text}/>
            {
              element.alt?.length ?
                (
                  <div className='ml-2'>
                    <TextToSpeechButton text={element.alt}/>
                  </div>
                ) :
                null
            }
          </>
        )
      }

      return (
        <>
          <QuestionText element={element} element_id={element_id} />
          {is_maths_only(element.text as string) || !is_readable_string(element.text as string) ?
            null :
            (
              <TextToSpeechButton
                text={element.alt?.length ? element.alt : element.text}
              />
            )}
        </>
      )
    case DisplayType.Image:
      return (
        <div className="flex flex-col items-center justify-center">
          <QuestionImage image={element}/>
          {
            element.alt?.length ?
              <TextToSpeechButton text={element.alt}/> :
              null
          }
        </div>
      )
    case DisplayType.MC_input:
      return (
        <div className='flex flex-col items-center justify-center'>
          <McTick
            id={element_id}
            type='button'
            value={element.value}
            selected={selected}
            expected={mc_result(correct_answers)(element.ref, element.value)}
            onClick={handleInputChange(`${line_index}-${element_index}`, element.ref, selected)}
          >
            {
              is_latex_included(element?.value as string) ?
                <TextWithMaths text={element.value as string}/> :
                element.value
            }
          </McTick>
          {
            is_maths_only(element.value as string) || !is_readable_string(element.value as string) ?
              null :
              <TextToSpeechButton text={element.value as string}/>
          }
        </div>
      )
    default: {
      const Component = get_component_for(element.type as InputType)

      return (
        <Component
          element_id={element_id}
          element={element}
          handleChange={handleInputChange(answer_key, element.ref)}
          answer_decision={answer_decision}
          value={value}
          correct_answers={correct_answers}
          answerIndex={get_answer_key(lines, line_index, element_index)}
        />
      )
    }
  }
})
ElementComponent.displayName = 'ElementComponent'


const get_answer_key = (lines: Lines, input_line_index: number, input_element_index: number) =>
  lines
    .flatMap(
      (line, line_index) => line.map(
        (element, element_index) => ({
          element,
          element_key: `${line_index}-${element_index}`
        })
      )
    )
    .filter(({element}) => Object.values(InputType).includes(element.type as InputType))
    .findIndex(
      ({element_key}) => {
        return element_key === `${input_line_index}-${input_element_index}`
      }
    )
