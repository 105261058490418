import {Instance, SnapshotOut, types} from 'mobx-state-tree'

import {NonEmptyString} from 'common/types/basic/non_empty_string'
import {Email} from 'common/types/basic/email'
import {DateString} from 'common/types/basic/date_string'
import {Country, CountryEnum} from 'common/types/basic/country'
import {Gender} from 'common/types/basic/gender'
import {AdminSubject} from 'common/types/subject/subject'

export const NewTutor = types.model('NewTutor', {
  first_name: NonEmptyString,
  last_name: NonEmptyString,
  email: Email,
  subjects: types.array(types.string),
  country: types.optional(Country, CountryEnum['United Kingdom'])
})
export type NewTutor = Instance<typeof NewTutor>

export const NewTutorPostRequest = types.model(
  'NewTutorPostRequest',
  {
    body: NewTutor
  }
)
export type NewTutorPostRequest = SnapshotOut<typeof NewTutorPostRequest>

export const Tutor = NewTutor
  .named('Tutor')
  .props({
    _id: types.identifier,
    uid: types.maybe(types.string),
    bank_account: types.optional(types.string, ''),
    dob: types.maybeNull(DateString),
    gender: types.maybeNull(Gender),
    phone_number: types.optional(types.string, ''),
    postcode: types.optional(types.string, ''),
    sort_code: types.optional(types.string, ''),
    dbs_issue_date: types.maybeNull(DateString),
    subjects: types.array(types.late(() => types.reference(AdminSubject)))
  })
export type Tutor = Instance<typeof Tutor>

export const UpdateTutorPutRequest = types.model(
  'UpdateTutorPutRequest',
  {
    body: Tutor
  }
)
export type UpdateTutorPutRequest = Instance<typeof UpdateTutorPutRequest>

export const TutorList = types.array(Tutor)
export type TutorList = Instance<typeof TutorList>

export const TutorListGetRequest = types.model({
})
export type TutorListGetRequest = SnapshotOut<typeof TutorListGetRequest>

export const TutorGetRequest = types.model({
  query: types.model({
    _id: types.string
  })
})
export type TutorGetRequest = SnapshotOut<typeof TutorGetRequest>
