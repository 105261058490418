import {types} from 'mobx-state-tree'

import {DateTimeString} from 'common/types/basic/date_time_string'
import {SessionId} from 'common/types/session/id'

export const SessionEventType = types.enumeration([
  'connect', 'disconnect',
  'add_local_video', 'remove_local_video', 'pause_local_video', 'unpause_local_video',
  'add_local_audio', 'remove_local_audio', 'pause_local_audio', 'unpause_local_audio',
  'add_local_screen', 'remove_local_screen', 'user_still_connected',
])
export type SessionEventType = typeof SessionEventType

export const SessionEvent = types.model('SessionEvent', {
  type: SessionEventType,
  time: DateTimeString
})
export type SessionEvent = typeof SessionEvent

export const PutSessionRecordRequest = types.model('PutSessionmodelRequest',{
  params: types.model({
    session_id: SessionId
  }),
  body: SessionEvent
})
export type PutSessionRecordRequest = typeof PutSessionRecordRequest

export const PutSessionRecordResponse = types.model('PutSessionmodelResponse',{
  success: types.boolean
})
