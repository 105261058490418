import { flow, Instance, types } from 'mobx-state-tree'

import { Student } from 'common/types/student'
import { get_students_for_tutor } from 'util/api/tutor/get_students'
import { get_students_for_teacher } from 'util/api/teacher/get_students'

export const StudentsStore = types.model({
  isLoading: types.boolean,
  students: types.array(Student)
}).actions(self => ({
  get_students_for_tutor: flow(function*(tutor_id: string){
    self.isLoading = true
    const response = yield get_students_for_tutor(tutor_id)

    if (response.success) {
      self.students = response.students
    }
    self.isLoading = false
  }),
  get_students_for_teacher: flow(function*(teacher_id: string){
    self.isLoading = true
    const response = yield get_students_for_teacher(teacher_id)

    if (response.success) {
      self.students = response.students
    }
    self.isLoading = false
  }),
}))
export type StudentsStore = Instance<typeof StudentsStore>
