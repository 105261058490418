import {DateTime} from 'luxon'
import {flow, getSnapshot, Instance, types} from 'mobx-state-tree'

import {HydratedSession} from 'common/types/session'
import {getUpcomingSessions} from 'util/api/sessions'

export const JoinSessionsStore = types
  .model({
    is_loading: types.boolean,
    sessions: types.array(HydratedSession),
    // can't use a reference for next_session because sessions can sometimes not have an identifier (if they're scheduled and not booked)
    next_session: types.maybe(HydratedSession),
    is_joinable: types.boolean
  })
  .volatile(_ => ({
    timer: undefined as NodeJS.Timeout | undefined
  }))
  .actions(self => ({
    set_is_joinable: (is_joinable) => self.is_joinable = is_joinable,
    set_next_session: (next_session) => self.next_session = next_session,
  }))
  .actions(self => {
    const session_check = () => {
      const now = DateTime.now()
      console.log('session_check')

      const current_session = self.sessions.find(s => {
        const start = DateTime.fromISO(s.session_date_time, { setZone: false }).toLocal()

        const end = start.plus({minutes: s.session_length})
        console.log('Current session', now.plus({minutes: 2}) >= start, now < end, !s.cancelled)
        return now.plus({minutes: 2}) >= start && now < end && !s.cancelled
      })
      if (current_session && current_session.paid) {
        self.set_is_joinable(true)
        self.set_next_session({ ...getSnapshot(current_session) })
      } else {
        self.set_is_joinable(false)
        const next_session = self.sessions.find(s => {
          const start = DateTime.fromISO(s.session_date_time)
          return start > now && !s.cancelled
        })

        if (next_session) {
          self.set_next_session({ ...getSnapshot(next_session) })
        }
      }
    }

    return {
      watch_sessions: flow(function* (user) {
        self.is_loading = true
        self.sessions.replace([
          ...yield getUpcomingSessions({ student_id: user._id }, DateTime.now().toISO()),
          ...yield getUpcomingSessions({ student_id: user._id }, DateTime.now().plus({days: 7}).toISO()),
          ...yield getUpcomingSessions({ student_id: user._id }, DateTime.now().plus({days: 14}).toISO()),
        ])
        console.log('getting sessions', self.sessions.length)
        self.timer = setInterval(session_check, 10000)
        session_check()
        self.is_loading = false
      })
    }
  })
  .named('JoinSessionsStore')

export type JoinSessionsStore = Instance<typeof JoinSessionsStore>
