import {flow, getRoot, Instance, types} from 'mobx-state-tree'
import {DateTime} from 'luxon'

import {Subscription} from 'common/types/subscription'
import {listSubscriptions} from 'util/api/subscriptions'
import {Roles} from 'util/auth/helper'
import {IRichStore} from 'types/store'
import {AdminSubject} from 'common/types/subject/subject'

export const StudentSubscriptions = types.model(
  'StudentSubscriptions', {
    isLoading: types.boolean,
    subscriptions: types.array(
      Subscription
        .named('StudentSubscription')
        .props({ class: types.maybe(types.string) }) // todo, bit of a hack - should make the students Class available in the tree so that the reference can be used
    ),
  }
).actions(self => ({
  get_subscriptions: flow(function* () {
    const rootStore: IRichStore = getRoot(self)

    if (
      rootStore.auth.role === Roles.STUDENT &&
      rootStore.auth.user
    ) {
      self.isLoading = true
      const subscriptionsResponse = yield listSubscriptions()
      self.subscriptions = subscriptionsResponse

      self.isLoading = false
    }
  }),
})).views(
  (self) => ({
    get current_subscriptions(): Subscription[] {
      return self.subscriptions.filter(({ payment_date, cancelled }) => {
        const now = DateTime.now().toISODate()
        const subValidUntil = DateTime.fromISO(payment_date).plus({days: 7}).toISODate()

        console.log('current_subscription', now, subValidUntil, cancelled)

        return now <= subValidUntil && !cancelled
      })
    }
  })
)
  .views(
    (self) => ({
      get current_active_subscription(): Subscription|null {
        return self.current_subscriptions?.[0] ?? null
      },
      get subscription_subject(): AdminSubject|null {
        return self.subscriptions.map(s => s.subject)?.[0]
      }
    })
  )
export type StudentSubscriptions = Instance<typeof StudentSubscriptions>
