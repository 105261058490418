import {Authenticator} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import {observer} from 'mobx-react-lite'
import {FC, ReactElement} from 'react'

import './index.css'

import {Roles} from 'util/auth/helper'
import {useStore} from 'types/store'
import {BlockLoader} from 'generic_components/BlockLoader'


interface IProps {
  role: Roles,
  header?: ReactElement,
  initialState?: 'signIn' | 'signUp' | 'resetPassword'
}

export const AuthComponent: FC<IProps> = observer(({ role, header, initialState }) => {
  const {auth} = useStore()

  if(auth.in_progress) {
    return <BlockLoader message='Loading... Please wait' />
  }
  
  return (
    <Authenticator
      initialState={initialState}
      loginMechanisms={[role === Roles.STUDENT ? 'username' : 'email']}
      hideSignUp={!(role === Roles.PARENT || role === Roles.SCHOOL)}
      components={{
        Header() {
          return header ?? null
        }
      }}
    />
  )
})
