import {flow, getRoot, Instance, types} from 'mobx-state-tree'

import {IRichStore} from 'types/store'
import {Roles} from 'util/auth/helper'
import {get_endcodes_stats} from 'components/progress/Components/TopicsTable/TopicsTable'

export const StudentMenuStore = types.model({
  is_loading: types.boolean
})
  .actions(self => ({
    initialise: flow(function*() {
      const {
        auth,
        actions_store,
        student_trends_store,
        student_performance_stats_store,
        subjectsStore
      } = getRoot<IRichStore>(self)

      self.is_loading = true
      if (auth.user.role === Roles.STUDENT) {
        yield subjectsStore.get_all_subjects()
        yield actions_store.get_actions('student', auth.user._id, 'watch_skill_videos')
        yield student_trends_store.get_trend_stats(auth.user._id)
        yield student_performance_stats_store.get_performance_stats(auth.user._id)
      }
      self.is_loading = false
    })
  }))
  .views(self => ({
    get has_unwatched_videos() {
      const {
        auth,
        actions_store,
        subjectsStore,
        student_trends_store,
        student_performance_stats_store
      } = getRoot<IRichStore>(self)

      if (auth.user.role === Roles.STUDENT) {
        const subject_stats = student_performance_stats_store.hierarchy.find(h => h.subject?.type === 'subject')
        if (!subject_stats) {
          return false
        }

        const live_endcodes = subjectsStore.live_endcodes_for_student(
          subject_stats.is_ahead,
          get_endcodes_stats(subject_stats),
          subject_stats.score
        )

        const watched_videos = (actions_store.actions?.get(auth.user._id) ?? [])
          .filter(a => a.label === 'watch_skill_videos')
          .map(a => a.value)

        return live_endcodes
          .filter(e => e.help_videos.length > 0)
          .filter(e => !watched_videos.includes(e._id))
          .filter(e => student_trends_store.new_endcodes_this_week.find(new_endcode => new_endcode._id === e._id)).length > 0
      }

      return false
    }
  }))
export type StudentMenuStore = Instance<typeof StudentMenuStore>
