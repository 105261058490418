import { types, SnapshotOut } from 'mobx-state-tree'

export const GetAvailabilityRequest = types.model({
  params: types.model({
    tutor_id: types.string
  }),
})
export type GetAvailabilityRequest = SnapshotOut<typeof GetAvailabilityRequest>

const TimeSlotRow = types.model('TimeSlotRow',{
  _id: types.maybe(types.string),
  day_of_week: types.number,
  time: types.number,
  busy: types.boolean,
  deleted: types.optional(types.boolean, false)
})
const DayAvailability = types.array(TimeSlotRow)

const Availability = types.model('Availability', {
  0: DayAvailability,
  1: DayAvailability,
  2: DayAvailability,
  3: DayAvailability,
  4: DayAvailability,
  5: DayAvailability,
  6: DayAvailability,
})
export type AvailabilitySnapshot = SnapshotOut<typeof Availability>

export const AvailabilityStatus = types.model('AvailabilityStatus', {
  availability: Availability,
  ideal_hours: types.maybe(types.number)
})
export type AvailabilityStatus = SnapshotOut<typeof AvailabilityStatus>

export const PutAvailabilityRequest = types.model({
  params: types.model({
    tutor_id: types.string
  }),
  body: AvailabilityStatus
})
export type PutAvailabilityRequest = SnapshotOut<typeof PutAvailabilityRequest>
