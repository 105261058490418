import {api} from 'util/api'
import {ClassPerformanceGetRequest, ClassPerformanceGetResponse,} from 'common/types/class'


export const get_class_performance = api(
  ClassPerformanceGetRequest,
  ClassPerformanceGetResponse,
  'GET',
  'class/performance/class_id',
)
