import {Instance, types} from 'mobx-state-tree'

import {Student} from 'common/types/student'
import {Teacher} from 'common/types/teacher'
import {SubjectId} from 'common/types/subject/id'
import {AdminSubject} from 'common/types/subject/subject'
import {Competition} from 'common/types/competition'
import {SubjectType} from 'common/types/subject/type'

export const AddClassPostRequest = types.model({
  body: types.model({
    school_id: types.string,
    teacher_ids: types.array(types.string),
    class_name: types.string,
    school_year: types.number,
  })
}).named('AddClassPostRequest')
export type AddClassPostRequest = Instance<typeof AddClassPostRequest>

export const AddClassPostResponse = types.model({
  success: types.boolean,
  class_id: types.string,
}).named('AddClassPostResponse')
export type AddClassPostResponse = Instance<typeof AddClassPostResponse>

export const UpdateClassPutRequest = types.model({
  body: types.model({
    class_id: types.string,
    student_ids: types.array(types.string),
    class_name: types.string,
    teacher_ids: types.array(types.string),
    subject_id: types.string,
    school_year: types.number,
  })
}).named('UpdateClassPutRequest')
export type UpdateClassPutRequest = Instance<typeof UpdateClassPutRequest>

export const UpdateClassPutResponse = types.model({ success: types.boolean }).named('UpdateClassPutResponse')
export type UpdateClassPutResponse = Instance<typeof UpdateClassPutResponse>

export const RemoveClassDeleteRequest = types.model({
  body: types.model({
    class_id: types.string,
    school_id: types.string,
  })
}).named('RemoveClassDeleteRequest')
export type RemoveClassDeleteRequest = Instance<typeof RemoveClassDeleteRequest>

export const RemoveClassDeleteResponse = types.model({ success: types.boolean }).named('RemoveClassDeleteResponse')
export type RemoveClassDeleteResponse = Instance<typeof RemoveClassDeleteResponse>

export const SubscribeClassPostRequest = types.model({
  body: types.model({
    school_id: types.maybe(types.string),
    class_id: types.maybe(types.string),
    student_ids: types.array(types.string),
    subject: SubjectId,
    success_url: types.string,
    error_url: types.string,
    promo_code: types.maybeNull(types.string),
    referral_code: types.maybeNull(types.string),
    school_year: types.number
  })
}).named('SubscribeClassPostRequest')
export type SubscribeClassPostRequest = Instance<typeof SubscribeClassPostRequest>

export const SubscribeClassPostResponse = types.model({
  redirect_url: types.string
}).named('SubscribeClassPostResponse')
export type SubscribeClassPostResponse = Instance<typeof SubscribeClassPostResponse>

export const EditClassSubscriptionPutRequest = types.model({
  body: types.model({
    school_id: types.maybe(types.string),
    class_id: types.maybe(types.string),
    student_ids: types.array(types.string),
    subject: SubjectId,
  })
}).named('EditClassSubscriptionPutRequest')
export type EditClassSubscriptionPutRequest = Instance<typeof EditClassSubscriptionPutRequest>

export const EditClassSubscriptionPutResponse = types.model({
  success: types.boolean
}).named('EditClassSubscriptionPutResponse')
export type EditClassSubscriptionPutResponse = Instance<typeof EditClassSubscriptionPutResponse>


export const ClassPerformanceGetRequest = types.model({
  params: types.model({
    class_id: types.string,
  })
}).named('ClassPerformanceGetRequest')
export type ClassPerformanceGetRequest = Instance<typeof ClassPerformanceGetRequest>


export const Score = types.model({
  subject: types.safeReference(AdminSubject),
  type: types.enumeration('SubjectType', Object.values(SubjectType)),
  score: types.maybeNull(types.number),
  last_answered: types.maybeNull(types.string),
  recent_correct_percentage: types.maybeNull(types.number),
  total_answers: types.maybeNull(types.number),
  is_ahead: types.maybeNull(types.boolean)
}).named('Score')
export type Score = Instance<typeof Score>

export const ScoreByStudent = types.model({
  student: types.reference(Student),
  scores: types.array(Score),
  flag_count: types.number,
  time_on_questions: types.maybeNull(types.number),
  tokens_earned: types.number,
}).named('ScoreByStudent')
export type ScoreByStudent = Instance<typeof ScoreByStudent>

export const ClassPerformanceGetResponse = types.model({
  success: types.boolean,
  scores_by_student: types.array(ScoreByStudent)
}).named('ClassPerformanceGetResponse')
export type ClassPerformanceGetResponse = Instance<typeof ClassPerformanceGetResponse>

export const Class = types.model({
  _id: types.identifier,
  name: types.string,
  subject: types.safeReference(AdminSubject),
  students: types.array(types.maybeNull(types.reference(types.late(() => Student)))),
  teachers: types.array(types.maybeNull(types.reference(types.late(() => Teacher)))),
  competitions: types.maybe(types.array(types.late(() => Competition))),
  school_year: types.maybe(types.number),
  deleted_at: types.maybeNull(types.string)
}).named('Class')
export type Class = Instance<typeof Class>
