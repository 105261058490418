import {Instance, types} from 'mobx-state-tree'

import {PositiveInteger} from 'common/types/basic/positive_int'
import {Score} from 'common/types/question/score'

export const ScoreDistribution = types.array(types.model({
  score: Score,
  occurs: PositiveInteger
}))
export type ScoreDistribution = Instance<typeof ScoreDistribution>
