import { types } from 'mobx-state-tree'

export enum SubjectType {
    subject = 'subject',
    endcode = 'endcode',
    topic = 'topic',
    theme = 'theme',
}

export const SubjectTypeEnum = types.enumeration('SubjectType', Object.values(SubjectType))
