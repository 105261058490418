import {Instance} from 'mobx-state-tree'

import {UserModel} from 'types/user/model'

export enum Roles {
  ADMIN = 'ADMIN',
  TUTOR = 'TUTOR',
  PARENT = 'PARENT',
  STUDENT = 'STUDENT',
  TEACHER = 'TEACHER',
  SCHOOL = 'SCHOOL',
  TEST = 'TEST',
  ANONYMOUS = 'ANONYMOUS'
}

export function checkPermission(user: Instance<typeof UserModel>, permittedRoles: Roles[]) {
  return {
    user,
    permitted: permittedRoles.includes(user?.role)
  }
}

export const clear_cookies = () => {
  const cookies = document.cookie.split(';')

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;domain=' + window.location.hostname + ';path=/;secure;'
  }
}
