import {Instance, SnapshotOut, types} from 'mobx-state-tree'
import {DateTime} from 'luxon'

import {Student} from 'common/types/student'
import {Tutor} from 'common/types/tutor/tutor'
import {AdminSubject} from 'common/types/subject/subject'
import {DateTimeString} from 'common/types/basic/date_time_string'

export const HydratedSession = types.model('HydratedSession', {
  _id: types.maybeNull(types.string),
  recurring_session_id: types.string,
  subscription_id: types.string,
  day_of_week: types.number,
  session_time: types.number,
  session_length: types.number,
  session_date_time: DateTimeString,
  session_date_time_end: types.maybe(DateTimeString),
  subject: types.safeReference(AdminSubject),
  participants: types.array(types.string),
  paid: types.boolean,
  cancelled: types.boolean,
  rescheduled: types.boolean,
  substituted: types.boolean,
  student_id: types.maybe(types.string),
  student: types.safeReference(Student),
  tutor: types.safeReference(Tutor),
  tutor_id: types.maybe(types.string),
  register: types.maybeNull(types.array(types.model({
    type: types.string,
    time: DateTimeString,
    user: types.string,
  }))),
  parent_id: types.maybeNull(types.string),
  parent: types.maybeNull(types.string),
})
  .views(self => ({
    get day() {
      const sessionTime = DateTime.fromISO(self.session_date_time)
      return sessionTime.toFormat('ccc d MMM')
    },
    get time() {
      const sessionTime = DateTime.fromISO(self.session_date_time)
      return sessionTime.toFormat('HH:mm')
    },
    get status() {
      let sessionStatus =
          self.cancelled ?
            self.rescheduled ?
              'Rescheduled' :
              self.substituted ?
                'Substituted' :
                'Cancelled' :
            self.paid ?
              'Booked' :
              'Scheduled'

      if((sessionStatus === 'Booked' || sessionStatus === 'Scheduled') &&
          self.session_date_time_end && DateTime.fromISO(self.session_date_time_end) < DateTime.now()
      ) {
        if (self.register) {
          const uniqueUsers = self.register.map((event) => {
            return event.user
          }).filter((value, index, self) => self.indexOf(value) === index)
          if (!uniqueUsers || uniqueUsers.length === 0) {
            sessionStatus = 'Missed(both)'
          } else {
            sessionStatus = uniqueUsers.find((user) => user === self.student_id) ?
              uniqueUsers.find((user) => user === self.tutor._id) ?
                'Attended' :
                'Missed(tutor)' :
              'Missed(student)'
          }
        } else {
          sessionStatus = 'Missed (both)'
        }
      }

      return sessionStatus
    }
  }))
export type HydratedSession = Instance<typeof HydratedSession>

export const GetSessionRequest = types.model({
  params: types.model({
    session_id: types.string
  })
})
export type GetSessionRequest = SnapshotOut<typeof GetSessionRequest>

export const GetUpcomingSessionsRequest = types.model({
  query: types.model({
    after_time: types.string,
    tutor_id: types.maybe(types.string),
    class_id: types.maybe(types.string),
  })
})
export type GetUpcomingSessionsRequest = SnapshotOut<typeof GetUpcomingSessionsRequest>

export const GetUpcomingSessionsResponse = types.array(HydratedSession)
export type GetUpcomingSessionsResponse = SnapshotOut<typeof GetUpcomingSessionsResponse>

export const GetPastSessionsRequest = types.model({
  query: types.model({
    before_time: types.string,
    tutor_id: types.maybe(types.string),
    class_id: types.maybe(types.string),
  })
})
export type GetPastSessionsRequest = SnapshotOut<typeof GetPastSessionsRequest>

export const GetPastSessionsResponse = types.array(HydratedSession)
export type GetPastSessionsResponse = SnapshotOut<typeof GetPastSessionsResponse>

export const ModifySessionRequest = types.model({
  query: types.model({
    subscription_id: types.maybeNull(types.string),
    session_id: types.maybeNull(types.string),
    session_date_time: types.maybeNull(DateTimeString)
  })
})
export type ModifySessionRequest = SnapshotOut<typeof ModifySessionRequest>

export const ModifySessionResponse = types.model({
  success: types.literal(true)
})
export type ModifySessionResponse = SnapshotOut<typeof ModifySessionResponse>

export const RescheduleSessionRequest = types.model({
  query: types.model({
    subscription_id: types.maybeNull(types.string),
    session_id: types.maybeNull(types.string),
    session_date_time: types.maybeNull(DateTimeString),
    new_session_date_time: DateTimeString
  })
})
export type RescheduleSessionRequest = SnapshotOut<typeof RescheduleSessionRequest>

export const MetaSession = types.model('MetaSession', {
  _id: types.string,
  day_of_week: types.number,
  session_time: types.number,
  session_length: types.number,
})

export type MetaSession = Instance<typeof MetaSession>
