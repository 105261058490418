import {flow, Instance, types} from 'mobx-state-tree'

import {postStreak} from 'util/api/student/attendance_streak'

export const AttendanceStreakStore = types.model({
  streak: types.maybe(types.number),
  has_loaded: types.maybe(types.boolean),
}).actions(
  self => ({
    add_streak: flow(function* (student_id: string) {
      const response = yield postStreak({body: { student_id }})
      self.streak = response.streak
      self.has_loaded = true
    })
  })
)
export type AttendanceStreakStore = Instance<typeof AttendanceStreakStore>
