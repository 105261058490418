import {destroy, flow, Instance, types} from 'mobx-state-tree'

import {Mix} from 'common/types/student/mixes'
import {deleteMix, getMixes, postMix, putMix} from 'util/api/student/mixes'
import {AdminSubject} from 'common/types/subject/subject'
import {logError} from 'util/helpers/logError'

export const Mix_Extended = Mix
  .named('Mix_')
  .actions(self => ({
    add_subject: (subject: AdminSubject) => {
      self.subjects.push(subject)
    },
    remove_subject: (subject_id: string) => {
      self.subjects.replace(
        self
          .subjects
          .filter(s => s._id !== subject_id)
      )
    },
    update_name: (name: string) => {
      self.name = name
    },
    save: flow(function* () {
      return putMix(self.student, self._id, self.name, self.subject, self.subjects.map((subject) => subject._id))
    })
  }))
export type Mix_Extended = typeof Mix_Extended

export const MixesList = types.model(
  'MixesList', {
    mixes: types.array(Mix_Extended),
    current_mix: types.safeReference(Mix_Extended),
  }
)
  .actions(self => ({
    refresh: flow(function* (student: string) {
      try {
        const mixes = yield getMixes(student)
        if (mixes) {
          self.mixes = mixes
        }
      } catch (err) {
        logError(err)
        console.error('Failed to load mixes', err)
      }
    }),
    add_mix: flow(function* (student: string, subject: string, mix_name = 'New Mix', subjects: string[] = []) {
      const mix = yield postMix(
        student,
        mix_name,
        subject,
        subjects
      )

      yield (self as any).refresh(student)
      self.current_mix = mix._id
    }),
    delete_mix: flow(function* (mix: Instance<Mix_Extended>) {
      try {
        const {mix_id} = yield deleteMix(mix.student, mix._id)
        if (mix_id) {
          destroy(mix)
        }
      } catch (err) {
        logError(err)
        console.error('Failed to delete mix', err)
      }
    }),
    set_current_mix: (mix) => {
      self.current_mix = mix
    },
  }))
export type MixesList = Instance<typeof MixesList>
