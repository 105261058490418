import { flow, Instance, types } from 'mobx-state-tree'

import { GetPromoCodeResponse } from 'common/types/promo_codes/promo_codes'
import { getPromo } from 'util/api/subscriptions/promo_codes'

export const PromoCodes = types.model(
  'PromoCodes',
  {
    promotion_information: types.maybe(GetPromoCodeResponse),
    promotion_code: types.maybe(types.string)
  }
)
  .actions(self => ({
    set_promotion_code: flow(function* (promotion_code: string) {
      self.promotion_code = promotion_code
      self.promotion_information = undefined
    }),
    get_promotion_information: flow(function* () {
      if (self.promotion_code) {
        const response: Instance<typeof GetPromoCodeResponse> = yield getPromo(self.promotion_code)
        if (response.is_valid) {
          self.promotion_information = response
          return
        }
      }

      self.promotion_code = undefined
      self.promotion_information = undefined
    }),
  }))


export type PromoCodes = Instance<typeof PromoCodes>
