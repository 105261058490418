import React from 'react'
import {observer} from 'mobx-react-lite'
import {Instance} from 'mobx-state-tree'

import {SecondaryClickableButton} from 'components/ClickableButton'
import {Mix} from 'common/types/student/mixes'
import {AdminSubject} from 'common/types/subject/subject'
import {Stats} from 'common/types/student/current_stats'

interface IAddToMixButtonProps {
    currentMix: Instance<typeof Mix>,
    row: Stats,
    toggleInMix: (subject: Instance<typeof AdminSubject>, already_in_mix: boolean) => void
}

export const AddToMixButton = observer(({currentMix, row, toggleInMix}: IAddToMixButtonProps): JSX.Element => {
  const isSubjectInMix = (currentMix?.subjects ?? []).find((mix_subject: AdminSubject) => mix_subject._id === row.subject._id)
  const isParentSubjectInMix = (currentMix?.subjects ?? []).find((mix_subject: AdminSubject) => mix_subject._id === row.parent_subject._id)

  if (!isSubjectInMix && !isParentSubjectInMix) {
    return (
      <SecondaryClickableButton
        className="float-right"
        onClick={(e) => {
          e.stopPropagation()
          toggleInMix(row.subject._id, false)
        }}
      >
        Add to {currentMix ? '' : 'New'} Mix
      </SecondaryClickableButton>
    )
  }

  return null
})
