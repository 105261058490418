import {useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {SnapshotOut, types} from 'mobx-state-tree'

import {PrimaryClickableButton} from 'components/ClickableButton'
import {IModalContent, Modal} from 'components/modal/modal'
import {useStore} from 'types/store'
import {GenderSelector} from 'components/GenderSelector'
import {NewStudent, Student} from 'common/types/student'
import {MSTForm} from 'generic_components/MstForm'
import {DateInput} from 'generic_components/MstFormDate'
import {StringInput} from 'generic_components/MstFormStringInput'

interface IProps {
  value: Student,
  onChange: (student_id: string) => void,
  disabled: boolean,
}

export const StudentSelector = observer(({ value, onChange, disabled }: IProps) => {
  const { parent_data: { profile, get_profile, subscriptions, add_student } } = useStore()

  const students = profile
    ?.students
    .filter((student) => !subscriptions.find(s => s.student._id === student._id && !s.cancelled)) ?? []

  useEffect(() => {
    if (students.length > 0 && value === undefined) {
      onChange(students[0]._id)
    }
  }, [value, students])

  useEffect(() => {
    get_profile()
  }, [])

  const [modelContent, setModalContent] = useState<IModalContent>(null)

  const handle_change = (e) => {
    const student = students.find(student => student._id === e.target.value)
    if (student) {
      onChange(student._id)
    }
  }

  const submit = useCallback(
    async (student: SnapshotOut<NewStudent>) => {
      const saved_student = await add_student(student)
      onChange(saved_student._id)
      setModalContent(null)
    }, [onChange]
  )

  const blank_student = types.model({
    school: types.string
  }).create({
    school: ''
  })

  return (
    <div className="flex flex-col">
      <div className="mb-6">
        <PrimaryClickableButton
          onClick={() => {
            setModalContent({
              title: 'Add a New Student',
              content: (
                <div className="flex max-h-[75vh] w-full justify-center overflow-auto">
                  <MSTForm
                    initial={blank_student}
                    model={NewStudent}
                    submit={submit}
                    force_edit_mode
                    is_modal
                    on_reset={() => setModalContent(null)}
                    submit_text='Create student'
                    success_text='Student created successfully.'
                    fields={[
                      {
                        name: 'first_name',
                        label: 'First name',
                        Component: StringInput,
                        required: true,
                        validators: [
                          {
                            validator: Boolean,
                            failMessage: 'Please enter a first name',
                          },
                        ],
                      },
                      {
                        name: 'last_name',
                        label: 'Last name',
                        Component: StringInput,
                        required: true,
                        validators: [
                          {
                            validator: Boolean,
                            failMessage: 'Please enter a last name',
                          },
                        ],
                      },
                      {
                        name: 'username',
                        label: 'Username',
                        Component: StringInput,
                        required: true,
                        validators: [
                          {
                            validator: Boolean,
                            failMessage: 'Please enter a username',
                          },
                        ],
                      },
                      {
                        name: 'password',
                        label: 'Password (minimum 6 characters)',
                        Component: StringInput,
                        required: true,
                        validators: [
                          {
                            validator: Boolean,
                            failMessage: 'Please enter a password',
                          },
                        ],
                      },
                      {
                        name: 'dob',
                        label: 'Date of birth',
                        Component: DateInput,
                        required: true,
                        validators: [
                          {
                            validator: Boolean,
                            failMessage: 'Please enter a date of birth',
                          },
                        ],
                      },
                      {
                        name: 'gender',
                        label: 'Gender',
                        Component: GenderSelector,
                        required: true,
                        validators: [
                          {
                            validator: Boolean,
                            failMessage: 'Please choose a gender',
                          },
                        ],
                      },
                      {
                        name: 'school',
                        label: 'School',
                        Component: StringInput,
                        disabled: false,
                        required: true,
                        validators: [
                          {
                            validator: Boolean,
                            failMessage: 'Please enter a school name',
                          },
                        ],
                      },
                    ]}
                  />
                </div>
              )
            })
          }}
        >
            Add New Student
        </PrimaryClickableButton>
      </div>
      {
        modelContent ? <Modal onClose={() => setModalContent(null)} title={modelContent.title} content={modelContent.content} /> : null
      }
      {
        students.length > 0 ?
          (
            <select
              className="w-full cursor-pointer appearance-none rounded border bg-gray-100 p-6 leading-tight text-gray-700 shadow focus:outline-none focus:ring"
              value={value?._id}
              onChange={handle_change}
              disabled={disabled}
            >
              {students.map(student =>
                <option
                  key={student._id}
                  value={student._id}
                >
                  {student.first_name}
                </option>
              )}
            </select>
          ) :
          null
      }

    </div>

  )
})
