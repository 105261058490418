import {applySnapshot, flow, getRoot, Instance, SnapshotOut, types} from 'mobx-state-tree'
import {Auth, Hub, Storage} from 'aws-amplify'

import {Store} from 'types/store'
import {getMe} from 'util/api/profile/me'
import {configureAuth, loadRole, loadUsername, saveRole, saveUsername} from 'util/auth/amplify'
import {clear_cookies, Roles} from 'util/auth/helper'
import {logError} from 'util/helpers/logError'

export const RolesType = types.enumeration(Object.values(Roles))

export const UserModel = types.model({
  uid: types.string,
  _id: types.maybe(types.string),
  displayName: types.maybe(types.string),
  email: types.maybe(types.string),
  role: RolesType,
  profile_picture: types.maybe(types.string),
  student_has_parent: types.maybe(types.boolean)
})
export type UserModel = Instance<typeof UserModel>

export const AuthModel = types.model({
  user: types.maybe(UserModel),
  role: types.maybe(RolesType),
  username: types.maybe(types.string),
  in_progress: types.boolean,
})
  .volatile(() => ({
    unsubscribe: () => { return }
  }))
  .actions(self => ({
    clear_user: () => {
      self.user = undefined
    },
    update_me: flow(function*() {
      self.in_progress = true
      try {
        const loggedInUser = yield getMe()

        const user = {
          uid: loggedInUser.uid,
          _id: loggedInUser.user_data?._id,
          displayName: loggedInUser.user_data?.display_name,
          email: loggedInUser.user_data?.email,
          username: loggedInUser.username,
          role: loggedInUser.role,
          profile_picture: loggedInUser.user_data?.profile_picture,
          student_has_parent: loggedInUser.user_data?.student_has_parent
        }
        if( self.user === undefined ){
          self.user = UserModel.create(user)
        }
        else {
          applySnapshot(self.user, user)
        }
      } catch (e) {
        logError(e)
        console.log('Auth...error fetching me:' + JSON.stringify(e))
        self.user = undefined

        const rootStore = getRoot<Instance<typeof Store>>(self)
        rootStore.reset()
        clear_cookies()
        yield Auth.signOut()

      } finally {
        self.in_progress = false
      }
    }),
    change_role: (role:SnapshotOut<typeof RolesType>) => {
      saveRole(role)
      self.role = role
      if( role ) {
        configureAuth(self.role)
      }
    },
    set_username: (username:string) => {
      saveUsername(username)
      self.username = username
    },
    set_in_progress: (state:boolean) => {
      self.in_progress = state
    }
  }))
  .actions(self => ({
    initialise: flow(function*() {
      console.log('Auth...initialise')
      self.unsubscribe()
      self.role = loadRole()
      self.username = loadUsername()

      if (self.role) {
        configureAuth(self.role)
        Storage.configure({
          customPrefix: {
            public: '',
            protected: 'protected/',
            private: 'private/'
          }
        })
        yield self.update_me()
      } else {
        self.user = undefined
        self.in_progress = false
      }
      const tokenwatch = Hub.listen('auth', (data) => {
        console.log('payload', data.payload.event)
        switch (data.payload.event) {
          case 'tokenRefresh': {
            console.log('useCognitoAuth...New token has been processed')
            break
          }
          case 'tokenRefresh_failure': {
            console.log('useCognitoAuth...Failed to retrieve new token')
            self.clear_user()
            break
          }
          case 'confirmSignUp': {
            self.set_in_progress(true)
            break
          }
          case 'signIn': {
            self.set_username(undefined)//Resets temp field used to pass childs username from parent
            self.update_me()
          }

        }
      })
      self.unsubscribe = () => {
        tokenwatch()
      }
    }),
    set_profile_picture: (profile_picture: string) => {
      self.user.profile_picture = profile_picture
    },
  }))

export type AuthModel = Instance<typeof AuthModel>
