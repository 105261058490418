import {Instance, SnapshotOut, types} from 'mobx-state-tree'

import {Student} from 'common/types/student'
import {NonEmptyString} from 'common/types/basic/non_empty_string'
import {Email} from 'common/types/basic/email'
import {Country, CountryEnum} from 'common/types/basic/country'

export const CreateParentRequest = types.model('CreateParentRequest', {
  first_name: types.string,
  last_name: types.string,
  country: types.optional(Country, CountryEnum['United Kingdom']),
  postcode: types.maybeNull(types.string),
  phone_number: types.maybeNull(types.string),
  email_opt_in: types.boolean,
})
export type CreateParentRequest = Instance<typeof CreateParentRequest>

export const Parent = types
  .model({
    _id: NonEmptyString,
    uid: NonEmptyString,
    email: Email,
    students: types.array(Student),
    first_name: types.maybe(types.string),
    last_name: types.maybe(types.string),
    country: types.optional(Country, CountryEnum['United Kingdom']),
    postcode: types.maybeNull(types.string),
    phone_number: types.maybeNull(types.string),
    email_opt_in: types.maybe(types.boolean),
  })
export type Parent = Instance<typeof Parent>

export const ParentPostRequest = types.model(
  'Parent',
  {
    body: CreateParentRequest
  }
)
export type ParentPostRequest = SnapshotOut<typeof ParentPostRequest>

export const GetParentRequest = types.model(
  'GetParentRequest', {
  }
)
export type GetParentRequest = SnapshotOut<typeof GetParentRequest>
