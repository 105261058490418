import React, {useCallback, useEffect} from 'react'
import {flatten} from 'lodash'
import {observer} from 'mobx-react-lite'
import {Instance} from 'mobx-state-tree'

import {ResponsiveTable} from 'components/ResponsiveTable'
import {BluePill, GreenPill, RedPill} from 'components/stats/StatsView/TableRecord'
import {ITopicRow} from 'components/progress/progress'
import {TopicBreakdown} from 'components/progress/Components/TopicsTable/TopicBreakdown'
import {BadDetail, GoodDetail, NormalDetail} from 'components/progress/Components/TopicsTable/PerformanceDetail'
import {AddToMixButton} from 'components/progress/Components/TopicsTable/AddToMixButton'
import {PerformanceRowDetail} from 'components/progress/Components/TopicsTable/PerformanceRowDetail'
import {Stats, SubjectStats} from 'common/types/student/current_stats'
import {Mix_Extended} from 'types/student/mixes'
import {AdminSubject} from 'common/types/subject/subject'
import {SubjectsStore} from 'types/subjects'
import {PrimaryClickableButton} from 'components/ClickableButton'
import {Icon} from 'generic_components/Icon'
import {useUser} from 'context/userContext'
import {Roles} from 'util/auth/helper'
import {sortSubjectsWithProvisionalScores} from 'util/helpers/sortSubjectsWithProvisionalScores'
import {isEndcodeLive} from 'util/helpers/isEndcodeLive'
import {useStore} from 'types/store'

export const get_topic_stats = (subject_stats: SubjectStats): Stats[] => {
  return subject_stats
    .themes
    .map(
      theme =>
        theme
          .topics
    )
    .flat() as Stats[]
}

export const get_endcodes_stats = (subject_stats: SubjectStats): Stats[] => {
  return subject_stats
    .themes
    .map(
      theme =>
        theme
          .topics
          .map(
            topic => topic.endcodes
          )
    )
    .flat()
    .flat() as Stats[]
}

interface ITopicsTableProps {
    subject_stats: SubjectStats,
    subjectsStore: SubjectsStore,
    subject_score: number,
    toggleInMix: (subject: Instance<typeof AdminSubject>, already_in_mix: boolean) => void,
    currentMix: Instance<Mix_Extended>,
    isShowingAdvanced: boolean,
    student_id: string,
}

export const TopicsTable = observer(({subject_stats, subjectsStore, subject_score, toggleInMix, currentMix, isShowingAdvanced, student_id}: ITopicsTableProps): React.JSX.Element => {
  const {
    actions_store,
    student_trends_store
  } = useStore()
  const { user } = useUser()

  const topics_with_scores = get_topic_stats(subject_stats)
  const endcodes_with_scores = get_endcodes_stats(subject_stats)

  useEffect(() => {
    actions_store.get_actions('student', student_id, 'watch_skill_videos')
      .then(() => student_trends_store.get_trend_stats(student_id))
  }, [student_id])

  const endcodes_with_watched_videos = (actions_store.actions?.get(student_id) ?? [])
    .filter(action => action.label === 'watch_skill_videos')
    .map(action => action.value)

  const subject_data = subjectsStore
    .topics()
    .filter((topic: AdminSubject) => {
      if (topic.status !== 'live') {
        return false
      }

      const endcodes_for_topic = subjectsStore.endcodes().filter(endcode => endcode.parent._id === topic._id)
      return Boolean(
        endcodes_for_topic
          .some((endcode) => {
            const endcode_score = endcodes_with_scores.find(e => e?.subject?._id === endcode._id)
            return endcode.status === 'live' && isEndcodeLive(subject_stats?.is_ahead, endcode_score?.score, subject_score, endcode)
          })
      )
    })
    .map((topic) => {
      const endcodes_for_topic = subjectsStore.endcodes().filter(endcode => endcode.parent._id === topic._id)
      const topic_score = topics_with_scores.find(topic_score => topic_score?.subject?._id === topic._id)

      return ({
        ...topic_score,
        topic_name: topic.name,
        theme_name: topic.parent.name,
        subject: topic,
        parent_subject: topic.parent,
        endcodes:
            endcodes_for_topic
              .map((subject: AdminSubject): Stats => ({
                ...(endcodes_with_scores ?? []).find(endcode_stats => endcode_stats.subject?._id === subject._id),
                subject: subject,
                parent_subject: subject.parent,
              }) as Stats)
              .filter((endcode) => {
                const endcode_score = endcodes_with_scores.find(e => e?.subject?._id === endcode.subject._id)
                return isEndcodeLive(subject_stats?.is_ahead, endcode_score?.score, subject_score, endcode?.subject)
              })
      })
    })

  const worst_topic_ids = (flatten(subject_data) as unknown as ITopicRow[])
    .sort(sortSubjectsWithProvisionalScores(subject_score))
    .map((a) => a.subject._id)
    .slice(0, 3)

  const expanded_row = useCallback((row: AdminSubject) => (
    <TopicBreakdown
      topic={row}
      subject_score={subject_score}
      isShowingAdvanced={isShowingAdvanced}
      toggleInMix={toggleInMix}
      currentMix={currentMix}
      student_id={student_id}
      endcodes_with_watched_videos={endcodes_with_watched_videos}
    />
  ), [endcodes_with_watched_videos])

  return (
    <div className="mt-6 w-full overflow-x-auto">
      {/*<div className="p-4 bg-amber-200 rounded shadow mb-2">*/}
      {/*      (Debugging New Endcodes Since Starting):*/}
      {/*  {*/}
      {/*    (student_trends_store.new_endcodes_this_week as AdminSubject[])*/}
      {/*      .map((s, i) => (*/}
      {/*        <div key={i}>*/}
      {/*          <span className="font-bold">{s.parent.name}</span> - {s.description}*/}
      {/*        </div>*/}
      {/*      ))*/}
      {/*  }*/}
      {/*</div>*/}

      <ResponsiveTable
        isSortable={false}
        headers={[
          ...(
            isShowingAdvanced ?
              [
                {
                  label: 'Score',
                  getter: row => {
                    if (row.score)
                      return row.score.toFixed(2)

                    return `(${((row.subject.min_score + row.subject.max_score) / 2).toFixed(2)})`
                  }
                },
                {
                  label: 'Min',
                  key: 'subject.min_score'
                },
                {
                  label: 'Max',
                  key: 'subject.max_score'
                }
              ] :
              []
          ),
          {
            label: 'Theme',
            getter: (row: ITopicRow) => (
              <PerformanceRowDetail
                row={row}
                subject_score={subject_score}
                worst_ids={worst_topic_ids}
                good={<GoodDetail>{row.theme_name}</GoodDetail>}
                bad={<BadDetail>{row.theme_name}</BadDetail>}
                normal={<NormalDetail>{row.theme_name}</NormalDetail>}
              />
            )
          },
          {
            label: 'Topic',
            getter: (row: ITopicRow) => (
              <PerformanceRowDetail
                row={row}
                subject_score={subject_score}
                worst_ids={worst_topic_ids}
                good={<GoodDetail>{row.topic_name}</GoodDetail>}
                bad={<BadDetail>{row.topic_name}</BadDetail>}
                normal={<NormalDetail>{row.topic_name}</NormalDetail>}
              />
            )
          },
          {
            label: '',
            getter: (row: ITopicRow) => {
              return (
                <div className="flex space-x-2">
                  <PerformanceRowDetail
                    row={row}
                    subject_score={subject_score}
                    worst_ids={worst_topic_ids}
                    good={<GreenPill label={<span><i className="fa-dumbbell fa-solid mr-1"/>Strength</span>} />}
                    bad={<RedPill label={<span><i className="fa-magnifying-glass fa-solid mr-1"/>Focus</span>} />}
                    normal={null}
                  />
                  {
                    user.role === Roles.STUDENT &&
                    row
                      .endcodes
                      .some((endcode) => {
                        const subject: AdminSubject = endcode.subject
                        return !endcodes_with_watched_videos.includes(subject._id) &&
                            subject.help_videos.length > 0 &&
                            student_trends_store.new_endcodes_this_week.find(new_endcode => new_endcode._id === subject._id)
                      }) ?
                      <BluePill label={<span><i className="fa-video fa-solid"/>&nbsp;New Help Videos</span>} /> :
                      null
                  }
                </div>
              )
            }
          },
          {
            getter: (row: ITopicRow) => (
              <div className="flex justify-end space-x-2">
                {
                  user.role === Roles.STUDENT ?
                    (
                      <PrimaryClickableButton
                        href={`/game/step1_setup?topic=${row.subject._id}`}
                        pulse={worst_topic_ids.includes(row.subject._id)}
                      >
                      Play
                        <Icon icon={'fa-gamepad fa-solid ml-1'}/>
                      </PrimaryClickableButton>
                    ) :
                    (
                      <AddToMixButton
                        currentMix={currentMix}
                        row={row}
                        toggleInMix={toggleInMix}
                      />
                    )
                }
              </div>
            )
          },
        ]}
        expanded_row={expanded_row}
        rows={
          subject_data
            .slice()
            .sort(sortSubjectsWithProvisionalScores(subject_score))
        }
      />
    </div>
  )
})
