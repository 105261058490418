import {Instance, types} from 'mobx-state-tree'

import {Student} from 'common/types/student'
import {Teacher} from 'common/types/teacher'
import {Subscription} from 'common/types/subscription'
import {Class} from 'common/types/class'

export const UpdateSchool = types.model({
  name: types.maybe(types.string),
  address: types.maybe(types.string),
  postcode: types.maybe(types.string),
  type: types.maybe(types.string),
  phone_number: types.maybe(types.string),
})
export type UpdateSchool = Instance<typeof UpdateSchool>

export const SchoolUpdatePostRequest = types.model({
  body: UpdateSchool
})
export type SchoolUpdatePostRequest = Instance<typeof SchoolUpdatePostRequest>

export const SchoolUpdatePostResponse = types.model({
  success: types.boolean
})
export type SchoolUpdatePostResponse = Instance<typeof SchoolUpdatePostResponse>


export const SchoolGetRequest = types.model({
  params: types.model({
    school_id: types.string
  })
})
export type SchoolGetRequest = Instance<typeof SchoolGetRequest>

export const SchoolGetResponse = UpdateSchool.named('SchoolGetResponse').props({
  _id: types.identifier,
  email: types.string,
  teachers: types.array(Teacher),
  students: types.array(Student),
  classes: types.array(Class),
  subscriptions: types.array(Subscription),
  created_at: types.string,
  updated_at: types.string,
})
export type SchoolGetResponse = Instance<typeof SchoolGetResponse>

export const School = SchoolGetResponse
export type School = Instance<typeof School>
