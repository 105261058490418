import {Instance, types} from 'mobx-state-tree'

import {BaseSubject} from './base'

import {PositiveInteger} from 'common/types/basic/positive_int'
import {Score} from 'common/types/question/score'
import {SubjectId} from 'common/types/subject/id'
import {ThemeTree} from 'common/types/subject/theme'
import {StudentId} from 'common/types/student/id'

export const SubjectTree = BaseSubject
  .named('SubjectTree')
  .props({
    themes: types.array(ThemeTree),
  })
export type SubjectTree = Instance<typeof SubjectTree>

export const SubjectForest = types.array(SubjectTree)

const SubjectStatus = types.enumeration(['live', 'draft'])
export type SubjectStatus = Instance<typeof SubjectStatus>

export const AdminSubject = BaseSubject
  .named('AdminSubject')
  .props({
    status: SubjectStatus,
    name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
    min_score: types.maybeNull(Score),
    max_score: types.maybeNull(Score),
    standard_level: types.maybeNull(Score),
    // question_distribution: ScoreDistribution,
    question_count: types.maybeNull(PositiveInteger),
    endcode_count: types.maybeNull(PositiveInteger),
    topic_count: types.maybeNull(PositiveInteger),
    theme_count: types.maybeNull(PositiveInteger),
    parent: types.late(() => types.maybeNull(types.reference(AdminSubject))),
    type: types.string,
    priority: types.maybeNull(types.string),
    help_videos: types.array(types.string),
    min_school_year: types.maybe(types.number),
    max_school_year: types.maybe(types.number)
  })
export type AdminSubject = Instance<typeof AdminSubject>

export const AdminSubjects = types.array(AdminSubject)
export type AdminSubjects = Instance<typeof AdminSubjects>

export const SubjectsGetRequest = types.model({
})
export type SubjectsGetRequest = typeof SubjectsGetRequest

export const SubjectGetRequest = types.model({
  params: types.model({
    subject_id: SubjectId
  })
})
export type SubjectGetRequest = typeof SubjectGetRequest

export const AchievableSubjectRequest = types.model({
  query: types.model({
    student: StudentId
  })
})
export type AchievableSubjectRequest = typeof AchievableSubjectRequest

export const AchievableSubjectResponse = types.array(BaseSubject)
export type AchievableSubjectResponse = typeof AchievableSubjectResponse

export const SubjectPostRequest = types.model({
  body: AdminSubject
})
export type SubjectPostRequest = typeof SubjectPostRequest
