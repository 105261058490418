import { DateTime } from 'luxon'
import { flow, Instance, types } from 'mobx-state-tree'

import { postNewCompetition } from 'util/api/competitions/add_competition'
import { PostNewCompetitionResponse } from 'common/types/competition'
import { getCompetitionDetails } from 'util/api/competitions/get_competition_details'
import { DetailResult } from 'common/types/competition/details'

export const TeacherCompetitionStore = types.model({
  is_loading: types.boolean,
  results: types.array(DetailResult),
  last_updated: types.maybe(types.string),
}).actions(self => ({
  add_competition: flow(function* (competition) {
    self.is_loading = true
    const result = yield postNewCompetition({
      body: competition
    })
    self.is_loading = false

    return result as PostNewCompetitionResponse
  }),
  get_details: flow(function*(competition_id: string) {
    self.is_loading = true
    const { results } =  yield getCompetitionDetails({ params: { competition_id } })
    self.results = results
    self.last_updated = DateTime.now().toISO()


    self.is_loading = false
  }),
  clear_previous: () => {
    self.last_updated = undefined
    self.is_loading = false
    self.results.clear()
  }
}))

export type TeacherCompetitionStore = Instance<typeof TeacherCompetitionStore>
