import {flow, Instance, types} from 'mobx-state-tree'

import {getTranslatedText} from 'util/api/translate'
import {TranslationRequest} from 'common/types/translation/translation'
import {logError} from 'util/helpers/logError'

export const TranslationStore = types.model({
  language: types.maybe(types.string),
  alt_language: types.maybe(types.string),
  voice: types.maybe(types.string),
}).actions(
  self => ({
    set_language(lang: string) {
      self.language = lang
    },
    set_alt_language(lang: string) {
      self.alt_language = lang
    },
    set_voice(voice: string) {
      self.voice = voice
    },
  })
)
  .actions(self => ({
    get_translation: flow(function* (translationDetails: TranslationRequest) {
      try {
        const response = yield getTranslatedText({body: translationDetails})
        return response.body.translated
      } catch (error) {
        logError(error)
        console.error('Error fetching translation:', error)
      }
    }),
  }))
export type TranslationStore = Instance<typeof TranslationStore>
