import { api } from 'util/api'
import {
  CreateBasketRequest,
  CreateBasketResponse,
  DeleteSubscriptionResponse,
  GetSubscriptionsRequest,
  GetSubscriptionsResponse,
  RescheduleSubscriptionRequest,
  SubscriptionByIdRequest,
  SubstituteSubscriptionRequest,
} from 'common/types/subscription'
import { ModifySessionResponse } from 'common/types/session'
import {CreateFreeTrialRequest, CreateFreeTrialResponse} from 'common/types/subscription/free_trial'
import {EditSubscriptionRequest, EditSubscriptionResponse} from 'common/types/subscription/edit_subscription'

export const createBasket = api(CreateBasketRequest, CreateBasketResponse, 'POST', 'subscription/create_basket')
export const createFreeTrial = api(CreateFreeTrialRequest, CreateFreeTrialResponse, 'POST', 'subscription/create_free_trial')
export const editSubscription = api(EditSubscriptionRequest, EditSubscriptionResponse, 'POST', 'subscription/edit_subscription')

const _deleteSubscription = api(SubscriptionByIdRequest, DeleteSubscriptionResponse, 'DELETE', 'subscription/subscription_id')
export const deleteSubscription = async (subscription_id) => {
  const data = await _deleteSubscription({ params: {subscription_id}})
  return data
}

const _listSubscriptions = api(GetSubscriptionsRequest, GetSubscriptionsResponse, 'GET', 'subscription/list')
export const listSubscriptions = async () => {
  const data = await _listSubscriptions()
  return data
}

export const rescheduleSubscription = api(RescheduleSubscriptionRequest, ModifySessionResponse, 'POST', 'subscription/subscription_id/reschedule')
export const substituteSubscription = api(SubstituteSubscriptionRequest, ModifySessionResponse, 'POST', 'subscription/subscription_id/substitute')
