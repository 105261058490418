import React, {useCallback, useState} from 'react'
import {observer} from 'mobx-react-lite'
import classNames from 'classnames'
import ReactPlayer from 'react-player'

import {ClickableButton, SecondaryClickableButton} from 'components/ClickableButton'
import {SessionOptionEnum, SessionOptions} from 'common/types/session/options'
import {StringSelector} from 'generic_components/MstFormStringSelector'
import {BlockLoader} from 'generic_components/BlockLoader'
import {useStore} from 'types/store'
import {TierOptionEnum} from 'types/subscriptions'
import {Modal} from 'components/modal/modal'

export const freeWeekCode = 'GETSTARTED'

const allFeatures = [
  'Automatically personalised content',
  'Smart AI Question Generation',
  'Competitions and Tokens',
  'Focus Areas and Gaps Highlighted',
  'Real-time Progress Tracking',
  'Review and Flag Capability',
  'Rewards & Incentives',
  'Proven Focus Tutoring Concept',
  'Dedicated 1-to-1 Expert Tutors',
  'External Discipline and Motivation',
  'Flexible Session Lengths',
]

const freeFeatures = [
  'Automatically personalised content',
  'Smart AI Question Generation',
  'Competitions and Tokens'
]

const premiumFeatures= [
  'Automatically personalised content',
  'Smart AI Question Generation',
  'Competitions and Tokens',
  'Focus Areas and Gaps Highlighted',
  'Real-time Progress Tracking',
  'Review and Flag Capability',
  'Rewards & Incentives',
]

export interface ITier {
  name: string
  value: SessionOptionEnum
  showIf?: (
    arg: {
      shouldShow?: boolean
    }
  ) => boolean
  BadgeComponent: (props) => JSX.Element
  PricingComponent: (props) => JSX.Element
  TierCardActions: (props) => JSX.Element
  FeaturesComponent: () => JSX.Element
}

export const tiers: ITier[] = [
  {
    name: TierOptionEnum.PlytimeFree,
    showIf: ({ shouldShow }) => shouldShow,
    value: SessionOptionEnum.FreeTrial,
    BadgeComponent: () => (
      <span
        className='mb-5 inline-block whitespace-nowrap rounded bg-blue-500 p-2 text-center align-baseline text-sm font-bold leading-none'
      >
        Plus Free Plytime Premium for 1 Week
      </span>
    ),
    PricingComponent: () => (
      <h2 className='py-4 text-2xl'>
        £0 <span className='text-sm text-gray-400'> forever</span>
      </h2>
    ),
    TierCardActions: ({onClick}) => (
      <ClickableButton
        className='w-full bg-blue-500 hover:bg-blue-700'
        onClick={onClick}
      >
        Get Free
      </ClickableButton>
    ),
    FeaturesComponent: () => <Features features={freeFeatures} />
  },
  {
    name: TierOptionEnum.PlytimePremium,
    value: SessionOptionEnum.PlatformOnly,
    BadgeComponent: ({hasSchoolSubscription}) => (
      <span
        className='mb-5 inline-block whitespace-nowrap rounded bg-green-500 p-2 text-center align-baseline text-sm font-bold leading-none'
      >
        {hasSchoolSubscription ? 'Provided by School' : 'Free for 1 Week'}
      </span>
    ),
    PricingComponent: ({cost}) => (
      <h2 className='py-4 text-2xl'>
        £{cost}<span className='pr-2 text-sm text-gray-400'>/week</span>
        <span className="border-l border-gray-100 pl-2 text-sm text-gray-100">Cancel anytime*</span>
      </h2>
    ),
    TierCardActions: ({onClick, hasSchoolSubscription}) => (
      <ClickableButton
        className='w-full bg-green-500 hover:bg-green-700'
        onClick={onClick}
        disabled={hasSchoolSubscription}
      >
        {hasSchoolSubscription ? 'Already Provided by School' : 'Get Premium Only'}
      </ClickableButton>
    ),
    FeaturesComponent: () => <Features features={premiumFeatures} />
  },
  {
    name: TierOptionEnum.WithFocusTutoring,
    value: SessionOptionEnum.OneFifteenMin,
    BadgeComponent: ({hasSchoolSubscription}) => (
      <span
        className='mb-5 inline-block whitespace-nowrap rounded bg-yellow-500 p-2 text-center align-baseline text-sm font-bold leading-none'
      >
        {hasSchoolSubscription ? 'Plytime Premium provided by School' : 'Incl. Plytime Premium (Free for 1 Week)'}
      </span>
    ),
    PricingComponent: ({cost}) => (
      <h2 className='py-4 text-2xl'>
        <span className='text-sm text-gray-400'>From </span>
        £{cost}
        <span className='pr-2 text-sm text-gray-400'>/week</span>
        <span className="border-l border-gray-100 pl-2 text-sm text-gray-100">Cancel anytime*</span>
      </h2>
    ),
    TierCardActions: ({onSelect, onScrollBtnClick}) => (
      <TutoringTierActions onSelect={onSelect} onClick={onScrollBtnClick} />
    ),
    FeaturesComponent: () => <Features features={allFeatures} />
  }
]

const SessionOptionsSelector = StringSelector(
  (SessionOptions as any)
    ._types
    .map(({value}) => {
      if (value === SessionOptionEnum.OneFifteenMin) {
        return {
          key: (
            <span className="flex items-center justify-center">
                15 Minutes
              <span className='ml-auto inline-block whitespace-nowrap rounded bg-purple-500 p-2 text-center text-sm font-bold leading-none text-gray-100'>
                <i className="fa-solid fa-coins mr-2"></i>
                    Lowest Cost
              </span>
            </span>
          ),
          value
        }
      }
      if (value === SessionOptionEnum.TwoFifteenMin) {
        return {
          key: (
            <span className="flex items-center justify-center">
                30 Minutes
              <span className='ml-auto inline-block whitespace-nowrap rounded bg-blue-500 p-2 text-center text-sm font-bold leading-none text-gray-100'>
                <i className="fa-solid fa-hourglass-half mr-2"></i>
                    Equivalent to an hour
              </span>
            </span>
          ),
          value
        }
      }
      if (value === SessionOptionEnum.ThreeFifteenMin) {
        return {
          key: (
            <span className="flex items-center justify-center">
                45 Minutes
              <span className='ml-auto inline-block whitespace-nowrap rounded bg-green-500 p-2 text-center text-sm font-bold leading-none text-gray-100'>
                <i className="fa-solid fa-flask mr-2"></i>
                    Research Backed
              </span>
            </span>
          ),
          value
        }
      }
      if (value === SessionOptionEnum.FourFifteenMin) {
        return {
          key: (
            <span className="flex items-center justify-center">
                1 Hour
              <span className='ml-auto inline-block whitespace-nowrap rounded bg-yellow-500 p-2 text-center text-sm font-bold leading-none text-gray-100'>
                <i className="fa-solid fa-rocket mr-2"></i>
                    Accelerated
              </span>
            </span>
          ),
          value
        }
      }
      if (value === SessionOptionEnum.FiveFifteenMin) {
        return {
          key: '1 Hour and 15 Minutes',
          value
        }
      }
      if (value === SessionOptionEnum.SixFifteenMin) {
        return {
          key: '1 Hour and 30 Minutes',
          value
        }
      }
      if (value === SessionOptionEnum.SevenFifteenMin) {
        return {
          key: '1 Hour and 45 Minutes',
          value
        }
      }

      return undefined
    })
    .filter(Boolean)
)

interface ITutoringTierActionsProps {
  onClick: () => void
  onSelect: () => void
}

const TutoringTierActions = observer(({ onSelect, onClick }: ITutoringTierActionsProps): JSX.Element => {
  const {
    subscription_wizard: {selected_session_option, existing_subscription, update_session_count}
  } = useStore()

  const selectedSessionHasTutoring = selected_session_option !== SessionOptionEnum.FreeTrial && selected_session_option !== SessionOptionEnum.PlatformOnly

  const existingSubscriptionHasTutoring = existing_subscription &&
    existing_subscription.session_option !== SessionOptionEnum.PlatformOnly &&
    existing_subscription.session_option !== SessionOptionEnum.FreeTrial

  return (
    <>
      <HelpVideoComponent />
      <SessionOptionsSelector
        value={selected_session_option}
        onChange={(e: any) => {
          update_session_count(e.target.value)
          onSelect()
        }}
        id="SessionOptionSelector"
      />
      {selectedSessionHasTutoring && <PriceWithDiscount />}
      {
        !existingSubscriptionHasTutoring && (
          <ClickableButton
            disabled={!selectedSessionHasTutoring}
            onClick={async () => {
              await onSelect()
              onClick()
            }}
            className="mt-4 w-full bg-yellow-500 hover:bg-yellow-700"
          >
            Choose Preferred Times
          </ClickableButton>
        )
      }
    </>
  )
})

interface IFeaturesProps {
    features: string[]
}

const Features = ({features}: IFeaturesProps): JSX.Element => (
  <ul className="my-6 w-full list-none p-0 text-left">
    {
      allFeatures.map((feature, index) => (
        <li key={index} className="my-1 flex items-center gap-4">
          {features.includes(feature) ?
            (
              <i className="fa-solid fa-check text-green-500"></i>
            ) :
            (
              <i className="fa-solid fa-xmark text-gray-400"></i>
            )}
          <span className={classNames(features.includes(feature) ? 'text-white' : 'text-gray-400')}>
            {feature}
          </span>
        </li>
      ))}
  </ul>
)

const PriceWithDiscount = observer((): JSX.Element => {
  const {
    subscription_wizard: {
      cost,
      product_cost,
      platform_only_cost,
      has_school_subscription,
      selected_session_option,
      sessions_time_total,
      isLoading
    }
  } = useStore()

  if (
    isLoading &&
      selected_session_option !== SessionOptionEnum.FreeTrial &&
      selected_session_option !== SessionOptionEnum.PlatformOnly
  ) {
    return (
      <div className="mt-6">
        <BlockLoader />
      </div>
    )
  }

  return (
    <div className="pt-6">
      <div className="text-gray-200">
        {has_school_subscription ? 'Normally Costing' : 'Total'} (incl. Plytime Premium):
        <span className="float-right font-bold">£{cost().toFixed(2)}</span>
      </div>
      {
        has_school_subscription && (
          <>
            <div className="flex items-baseline justify-between pt-2 text-gray-200">
              Discount applied (Plytime Premium provided by school):
              <span className="float-right ml-5 font-bold text-red-500">£{platform_only_cost.toFixed(2)}</span>
            </div>
            <div className="pt-3 text-gray-200">
              Total (For {sessions_time_total()} Per Week):
              <span className="float-right font-bold">
                £{product_cost(selected_session_option as SessionOptionEnum)}
              </span>
            </div>
          </>
        )
      }
    </div>
  )
})

const SubscriptionVideo = ({ video_url }) => {
  return (
    <div className="mx-auto w-fit">
      <ReactPlayer
        url={video_url}
        controls={true}
      />
    </div>
  )
}

const HelpVideoComponent = () => {
  const [watchHelpVideo, setWatchHelpVideo] = useState<boolean>(false)

  const setVideoViewAction = useCallback((state: boolean) => () => {
    setWatchHelpVideo(state)
  }, [])

  return (
    <>
      {
        watchHelpVideo && (
          <Modal
            onClose={setVideoViewAction(false)}
            title={'Choosing Sessions'}
            content={<SubscriptionVideo video_url={'https://vimeo.com/963085375/2330116b8b'} />}
          />
        )
      }

      <div className="text-gray-200">
          How much 1-to-1 support do you need per week?
        <SecondaryClickableButton
          className="my-2 w-full"
          onClick={setVideoViewAction(true)}
        >
          Watch the Video <i className="fa-solid fa-video ml-2"></i>
        </SecondaryClickableButton>
      </div>
    </>
  )
}
