import {Instance, types} from 'mobx-state-tree'

export const AnswerTypes = types.string
export type AnswerTypes = typeof AnswerTypes

export const AnswerPart = types
  .model('AnswerPart', {
    ref: types.optional(types.string, ''), //TODO no defaults
    values: types.optional(types.array(AnswerTypes), ['']),
  })
export type AnswerPart = Instance<typeof AnswerPart>

export const AllowedAnswer = types.array(AnswerPart)
export type AllowedAnswer = Instance<typeof AllowedAnswer>

export const AllowedAnswers = types.array(AllowedAnswer)
export type AllowedAnswers = Instance<typeof AllowedAnswers>

export const SubmittedAnswer = types.model('SubmittedAnswer', {
  ref: types.string,
  value: types.string,
})
export type SubmittedAnswer = Instance<typeof SubmittedAnswer>

export const SubmittedAnswerMap = types.map(SubmittedAnswer)
export type SubmittedAnswerMap = Instance<typeof SubmittedAnswerMap>
