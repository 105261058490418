import {FC} from 'react'
import {observer} from 'mobx-react-lite'

import {AllowedAnswers} from 'common/types/question/answer/answer'
import {useStore} from 'types/store'
import {InputProps} from 'components/question/inputProps'
import {Input} from 'generic_components/AnswerInputContainers'

export const get_formatted_correct_answer = (correct_answers: AllowedAnswers, answerIndex: number, ref: string): string => {
  if (correct_answers.length === 0) {
    return
  }

  const correct_answer = get_correct_answer(correct_answers, answerIndex, ref)

  if (correct_answer.includes(';')) {
    const [min, max] = correct_answer.split(';')
    return `${min} to ${max}`
  }

  return correct_answer
}

const get_correct_answer = (correct_answers: AllowedAnswers, answerIndex: number, ref: string) => {
  if (ref !== '') {
    return correct_answers
      .flatMap(answers => answers)[answerIndex]
      .values[0]
  }

  return correct_answers
    .flatMap(answers => answers)
    .filter(answerPart => answerPart.ref === ref)[answerIndex]
    .values[0]
}

export const InputComponent: FC<InputProps> = observer(({
  element,
  handleChange,
  answer_decision,
  correct_answers,
  value,
  element_id,
  answerIndex
}) => {
  const {game, auth: {user}} = useStore()

  const size = value.length <= 3 ? 4 : value.length + 1

  const correct_answer = get_formatted_correct_answer(correct_answers, answerIndex, element.ref)

  return (
    <div className="flex w-full flex-col 2xl:w-auto">
      <Input
        placeholder={element.placeholder}
        autoFocus={element.autoFocus}
        id={element_id}
        onChange={handleChange}
        answer_decision={answer_decision}
        readOnly={Boolean(answer_decision)}
        value={value}
        size={size}
        onKeyPress={e => {
          if (e.key === 'Enter' && !game.over) {
            game.submit_answer(user._id)
          }
        }}
      />
      {answer_decision !== 'CORRECT' && correct_answers.length > 0 &&
        <Input
          placeholder={element.placeholder}
          value={correct_answer || 'error'}
          answer_decision="CORRECT"
          readOnly={true}
          size={size}
        />
      }
    </div>
  )
})
InputComponent.displayName = 'InputComponent'