import {StringSelector} from 'generic_components/MstFormStringSelector'
import {Gender} from 'common/types/basic/gender'

export const GenderSelector = StringSelector([
  {
    key: 'Please choose a gender',
    value: '',
  },
  ...(Gender as any)._types
])
