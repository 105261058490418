import React, {FC, useEffect, useRef, useState} from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react-lite'
import {PresenceChannel} from 'pusher-js'
import {useInterval} from 'usehooks-ts'

import {Props} from './props'

import {useUser} from 'context/userContext'
import {Icon} from 'generic_components/Icon'
import {DayOrTime} from 'components/notes/common'
import {Roles} from 'util/auth/helper'
import {useStore} from 'types/store'
import {BlockLoader} from 'generic_components/BlockLoader'
import {getPusher} from 'services/pusher'

export interface IChannelMember {
  id: string,
  info: {
    role: Roles
  }
}

export const NotesCard:FC<Props> = observer(({ shouldShowCloseButton, student, subject }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { messagesStore } = useStore()

  const { user } = useUser()

  const listref = useRef<HTMLDivElement>()

  const [isChatVisible, setIsChatVisible] = useState(true)
  const [message, set_message] = useState('')
  const [members, setMembers] = useState<IChannelMember[]>([])

  const get_members = () => {
    getPusher().then(pusher => {
      const channel = pusher.channel(`presence-${student}-${subject}`) as PresenceChannel
      const members = []
      if (channel) {
        channel.members.each(member => members.push(member))
        setMembers(members)
      }

      return true
    })
  }
  useEffect(() => {
    messagesStore.set_message_window({
      subject_id: subject,
      student_id: student
    })

    messagesStore.register_websocket_listener(subject, student)
      .then(() => messagesStore.update_read_timestamp(subject, student))
      .then(() => messagesStore.list_notes(subject, student))
      .then(() => messagesStore.get_unread_notes(subject, student))
      .then(() => get_members())
      .then(() => setIsLoading(false))

    return () => {
      messagesStore.set_message_window(undefined)
    }
  }, [])
  useInterval(get_members, 5000)

  if (isLoading) {
    return <BlockLoader />
  }

  const recipient_name = user.role === Roles.STUDENT || user.role === Roles.PARENT ? 'Your Tutor' : 'Your Student'

  const recipient_online =
      user.role === Roles.TUTOR ?
        members.find(member => member.info.role === Roles.STUDENT) :
        members.find(member => member.info.role === Roles.TUTOR)

  return (
    <div className={
      classNames(
        'h-96 flex flex-col border shadow-md bg-white transition-all duration-100',
        isChatVisible ? 'mt-0' : 'mt-[330px]'
      )
    }>
      <div className="flex items-center justify-between border-b p-2">
        <ChatBoxHeader
          recipient_name={recipient_name}
          recipient_status={recipient_online ? 'Online' : 'Offline'}
        />
        {
          shouldShowCloseButton ?
            (
              <CloseChatWindowButton
                onClick={() => {setIsChatVisible(!isChatVisible)}}
                isChatVisible={isChatVisible}
              />
            ) :
            null
        }
      </div>

      <div
        ref={listref}
        className="flex flex-col-reverse overflow-y-auto p-4"
        onScroll={() => messagesStore.load_more_notes(subject, student, listref)}
      >
        {
          (
            messagesStore.messages?.notes ?? []
          )
            .map(n => n.sender_id === user._id ?
              <OutgoingMessage key={n._id} sender_name={n.sender_name} message={n.message} timestamp={n.timestamp}/> :
              <IncomingMessage key={n._id} sender_name={n.sender_name} message={n.message} timestamp={n.timestamp}/>
            )
        }
      </div>

      <div className="mt-auto flex w-full items-center border-t bg-gray-200 p-2">
        <div className="mx-2 w-full">
          <input
            className="w-full rounded border border-gray-200 p-4"
            type="text"
            value={message}
            placeholder="Aa"
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                messagesStore
                  .send_note(subject, student, message)
                  .then(() => set_message(''))
              }
            }}
            onChange={(event) => set_message(event.target.value)}
            autoFocus
          />
        </div>
        <div>
          <button
            onClick={() => messagesStore.send_note(subject, student, message).then(() => set_message(''))}
            className="inline-flex rounded-full p-2 hover:bg-indigo-50"
            type="button"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="size-6" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
})

const ChatBoxHeader = ({recipient_name, recipient_status}) => {
  return (
    <div className="flex items-center text-ellipsis">
      <Icon icon="fa-user fa-solid text-indigo-400 text-xl"/>
      <div className="pl-2">
        <div className="max-w-[180px] truncate font-semibold">
          {recipient_name}
        </div>
        {
          recipient_status === 'Online' ?
            <>
              <i className="fa-solid fa-signal text-green-600"></i>&nbsp;
              <h3 className="inline text-xs font-bold text-green-600">{recipient_status}</h3>
            </> :
            <>
              <i className="fa-solid fa-signal text-red-600"></i>&nbsp;
              <h3 className="inline text-xs font-bold text-red-600">{recipient_status}</h3>
            </>
        }
      </div>
    </div>
  )
}

const IncomingMessage = ({sender_name, message, timestamp}) => {
  return (
    <div className="mb-4 flex items-center">
      <div className="mr-4 flex flex-none flex-col items-center space-y-1">
        <Icon icon="fa-user fa-solid text-indigo-400"/>
        <span className="block text-xs">{sender_name}</span>
        <span className="text-xs">{DayOrTime(timestamp)}</span>
      </div>
      <div className="relative mb-2 max-w-md flex-1 whitespace-break-spaces rounded-lg bg-indigo-400 p-2 text-white">
        {message}
        <div
          className="absolute left-0 top-1/2 size-2 -translate-x-1/2 rotate-45 bg-indigo-400"></div>
      </div>
    </div>
  )
}

const OutgoingMessage = ({sender_name, message, timestamp}) => {
  return (
    <div className="mb-4 flex flex-row-reverse items-center">
      <div className="ml-4 flex flex-none flex-col items-center space-y-1">
        <Icon icon="fa-user fa-solid text-indigo-100"/>
        <span className="block text-xs">{sender_name}</span>
        <span className="text-xs">{DayOrTime(timestamp)}</span>
      </div>
      <div className="relative mb-2 max-w-md flex-1 whitespace-break-spaces rounded-lg bg-indigo-100 p-2 text-gray-800">
        <div>{message}</div>
        <div
          className="absolute right-0 top-1/2 size-2 translate-x-1/2 rotate-45 bg-indigo-100"></div>
      </div>
    </div>
  )
}

const CloseChatWindowButton = ({ onClick, isChatVisible }) => {
  return (
    <button className="rounded-full p-2 hover:bg-indigo-50" type="button" onClick={onClick}>
      {
        isChatVisible ?
          (
            <i className="fa-solid fa-arrow-down"></i>
          ) :
          (
            <i className="fa-solid fa-arrow-up"></i>
          )
      }
    </button>
  )
}
