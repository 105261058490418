import {api} from 'util/api'
import {AnswerStatsRequest, AnswerStatsResponse} from 'common/types/student/answer_stats'

export const getAnswerStats = async (student_id: string) => {
  return api(AnswerStatsRequest, AnswerStatsResponse, 'GET', 'student/student_id/answer_stats')(
    {
      params: { student_id },
    }
  )
}
