import {flow, Instance, types} from 'mobx-state-tree'
import {DateTime} from 'luxon'

import {UserModel} from 'types/user/model'
import {get_selector} from 'types/sessions/past_sessions'
import {HydratedSession} from 'common/types/session'
import {getUpcomingSessions} from 'util/api/sessions'


export const UpcomingSessionsStore = types.model({
  sessions: types.array(HydratedSession),
  busy: types.boolean,
  weeks: types.number
}).volatile((self) => ({
  user: null,
  tutor: null,
})).actions(self => {
  const get_sessions = async () => {
    const selector = get_selector(self.user, self.tutor)
    const sessions = await Promise.all(Array.from({length: self.weeks}).map((_, week) =>
      getUpcomingSessions(selector, DateTime.now().plus({week}).toISO())
    ))

    return sessions.flat()
  }

  return {
    refresh: flow(function* (user?: UserModel, tutor?: string) {
      self.busy = true
      // todo refactor out this volatile state
      self.user = user
      self.tutor = tutor

      self.sessions = yield get_sessions()
      self.busy = false
    }),
    load_next_week: flow(function* () {
      self.busy = true
      self.weeks = self.weeks + 1
      self.sessions = yield get_sessions()
      self.busy = false
    })
  }
})

export type UpcomingSessionsStore = Instance<typeof UpcomingSessionsStore>

