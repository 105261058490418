import {flow, getRoot, Instance} from 'mobx-state-tree'
import toast from 'react-hot-toast'

import {IRichStore} from 'types/store'
import {setHomework} from 'util/api/homework/set_homework'
import {clearHomework} from 'util/api/homework/clear_homework'
import {StudentHomeworkStore} from 'types/student_homework/student_homework_store'

export const TeacherHomeworkStore = StudentHomeworkStore
  .named('TeacherHomeworkStore')
  .actions(self => ({
    set_homework: flow(function* () {
      const {classPerformanceStore, teacherPageStore}: IRichStore = getRoot(self)

      self.homework = {
        refresh: classPerformanceStore.selectedRefresh,
        topic: classPerformanceStore.selectedTopic,
        endcode: classPerformanceStore.selectedEndcode,
        class: teacherPageStore.currentClass
      }

      yield setHomework(
        {
          body: {
            homework: {
              refresh_id: self.homework.refresh?._id,
              topic_id: self.homework.topic?._id,
              endcode_id: self.homework.endcode?._id,
              class_id: teacherPageStore.currentClass._id,
            }
          }
        })
      toast.success('Homework has been set')

      return true
    }),
    clear_homework: flow(function* () {
      const {teacherPageStore}: IRichStore = getRoot(self)
      self.homework = null
      yield clearHomework({ body: { class_id: teacherPageStore.currentClass._id } })
      toast.success('Homework has been cleared')

      return true
    }),
    view_homework: () => {
      const {classPerformanceStore}: IRichStore = getRoot(self)

      classPerformanceStore.set_selected_refresh(self.homework.refresh)
      classPerformanceStore.set_selected_topic(self.homework.topic)
      classPerformanceStore.set_selected_endcode(self.homework.endcode)

      return true
    }
  }))


export type TeacherHomeworkStore = Instance<typeof TeacherHomeworkStore>
