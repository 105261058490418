import {flow, getRoot, getSnapshot, Instance, types} from 'mobx-state-tree'

import {Store} from './store'
import {Question} from './question_management/question/question'

import {AdminSubject} from 'common/types/subject/subject'
import {postEndcodeQuestion} from 'util/api'

export const SubjectManagementStore = types.model({
  selected_top_level_subject: types.maybe(types.reference(AdminSubject)),
  selected_theme: types.maybe(types.reference(AdminSubject)),
  selected_topic: types.maybe(types.reference(AdminSubject)),
  selected_endcode: types.maybe(types.reference(AdminSubject)),
  selected_question: types.maybe(types.safeReference(Question)),
  editing_subject: types.maybe(types.safeReference(AdminSubject)),
  string_filter: types.string,
}).actions(
  self => ({
    set_selected_top_level_subject: (subject_id: string) => {
      const { admin } = getRoot<Instance<typeof Store>>(self)
      admin.reset_questions()

      self.selected_question = undefined
      self.selected_top_level_subject = subject_id
      self.selected_theme = undefined
      self.selected_topic = undefined
      self.selected_endcode = undefined
    },
    set_selected_theme: (subject_id: string) => {
      const { admin } = getRoot<Instance<typeof Store>>(self)
      admin.reset_questions()

      self.selected_question = undefined
      self.selected_theme = subject_id
      self.selected_topic = undefined
      self.selected_endcode = undefined
    },
    set_selected_topic: (subject_id: string) => {
      const { admin } = getRoot<Instance<typeof Store>>(self)
      admin.reset_questions()

      self.selected_question = undefined
      self.selected_topic = subject_id
      self.selected_endcode = undefined
    },
    set_selected_endcode: (subject_id: string) => {
      const { admin } = getRoot<Instance<typeof Store>>(self)
      admin.reset_questions()

      self.selected_question = undefined
      self.selected_endcode = subject_id
    },
    set_selected_question: (question_id: string) => {
      self.selected_question = question_id as any
    },
    set_editing_subject: (subject_id: string) => {
      self.editing_subject = subject_id
    },
    set_string_filter: (string_filter: string) => {
      self.string_filter = string_filter
    },
    save_all_questions: flow(function*() {
      const { admin } = getRoot<Instance<typeof Store>>(self)

      admin.set_is_loading(true)

      for (const question of admin.questions) {
        if (question.has_changed) {
          yield postEndcodeQuestion(
            self.selected_endcode._id,
            getSnapshot(question)
          )
        }
      }

      yield admin.get_questions_for_endcode(self.selected_endcode._id)
      admin.set_is_loading(false)
    }),
  })
)
export type SubjectManagementStore = Instance<typeof SubjectManagementStore>
