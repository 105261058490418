import React from 'react'

interface IProps {
  label: string|React.JSX.Element,
}

export const GreenPill = ({ label }: IProps): JSX.Element => (
  <strong
    className="rounded bg-green-100 px-3 py-1.5 text-xs font-medium text-green-700 shadow"
  >
    { label }
  </strong>
)

export const OrangePill = ({ label }: IProps): JSX.Element => (
  <strong
    className="rounded bg-orange-100 px-3 py-1.5 text-xs font-medium text-orange-700 shadow"
  >
    { label }
  </strong>
)

export const RedPill = ({ label }: IProps): JSX.Element => (
  <strong
    className="rounded bg-red-100 px-3 py-1.5 text-xs font-medium text-red-700 shadow"
  >
    { label }
  </strong>
)

export const BluePill = ({ label }: IProps): JSX.Element => (
  <strong
    className="rounded bg-blue-100 px-3 py-1.5 text-xs font-medium text-blue-700 shadow"
  >
    { label }
  </strong>
)
