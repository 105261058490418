import {$window} from 'services/window'

export const logError = (error: Error, customAttributes?: object): boolean => {
  if ($window.newrelic?.noticeError) {
    $window.newrelic.noticeError(error, customAttributes)
    return true
  }

  return false
}
