import {Instance, types} from 'mobx-state-tree'

import {AdminSubject} from 'common/types/subject/subject'

export const Refresh = types.model('Refresh', {
  _id: types.identifier,
  label: types.string,
  parent_subject: types.reference(AdminSubject),
  subjects: types.array(types.safeReference(types.late(() => AdminSubject))),
  reference_score: types.number,
})
export type Refresh = Instance<typeof Refresh>

export const GetRefreshesRequest = types.model({})
export type GetRefreshesRequest = Instance<typeof GetRefreshesRequest>

export const GetRefreshesResponse = types.model({
  success: types.boolean,
  refreshes: types.array(Refresh)
})
export type GetRefreshesResponse = Instance<typeof GetRefreshesResponse>
