import {flow, Instance, types} from 'mobx-state-tree'

import {AdminSubjects} from 'common/types/subject/subject'
import {getAllSubjects} from 'util/api/subject'
import {logError} from 'util/helpers/logError'

export const SubjectList = types
  .model({
    subjects: AdminSubjects,
  })
  .actions(self => ({
    refresh: flow(function* () {
      try {
        const subjects = yield getAllSubjects()
        self.subjects = subjects
      } catch (error) {
        logError(error)
        console.error('Failed list Subjects', error)
      }
    })
  })
  )

export type SubjectList = Instance<typeof SubjectList>
