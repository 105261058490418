import {Instance, types} from 'mobx-state-tree'

import {AdminSubject} from 'common/types/subject/subject'

export const AnswerStatsRequest = types.model({
  params: types.model({
    student_id: types.string
  }),
}).named('AnswerStatsRequest')
export type AnswerStatsRequest = Instance<typeof AnswerStatsRequest>

export const AnswerStat = types.model({
  subject: types.safeReference(types.late(() => AdminSubject)),
  last_answered: types.maybeNull(types.string),
  recent_correct_percentage: types.maybeNull(types.number),
  total_answers: types.number,
}).named('AnswerStat')
export type AnswerStat = Instance<typeof AnswerStat>

export const AnswerStatsResponse = types.model({
  stats: types.array(AnswerStat)
}).named('AnswerStatsResponse')
export type AnswerStatsResponse = Instance<typeof AnswerStatsResponse>
