import React from 'react'
import {observer} from 'mobx-react-lite'

import {Roles} from 'util/auth/helper'
import {IMenuButtonProps} from 'generic_components/MenuWrapper/MenuButton'
import {UserModel} from 'types/user/model'
import {BluePill} from 'components/stats/StatsView/TableRecord'
import {useStore} from 'types/store'

const schoolItems: IMenuButtonProps[] = [
  {
    label: 'School Profile',
    href: '/school/profile',
    icon: <i className="fa-solid fa-id-card"></i>,
  },
  {
    label: 'Manage Teachers',
    href: '/school/teachers',
    icon: <i className="fa-solid fa-person-chalkboard"></i>,
  },
  {
    label: 'Manage Students',
    href: '/school/students',
    icon: <i className="fa-solid fa-graduation-cap"></i>,
  },
  {
    label: 'Manage Classes',
    href: '/school/classes',
    icon: <i className="fa-solid fa-school"></i>,
  },
]

const tutorItems: IMenuButtonProps[] = [
  {
    label: 'Home',
    href: '/tutor',
    icon: <i className="fa-solid fa-house"></i>,
  },
  {
    label: 'Your Profile',
    href: '/tutor/profile',
    icon: <i className="fa-solid fa-id-card"></i>,
  },
  {
    label: 'Manage Availability',
    href: '/tutor/availability',
    icon: <i className="fa-solid fa-calendar-days"></i>,
  },
]

const parentItems: IMenuButtonProps[] = [
  {
    label: 'Home',
    href: '/parent',
    icon: <i className="fa-solid fa-house"></i>,
  },
  {
    label: 'How to use Plytime®',
    href: 'https://vimeo.com/865631340/fb2cb90acd?share=copy',
    icon: <i className="fa-solid fa-video-camera"></i>,
  },
  {
    label: 'Add New Subscription',
    href: '/parent/new-subscription',
    icon: <i className="fa-solid fa-basket-shopping"></i>,
    shouldPing: true
  },
  {
    label: 'Your Profile',
    href: '/parent/profile',
    icon: <i className="fa-solid fa-id-card"></i>,
  },
  {
    label: 'Student Performance',
    href: '/parent/student_performance',
    icon: <i className="fa-solid fa-chart-line"></i>,
  },
  {
    label: 'Manage Subscriptions',
    href: '/parent/subscriptions',
    icon: <i className="fa-solid fa-list"></i>,
    sub_items: [
      {
        label: 'Cancel Subscription',
        href: '/parent/subscriptions/cancel',
        icon: <i className="fa-solid fa-cancel"></i>,
      },
      {
        label: 'Add or Edit Focus Tutoring',
        href: '/parent/subscriptions/edit',
        icon: <i className="fa-solid fa-pencil"></i>,
      },
    ]
  },
]

const adminItems: IMenuButtonProps[] = [
  {
    label: 'Tutors',
    href: '/admin/tutors',
    icon: <i className="fa-solid fa-chalkboard-user"></i>,
    sub_items: [
      {
        label: 'Add a New Tutor',
        href: '/admin/tutors/add',
        icon: <i className="fa-solid fa-user-plus"></i>,
      },
      {
        label: 'List Tutors',
        href: '/admin/tutors/list',
        icon: <i className="fa-solid fa-users"></i>,
      },
      {
        label: 'All Availability',
        href: '/admin/tutors/all_availability',
        icon: <i className="fa-solid fa-calendar"></i>,
      },
    ]
  },
  {
    label: 'Student Scores',
    href: '/admin/student_scores',
    icon: <i className="fa-solid fa-square-poll-vertical"></i>,
  },
  {
    label: 'Question Manager',
    href: '/admin/question_manager',
    icon: <i className="fa-solid fa-circle-question"></i>,
  },
  {
    label: 'Subject Manager',
    href: '/admin/subjects',
    icon: <i className="fa-solid fa-circle-question"></i>,
  },
  {
    label: 'Import Subjects and Questions',
    href: '/admin/subjects_import',
    icon: <i className="fa-solid fa-upload"></i>,
  },
  {
    label: 'Competitions',
    href: '/admin/competitions',
    icon: <i className="fa-solid fa-medal"></i>,
    sub_items: [
      {
        label: 'List Competitions',
        href: '/admin/competitions/list',
        icon: <i className="fa-solid fa-medal" />,
      },
      {
        label: 'Add New Competition',
        href: '/admin/competitions/add',
        icon: <i className="fa-solid fa-medal" />,
      }
    ]
  },
]

export const getMenuItemsForRole = (user: UserModel): IMenuButtonProps[] => {
  switch (user?.role) {
    case Roles.STUDENT:
      return [
        {
          label: 'Home',
          href: '/student',
          icon: <i className="fa-solid fa-home"></i>,
        },
        {
          label: 'Upcoming Sessions',
          href: '/student/sessions',
          icon: <i className="fa-solid fa-chalkboard-user"></i>,
        },
        {
          label: 'Play',
          href: '/game/step1_setup',
          icon: <i className="fa-gamepad fa-solid"></i>,
          shouldPing: true
        },
        {
          label: 'Competitions',
          href: '/student/competitions',
          icon: <i className="fa-solid fa-trophy"></i>,
        },
        {
          label: <StudentProgressPageLabel />,
          href: '/student/progress',
          icon: <i className="fa-chart-line fa-solid"></i>,
        },
        {
          label: 'Refresh',
          href: '/student/refresh',
          icon: <i className="fa-solid fa-arrows-rotate"></i>,
        },
        {
          label: 'Review',
          href: '/student/answers',
          icon: <i className="fa-table fa-solid"></i>,
        },
        {
          label: 'Rewards',
          href: '/student/tokens',
          icon: <i className="fa-coins fa-solid"></i>,
        },
        !user.student_has_parent ?
          {
            label: 'Link Parent',
            href: '/student/link-parent',
            icon: <i className="fa-user-plus fa-solid"></i>,
            shouldPing: true
          } :
          {
            label: 'Log into Parent Account',
            href: '/sign-out?redirect=/signin/parent',
            icon: <i className="fa-solid fa-right-from-bracket"></i>,
          },

      ].filter(Boolean) as IMenuButtonProps[]
    case Roles.SCHOOL:
      return schoolItems
    case Roles.TUTOR:
      return tutorItems
    case Roles.PARENT:
      return parentItems
    case Roles.ADMIN:
      return adminItems
    default:
      return []
  }
}

const StudentProgressPageLabel = observer(() => {
  const {student_menu_store} = useStore()
  return (
    <span>
      Progress
      {
        student_menu_store.has_unwatched_videos ?
          <span className="ml-4"><BluePill label={<span><i className="fa-video fa-solid"/>&nbsp;New Video</span>}/></span> :
          null
      }
    </span>
  )
})
StudentProgressPageLabel.displayName = 'StudentProgressPageLabel'
