import React, {ReactElement} from 'react'
import Select from 'react-select'
import {observer} from 'mobx-react-lite'
import classNames from 'classnames'

import {WarningClickableButton} from './ClickableButton'

export interface IOption {
    label: ReactElement | string,
    value: string,
}
export interface IGroupedOption {
  label: string;
  options: IOption[]
}

interface IRichSelectProps {
    label?: string,
    id: string,
    options: IOption[]|IGroupedOption[],
    selected?: string|number|IOption[],
    onChange: (option: IOption) => void
    onClear?: () => void,
    isDisabled?: boolean,
    hasLightText?: boolean,
    isMulti?: boolean,
    isSearchable?: boolean,
    placeholder?: string,
}

export const RichSelect = observer(({
  id,
  label,
  options,
  selected,
  onChange,
  onClear,
  isDisabled,
  placeholder,
  hasLightText = false,
  isMulti = false,
  isSearchable = false
}: IRichSelectProps): JSX.Element => {
  return (
    <div className='my-2 flex items-end'>
      <div className="grow">
        {
          label ?
            (
              <label
                htmlFor={id}
                className={classNames(hasLightText ? 'text-gray-200' : '', 'pb-6 font-bold')}
              >
                {label}
              </label>
            ) :
            null
        }
        <Select
          isSearchable={isSearchable}
          aria-label={label}
          id={id}
          placeholder={placeholder}
          options={options}
          onChange={onChange}
          defaultValue={selected as any}
          value={options.flatMap((el) => el.options ? el.options: el).find(({value}) => value === selected || value?.toString() === selected?.toString())}
          isDisabled={isDisabled}
          isMulti={isMulti}
          menuPortalTarget={document.body}
          menuPosition='fixed'
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
          }}
        />
      </div>
      {
        onClear ?
          (
            <WarningClickableButton
              className="h-[40px]"
              disabled={!selected || isDisabled}
              onClick={onClear}
            >
            Clear
            </WarningClickableButton>
          ) :
          null
      }
    </div>
  )
})
RichSelect.displayName = 'RichSelect'
