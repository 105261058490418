import { Instance, types } from 'mobx-state-tree'

import { Competition } from './index'

export const AdminGetCompetitionsRequest = types.model({})
export type AdminGetCompetitionsRequest = Instance<typeof AdminGetCompetitionsRequest>

export const AdminGetCompetitionsResponse = types.model({
  success: types.boolean,
  competitions: types.array(Competition)
})
export type AdminGetCompetitionsResponse = Instance<typeof AdminGetCompetitionsResponse>
