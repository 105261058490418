import {InlineMath} from 'react-katex'
import 'katex/dist/katex.min.css'

interface IProps {
    text: string
}

export const TextWithMaths = ( {text}: IProps ) => {
  return (
    <div>
      {
        text
          .split('$')
          .map((t, i) => {
            if (i % 2 == 0) {
              return <span key={i}>{t}</span>
            }

            return <InlineMath key={i} math={t} />
          })
      }
    </div>
  )
}
