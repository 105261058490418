import {FC} from 'react'
import {observer} from 'mobx-react-lite'

import {useStore} from 'types/store'
import {ColumnContainer, Container, Input, SubElement} from 'generic_components/AnswerInputContainers'
import {InputProps} from 'components/question/inputProps'
import {get_formatted_correct_answer} from 'components/question/input'

const calculateLength = (x: string): number => x.length <= 3 ? 3 : x.length + 1

const parseNumber = (value: string): string => value.replaceAll(/[^0-9.-]/g, '')

const parseCoordinate = (coordinate: string): [string, string] => {
  if (coordinate.includes(';')) {
    const [x,y] = coordinate.split(';')
    const [x_min, y_min] = x.replaceAll(/[() ]/g, '').split(',')
    const [x_max, y_max] = y.replaceAll(/[() ]/g, '').split(',')

    return [`${x_min} to ${x_max}`, `${y_min} to ${y_max}`]
  }

  const [x,y] = (coordinate !== '') ? coordinate.replaceAll(/[( )]/g, '').split(',') : ['','']
  return [x,y]
}

export const CoordinateInputComponent: FC<InputProps> = observer(({ answerIndex, element, handleChange, answer_decision, correct_answers, value, element_id }) => {
  const {game, auth: { user }} = useStore()

  const [x_value, y_value] = parseCoordinate(value)

  const [sizeX, sizeY] = [calculateLength(x_value), calculateLength(y_value) ]

  const [x_answer, y_answer] = (correct_answers.length > 0 && answer_decision !== 'CORRECT') ?
    parseCoordinate(get_formatted_correct_answer(correct_answers, answerIndex, element.ref)) :
    ['','']

  const inputChangeX = (e) => {
    e.target.value = `(${parseNumber(e.target.value)},${y_value})`
    handleChange(e)
  }

  const inputChangeY = (e) => {
    e.target.value = `(${x_value},${parseNumber(e.target.value)})`
    handleChange(e)
  }

  return (
    <ColumnContainer>
      <Container>
        <SubElement>(</SubElement>
        <Input
          autoFocus
          id={`${element_id}_x`}
          onChange={(e) => inputChangeX(e) }
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          value={x_value}
          size={sizeX}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              if(x_value !== '' && y_value !== '') {
                game.submit_answer(user._id)
              }
            }
          }}
        />
        <SubElement>,</SubElement>
        <Input
          id={`${element_id}_y`}
          onChange={(e) => inputChangeY(e)}
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          value={y_value}
          size={sizeY}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              if(x_value !== '' && y_value !== '') {
                game.submit_answer(user._id)
              }
            }
          }}
        />
        <SubElement>)</SubElement>
      </Container>
      {answer_decision !== 'CORRECT' && x_answer !== '' && y_answer !== '' && (
        <Container>
          <SubElement>(</SubElement>
          <Input
            answer_decision={'CORRECT'}
            readOnly={true}
            value={x_answer}
            size={x_answer.length}
          />
          <SubElement>,</SubElement>
          <Input
            answer_decision={'CORRECT'}
            readOnly={true}
            value={y_answer}
            size={y_answer.length}
          />
          <SubElement>)</SubElement>
        </Container>
      )}
    </ColumnContainer>
  )

})
