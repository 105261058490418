const config = {
  region: import.meta.env.REACT_APP_COGNITO_REGION,
  cookieDomain: import.meta.env.REACT_APP_COGNITO_COOKIE_DOMAIN,
  userPoolId: import.meta.env.REACT_APP_COGNITO_POOL_PARENT,
  clientId: import.meta.env.REACT_APP_COGNITO_CLIENT_PARENT,
  hostedUiDomain: import.meta.env.REACT_APP_COGNITO_HOSTED_UI_DOMAIN_PARENT,
  appUrl: import.meta.env.REACT_APP_URL,
  identityPoolId: import.meta.env.REACT_APP_COGNITO_IDPOOL_PARENT,
  dataS3Bucket: import.meta.env.REACT_APP_DATA_S3_BUCKET
}


export default {
  Auth: {

    // REQUIRED - Amazon Cognito Region
    region: config.region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: config.userPoolId,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: config.clientId,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    identityPoolId: config.identityPoolId,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: config.cookieDomain,
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 30,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite: 'strict',
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true
    },


    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH',


    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: config.hostedUiDomain,
      scope: ['email', 'openid'],
      redirectSignIn: config.appUrl,
      redirectSignOut: config.appUrl,
      responseType: 'code' // 'code' or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  },
  Storage: {
    AWSS3: {
      bucket: config.dataS3Bucket, //REQUIRED -  Amazon S3 bucket name
      region: 'eu-west-2', //OPTIONAL -  Amazon service region
    }

  }
}
