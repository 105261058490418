import React, {useCallback} from 'react'
import {observer} from 'mobx-react-lite'

import {FieldComponent} from 'generic_components/MstForm'
import {RichSelect} from 'components/RichSelect'

interface IOptions {
    key?: string|JSX.Element,
    value: string|number,
}

export const StringSelector = <T extends string>(options: IOptions[]): FieldComponent<string|number> => {
  const component = observer(({id, value, onChange, disabled, isSearchable}: any) => {
    const selected_option = options.find(option => option.value === value)
    const onChangeCallback = useCallback((e) => {
      // A hack, to save changing every instance of StringSelector.
      onChange({
        currentTarget: { value: e.value, id },
        target: { value: e.value, id },
      })
    }, [onChange])

    return (
      <RichSelect
        id={id}
        selected={selected_option?.value}
        onChange={onChangeCallback}
        isDisabled={disabled}
        options={
          // Again, a bit of a hack, to save changing every instance of StringSelector.
          options.map(old_option => ({
            label: old_option.key ?? old_option.value?.toString(),
            value: old_option.value?.toString()
          }))
        }
        isSearchable={isSearchable}
      />
    )
  })
  component.displayName = 'StringSelector'

  return component
}
