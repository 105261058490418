import {Instance, types} from 'mobx-state-tree'

export enum CountryEnum {
  'United Kingdom' = 'United Kingdom',
  'Australia' = 'Australia',
  'Belgium' = 'Belgium',
  'Canada' = 'Canada',
  'France' = 'France',
  'Germany' = 'Germany',
  'Isle of Man' = 'Isle of Man',
  'Italy' = 'Italy',
  'Netherlands' = 'Netherlands',
  'New Zealand' = 'New Zealand',
  'Poland' = 'Poland',
  'Portugal' = 'Portugal',
  'Republic of Ireland' = 'Republic of Ireland',
  'Spain' = 'Spain',
  'Sweden' = 'Sweden',
  'Switzerland' = 'Switzerland',
  'United States' = 'United States',
}

export const Country = types.enumeration<CountryEnum>( 'Country', [
  CountryEnum['United Kingdom'],
  CountryEnum['Australia'],
  CountryEnum['Belgium'],
  CountryEnum['Canada'],
  CountryEnum['France'],
  CountryEnum['Germany'],
  CountryEnum['Isle of Man'],
  CountryEnum['Italy'],
  CountryEnum['Netherlands'],
  CountryEnum['New Zealand'],
  CountryEnum['Poland'],
  CountryEnum['Portugal'],
  CountryEnum['Republic of Ireland'],
  CountryEnum['Spain'],
  CountryEnum['Sweden'],
  CountryEnum['Switzerland'],
  CountryEnum['United States'],
])
export type Country = Instance<typeof Country>
