import {flow, Instance, types} from 'mobx-state-tree'
import {DateTime} from 'luxon'
import Pusher, {PresenceChannel} from 'pusher-js'

import {get_unread_notes, set_last_read_time} from 'util/api/notes/read'
import {Note} from 'common/types/notes'
import {list_notes, send_note} from 'util/api/notes'
import {getPusher} from 'services/pusher'

const bound_channels = []

interface IOpenMessageWindow {
  subject_id: string,
  student_id: string,
}

export const MessagesStore = types
  .model({
    is_loading: types.boolean,
    unread_indicator_groups: types.array(
      types.model({
        unread_count: types.maybe(types.number),
        student_id: types.string,
        subject_id: types.string,
      })
    ),
    messages: types.maybe(
      types.model({
        fetched_count: types.maybe(types.number),
        total_count: types.maybe(types.number),
        notes: types.maybe(types.array(Note)),
      })
    ),
  })
  .volatile(self => ({
    open_message_window: undefined as IOpenMessageWindow|undefined
  }))
  .views(self => ({
    foundIndicatorGroup(subject_id: string, student_id: string) {
      return self.unread_indicator_groups
        .find(
          (m) => m.student_id === student_id && m.subject_id === subject_id
        )
    }
  }))
  .actions( self => ({
    set_message_window: (input) => {
      self.open_message_window = input
    },
    update_read_timestamp: flow(function* (
      subject_id: string,
      student_id: string,
      timestamp?:string,
    ) {
      return set_last_read_time({
        params:{
          student: student_id,
          subject: subject_id
        },
        body: {
          timestamp: timestamp ?
            timestamp :
            DateTime.now().toISO()
        }
      })
    }),
  }))
  .actions(self => ({
    get_unread_notes: flow(function* (subject_id: string, student_id: string) {
      if (
        subject_id &&
        student_id
      ) {
        const foundIndicatorGroup = self.foundIndicatorGroup(
          subject_id,
          student_id
        )

        if (
          (self.open_message_window as any)?.subject_id === subject_id &&
          (self.open_message_window as any)?.student_id === student_id &&
          foundIndicatorGroup
        ) {
          foundIndicatorGroup.unread_count = 0
          return
        }

        const result = yield get_unread_notes({
          params: {
            subject: subject_id,
            student: student_id,
          }}
        )

        if (foundIndicatorGroup) {
          foundIndicatorGroup.unread_count = result.count
          return
        }

        self.unread_indicator_groups.push({
          student_id,
          subject_id,
          unread_count: result.count
        })
      }
    }),

    list_notes: flow(function* (subject_id: string, student_id: string) {
      if (subject_id && student_id) {
        self.is_loading = true
        const list = yield list_notes(subject_id, student_id)

        self.messages = {
          notes: list.notes,
          total_count: list.total,
          fetched_count: list.count,
        }

        yield self.update_read_timestamp(
          subject_id,
          student_id
        )

        self.is_loading = false
      }
    }),

    load_more_notes: flow(function* (
      subject_id: string,
      student_id: string,
      listref
    ) {
      if( self.messages.total_count > self.messages.fetched_count &&
          !self.is_loading &&
          listref.current.scrollHeight <= listref.current.clientHeight - listref.current.scrollTop + 100
      )
      {
        self.is_loading = true
        try{
          const oldest_note = self.messages.notes[self.messages.notes.length - 1]
          const list = yield list_notes(student_id, subject_id, oldest_note.timestamp)

          self.messages = {
            notes: [...self.messages.notes, ...list.notes] as any,
            total_count: list.total,
            fetched_count: self.messages.fetched_count + list.count,
          }
        }
        catch(e){
          console.error('Error getting notes:')
          e.errors?.forEach(e => console.error(e))
        }
        self.is_loading = false
      }
    })
  }))
  .actions( self => {
    return ({
      send_note: flow(function* (
        subject_id: string,
        student_id: string,
        message?: string,
      ) {
        yield send_note(subject_id, student_id, message)
        yield self.list_notes(subject_id, student_id)
        yield self.update_read_timestamp(subject_id, student_id)
      }),
      register_websocket_listener: flow(function* (subject_id: string, student_id: string) {
        const channel_name = `presence-${student_id}-${subject_id}`
        if (!bound_channels.includes(channel_name)) {
          bound_channels.push(channel_name)

          const pusher: Pusher = yield getPusher()

          pusher.signin()

          const channel = pusher.subscribe(channel_name) as PresenceChannel
          channel.bind(
            'message',
            () => {
              self.get_unread_notes(subject_id, student_id)
              if (
                self.open_message_window &&
                    self.open_message_window.subject_id === subject_id &&
                    self.open_message_window.student_id === student_id
              ) {
                self.list_notes(subject_id, student_id)
              }
            }
          )

          self.get_unread_notes(subject_id, student_id)
        }
      })
    })
  })


export type MessagesStore = Instance<typeof MessagesStore>
