import {observer} from 'mobx-react-lite'

import {FieldComponent} from 'generic_components/MstForm'

export const NumberInput:FieldComponent<number> = observer(({id, value, onChange, disabled, readOnly, min, max}) => {
  return (
    <input
      id={id}
      type='number'
      value={value}
      onChange={onChange}
      disabled={disabled}
      className="h-[36px] w-full appearance-none rounded border px-2 leading-tight text-gray-700 shadow focus:outline-none focus:ring"
      readOnly={readOnly}
      min={min}
      max={max}
    />)
})
NumberInput.displayName = 'NumberInput'
