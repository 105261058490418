import {DateTime} from 'luxon'

export const days_from_now = (dateISO: string): number => {
  return Math.floor(
    Math.abs(
      DateTime
        .fromISO(dateISO)
        .diffNow()
        .as('days')
    )
  )
}
