import React from 'react'
import {observer} from 'mobx-react-lite'
import {Instance} from 'mobx-state-tree'

import {PrimaryClickableButton, WarningClickableButton} from 'components/ClickableButton'
import {ResponsiveTable} from 'components/ResponsiveTable'
import {ModalField} from 'components/subscription/TutoringDetails'
import {Day} from 'common/types/basic/day'
import {ApproxTime} from 'common/types/session/options'
import {useStore} from 'types/store'

const shorten_time = (time: Instance<ApproxTime>): string => {
  if (time === 'Morning (08:00-11:45)') return '08:00-11:45'
  if (time === 'Early Afternoon (12:00-14:45)') return '12:00-14:45'
  if (time === 'Late Afternoon (15:00-17:45)') return '15:00-17:45'
  if (time === 'Early Evening (18:00-20:45)') return '18:00-20:45'
  if (time === 'Late Evening (21:00-23:45)') return '21:00-23:45'
  
  return ''
}

interface MstEnum {
    _types: { value: string }[]
}
  
export const PreferredAvailabilityWindowSelection = observer(() => {
  const { subscription_wizard: {is_available, update_availability} } = useStore()
  
  return (
    <div className="overflow-x-auto">
      <ModalField
        title="Preferred Day & Time Windows"
        instructions={(
          <div>
            <p>Please select all that apply and choose your specific time slots in the next screen.</p>
          </div>
        )}
        field={(
          <div className="h-[50vh] overflow-auto">
            <ResponsiveTable
              isSortable={false}
              headers={[
                {
                  getter: (day) => {
                    return day.value
                  }
                },
                {
                  getter: (day) => {
                    return (ApproxTime as any)._types.map(({value: approx_time}) => (
                      <div
                        data-testid={`${day.value}-${approx_time}`}
                        key={approx_time}
                        className="flex justify-between py-2"
                      >
                        {shorten_time(approx_time)}
                        {
                          !is_available((day as any).value, approx_time) ?
                            (
                              <PrimaryClickableButton
                                onClick={() => {
                                  update_availability((day as any).value, approx_time, false)
                                }}
                              >
                                                Add
                              </PrimaryClickableButton>
                            ) :
                            (
                              <WarningClickableButton
                                onClick={() => {
                                  update_availability((day as any).value, approx_time, true)
                                }}
                              >
                                                Remove
                              </WarningClickableButton>
                            )
                        }
                      </div>
                    ))
                  }
                }
              ]}
              rows={(Day as unknown as MstEnum)._types}
            />
          </div>
        )}
      />
    </div>
  )
})