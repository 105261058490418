import {Instance, types} from 'mobx-state-tree'

export const Action = types.model({
  label: types.string,
  value: types.string,
  timestamp: types.string
})
export type Action = Instance<typeof Action>

export const PostActionRequest = types.model({
  body: types.model({
    type: types.string,
    user_id: types.string,
    label: types.string,
    value: types.string,
  })
})
export type PostActionRequest = Instance<typeof PostActionRequest>

export const PostActionResponse = types.model({
  success: types.boolean
})
export type PostActionResponse = Instance<typeof PostActionResponse>

export const GetActionsRequest = types.model({
  params: types.model({
    type: types.string,
    user_id: types.string,
  }),
  query: types.model({
    label: types.maybe(types.string)
  })
})
export type GetActionsRequest = Instance<typeof GetActionsRequest>

export const GetActionsResponse = types.model({
  success: types.boolean,
  actions: types.array(Action)
})
export type GetActionsResponse = Instance<typeof GetActionsResponse>
