import {Instance, types} from 'mobx-state-tree'

import {Competition} from 'common/types/competition'

export const GetStudentCompetitionsRequest = types.model({})
export type GetStudentCompetitionsRequest = Instance<typeof GetStudentCompetitionsRequest>

export const GetStudentCompetitionsResponse = types.model({
  success: types.boolean,
  competitions: types.array(Competition)
})
export type GetStudentCompetitionsResponse = Instance<typeof GetStudentCompetitionsResponse>
