import {Instance, types} from 'mobx-state-tree'

export const AwardTokensPostRequest = types.model({
  body: types.model({
    student_id: types.string,
    amount: types.number
  }),
})
export type AwardTokensPostRequest = Instance<typeof AwardTokensPostRequest>

export const AwardTokensPostResponse = types.model({
  success: types.boolean
})
export type AwardTokensPostResponse = Instance<typeof AwardTokensPostResponse>
