import {ITopicRow} from 'components/progress/progress'

export const sortSubjectsWithProvisionalScores = (subjectScore: number) => (a: ITopicRow, b: ITopicRow): 1|-1 => {
  const aScore = a.score ?? Math.min(subjectScore, (a.subject.min_score + a.subject.max_score) / 2)
  const bScore = b.score ?? Math.min(subjectScore, (b.subject.min_score + b.subject.max_score) / 2)

  if (aScore === bScore) {
    return a.subject.min_score > b.subject.min_score ? 1 : -1
  }

  return aScore > bScore ? 1 : -1
}
