import {FC, useState} from 'react'
import {observer} from 'mobx-react-lite'
import classNames from 'classnames'

import {PrimaryClickableButton, SecondaryClickableButton} from 'components/ClickableButton'
import {ModalField} from 'components/subscription/TutoringDetails'
import {SessionOptionEnum} from 'common/types/session/options'
import {useStore} from 'types/store'

interface Props {
  close: () => void
  isModal: boolean
}

export const isReferralCode = (code: string): boolean => /^(PTR|PPR|PSR|PBR).*/.test(code)

export const Referral: FC<Props> = observer(({ close, isModal }) => {
  const {
    subscription_wizard: {
      back,
      next,
      query_with_referral_code,
      referral_code,
      selected_session_option,
      update_query_with_referral_code,
      update_referral_code
    }
  } = useStore()

  const [referral_code_error, set_referral_code_error] = useState<string>(null)
  return (
    <div>
      <div className={classNames(isModal ? 'overflow-auto max-h-[50vh] md:max-h-[70vh]' : 'py-6')}>
        <ModalField
          title="Referral code"
          instructions={(
            <div>
              <p>If you have a referral code, please enter it here (e.g. PTR-Gina101). If not please click &quot;Next&quot;.</p>
              <p>Please note - Discount Codes are entered later at the checkout step.</p>
            </div>
          )}
          field={(
            <>
              <input
                className="w-1/2 appearance-none rounded border-2 border-gray-600 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
                disabled={false}
                value={referral_code}
                onChange={(event) => {
                  update_referral_code(event.target.value)
                  if (isReferralCode(event.target.value)){
                    set_referral_code_error(null)
                  }
                }}
              />

              {
                referral_code_error ?
                  <p className="mt-2 rounded bg-red-100 p-2 text-red-700">{referral_code_error}</p> :
                  null
              }
            </>
          )}
        />


        {
          selected_session_option !== SessionOptionEnum.FreeTrial &&
          selected_session_option !== SessionOptionEnum.PlatformOnly ?
            (
              <ModalField
                title="Tutor selection"
                instructions={(
                  <div>
                    <p>If your Referral code was for a specific tutor and you would like your sessions to be with that tutor, please check this box.</p>
                    <p>If left unchecked, you will be shown the available times of all tutors.</p>
                  </div>
                )}
                field={(
                  <input
                    className="size-6 cursor-pointer rounded"
                    type="checkbox"
                    checked={query_with_referral_code}
                    onChange={e => {
                      update_query_with_referral_code(e.target.checked)
                    }}
                  />
                )}
              />
            ) :
            null
        }
      </div>
      <div className="flex h-1/3 justify-around py-6">
        <SecondaryClickableButton
          onClick={back}
        >
          Back
        </SecondaryClickableButton>
        <PrimaryClickableButton
          disabled={Boolean(referral_code_error)}
          onClick={() => {
            if (
              referral_code &&
              referral_code.length > 0 &&
              !isReferralCode(referral_code)
            ) {
              set_referral_code_error('That\'s not a valid referral code.  If it\'s a discount code, you need to add that as a  promotion code in the Stripe checkout.')
              return
            }

            next()
          }}
        >
          Next
        </PrimaryClickableButton>
      </div>
    </div>
  )
})
