import React from 'react'

import {Icon} from 'generic_components/Icon'

export const PriorityIcon = ({ priority }) => {
  if (priority === '1') {
    return (
      <Icon
        icon="fa-1 fa-solid text-red-600"
      />
    )
  }

  if (priority === '2') {
    return (
      <Icon
        icon="fa-2 fa-solid text-orange-400"
      />
    )
  }

  if (priority === '3') {
    return (
      <Icon
        icon="fa-3 fa-solid text-green-600"
      />
    )
  }
}
