import {flow, getRoot, Instance, types} from 'mobx-state-tree'
import {max, mean, min, sum, uniqBy} from 'lodash'

import {IRichStore} from 'types/store'
import {get_class_performance} from 'util/api/class/performance'
import {ClassPerformanceGetResponse, Score} from 'common/types/class'
import {AdminSubject} from 'common/types/subject/subject'
import {Refresh} from 'common/types/refreshes/get_refreshes'
import {Student} from 'common/types/student'
import {isEndcodeLive} from 'util/helpers/isEndcodeLive'

const ScoreType = types.model({
  label: types.string,
  value: types.enumeration(['score', 'recent_correct_percentage'])
})

export interface IFocusCount {
    subject_id: string,
    student_ids: string[]
}

export interface IMinMaxSubjectScoreResult {
    min_subject_score: number,
    max_subject_score: number
}

export const ClassPerformanceStore = types.model({
  isLoading: types.boolean,
  classPerformance: ClassPerformanceGetResponse,
  selectedScoreType: ScoreType,
  selectedRefresh: types.maybeNull(types.safeReference(Refresh)),
  selectedTopic: types.maybeNull(types.safeReference(AdminSubject)),
  selectedEndcode: types.maybeNull(types.safeReference(AdminSubject))
})
  .actions(self => ({
    get_class_performance: flow(function* (class_id: string) {
      self.isLoading = true
      self.classPerformance = yield get_class_performance({
        params: {class_id},
      })
      self.isLoading = false
    }),
    set_selected_score_type: (scoreType) => {
      self.selectedScoreType = scoreType
    },
    set_selected_refresh: (refresh) => {
      self.selectedRefresh = refresh
    },
    set_selected_topic: (focus) => {
      self.selectedTopic = focus
    },
    set_selected_endcode: (focus) => {
      self.selectedEndcode = focus
    }
  }))
  .views(self => ({
    selected_focus(scores: Score[]): Score|null {
      if (self.selectedRefresh && self.selectedTopic && !self.selectedEndcode) {
        const topicScores = scores
          .filter(s => self.selectedRefresh.subjects.map((s) => s._id).includes(s.subject._id))
          .filter(s => s.subject.parent._id === self.selectedTopic._id)

        return {
          subject: null,
          type: null,
          score: mean(topicScores.map(score => score.score)),
          last_answered: max(topicScores.map(score => score.last_answered)),
          recent_correct_percentage: mean(topicScores.map(score => score.recent_correct_percentage)),
          total_answers: sum(topicScores.map(score => score.total_answers)),
          is_ahead: null,
        }
      }
      if (self.selectedEndcode) {
        return scores.find(s => s.subject._id === self.selectedEndcode._id)
      }
      if (self.selectedTopic) {
        return scores.find(s => s.subject._id === self.selectedTopic._id)
      }
      if (self.selectedRefresh) {
        const yearScores = scores.filter(s => self.selectedRefresh.subjects.map((s) => s._id).includes(s.subject._id))

        return {
          subject: null,
          type: null,
          score: mean(yearScores.map(score => score.score)),
          last_answered: max(yearScores.map(score => score.last_answered)),
          recent_correct_percentage: mean(yearScores.map(score => score.recent_correct_percentage)),
          total_answers: sum(yearScores.map(score => score.total_answers)),
          is_ahead: null,
        }
      }

      return null
    },
    refresh_subjects_for_topic(topic_id: string): AdminSubject[] {
      return self
        .selectedRefresh
        .subjects
        .filter(refresh_subject => refresh_subject.parent._id === topic_id)
    },
    all_scores(student_id?: string): Score[] {
      return self
        .classPerformance
        .scores_by_student
        .filter(s => {
          if (student_id) {
            return s.student._id === student_id
          }
          return true
        })
        .map(s => s.scores)
        .flat()
        .filter(s => Boolean(s.total_answers)) as Score[]
    }
  }))
  .views(self => ({
    scores_for_refresh(refresh: Refresh, student?: Student): Score[] {
      return self
        .all_scores(student?._id)
        .filter(s => refresh.subjects.map(s => s._id).includes(s.subject._id))
    },
    scores_for_subject(student: Student, subject: AdminSubject) {
      return self
        .all_scores(student._id)
        .filter(score =>
          self
            .refresh_subjects_for_topic(subject?._id)
            .map(j => j._id)
            .includes(score.subject._id)
        )
    },
    has_live_endcode(student: Student, topic: AdminSubject) {
      const {subjectsStore, teacherPageStore}: IRichStore = getRoot(self)

      const scores = self.classPerformance
        .scores_by_student
        .find(s => s.student._id === student._id)
        .scores

      const subject_score = scores.find(s => s.subject._id === teacherPageStore.currentClass.subject._id)

      return subjectsStore
        .subjects
        .filter(s => s.parent?._id === topic._id)
        .filter(endcode => {
          const endcode_score = scores.find(s => s.subject._id === endcode._id)

          return isEndcodeLive(subject_score?.is_ahead, endcode_score?.score, subject_score?.score, endcode)
        })
        .length > 0
    }
  }))
  .views(self => ({
    is_subject_live(student: Student, subject: AdminSubject) {
      const {teacherPageStore}: IRichStore = getRoot(self)

      if (subject.type === 'topic') {
        return self.has_live_endcode(student, subject)
      }

      if (subject.type === 'endcode') {
        const scores = self.classPerformance
          .scores_by_student
          .find(s => s.student._id === student._id)
          .scores

        const subject_score = scores.find(s => s.subject._id === teacherPageStore.currentClass.subject._id)
        const endcode_score = scores.find(s => s.subject._id === subject._id)

        return isEndcodeLive(subject_score?.is_ahead, endcode_score?.score, subject_score?.score, subject)
      }

      return true
    }
  }))
  .views(self => ({
    selected_focus_score(scores: Score[]): Score {
      const {teacherPageStore}: IRichStore = getRoot(self)

      if (
        self.selectedTopic ||
        self.selectedEndcode
      ) {
        return self.selected_focus(scores)
      }

      return scores.find(s => s.subject._id === teacherPageStore.currentClass.subject._id)
    },
    min_max_subject_score(): IMinMaxSubjectScoreResult {
      const is_refresh_selected = !self.selectedEndcode?._id &&
        !self.selectedTopic?._id &&
        self.selectedRefresh?._id

      if (is_refresh_selected) {
        const scores = self
          .classPerformance
          .scores_by_student
          .map(({scores}) => scores)
          .map((scores) => scores.filter(s => self.selectedRefresh?.subjects.map(s => s._id).includes(s.subject._id)))
          .map(scores => mean(scores.map(score => score[self.selectedScoreType.value])))

        return {
          min_subject_score: min(scores) ?? 0,
          max_subject_score: max(scores) ?? 0,
        }
      }

      const scores = (
        self
          .classPerformance
          .scores_by_student
          .map(({scores, student}) => (scores ?? []).filter(s => self.is_subject_live(student, s.subject)))
          .flat()
          .filter((score) => {
            if (self.selectedEndcode?._id) {
              return score.subject._id === self.selectedEndcode?._id
            }

            if (self.selectedTopic?._id) {
              return score.subject._id === self.selectedTopic?._id
            }

            return score.subject.type === 'subject'
          })
          .map(score => score[self.selectedScoreType.value])
      )

      return {
        min_subject_score: min(scores) ?? 0,
        max_subject_score: max(scores) ?? 0,
      }
    },
  }))
  .views(self => ({
    get topic_options() {
      const {subjectsStore: {topics}, teacherPageStore: {currentClass}}: IRichStore = getRoot(self)

      return (self.selectedRefresh ?
        (
          uniqBy(
            self.selectedRefresh.subjects
              .map((endcode: AdminSubject) => ({
                label: endcode.parent.name,
                value: endcode.parent._id
              })),
            ({label}) => label
          )
        ) :
        (
          topics()
            .filter(topic => topic.parent.parent._id === currentClass.subject._id)
            .map((topic: AdminSubject) => ({
              label: topic.name,
              value: topic._id
            }))
        )
      )
        .sort((a, b) => a.label <= b.label ? -1 : 1)

    },
    get endcode_options() {
      const {subjectsStore}: IRichStore = getRoot(self)
      const selectable_endcodes = self.selectedRefresh ?
        self.selectedRefresh.subjects :
        subjectsStore.endcodes()

      return selectable_endcodes
        .filter((subject: AdminSubject) => subject.parent._id === self.selectedTopic?._id)
        .map(({description, _id, priority}: AdminSubject) => ({
          label: description,
          value: _id,
          priority
        }))
    }
  }))
  .views(self => ({
    subjectLiveCount: (current_subject: AdminSubject) => {
      const { subjectsStore, teacherPageStore }: IRichStore = getRoot(self)
      if (current_subject.type === 'topic') {
        const live_students = self
          .classPerformance
          .scores_by_student
          .filter(score_by_student => {
            const subject_score = score_by_student
              .scores
              .find((s) => s.subject._id === teacherPageStore.currentClass.subject._id)

            return subjectsStore
              .subjects
              .filter(s => s.parent?._id === current_subject._id)
              .filter(endcode => {
                const endcode_score = score_by_student.scores.find(s => s.subject._id === endcode._id)

                return isEndcodeLive(subject_score?.is_ahead, endcode_score?.score, subject_score?.score, endcode)
              })
              .length > 0
          })

        return live_students.length
      }

      const live_subject_scores = self
        .classPerformance
        .scores_by_student
        .filter(score_by_student => {
          const subject_score = score_by_student
            .scores
            .find((s) => s.subject._id === teacherPageStore.currentClass.subject._id)

          const endcode_score = score_by_student.scores.find(s => s.subject._id === current_subject._id)

          return isEndcodeLive(subject_score?.is_ahead, endcode_score?.score, subject_score?.score, current_subject)
        })

      return live_subject_scores.length
    }
  }))

export type ClassPerformanceStore = Instance<typeof ClassPerformanceStore>
