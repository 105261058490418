import {Instance, types} from 'mobx-state-tree'

import {ThemeId, TopicId} from './id'
import {GetSubjectThemesRequest} from './theme'
import {BaseSubject} from './base'

import {ScoreDistribution} from 'common/types/question/score_distribution'
import {PositiveInteger} from 'common/types/basic/positive_int'
import {Score} from 'common/types/question/score'

export const AdminTopic = BaseSubject
  .named('AdminTopic')
  .props({
    min_score: Score,
    max_score: Score,
    question_distribution: ScoreDistribution,
    question_count: PositiveInteger,
    endcode_count: PositiveInteger,
  })
export type AdminTopic = Instance<typeof AdminTopic>

export const Topic = types.model('Topic', {
  _id: TopicId,
  name: types.string,
})
export type Topic = Instance<typeof Topic>

export const GetThemeTopicsRequest = types.model(
  'GetThemeTopicsRequest',
  {
    params: types.model({
      theme_id: ThemeId
    }),
  }
)
export type GetThemeTopicsRequest = typeof GetSubjectThemesRequest

export const GetTopicRequest = types.model(
  'GetTopicRequest',
  {
    params: types.model({
      topic_id: TopicId
    }),
  }
)
export type GetTopicRequest = typeof GetTopicRequest

export const PostThemeTopicRequest = types.model(
  'PostThemeTopicRequest',
  {
    params: types.model({
      theme_id: ThemeId
    }),
    body: AdminTopic
  }
)
export type PostThemeTopicRequest = typeof PostThemeTopicRequest

export const ThemeTopics = types.array(AdminTopic)
