import React, {useCallback} from 'react'
import toast from 'react-hot-toast'
import {Outlet} from 'react-router'
/* eslint-disable */
import {useRegisterSW} from 'virtual:pwa-register/react'
/* eslint-enable */
import {ActionToast} from 'components/ActionToast'

const INTERVAL_MS = 60 * 1000

export const PwaPageWrapper = () => {
  const { updateServiceWorker } = useRegisterSW({
    immediate: true,
    onRegisteredSW(swUrl, r) {
      r && setInterval(async () => {
        if (r.installing || !navigator) {
          console.log('SW already installing or no navigator')
          return
        }

        if (('connection' in navigator) && !navigator.onLine) {
          console.log('SW Offline or no navigator connection')
          return
        }

        const resp = await fetch(swUrl, {
          cache: 'no-store',
          headers: {
            'cache': 'no-store',
            'cache-control': 'no-cache',
          },
        })

        if (resp?.status === 200) {
          console.log('updating SW')
          await r.update()
        }
      }, INTERVAL_MS)
    },
    onNeedRefresh: () => {
      console.log('onNeedRefresh')
      toast.custom((t) =>
        <CustomToast t={t} updateServiceWorker={updateServiceWorker} />,
      {
        duration: 999999,
        id: 'update_prompt'
      })
    }
  })

  return <Outlet />
}

const CustomToast = ({ t, updateServiceWorker }) => {
  const onDismiss = useCallback(async () => {
    console.log('dismiss', 0)
    await updateServiceWorker()
    console.log('dismiss', 1)
    window.location.reload()
    console.log('dismiss', 2)
  }, [])

  return (
    <ActionToast
      toastInstance={t}
      onDismiss={onDismiss}
      title="There are new updates"
      subTitle="Click update to install them"
      actionLabel="Update"
    />
  )
}