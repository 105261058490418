import {flow, getRoot, Instance, types} from 'mobx-state-tree'

import {getTutor} from 'util/api/tutor'
import {Subscription} from 'common/types/subscription'
import {listSubscriptions} from 'util/api/subscriptions'
import {Tutor} from 'common/types/tutor/tutor'
import {logError} from 'util/helpers/logError'

export const TutorData = types
  .model(
    'TutorData',
    {
      profile: types.maybe(Tutor),
      subscriptions: types.array(Subscription)
    }
  )
  .volatile(self => ({
    busy: false
  }))
  .actions(self => ({
    get_profile: flow(function* () {
      console.log('getting tutor profile...')
      if (self.busy) {
        console.log('Cannot get profile when busy')
        return
      }
      const my_id = (getRoot(self) as any)?.auth?.user?._id
      if (my_id === undefined) {
        console.log('Cannot get profile for unknown user')
      }
      self.busy = true
      try {
        const tutor = yield getTutor({ query: { _id: my_id } })
        self.profile = tutor
      } catch (error) {
        logError(error)
        console.log('error fetching profile...' + error)
        console.error(error.errors || error.message || error)
      }
      finally {
        self.busy = false
      }
    }),
    get_subscriptions: flow(function* () {
      try {
        const subs = yield listSubscriptions()
        self.subscriptions = subs

      } catch (error) {
        console.error(`get_subscriptions: ${error}`)
        throw error
      }
    }),
  }))
export type TutorData = Instance<typeof TutorData>
