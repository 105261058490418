import {Instance, types} from 'mobx-state-tree'

import {Student} from 'common/types/student'

export const AdminGetStudentsRequest = types.model({})
export type AdminGetStudentsRequest = Instance<typeof AdminGetStudentsRequest>

export const AdminGetStudentsResponse = types.model({
  success: types.boolean,
  students: types.array(Student)
})
export type AdminGetStudentsResponse = Instance<typeof AdminGetStudentsResponse>
