import React, {useCallback, useEffect, useRef, useState} from 'react'
import {observer} from 'mobx-react-lite'

import {SubscriptionJourneyTypes} from './SubscriptionWizardState'

import {PrimaryClickableButton, SecondaryClickableButton} from 'components/ClickableButton'
import {PreferredAvailabilityWindowSelection} from 'components/subscription/AvailabilityWindow'
import {TierCard} from 'components/subscription_tiers/TierCard'
import {SessionOptionEnum} from 'common/types/session/options'
import {TierOptionEnum} from 'types/subscriptions'
import {useStore} from 'types/store'

interface Props {
  shouldShowBack?: boolean,
  isModal: boolean
}

export const Availability = observer((
  {
    shouldShowBack = true,
    isModal
  }: Props
) => {
  const {
    subscription_wizard: {
      availability,
      back,
      next,
      selected_session_option,
      subscription_tiers,
      existing_subscription,
      subscription_journey_type
    }
  } = useStore()
  const [selectedTier, setSelectedTier] = useState(null)
  const preferredWindowRef = useRef<HTMLDivElement>(null)

  const showPreferred = subscription_journey_type === SubscriptionJourneyTypes.CreateSubscription ||
      existing_subscription.session_option === SessionOptionEnum.FreeTrial ||
      existing_subscription.session_option === SessionOptionEnum.PlatformOnly

  useEffect(() => {
    if (subscription_journey_type !== SubscriptionJourneyTypes.CreateSubscription) {
      if(
        existing_subscription.session_option !== SessionOptionEnum.FreeTrial &&
        existing_subscription.session_option !== SessionOptionEnum.PlatformOnly
      ) {
        setSelectedTier(TierOptionEnum.WithFocusTutoring)
        return
      }

      const tierName = subscription_tiers.find((tier) => tier.value === existing_subscription.session_option)?.name
      setSelectedTier(tierName)
    }
  }, [])

  const selectTierOption = useCallback((name) => () => {
    setSelectedTier(name)
  }, [selected_session_option])

  return (
    <div className='mt-5'>
      <div className='flex flex-wrap items-baseline gap-4'>
        {
          subscription_tiers.map((tier, i) => (
            <TierCard
              key={`Tier-${i}`}
              tier={tier}
              onSelectTier={selectTierOption(tier.name)}
              selectedTier={selectedTier}
              preferredWindowRef={preferredWindowRef}
            />
          ))
        }
      </div>

      {
        showPreferred && selectedTier === TierOptionEnum.WithFocusTutoring ?
          (
            <div ref={preferredWindowRef}>
              <PreferredAvailabilityWindowSelection />
            </div>
          ) :
          null
      }

      <div className="flex h-1/3 justify-around py-6">
        {shouldShowBack ?
          (
            <SecondaryClickableButton
              onClick={back}
            >
            Back
            </SecondaryClickableButton>
          ) :
          null
        }
        <PrimaryClickableButton
          onClick={next}
          disabled={
            !selectedTier ||
            (availability.length === 0 &&
              showPreferred && (
              selected_session_option !== SessionOptionEnum.PlatformOnly &&
                  selected_session_option !== SessionOptionEnum.FreeTrial
            ))
          }
        >
          Next
        </PrimaryClickableButton>
      </div>
    </div>
  )
})

