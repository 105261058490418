import Pusher from 'pusher-js'
import {Auth} from 'aws-amplify'

import {configureAuth} from 'util/auth/amplify'

let pusher: Pusher
export const getPusher = async () => {
  if (pusher) return pusher

  pusher = new Pusher(import.meta.env.REACT_APP_PUSHER_CLIENT, await getPusherOptions() as any)
  return pusher
}

interface IPusherHeaders {
    Authorization: string;
    'Cache-Control': string;
    Pragma: string;
    Expires: string
}

export interface IPusherOptions {
    cluster: string;
    clientKey: string;
    enabledTransports: string[];
    userAuthentication: {
        endpoint: string;
        transport: string;
        headersProvider: () => IPusherHeaders
    };
    channelAuthorization: {
        endpoint: string;
        transport: string;
        headersProvider: () => IPusherHeaders
    }
}

export const getPusherOptions: () => Promise<IPusherOptions> = async () => {
  await configureAuth(localStorage.getItem('plytime_role'))

  const authSession = await Auth.currentSession().catch(e => {
    console.log('[pusher_auth] error getting auth session:' + e)
    return undefined
  })

  return {
    clientKey: import.meta.env.REACT_APP_PUSHER_CLIENT,
    cluster: import.meta.env.REACT_APP_PUSHER_CLUSTER,
    enabledTransports: ['ws'],
    channelAuthorization: {
      headersProvider: () => {
        return {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
          Authorization: `Bearer ${authSession?.getIdToken()?.getJwtToken()}`,
        }
      },
      transport: 'ajax',
      endpoint: '/api/pusher/channel/auth',
    },
    userAuthentication: {
      headersProvider: () => {
        return {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
          Authorization: `Bearer ${authSession?.getIdToken()?.getJwtToken()}`,
        }
      },
      transport: 'ajax',
      endpoint: '/api/pusher/user/auth',
    },
  }
}
