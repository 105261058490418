import {DateTime} from 'luxon'
import {Instance, types} from 'mobx-state-tree'

import {Class} from 'common/types/class'
import {AdminSubject} from 'common/types/subject/subject'
import {Team} from 'common/types/teacher/teams'

export const CompetitionTypeEnum = types.enumeration('CompetitionTypeEnum', [
  'individual',
  'teams',
  'monthly'
])
export type CompetitionTypeEnum = Instance<typeof CompetitionTypeEnum>

export const Competition = types.model('Competition', {
  _id: types.identifier,
  description: types.maybe(types.string),
  first_prize: types.maybe(types.string),
  second_prize: types.maybe(types.string),
  type: types.maybe(CompetitionTypeEnum),
  name: types.string,
  class: types.maybeNull(types.reference(types.late(() => Class))),
  teams: types.array(types.reference(types.late(() => Team))),
  subject: types.reference(types.late(() => AdminSubject)),
  start: types.string,
  end: types.string,
  image_url: types.maybe(types.string),
  sponsor_name: types.maybe(types.string),
  monthly_competition: types.maybe(types.reference(types.late(() => Competition))),
})
  .views(self => ({
    get status() {
      const start_time = DateTime.fromISO(self.start, { setZone: false }).toLocal().toISO()
      const end_time = DateTime.fromISO(self.end, { setZone: false }).toLocal().toISO()
      const now = DateTime.now().toISO()

      if (now > start_time && now < end_time) {
        return 'Ongoing'
      }

      if (now > end_time) {
        return 'Finished'
      }

      if (now < start_time) {
        return 'Upcoming'
      }
    },
  }))
  .views(self => ({
    get ending_label() {
      if (self.status === 'Ongoing') {
        return 'Finishing in'
      }

      if (self.status === 'Finished') {
        return 'Lasted for'
      }

      if (self.status === 'Upcoming') {
        return 'Starts in'
      }
    },
  }))
export type Competition = Instance<typeof Competition>

export const NewCompetition = Competition.props({ _id: types.undefined })
export type NewCompetition = Instance<typeof NewCompetition>

export const PostNewCompetitionRequest = types.model({
  body: NewCompetition
})
export type PostNewCompetitionRequest = Instance<typeof PostNewCompetitionRequest>

export const PostNewCompetitionResponse = types.model({
  success: types.boolean
})
export type PostNewCompetitionResponse = Instance<typeof PostNewCompetitionResponse>

export const PostSetCompetitionTeamsRequest = types.model({
  body: types.model({
    competition_id: types.string,
    team_ids: types.array(types.string),
  })
})
export type PostSetCompetitionTeamsRequest = Instance<typeof PostSetCompetitionTeamsRequest>

export const PostSetCompetitionTeamsResponse = types.model({
  success: types.boolean
})
export type PostSetCompetitionTeamsResponse = Instance<typeof PostSetCompetitionTeamsResponse>

export const PutCompetitionRequest = types.model({
  body: Competition
})
export type PutCompetitionRequest = Instance<typeof PutCompetitionRequest>

export const PutCompetitionResponse = types.model({
  success: types.boolean
})
export type PutCompetitionResponse = Instance<typeof PutCompetitionResponse>
