import React from 'react'

import {PlytimePage} from 'components/plytime_page'
import {BlockLoader} from 'generic_components/BlockLoader'

export const PageLoader = ({ message = 'Loading' }) => {
  return (
    <PlytimePage>
      <div
        tabIndex={-1}
        className="fixed inset-0 z-50 flex w-full flex-col justify-center overflow-hidden bg-gray-900/50 p-4 md:inset-0"
      >
        <div
          className="m-auto w-fit"
        >
          <BlockLoader message={message} />
        </div>
      </div>
    </PlytimePage>
  )
}
