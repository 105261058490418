import React from 'react'
import {Link, useLocation} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import classNames from 'classnames'

interface IPageBreadcrumb {
    items: IBreadcrumb[]
}

export const PageBreadcrumb = observer(({ items }: IPageBreadcrumb): JSX.Element => {
  const {pathname} = useLocation()

  if (items.length === 0) {
    return null
  }
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 rounded bg-gray-100 px-4 py-2 md:space-x-3">
        {
          items.map((item, i) => {
            return (
              <Breadcrumb
                key={i}
                label={item.label}
                href={item.href}
                isLast={items.length === i + 1}
                isCurrent={pathname === item.href}
              />
            )
          })
        }
      </ol>
    </nav>
  )
})

interface IBreadcrumb {
    isLast?: boolean,
    label: string|React.JSX.Element,
    href: string,
}

interface IBreadcrumbProps extends IBreadcrumb{
    isCurrent: boolean
}

const Breadcrumb = ({ isLast = false, label, href, isCurrent }: IBreadcrumbProps): JSX.Element => {
  return (
    <li className="inline-flex items-center">
      <Link
        to={href}
        className={
          classNames(
            'inline-flex items-center text-sm hover:text-blue-600 font-medium',
            isCurrent ? 'text-blue-700' : 'text-gray-700 '
          )
        }
      >
        { label }
        {
          isLast ?
            null :
            <i className="fa-solid fa-arrow-right pl-4"></i>
        }
      </Link>
    </li>
  )
}
