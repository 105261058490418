import React, {useCallback, useState} from 'react'
import {at, chunk} from 'lodash'
import classNames from 'classnames'
import {observer} from 'mobx-react-lite'
import {DateTime} from 'luxon'

import {SecondaryClickableButton} from './ClickableButton'

import {logError} from 'util/helpers/logError'

interface IFormatterProps<T> {
    value: string | number | null,
    row: T,
    index: number
}

interface IHeader<T> {
    label?: string | React.JSX.Element | ((rows: T[]) => string),
    key?: string,
    getter?: (row: T, index: number) => number | string | null | React.JSX.Element, // todo remove React.JSX.Element from this type
    formatter?: (props: IFormatterProps<T>) => number | string | null | React.JSX.Element,
    hideSortIcon?: boolean,
}

export interface IResponsiveTableProps<T> {
    headers: IHeader<T>[],
    rows: T[],
    onClickRow?: (row: T, index: number) => void,
    noRowsMessage?: string | React.JSX.Element,
    rowStyle?: (row: T, index: number) => string | undefined,
    chunkSize?: number,
    isSortable?: boolean,
    expanded_row?: (row: T, index: number) => number | string | null | React.JSX.Element,
}

const DEFAULT_CHUNK_SIZE = 50

const getLabel = <T,>(header: IHeader<T>, rows): string|null|React.JSX.Element => {
  if (typeof header.label === 'string') {
    return header.label
  }

  if (typeof header.label === 'function') {
    return header.label(rows)
  }

  return header.label
}

const getValue = (header, row, i) => {
  if (!header) {
    return
  }
  if (header.getter) {
    return header.getter(row, i)
  }

  return at(row, header.key)
}

export const ResponsiveTable = observer(<T, >({
  headers,
  rows,
  onClickRow,
  noRowsMessage,
  rowStyle,
  chunkSize = DEFAULT_CHUNK_SIZE,
  isSortable = true,
  expanded_row
}: IResponsiveTableProps<T>): React.JSX.Element => {
  const [expandedRowIndex, setExpandedRowIndex] = useState<number|null>(null)
  const [sortKey, setSortKey] = useState<string|React.JSX.Element>(null)
  const [sortDirection, setSortDirection] = useState<1|-1>(1)
  const [chunkIndex, setChunkIndex] = useState(0)

  const chunks = chunk(
    (rows ?? [])
      .filter(Boolean)
      .slice()
      .sort((a, b) => {
        if (sortKey) {
          const header = headers.find(h => getLabel(h, rows) === sortKey)
          const aValue = getValue(header, a, 0)
          const bValue = getValue(header, b, 0)

          // if the values are 'date-like' convert to ISO before sorting
          try {
            const aValueAsDate = DateTime.fromFormat(aValue, 'D', {locale: 'en-GB'})
            const bValueAsDate = DateTime.fromFormat(bValue, 'D', {locale: 'en-GB'})

            if (aValueAsDate.isValid && bValueAsDate.isValid) {
              console.log('is date-like')
              const aIso = aValueAsDate.toISO()
              const bIso = bValueAsDate.toISO()
              if (sortDirection === 1) {
                return aIso > bIso ? 1 : -1
              }
              if (sortDirection === -1) {
                return aIso > bIso ? -1 : 1
              }
            }
          } catch (e) {
            logError(e)
          }

          const numeric_regex = /^[0-9]+$/
          // if the values are 'number-like' convert to number before sorting
          if (numeric_regex.test(aValue) && numeric_regex.test(bValue)) {
            console.log('is numeric')
            const aNumberValue = Number.parseInt(aValue)
            const bNumberValue = Number.parseInt(bValue)
            if (sortDirection === 1) {
              return aNumberValue > bNumberValue ? 1 : -1
            }
            if (sortDirection === -1) {
              return aNumberValue > bNumberValue ? -1 : 1
            }
          }

          if (sortDirection === 1) {
            return aValue > bValue ? 1 : -1
          }
          if (sortDirection === -1) {
            return aValue > bValue ? -1 : 1
          }
        }
      }),
    chunkSize
  )

  const decrementChunkIndex = useCallback(() => {
    setChunkIndex(chunkIndex - 1)
    setExpandedRowIndex(null)
  }, [chunkIndex])
  const incrementChunkIndex = useCallback(() => {
    setChunkIndex(chunkIndex + 1)
    setExpandedRowIndex(null)
  }, [chunkIndex])
  const setSpecificChunkIndex = useCallback((index) => () => {
    setChunkIndex(index)
    setExpandedRowIndex(null)
  }, [])


  const thStyle = classNames(
    'px-2 py-4 bg-gray-700 text-gray-200 text-left',
    isSortable && 'hover:text-white hover:underline cursor-pointer'
  )

  const onClickHeaderCallback = useCallback((label) => () => {
    setExpandedRowIndex(null)

    if (!isSortable) {
      return
    }

    if (sortKey !== label) {
      setSortKey(label)
      return
    }

    if (sortDirection === 1) {
      setSortDirection(-1)
      return
    }

    if (sortDirection === -1) {
      setSortDirection(1)
      return
    }
  }, [
    expandedRowIndex,
    sortKey,
    sortDirection
  ])

  const onClickRowCallback = useCallback((row, i) => (e) => {
    if (expandedRowIndex === i) {
      setExpandedRowIndex(null)
    } else {
      setExpandedRowIndex(i)
    }

    if (onClickRow) {
      e.preventDefault()
      onClickRow(row, i)
    }
  }, [expandedRowIndex, onClickRow])

  return (
    <>
      <table className="mx-auto w-full table-auto">
        {
          headers.some((header) => header.label) ?
            (
              <thead className="sticky top-0 z-10">
                <tr className="bg-gray-100 p-2">
                  {
                    headers.map((header, i) => {
                      const label = getLabel<T>(header, rows)
                      return (
                        <th
                          className={thStyle}
                          key={typeof label === 'string' ? label : i}
                          onClick={onClickHeaderCallback(label)}
                        >
                          <div className="flex w-max">
                            {!header.hideSortIcon && isSortable && Boolean(label) && sortKey !== label ? <NoSortIcon /> : null}
                            {!header.hideSortIcon && isSortable && sortKey && sortKey === label && sortDirection === 1 ? <UpSortIcon /> : null}
                            {!header.hideSortIcon && isSortable && sortKey && sortKey === label && sortDirection === -1 ? <DownSortIcon /> : null}
                            {label}
                          </div>
                        </th>
                      )
                    })
                  }
                  {expanded_row ? <th className={thStyle}></th> : null}
                </tr>
              </thead>
            ) :
            null
        }

        {
          rows.length ?
            (
              <tbody>
                {chunks[chunkIndex].map((row, i) => (
                  <>
                    <tr
                      key={(row as any)?._id ?? i}
                      className={
                        classNames(
                          'p-2 border-t-2 border-solid border-black last:border-b-0 whitespace-nowrap',
                          onClickRow || expanded_row ?
                            'cursor-pointer hover:bg-gray-400' :
                            null,
                          rowStyle && rowStyle(row, i),
                          i % 2 === 0 ?
                            'bg-gray-300' :
                            'bg-gray-100',
                        )
                      }
                      onClick={onClickRowCallback(row, i)}
                    >
                      {
                        headers.map((header, j) => {
                          const value = getValue(header, row, i)

                          return (
                            <td
                              key={value?._id ?? j}
                              className="p-2"
                            >
                              {
                                header.formatter ?
                                  header.formatter({value, row, index: i}) :
                                  value
                              }
                            </td>
                          )
                        })
                      }
                      {
                        expanded_row ?
                          (
                            <td className="p-2">
                              {
                                expandedRowIndex === i ?
                                  <i className="fa-solid fa-caret-up text-blue-500"></i>:
                                  <i className="fa-solid fa-caret-down text-blue-500"></i>
                              }
                            </td>
                          )                        :
                          null
                      }
                    </tr>
                    {
                      expanded_row && expandedRowIndex === i ?
                        (
                          <tr>
                            <td
                              className={
                                classNames(
                                  'px-4 py-8',
                                  i % 2 === 0 ?
                                    'bg-gray-300' :
                                    'bg-gray-100',
                                  rowStyle && rowStyle(row, i),
                                )
                              }
                              colSpan={headers.length + 1}
                            >
                              {expanded_row(row, i)}
                            </td>
                          </tr>
                        ) :
                        null
                    }
                  </>
                ))}
              </tbody>
            ) :
            null
        }
      </table>

      {!rows.length ?
        <div className="bg-gray-100 py-2 text-center">{noRowsMessage}</div> :
        null
      }

      {
        chunks.length > 1 ?
          (
            <div className="flex justify-between overflow-x-auto">
              <div className="p-4">
                <SecondaryClickableButton
                  disabled={chunkIndex === 0}
                  onClick={decrementChunkIndex}
                  ariaLabel="page-prev"
                >
                  <i className="fa-solid fa-arrow-left"></i>
                </SecondaryClickableButton>
              </div>
              <div className="flex space-x-2 p-4">
                {
                  chunks.map((chunk, index) => {
                    return (
                      <SecondaryClickableButton
                        disabled={chunkIndex === index}
                        key={index}
                        className="mb-2"
                        onClick={setSpecificChunkIndex(index)}
                      >
                        {index + 1}
                      </SecondaryClickableButton>
                    )
                  })
                }
              </div>
              <div className="p-4">
                <SecondaryClickableButton
                  disabled={(chunkIndex + 1) === chunks.length}
                  onClick={incrementChunkIndex}
                  ariaLabel="page-next"
                >
                  <i className="fa-solid fa-arrow-right"></i>
                </SecondaryClickableButton>
              </div>
            </div>
          ) :
          null
      }
    </>
  )
})
ResponsiveTable.displayName = 'ResponsiveTable'

const NoSortIcon = () => {
  return (
    <span>
      <span className="fa-stack">
        <i className="fa-solid fa-square fa-stack-2x text-gray-100"></i>
        <i className="fa-solid fa-sort fa-stack-1x text-gray-800"></i>
      </span>
    </span>
  )
}

const UpSortIcon = () => {
  return (
    <span>
      <span className="fa-stack">
        <i className="fa-solid fa-square fa-stack-2x text-gray-100"></i>
        <i className="fa-solid fa-sort-up fa-stack-1x text-gray-800"></i>
      </span>
    </span>
  )
}

const DownSortIcon = () => {
  return (
    <span>
      <span className="fa-stack">
        <i className="fa-solid fa-square fa-stack-2x text-gray-100"></i>
        <i className="fa-solid fa-sort-down fa-stack-1x text-gray-800"></i>
      </span>
    </span>
  )
}
