import React, {FC, useCallback, useEffect, useRef, useState} from 'react'
import {SnapshotOrInstance} from 'mobx-state-tree'
import {DateTime} from 'luxon'
import {observer} from 'mobx-react-lite'

import {Question} from 'components/question'
import {VideoHelp} from 'components/video_help'
import {PrimaryClickableButton, SecondaryClickableButton} from 'components/ClickableButton'
import {Modal} from 'components/modal/modal'
import {PriorityIcon} from 'components/PriorityIcon'
import {Table} from 'components/question_review/answersTable'
import {putResolvedStatus, putReviewStatus} from 'util/api/student/answers'
import {StudentId} from 'common/types/student/id'
import {Icon} from 'generic_components/Icon'
import {BlockLoader} from 'generic_components/BlockLoader'
import {useStore} from 'types/store'
import {useUser} from 'context/userContext'
import {Roles} from 'util/auth/helper'
import {logError} from 'util/helpers/logError'

interface AnswerProps {
  row: any,
  update: any
}

interface QuestionReviewProps {
  student_id: SnapshotOrInstance<StudentId>
  AnswerDetailComponent: FC<AnswerProps>
  initialFilters?: IInitialFilter[],
  fetch_since?: string
}

interface GameReviewProps {
  student_id: SnapshotOrInstance<StudentId>
  AnswerDetailComponent: FC<AnswerProps>
  game_start_time: DateTime
  initialFilters?: IInitialFilter[]
}

export interface IInitialFilter {
  id: QuestionTableHeader,
  value: string,
}

interface QuestionTableProps {
  isLoading?: boolean
  AnswerDetailComponent: FC<AnswerProps>
  showDate?: boolean
  initialFilters?: IInitialFilter[]
}

export enum QuestionTableHeader {
  Date='Date',
  Theme='Theme',
  Topic='Topic',
  Endcode='Endcode',
  Difficulty='Difficulty',
  Performance='Performance',
  Priority='Priority',
  Reviewed='Reviewed',
  Tokens='Tokens',
}

const QuestionTable: FC<QuestionTableProps> = observer(({ isLoading = false, AnswerDetailComponent, showDate, initialFilters=[] }) => {
  const {answersStore} = useStore()
  const pending_tokens_cutoff_date = DateTime.now().minus({days: 14}).toISODate()

  const columns = [
    ...showDate ?
      [{
        Header: QuestionTableHeader.Date,
        accessor: answer => DateTime.fromISO(answer.timestamp),
        Cell: ({ cell }) => cell.value.toFormat('dd/MM/yy HH:mm'),
        disableFilters: true
      }] :
      [],
    {
      Header: QuestionTableHeader.Theme,
      accessor: (answer) => answer.subject[1].name
    },
    {
      Header: QuestionTableHeader.Topic,
      accessor: (answer) => answer.subject[2].name
    },
    {
      Header: QuestionTableHeader.Endcode,
      accessor: (answer) => answer.subject[3].description,
      Cell: ({ cell }) => <div title={cell.value}>
        {
          cell.value?.length <= 10 ?
            cell.value :
            cell.value?.substr(0, 9) + '...'
        }
      </div>
    },
    {
      Header: QuestionTableHeader.Difficulty,
      accessor: 'difficulty',
    },
    {
      Header: QuestionTableHeader.Performance,
      accessor: (answer) => {
        if (!answer.question) {
          return ''
        }
        if (answer.result === 'INCORRECT') return 'wrong'
        if (answer.result === 'PASSED') return 'pass'
        if (answer.result === 'CORRECT') {
          const response_time_pc = (answer.response_time / answer.question.standard_time) * 100
          if (response_time_pc <= 100) return 'perfect'
          if (answer.response_time <= answer.question.maximum_time) return 'okay'
          if (answer.response_time > answer.question.maximum_time) return 'good effort'
        }
      },
      Cell: ({ cell }) => <span
        color={
          cell.value.includes('perfect') ?
            'success' :
            cell.value.includes('good effort') ?
              'warning' :
              cell.value.includes('okay') ?
                'secondary' :
                cell.value.includes('wrong') ?
                  'danger' :
                  'primary'
        }
      >
        {cell.value}
      </span>
    },
    {
      Header: QuestionTableHeader.Tokens,
      accessor: (answer) => {
        if (answer.timestamp < pending_tokens_cutoff_date) return 'n/a'
        if (answer.timestamp < '2022-12-21T15:00:00') return 'n/a'
        if (answer.result === 'INCORRECT' && !answer.reviewed) return 'pending'
        if (answer.result === 'PASSED' && !answer.reviewed) return 'pending'

        return 'awarded'
      },
      Cell: ({ cell }) => {
        if (cell.value === 'n/a') return 'n/a'

        return (
          cell.value === 'awarded' ?
            <Icon icon="fa-check fa-solid"/> :
            <Icon icon="fa-xmark fa-solid"/>
        )
      }
    },
    {
      Header: QuestionTableHeader.Priority,
      accessor: (row) => {
        if (row.subject[3]) {
          return row.subject[3].priority
        }
      },
      Cell: ({ cell }) => {
        return (
          <PriorityIcon priority={cell.value} />
        )
      }
    },
    {
      Header: QuestionTableHeader.Reviewed,
      accessor: (answer) => {
        if (answer.help_requested && !answer.resolved) {
          return 'help'
        }

        if (!answer.reviewed && !answer.help_requested) {
          return 'todo'
        }

        return 'okay'
      },
      Cell: ({ cell }) => {
        if (cell.value === 'help') {
          return (
            <Icon
              icon="fa-flag fa-solid text-red-500"
            />
          )
        }

        if (cell.value === 'okay') {
          return (
            <Icon
              icon="fa-check fa-solid text-green-600"
            />
          )
        }

        return (
          <Icon
            icon="fa-square fa-regular"
          />
        )
      }
    },
    {
      Header: () => null,
      id: 'expander',
      accessor: (answer) => answer,
      Cell: ({ row }) => (
        <Icon icon={row.isExpanded ? 'fa-chevron-up fa-solid' : 'fa-chevron-down fa-solid'} />
      ),
      disableFilters: true,
      disableSortBy: true,
    },
  ]

  return (
    <div>
      {answersStore.answers !== undefined && (
        <>
          {answersStore.answers.length === 0 && !isLoading && <div className="rounded bg-gray-100 p-4 shadow">No answers found. Please load another weeks data.</div>}
          {
            answersStore.answers.length > 0 && (
              <Table
                columns={columns}
                data={answersStore.answers}
                RowSubComponent={AnswerDetailComponent}
                update={answersStore.update_answer}
                initialFilters={initialFilters}
              />
            )
          }
          {isLoading && (
            <div className="my-6">
              <BlockLoader
                message={'Please wait...'}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
})

export const GameReview: FC<GameReviewProps> = observer(({
  student_id,
  AnswerDetailComponent,
  game_start_time,
  initialFilters
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const { answersStore, subjectsStore } = useStore()

  useEffect(() => {
    subjectsStore
      .get_all_subjects()
      .then(() => answersStore.get_answers(student_id, game_start_time.toISO()))
      .then(() => setIsLoading(false))
  }, [])

  if (isLoading) {
    return <BlockLoader message="Loading answers" />
  }

  return (
    <>
      {answersStore.answers && (
        <QuestionTable
          AnswerDetailComponent={AnswerDetailComponent}
          initialFilters={initialFilters}
        />
      )}
    </>
  )
})

export const QuestionReview: FC<QuestionReviewProps> = observer(({ student_id, AnswerDetailComponent, initialFilters= [], fetch_since }) => {
  const [isLoading, setIsLoading] = useState(true)
  const { answersStore, subjectsStore } = useStore()

  useEffect(() => {
    const two_weeks_ago = DateTime.now().minus({weeks: 2}).toISO()
    subjectsStore
      .get_all_subjects()
      .then(() =>
        answersStore.get_answers(
          student_id,
          fetch_since ?? two_weeks_ago
        )
      )
      .then(() => setIsLoading(false))
  } , [])


  if (isLoading) {
    return <BlockLoader />
  }

  return (
    <div className="flex flex-col items-center">
      <div className="w-full overflow-x-auto">
        <QuestionTable
          isLoading={isLoading}
          AnswerDetailComponent={AnswerDetailComponent}
          showDate
          initialFilters={initialFilters}
        />
      </div>
      <div className="mt-6">
        <PrimaryClickableButton
          type="button"
          disabled={isLoading}
          onClick={answersStore.load_next_week}
        >
          {
            `Load data from ${
              Math.floor(
                DateTime
                  .now()
                  .diff(
                    DateTime.fromISO(answersStore.fetch_since)
                  )
                  .as('weeks') + 1)
            } weeks ago`
          }
        </PrimaryClickableButton>
      </div>
    </div>
  )
})

const markReviewed = async (answer, update) => {
  if (answer.reviewed) { return }
  try {
    const result = await putReviewStatus({
      answer_id: answer._id,
      help_requested: answer.help_requested,
      reviewed: true,
    })
    if (!result.success) {
      throw new Error('Failed to update reviewed flag')
    }
    update({
      _id: answer._id,
      reviewed: true
    })
  } catch (e) {
    logError(e)
    console.error('Failed to update reviewed flag, undoing local change')
    update({
      _id: answer._id,
      reviewed: false,
    })
  }
}

export const AnswerDetailView = observer(({ row: { values: { expander: answer } }, update }: any) => {
  const {student_tokens} = useStore()
  const {user} = useUser()
  const [watchVideos, setWatchVideos] = useState<boolean>(false)
  const [hasEnoughTimePassed, setHasEnoughTimePassed] = useState<boolean>(false)

  const timeout = useRef<NodeJS.Timeout>()
  const onClickMarkReviewedCallback = useCallback(
    () => {
      markReviewed(answer, update).then(() => {
        if (timeout.current !== undefined) {
          clearTimeout(timeout.current)
        }

        if (user.role === Roles.STUDENT) {
          student_tokens.fetch_tokens(user._id)
        }
      })
    },
    [answer, timeout, update]
  )


  useEffect(() => {
    if (timeout.current !== undefined) {
      clearTimeout(timeout.current)
    }
    if (!answer.reviewed) {
      timeout.current = setTimeout(() => {
        setHasEnoughTimePassed(true)
      }, 5000)
    }
    return () => {
      if (timeout.current !== undefined) {
        clearTimeout(timeout.current)
      }
    }
  }, [answer])

  const requestHelp = async () => {
    if (timeout.current !== undefined) {
      clearTimeout(timeout.current)
    }
    const help_requested = !answer.help_requested
    update({
      _id: answer._id,
      help_requested,
      reviewed: true
    })
    try {
      const result = await putReviewStatus({
        answer_id: answer._id,
        help_requested,
        reviewed: true,
      })
      if (!result.success) {
        throw new Error('Failed to update help flag')
      }
    } catch (e) {
      logError(e)
      console.error('Failed to update help flag, undoing local change')
      update({
        _id: answer._id,
        help_requested: !help_requested,
        reviewed: answer.reviewed
      })
    }
  }

  const markResolved = async () => {
    if (answer.resolved) { return }
    try {
      const result = await putResolvedStatus({
        answer_id: answer._id,
        resolved: true
      })
      if (!result.success) {
        throw new Error('Failed to update reviewed flag')
      }
      update({
        _id: answer._id,
        resolved: true
      })
    } catch (e) {
      logError(e)
      console.error('Failed to update reviewed flag, undoing local change')
      update({
        _id: answer._id,
        resolved: false
      })
    }
  }

  return (
    <div>
      {watchVideos && (
        <Modal
          onClose={() => setWatchVideos(false)}
          title={`Help for: ${answer.subject[3].description}`}
          content={(
            <div>
              <VideoHelp subject={answer.subject[3]} help_videos={answer.subject[3].help_videos}/>
            </div>
          )}
        />
      )}

      <div className="p-6">
        <div className="flex">
          <div className="mx-auto">
            <Question
              question={answer.question}
              answer_decision={answer.result}
              student_answer={answer.answer}
              correct_answers={answer.question.answers}
              update_answer={noop}
            />
          </div>
        </div>

        {
          user.role === Roles.TUTOR ?
            (
              <div className="h-[700px]">
                <iframe src='https://sketch.io/sketchpad/' className="size-full" />
              </div>
            ) :
            null
        }


        <div className="flex justify-between py-2">
          {answer.subject[3].help_videos && answer.subject[3].help_videos.length > 0 &&
              <PrimaryClickableButton
                type="button"
                disabled={!answer.subject[3].help_videos || answer.subject[3].help_videos.length === 0}
                onClick={() => setWatchVideos(true)}
              >
                Help &nbsp;
                <Icon icon={'fa-video fa-solid'} />
              </PrimaryClickableButton>
          }

          {(answer.help_requested && !answer.resolved) &&
              <SecondaryClickableButton
                type="button"
                onClick={markResolved}
              >
                Resolve &nbsp;
                <Icon icon={'fa-flag fa-solid'} />
              </SecondaryClickableButton>
          }


          {
            !answer.help_requested ?
              (
                <SecondaryClickableButton
                  type="button"
                  // disabled={answer.length < 2}
                  onClick={requestHelp}
                >
                    Request help &nbsp;
                  <Icon icon={'fa-flag fa-solid'} />
                </SecondaryClickableButton>
              ) :
              null
          }
          {
            !answer.reviewed ?
              (
                <PrimaryClickableButton
                  type="button"
                  disabled={!hasEnoughTimePassed}
                  onClick={onClickMarkReviewedCallback}
                >
                  Mark as reviewed &nbsp;
                  <Icon icon={'fa-check fa-solid'} />
                </PrimaryClickableButton>
              ) :
              null
          }
        </div>
      </div>
    </div>
  )
})

const noop = (line_elem_key: string, ref: string, value: string, selected: boolean) => undefined
