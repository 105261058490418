import React, {FC, ReactElement, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import {PusherProvider} from '@harelpls/use-pusher'

import {PlytimePage} from 'components/plytime_page'
import {SubscriptionLevelTooLow} from 'components/SubscriptionLevelTooLow'
import {PageLoader} from 'generic_components/PageLoader'
import {useStore} from 'types/store'
import {MenuWrapper} from 'generic_components/MenuWrapper'
import {getPusherOptions, IPusherOptions} from 'services/pusher'

interface Props {
  children: ReactElement|ReactElement[]|any
  fullScreen?: boolean
}

export const PagesRequiringASubscription = [
  '/student/answers',
  '/student/progress',
  '/student/refresh'
]

export const PlytimePageStudentBackground: FC<Props> = observer(({ children, fullScreen=false }) => {
  const {pathname} = useLocation()
  const {student_subscriptions} = useStore()
  const [pusherOptions, setPusherOptions] = useState<IPusherOptions>()

  useEffect(() => {
    getPusherOptions().then(options => setPusherOptions(options))
  }, [])

  if (!pusherOptions) {
    return <PageLoader />
  }

  return (
    <PlytimePage fullwidth={true}>
      {
        fullScreen ?
          (
            <PusherProvider {...pusherOptions as any}>
              {children}
            </PusherProvider>
          ) :
          (
            <MenuWrapper>
              <PusherProvider {...pusherOptions as any}>
                <div className="mx-auto max-w-6xl">
                  {
                    (
                      PagesRequiringASubscription.includes(pathname) &&
                                          !student_subscriptions.current_active_subscription
                    ) ?
                      <SubscriptionLevelTooLow/> :
                      children
                  }
                </div>
              </PusherProvider>
            </MenuWrapper>
          )
      }
    </PlytimePage>
  )
})
PlytimePageStudentBackground.displayName = 'PlytimePageStudentBackground'
