import {Instance, types} from 'mobx-state-tree'

export const AttendanceStreakPostRequest = types.model({
  body: types.model({
    student_id: types.string,
  })
}).named('AttendanceStreakPostRequest')
export type AttendanceStreakPostRequest = Instance<typeof AttendanceStreakPostRequest>
export const AttendanceStreakPostResponse = types.model({
  streak: types.number
}).named('AttendanceStreakPostResponse')
export type AttendanceStreakPostResponse = Instance<typeof AttendanceStreakPostResponse>
