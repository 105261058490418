import { flow, Instance, types } from 'mobx-state-tree'

import { AnswerStat } from 'common/types/student/answer_stats'
import { getAnswerStats } from 'util/api/student/get_answer_stats'

export const AnswerStatsStore = types.model({
  answer_stats: types.array(AnswerStat),
  is_loading: types.boolean
}).actions(self => ({
  get_answer_stats: flow(function* (student_id: string) {
    self.is_loading = true
    const { stats } = yield getAnswerStats(student_id)
    self.answer_stats = stats
    self.is_loading = false
  }),
}))

export type AnswerStatsStore = Instance<typeof AnswerStatsStore>
