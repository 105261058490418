import {AdminSubject} from 'common/types/subject/subject'

export const isEndcodeLive = (
  is_ahead: boolean,
  endcode_score: number|null,
  subject_score: number|null,
  endcode: AdminSubject
) => {
  const liveBasedOnSubjectScore = checkScore(is_ahead, subject_score, endcode)

  if (endcode_score) {
    const liveBasedOnEndcodeScore = checkScore(is_ahead, endcode_score, endcode)
    return liveBasedOnSubjectScore && liveBasedOnEndcodeScore
  }

  return liveBasedOnSubjectScore
}

const checkScore = (is_ahead: boolean, score: number|null, endcode: AdminSubject): boolean => {
  if (!score) {
    return false
  }

  return (
    score >= endcode.min_score &&
        (
          is_ahead ?
            score < endcode.max_score :
            score < endcode.standard_level
        )
  )
}
