import {createContext, useContext} from 'react'
import {applySnapshot, Instance, onAction, onPatch, onSnapshot, types} from 'mobx-state-tree'

import {TranslationStore} from 'types/translation'
import {AuthModel} from 'types/user/model'
import {GameModel} from 'types/game/model'
import {QuestionManagement, QuestionManagementModel} from 'types/question_management/model'
import {TutorList} from 'types/admin/tutor/tutor_list'
import {SubjectList} from 'types/reference/subjects/subject_list'
import {TutorData} from 'types/tutor'
import {AdminStore} from 'types/admin/store'
import {ParentData} from 'types/parent'
import {MixesList} from 'types/student/mixes'
import {StudentSubjectScoreList} from 'types/admin/student_subject_scores/student_subject_scores_list'
import {SubscriptionJourneyTypes, SubscriptionWizardState} from 'components/subscription/SubscriptionWizardState'
import {Tokens} from 'types/student/tokens'
import {StudentSubscriptions} from 'types/student/subscriptions'
import {PromoCodes} from 'types/PromoCodes/PromoCodes'
import {SchoolStore} from 'types/school'
import {SubjectsStore} from 'types/subjects'
import {TeacherStore} from 'types/teacher'
import {ClassPerformanceStore} from 'types/class_performance'
import {StudentsStore} from 'types/students'
import {SubscriptionsStore} from 'types/subscriptions'
import {TeacherPageStore} from 'types/page_stores/teacher'
import {UpcomingSessionsStore} from 'types/sessions/upcoming_sessions'
import {PastSessionsStore} from 'types/sessions/past_sessions'
import {StudentSessionStore} from 'types/session/student_session'
import {MessagesStore} from 'types/messages'
import {TutorPageStore} from 'types/page_stores/tutor'
import {ParentPageStore} from 'types/page_stores/parent_home'
import {FocusStatsStore} from 'components/progress/Components/FocusStatsStore'
import {RefreshesStore} from 'types/refreshes'
import {AnswersStore} from 'types/answers/answers'
import {AnswerStatsStore} from 'types/answer_stats/answer_stats'
import {TeacherCompetitionStore} from 'types/teacher/competitions'
import {TeacherStrengthsAndFocusesStore} from 'types/class_performance/strengths_and_focuses'
import {TeacherTeamsList} from 'types/teacher/teams'
import {TeacherStudentPerformanceScores} from 'types/class_performance/performance_scores'
import {TeacherHomeworkStore} from 'pages/teacher/performance_detail/teacher_homework/teacher_homework_store'
import {StudentHomeworkStore} from 'types/student_homework/student_homework_store'
import {JoinSessionsStore} from 'types/join_sessions_store'
import {SubjectManagementStore} from 'types/subject_management_store'
import {AttendanceStreakStore} from 'types/attendance_streak_store'
import {StudentTrendsStore} from 'types/student_trends_store'
import {ActionsStore} from 'types/actions/actions'
import {StudentMenuStore} from 'types/menu_store/student_menu_store'
import {StudentPerformanceStatsStore} from 'types/student/performance_stats'
import {AdminQuestionAnswersStore} from 'types/admin_question_answers/admin_question_answers'

export const Store = types.model({
  auth: types.optional(AuthModel, {
    in_progress: true
  }),
  game: types.optional(GameModel, {
    selected_subjects: { subjects: [] },
    competition_store: { competitions: [], isLoading: true },
    difficulty: 'MEDIUM',
    is_muted: false,
    game_duration: 10 * 60,
    is_duplicate_answer: false,
    question_asked: false,
  }),
  question_management: types.optional(QuestionManagementModel, {
    subjects: [],
    loading: true
  }),
  admin_tutor_list: types.optional(TutorList, {}),
  admin_student_score_list: types.optional(StudentSubjectScoreList, {}),
  subject_list: types.optional(SubjectList, {}),
  tutor_data: types.optional(TutorData, {}),
  admin: types.optional(AdminStore, {
    is_loading: false,
    competitions: [],
    students: []
  }),
  parent_data: types.optional(ParentData, {}),
  promo_codes: types.optional(PromoCodes, {}),
  student_tokens: types.optional(Tokens, {
    isLoading: false,
    tokens: [],
    prizes: [],
    total_token_count: 0,
    total_pending_token_count: 0,
  }),
  student_mixes_list: types.optional(MixesList, {}),
  student_subscriptions: types.optional(StudentSubscriptions, {
    isLoading: false,
    subscriptions: []
  }),
  subscription_wizard: types.optional(SubscriptionWizardState, {
    subscription_journey_type: SubscriptionJourneyTypes.CreateSubscription,
    isLoading: false,
  }),
  focus_stats: types.optional(FocusStatsStore, {
    isLoading: true,
    answers: {
      thisWeek: [],
      lastWeek: []
    }
  }),
  schoolStore: types.optional(SchoolStore, {
    isLoading: false,
    stage: 0,
  }),
  subjectsStore: types.optional(SubjectsStore, {
    isLoading: false,
    subjects: []
  }),
  teacherStore: types.optional(TeacherStore, {
    isLoading: false
  }),
  classPerformanceStore: types.optional(ClassPerformanceStore, {
    isLoading: false,
    classPerformance: {
      success: false,
      scores_by_student: []
    },
    selectedScoreType: {
      label: 'Subject Scores',
      value: 'score',
    },
    selectedRefresh: null,
    selectedTopic: null,
    selectedEndcode: null,
  }),
  studentsStore: types.optional(StudentsStore, {
    isLoading: false,
    students: []
  }),
  subscriptionsStore: types.optional(SubscriptionsStore, {
    isLoading: false,
    subscriptions: []
  }),
  upcomingSessionsStore: types.optional(UpcomingSessionsStore, {
    busy: false,
    sessions: [],
    weeks: 2
  }),
  pastSessionsStore: types.optional(PastSessionsStore, {
    busy: false,
    sessions: [],
    weeks: 2
  }),
  studentSessionStore: types.optional(StudentSessionStore, {is_session_active: false}),
  messagesStore: types.optional(MessagesStore, {
    unread_indicator_groups: [],
    is_loading: false
  }),
  refreshesStore: types.optional(RefreshesStore, {
    refreshes: [],
    is_loading: false
  }),
  answersStore: types.optional(AnswersStore, {
    answers: [],
    isLoading: false
  }),
  answerStatsStore: types.optional(AnswerStatsStore, {
    answer_stats: [],
    is_loading: false
  }),

  tutorPageStore: types.optional(TutorPageStore, {
    isLoading: true,
    hasInitialised: false,
  }),
  teacherPageStore: types.optional(TeacherPageStore, {
    isLoading: true,
  }),
  parentPageStore: types.optional(ParentPageStore, {
    isLoading: true,
    hasInitialised: false,
    parentExistsInDB: false,
  }),
  teacherCompetitionStore: types.optional(TeacherCompetitionStore, {
    is_loading: false
  }),
  teacher_strengths_and_focuses_store: types.optional(TeacherStrengthsAndFocusesStore, {}),
  teacher_teams_list: types.optional(TeacherTeamsList, {}),
  teacherStudentPerformanceScores: types.optional(TeacherStudentPerformanceScores, {}),
  teacher_homework_store: types.optional(TeacherHomeworkStore, {
    homework: null,
    isLoading: false
  }),
  student_homework_store: types.optional(StudentHomeworkStore, {
    homework: null,
    isLoading: false
  }),
  join_sessions_store: types.optional(JoinSessionsStore, {
    is_joinable: false,
    is_loading: false
  }),
  subject_management_store: types.optional(SubjectManagementStore, {
    string_filter: ''
  }),
  attendance_streak_store: types.optional(AttendanceStreakStore, {}),
  student_trends_store: types.optional(StudentTrendsStore, {}),
  student_menu_store: types.optional(StudentMenuStore, {is_loading: false}),
  actions_store: types.optional(ActionsStore, { is_loading: false, actions: {} }),
  student_performance_stats_store: types.optional(StudentPerformanceStatsStore, { is_loading: false }),
  translation: types.optional(TranslationStore, {}),
  admin_question_answers_store: types.optional(AdminQuestionAnswersStore, { show_result: false }),
})
  .actions(self => ({
    setSubscriptionWizard: (subscription_wizard) => {
      self.subscription_wizard = subscription_wizard
    },
    reset: () => {
      console.log('store reset')
      applySnapshot(self, {
        auth: {
          role: self.auth.role,
          username: self.auth.username,
          in_progress: false
        }
      }
      )
    }
  }))

export type Store = Instance<typeof Store>

const initialise = () => {
  try {
    const role = localStorage.getItem('plytime_role')
    return Store.create(JSON.parse(localStorage.getItem(`${role}-store-v2`)))
  } catch {
    return Store.create({})
  }
}

let RootStore = null
export const getRootStore = (): IRichStore & Store => {
  if (!RootStore) {
    RootStore = initialise()
  }
  return RootStore
}

onSnapshot(getRootStore(), snapshot => {
  const role = localStorage.getItem('plytime_role')
  localStorage.setItem(`${role}-store-v2`, JSON.stringify(snapshot))
})
onAction(getRootStore(), call => {
  if (import.meta.env.REACT_APP_ENVIRONMENT !== 'PROD'){
    console.info('Action was called:', call)
  }
})
onPatch(getRootStore(), patch => {
  if (import.meta.env.REACT_APP_ENVIRONMENT !== 'PROD') {
    console.info('Got change: ', patch)
  }
})

const StoreContext = createContext<Store>(getRootStore())

// A bit of a hack, but without it TS thinks all the stores are `any` when using useStore.
export interface IRichStore {
  admin: AdminStore,
  answersStore: AnswersStore,
  student_tokens: Tokens,
  teacherPageStore: TeacherPageStore,
  classPerformanceStore: ClassPerformanceStore,
  teacherStudentPerformanceScores: TeacherStudentPerformanceScores,
  subscriptionsStore: SubscriptionsStore,
  refreshesStore: RefreshesStore,
  subjectsStore: SubjectsStore,
  student_mixes_list: MixesList,
  auth: AuthModel,
  game: GameModel,
  focus_stats: FocusStatsStore,
  answerStatsStore: AnswerStatsStore,
  student_subscriptions: StudentSubscriptions,
  messagesStore: MessagesStore,
  pastSessionsStore: PastSessionsStore,
  upcomingSessionsStore: UpcomingSessionsStore,
  teacherStore: TeacherStore,
  subscription_wizard: SubscriptionWizardState,
  promo_codes: PromoCodes,
  tutorPageStore: TutorPageStore
  tutor_data: TutorData,
  parent_data: ParentData,
  schoolStore: SchoolStore,
  teacherCompetitionStore: TeacherCompetitionStore,
  admin_student_score_list: StudentSubjectScoreList
  admin_tutor_list: TutorList,
  parentPageStore: ParentPageStore,
  studentSessionStore: StudentSessionStore,
  studentsStore: StudentsStore,
  teacher_strengths_and_focuses_store: TeacherStrengthsAndFocusesStore,
  teacher_teams_list: TeacherTeamsList,
  teacher_homework_store: TeacherHomeworkStore,
  student_homework_store: StudentHomeworkStore,
  join_sessions_store:JoinSessionsStore,
  question_management: QuestionManagement,
  subject_management_store: SubjectManagementStore,
  attendance_streak_store: AttendanceStreakStore,
  student_trends_store: StudentTrendsStore,
  actions_store: ActionsStore,
  student_menu_store: StudentMenuStore,
  student_performance_stats_store: StudentPerformanceStatsStore,
  translation: TranslationStore,
  admin_question_answers_store: AdminQuestionAnswersStore,
  setSubscriptionWizard: (subscription_wizard: SubscriptionWizardState) => void,
}


export const useStore = (): IRichStore => useContext<Store>(StoreContext)

export const StoreProvider = StoreContext.Provider
