import {Amplify, Auth} from 'aws-amplify'

import {clear_cookies, Roles} from 'util/auth/helper'
import auth_admin_config from 'util/auth/amplify/amplify-config-admin'
import auth_parent_config from 'util/auth/amplify/amplify-config-parent'
import auth_student_config from 'util/auth/amplify/amplify-config-student'
import auth_tutor_config from 'util/auth/amplify/amplify-config-tutor'
import auth_teacher_config from 'util/auth/amplify/amplify-config-teacher'
import auth_school_config from 'util/auth/amplify/amplify-config-school'
import {Store, useStore} from 'types/store'

export const loadRole = () => {
  const role = localStorage.getItem('plytime_role')
  if( Object.values(Roles).includes(role as Roles) ) {
    return role as Roles
  }
  return undefined
}

export const saveRole = (role: string) => {
  if(role === undefined){
    localStorage.removeItem('plytime_role')
  } else {
    localStorage.setItem('plytime_role', role)
  }
}

export const loadUsername = () => {
  const username = localStorage.getItem('plytime_username')
  if( typeof username === 'string' ) return username
  return undefined
}

export const saveUsername = (role: string) => {
  if(role === undefined){
    localStorage.removeItem('plytime_username')
  } else {
    localStorage.setItem('plytime_username', role)
  }
}

export const configureAuth = (role: string) => {
  let amplifyConfig
  switch (role) {
    case Roles.STUDENT:
      amplifyConfig = auth_student_config
      break
    case Roles.TUTOR:
      amplifyConfig = auth_tutor_config
      break
    case Roles.ADMIN:
      amplifyConfig = auth_admin_config
      break
    case Roles.PARENT:
      amplifyConfig = auth_parent_config
      break
    case Roles.TEACHER:
      amplifyConfig = auth_teacher_config
      break
    case Roles.SCHOOL:
      amplifyConfig = auth_school_config
      break
    default:
      console.log('Unsupported login role:' + role)
      throw new Error('Unsupported login role:' + role)
  }

  console.log('Configured Auth:' + JSON.stringify(amplifyConfig))
  Amplify.configure(amplifyConfig)
  // Auth.configure(amplifyConfig)
}

export const useSignout = () => {
  const store: Store = useStore()
  return async () => {
    store.reset()
    clear_cookies()
    await Auth.signOut()
  }
}