import {Instance, SnapshotIn, types} from 'mobx-state-tree'

import {AdminSubject} from 'common/types/subject/subject'
import {Status} from 'common/types/question/status'
import {EndcodeId} from 'common/types/subject/id'
import {AllowedAnswers} from 'common/types/question/answer/answer'
import {Score} from 'common/types/question/score'
import {PositiveInteger} from 'common/types/basic/positive_int'

export enum DisplayType {
  Text = 'Text',
  Image = 'Image',
  MC_input = 'MC input'
}

export enum InputType {
  Whole_Number = 'Whole Number',
  GBP = 'GBP',
  OneDP = '1dp',
  TwoDP = '2dp',
  ThreeDP = '3dp',
  FourDP = '4dp',
  Fraction = 'Fraction',
  Coordinate = 'Coordinate',
  RatioXY = 'RatioXY',
  RatioXYZ = 'RatioXYZ',
  Textcs = 'Textcs',
  MixedFraction = 'MixedFraction',
  Text_input = 'Text input',
  Text_input_exact = 'Text input Exact',
  LaTeX = 'LaTeX',
  LaTeX_exact = 'LaTeX Exact',
  Range = 'Range',
  RangeCoordinateXY = 'Range Coordinate XY',
  Time = 'Time'
}

export type ElementType = DisplayType | InputType

export const Element = types.model({
  type: types.enumeration([...Object.values(InputType), ...Object.values(DisplayType)]),
  text: types.maybeNull(types.string),
  alt: types.maybeNull(types.string),
  ref: types.maybeNull(types.string),
  placeholder: types.maybeNull(types.string),
  autoFocus: types.maybeNull(types.boolean),
  image_id: types.maybeNull(types.string),
  value: types.maybeNull(types.union(types.string, types.number))
})
export type Element = Instance<typeof Element>

export const Line = types.array(Element)
export type Line = Instance<typeof Line>

export const Lines = types.array(Line)
export type Lines = Instance<typeof Lines>

const AskedQuestionBase_ = types.model('AskedQuestionBase_', {
  _id: types.identifier,
  ref: types.string,
  score: Score,
  maximum_time: PositiveInteger,
  standard_time: PositiveInteger,
  updated_at: types.maybe(types.string),
})

export const AskedQuestionBase = types.refinement(
  'AskedQuestionBase',
  AskedQuestionBase_,
  (snapshot:SnapshotIn<typeof AskedQuestionBase_>) => snapshot.maximum_time > snapshot.standard_time,
  () => 'Maximum time must be longer than standard time'
)

export const AskedQuestion = AskedQuestionBase_
  .named('AskedQuestion')
  .props({
    lines: Lines,
  })
export type AskedQuestion = Instance<typeof AskedQuestion>

export const AdminQuestionBase = AskedQuestionBase_
  .named('AdminQuestionBase')
  .props({
    status: Status,
    subject: types.array(types.reference(types.late(() => AdminSubject)))
  })
  .views(self => ({
    get theme() {
      return self.subject[1]
    },
    get topic() {
      return self.subject[2]
    },
    get endcode() {
      return self.subject[3]
    }
  }))

export const AdminQuestion = AdminQuestionBase
  .named('AdminQuestion')
  .props({
    lines: Lines,
    answers: AllowedAnswers,
    updated_at: types.maybe(types.string),
  })

export type AdminQuestion = Instance<typeof AdminQuestion>

export const QuestionUpsertRequest = types.model({
  params: types.model({
    endcode_id: EndcodeId
  }),
  body: AdminQuestion
})

