import {ChangeEvent, FC} from 'react'
import {observer} from 'mobx-react-lite'

import {ElementComponent} from 'components/question/element'
import {Line, Lines} from 'common/types/question/question'
import {AllowedAnswers, SubmittedAnswerMap} from 'common/types/question/answer/answer'

interface Props {
  lines: Lines
  line_index: number
  line: Line,
  handleInputChange: (answer_key: string, ref: string, selected?: boolean) => (e: ChangeEvent) => void,
  student_answer: SubmittedAnswerMap,
  correct_answers: AllowedAnswers,
  answer_decision: string,
}

export const LineComponent: FC<Props> = observer(({ lines, line_index, line, handleInputChange, student_answer, answer_decision, correct_answers, }) => {
  return (
    <div className='my-2 flex flex-wrap items-baseline justify-center'>
      {
        line.map((element, element_index) => (
          <ElementComponent
            lines={lines}
            key={element_index}
            element_index={element_index}
            line_index={line_index}
            element={element}
            handleInputChange={handleInputChange}
            student_answer={student_answer}
            answer_decision={answer_decision}
            correct_answers={correct_answers}
          />
        ))
      }
    </div>
  )
})
LineComponent.displayName = 'LineComponent'
