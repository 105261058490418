import React, {useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import classNames from 'classnames'

import {AdminSubject} from 'common/types/subject/subject'
import {Student} from 'common/types/student'
import {IModalContent, Modal} from 'components/modal/modal'
import {useStore} from 'types/store'
import {NotesCard} from 'components/notes/notes_card'
import {InlineLoader} from 'generic_components/InlineLoader'

interface IProps {
    subject: AdminSubject,
    student: Student
}

export const ChatButton = observer(({
  subject,
  student
}: IProps) => {
  const [modalContent, setModalContent] = useState<IModalContent>(null)
  const {messagesStore} = useStore()
  useEffect(() => {
    messagesStore.register_websocket_listener(subject?._id, student?._id)
  }, [subject, student])
  const foundIndicatorGroup = messagesStore.foundIndicatorGroup(
    subject?._id,
    student?._id
  )

  const onClickChatCallback = useCallback(() => {
    setModalContent({
      title: 'Messages',
      content: (
        <NotesCard
          student={student._id}
          subject={subject._id}
          shouldShowCloseButton={false}
        />
      )
    })

  }, [student, subject])

  const onCloseModalCallback = useCallback(() => {
    setModalContent(null)
  }, [])

  if (!foundIndicatorGroup) {
    return (
      <div className="mt-[15px]">
        <InlineLoader />
      </div>
    )
  }

  return (
    <>
      {
        modalContent ?
          (
            <Modal
              onClose={onCloseModalCallback}
              title={modalContent.title}
              content={modalContent.content}
            /> ) :
          null
      }
      <button
        onClick={onClickChatCallback}
        className={
          classNames(
            foundIndicatorGroup?.unread_count > 0 ? 'bg-red-600' : 'bg-gray-600',
            'm-2 align-top p-2 text-sm rounded shadow inline-block text-gray-100 mr-2 relative flex justify-center items-center w-[80px] h-[36px]'
          )
        }
      >
        <i className="fa-solid fa-comments pr-2"></i>
        {foundIndicatorGroup?.unread_count ?? 0}
        { foundIndicatorGroup?.unread_count > 0 ?
          <span className="absolute hidden h-full w-full animate-ping rounded-full bg-red-600 opacity-75 motion-safe:inline-block"></span> :
          null
        }
      </button>
    </>
  )
})
