import React, {ReactNode} from 'react'
import {observer} from 'mobx-react-lite'

import {useStore} from 'types/store'
import {PlytimePage} from 'components/plytime_page'
import {MenuWrapper} from 'generic_components/MenuWrapper'
import {BlockLoader} from 'generic_components/BlockLoader'

interface IProps {
    children: ReactNode|ReactNode[],
}

export const MenuPageWrapper = observer(({ children }: IProps): JSX.Element => {
  const {schoolStore} = useStore()

  return (
    <PlytimePage>
      <MenuWrapper>
        {
          schoolStore.isLoading ?
            <BlockLoader /> :
            children
        }
      </MenuWrapper>
    </PlytimePage>
  )
})
