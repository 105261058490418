import {flow, getRoot, Instance, types} from 'mobx-state-tree'
import {isEqual, uniqWith} from 'lodash'

import {Store} from 'types/store'
import {HydratedSession} from 'common/types/session'
import {getMe} from 'util/api/profile/me'

export const ParentPageStore = types
  .model({
    isLoading: types.boolean,
    hasInitialised: types.boolean,
    newParent: types.maybe(
      types.model({
        _id: types.string,
        uid: types.string,
        email: types.string,
        email_opt_in: types.boolean
      })
    ),
    parentExistsInDB: types.boolean,
  })
  .actions(self => ({
    create_initial_profile: flow(function* () {
      const { username: email, uid } = yield getMe()
      self.newParent = {
        _id: uid,
        uid,
        email,
        email_opt_in: false
      }
    })
  }))
  .actions((self) => ({
    initialise: flow(function* () {
      self.isLoading = true
      const {
        auth,
        parent_data,
        subjectsStore,
        admin_tutor_list: { refresh: refresh_tutor_list },
        upcomingSessionsStore,
        pastSessionsStore,
        messagesStore
      } = getRoot<Instance<typeof Store>>(self)

      yield parent_data.get_profile()
      if (!parent_data.profile?.first_name) {
        yield self.create_initial_profile()
        return
      }

      yield upcomingSessionsStore.refresh(auth.user._id)
      yield pastSessionsStore.refresh(auth.user._id)
      yield subjectsStore.get_all_subjects()
      yield refresh_tutor_list()
      yield parent_data.get_subscriptions()

      const uniqueSessions = uniqWith(
        upcomingSessionsStore.sessions.map((session: HydratedSession) => ({
          student_id: session.student?._id,
          subject_id: session.subject?._id
        })),
        isEqual
      )

      for (const {student_id, subject_id} of uniqueSessions) {
        yield messagesStore.get_unread_notes(subject_id, student_id)
        yield messagesStore.register_websocket_listener(subject_id, student_id)
      }

      // todo replace isLoading flipping in all the stores with a middleware.
      self.isLoading = false
    }),
  }))
export type ParentPageStore = Instance<typeof ParentPageStore>
