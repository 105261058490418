import React, {ReactNode} from 'react'
import {observer} from 'mobx-react-lite'
import {useLocation, useParams} from 'react-router-dom'

import {useStore} from 'types/store'
import {PlytimePage} from 'components/plytime_page'
import {MenuWrapper} from 'generic_components/MenuWrapper'
import {BlockLoader} from 'generic_components/BlockLoader'
import {IMenuButtonProps} from 'generic_components/MenuWrapper/MenuButton'
import {Class} from 'common/types/class'
import {PageBreadcrumb} from 'generic_components/PageBreadcrumb'
import {getRoutePath} from 'util/helpers/getRoutePath'
import {Competition} from 'common/types/competition'

interface IProps {
    children: ReactNode|ReactNode[],
}

export const TeacherMenuPageWrapper = observer(({ children }: IProps): JSX.Element => {
  const { teacherPageStore, teacherStore } = useStore()

  const location = useLocation()
  const params = useParams<{class_id?: string}>()

  const teacherItems: IMenuButtonProps[] = [
    {
      label: 'Home',
      href: '/teacher',
      icon: <i className="fa-solid fa-house" />,
    },
    {
      label: 'Class Performance',
      href: '/teacher/performance',
      icon: <i className="fa-solid fa-chart-line" />,
      sub_items: teacherStore.teacher?.classes
        .map((class_model: Class): IMenuButtonProps => ({
          label: `${class_model.name} Detail`,
          href: `/teacher/performance/${class_model._id}`,
          icon: <i className="fa-solid fa-chalkboard-user" />,
        }))
    },
    {
      label: 'Competitions',
      href: '/teacher/competitions',
      icon: <i className="fa-solid fa-medal"></i>,
      sub_items: teacherStore
        .ongoing_competitions
        .map((competition: Competition): IMenuButtonProps => ({
          label: competition.name,
          href: `/teacher/competitions/${competition._id}`,
          icon: <i className="fa-solid fa-medal" />,
        }))
    },
    {
      label: 'Teams',
      href: '/teacher/teams',
      icon: <i className="fa-solid fa-people-group"></i>,
    },
  ]

  const breadcrumbs = teacherPageStore.breadcrumbs(
    getRoutePath(location, params),
    params,
  )

  return (
    <PlytimePage>
      <MenuWrapper items={teacherItems}>
        {
          teacherPageStore.isLoading ?
            <BlockLoader /> :
            <div>
              <div className="mb-6">
                <PageBreadcrumb items={breadcrumbs} />
              </div>
              { children }
            </div>
        }
      </MenuWrapper>
    </PlytimePage>
  )
})
