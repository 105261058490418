import classNames from 'classnames'

import {IStep} from 'generic_components/SubscriptionSteps'

interface IProps {
    steps: IStep[],
    current_step: number,
}

export const ProgressStepper = ({
  steps,
  current_step,
}: IProps) => {
  return (
    <div>
      <ol aria-label='progressbar'
        className="mb-4 flex w-full items-center justify-center space-x-2 bg-gray-700/70 px-6 py-3 text-center font-medium text-gray-400 shadow md:justify-between md:rounded">
        {
          steps.map((step, i) => (
            <li
              key={`progress-${i}`}
              className={
                classNames(
                  current_step >= i && 'text-blue-300',
                  current_step === i ? 'flex' : 'hidden md:flex',
                  'md:flex-col'
                )
              }
            >
              <div>
                <span className='md:hidden'>Step </span>
                {
                  current_step >= i + 1 ?
                    (
                      <i className="fa-solid fa-check"></i>
                    ) :
                    (
                      i + 1
                    )
                }
                <span className='pr-1 md:hidden'> of {steps.length}:</span>
              </div>
              <div>
                {step.label}
              </div>
            </li>
          ))
        }
      </ol>
    </div>
  )
}
