import {Instance, SnapshotIn, SnapshotOut, types} from 'mobx-state-tree'

export enum TokenState {
  Manual='Manual',
  Pending='Pending',
  Awarded='Awarded',
  Reviewed='Reviewed',
  Spent='Spent',
}

export const GetTokensRequest = types.model({
  params: types.model({
    student_id: types.string,
  }),
})
export type GetTokensRequest = SnapshotIn<typeof GetTokensRequest >

export const DisplayToken = types.model({
  _id: types.maybe(types.string),
  created_at: types.string,
  game_summary_id: types.maybeNull(types.string),
  game: types.maybeNull(types.string),
  prize: types.maybeNull(types.string),
  pending_token_count: types.number,
  token_count: types.number,
  without_subscription_count: types.number,
  rows: types.number,
})
export type DisplayToken = SnapshotIn<typeof DisplayToken>

export const GetTokensResponse = types.model({
  rows: types.array(DisplayToken),
  total_token_count: types.number,
  total_pending_token_count: types.number,
  total_earned_without_subscription_count: types.number,
})
export type GetTokensResponse = SnapshotIn<typeof GetTokensResponse>

export const DisplayPrize = types.model({
  _id: types.string,
  amount: types.number,
  name: types.string,
  image: types.string,
  description: types.string,
  minimum_subscription_value: types.maybeNull(types.number),
  created_at: types.string,
  updated_at: types.string,
})
export type DisplayPrize = SnapshotIn<typeof DisplayPrize>

export const GetPrizesRequest = types.model({})
export type GetPrizesRequest = SnapshotIn<typeof GetPrizesRequest>

export const GetPrizesResponse = types.model({
  results: types.array(DisplayPrize)
})
export type GetPrizesResponse = SnapshotIn<typeof GetPrizesResponse>

export const PostPrizeRequest = types.model({
  body: types.model({
    prize_id: types.string,
    student_id: types.string,
  })
})
export type PostPrizeRequest = SnapshotIn<typeof PostPrizeRequest>

export const PostPrizeResponse = types.model({
  success: types.boolean
})
export type PostPrizeResponse = SnapshotIn<typeof PostPrizeResponse>

export const CreatePrizeRequest = types.model('CreatePrizeRequest', {
  body: types.model({
    amount: types.number,
    name: types.string,
    image: types.string,
    description: types.string,
    minimum_subscription_value: types.maybe(types.number)
  })
})
export type CreatePrizeRequest = Instance<typeof CreatePrizeRequest>

export const CreatePrizeResponse = types.model({
  success: types.boolean,
  prize_id: types.string
})

export type CreatePrizeResponse = SnapshotOut<typeof CreatePrizeResponse>

