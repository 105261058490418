import {DateTime} from 'luxon'
import {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import classNames from 'classnames'

import {get_school_year} from 'util/helpers/school_year'
import {SubjectSelector} from 'components/common/SubjectSelector'
import {StudentSelector} from 'components/subscription/StudentSelector'
import {PrimaryClickableButton, SecondaryClickableButton} from 'components/ClickableButton'
import {useStore} from 'types/store'
import {BlockLoader} from 'generic_components/BlockLoader'
import {NumberInput} from 'generic_components/MstFormNumberInput'

interface Props {
  close?: () => void
  isModal: boolean
}

export const TutoringDetails: FC<Props> = observer(({ close, isModal }) => {
  const [isLoading, setIsLoading] = useState(true)
  const {
    parent_data: { get_subscriptions, get_profile },
    subjectsStore,
    admin_tutor_list: { refresh: refresh_tutor_list },
    subscription_wizard: {next, student, subject, update_student, update_subject, set_school_year, school_year}
  } = useStore()

  useEffect(() => {
    subjectsStore.get_all_subjects()
      .then(() => refresh_tutor_list())
      .then(() => get_profile())
      .then(() => get_subscriptions())
      .finally(() => setIsLoading(false))
  }, [])

  const onChangeSchoolYear = useCallback((e) => set_school_year(Number.parseInt(e.target.value)), [set_school_year])

  if(isLoading) {
    return (
      <BlockLoader />
    )
  }


  return (
    <div>
      <div className={classNames(isModal ? 'overflow-auto max-h-[50vh] md:max-h-[70vh]' : '')}>
        <ModalField
          title="Which student is this subscription for?"
          instructions="Choose from the drop down list or add a new student, a student can only have one subscription per subject."
          field={<StudentSelector value={student} onChange={update_student} disabled={false} />}
        />

        <ModalField
          title="Subject"
          instructions="What would they like to learn?"
          field={
            <SubjectSelector
              id='SubscriptionWizardSubjectSelector'
              value={subject?._id}
              onChange={(e: any) => update_subject(e.target.value)}
              disabled={false}
              subjects={
                subjectsStore
                  .top_level_subjects()
                  .filter((s) => s.status === 'live')
              }
            />
          }
        />

        <ModalField
          title="School Year"
          instructions={`What is their school year? (starting September ${get_school_year(DateTime.now())})`}
          field={<NumberInput onChange={onChangeSchoolYear} value={school_year} id="School Year"/>}
        />
      </div>

      <div className="flex h-1/3 justify-around py-6">
        {
          isModal ?
            (
              <SecondaryClickableButton
                onClick={close}
              >
              Cancel
              </SecondaryClickableButton>
            ) :
            null
        }

        <PrimaryClickableButton
          onClick={next}
          disabled={!(student && subject && school_year)}
        >
          Next
        </PrimaryClickableButton>
      </div>
    </div>
  )
})

interface IModalFieldProps {
    title?: string|JSX.Element,
    instructions?: string|JSX.Element,
    field?: JSX.Element,
}

export const ModalField = ({
  title,
  instructions,
  field,
}: IModalFieldProps) => {
  return (
    <div className="my-2 overflow-x-auto bg-gray-700/70 p-6 shadow md:rounded">
      <div className="opacity-100">
        <label htmlFor={title.toString()} className="text-xl font-bold capitalize text-gray-200" >{title}</label>
        <div className="my-6 text-gray-200">
          {instructions ?? ''}
        </div>
        {field}
      </div>
    </div>
  )
}
