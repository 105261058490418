import {Instance, types} from 'mobx-state-tree'

import {Student} from 'common/types/student'

export const GetCompetitionDetailsRequest = types.model({
  params: types.model({
    competition_id: types.string,
  })
})
export type GetCompetitionDetailsRequest = Instance<typeof GetCompetitionDetailsRequest>

export const DetailResult = types.model({
  student: types.reference(types.late(() => Student)),
  total_tokens: types.number
})
export type DetailResult = Instance<typeof DetailResult>

export const GetCompetitionDetailsResponse = types.model({
  success: types.boolean,
  results: types.array(DetailResult),
})
export type GetCompetitionDetailsResponse = Instance<typeof GetCompetitionDetailsResponse>
