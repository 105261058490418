import React from 'react'

interface IProps {
  headline: string | JSX.Element
  content?: string | JSX.Element
}

export const SubscriptionJourneyMessage = ({headline, content}: IProps) => {
  return (
    <div className='my-12 flex flex-col items-center justify-center'>
      <h1 className="mb-4 p-4 text-center text-3xl font-bold text-gray-100 md:text-4xl md:leading-[3.5rem]">
        {headline}
      </h1>
      <div className="mb-4 w-12 border-4 border-gray-100"></div>
      {
        content && (
          <div className='p-4 text-center text-lg text-gray-300 md:text-xl'>
            {content}
          </div>)
      }
    </div>
  )
}
