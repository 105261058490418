import {FC, ReactElement} from 'react'
import {observer} from 'mobx-react-lite'
import {Toaster} from 'react-hot-toast'

import {IPage, NavBar} from 'components/NavBar'
import {ContentContainer} from 'components/ContentContainer'
import {checkPermission, Roles} from 'util/auth/helper'
import {publiclyAvailableRoutes} from 'util/helpers/getRoutePath'
import {useStore} from 'types/store'
import {SettingEnum} from 'types/game/model'
import {UserModel} from 'types/user/model'

interface Props {
  roles?: Roles[]
  title?: string
  fullwidth?: boolean
  background?: string
  children: ReactElement | ReactElement[]
}

const getFaqLink = (role: Roles): string => {
  if (role === Roles.PARENT) {
    return 'https://plytime.com/plytime-parent-help-videos/'
  }
  if (role === Roles.STUDENT) {
    return 'https://plytime.com/student-plytime-help-videos/'
  }
  if (role === Roles.TUTOR) {
    return 'https://plytime.com/tutor-plytime-help-videos/'
  }
  if (role === Roles.TEACHER) {
    return 'https://plytime.com/school-plytime-help-videos/'
  }
  if (role === Roles.SCHOOL) {
    return 'https://plytime.com/school-plytime-help-videos/'
  }

  return 'https://plytime.com/faqs/'
}

const getRoleRoutes = (role: Roles, user: UserModel): IPage[] => {
  if (!user && publiclyAvailableRoutes.includes(window.location.pathname)) {
    return [
      {
        href: '/signin/student',
        name: <span><i className="fa-solid fa-home"/> Not a parent?</span>
      },
      {
        href: getFaqLink(role),
        name: 'FAQ/Help',
        target: '_blank'
      }
    ]
  }
  return [
    {
      href: `/${role.toLowerCase()}`,
      name: <span><i className="fa-solid fa-home"/> Home</span>
    },
    {
      name: 'Sign out',
      href: '/sign-out',
    },
    {
      href: getFaqLink(role),
      name: 'FAQ/Help',
      target: '_blank'
    }
  ]
}

export const get_background_for_setting = (setting: SettingEnum): string|undefined => {
  if (setting === 'Space') {
    return 'bg-cover bg-body-pattern-game-space bg-fixed'
  }
  if (setting === 'Forest') {
    return  'bg-cover bg-body-pattern-game-forest bg-fixed'
  }
  if (setting === 'City') {
    return 'bg-cover bg-body-pattern-game-city bg-fixed'
  }
  if (setting === 'Tropical') {
    return 'bg-cover bg-body-pattern-game-tropical bg-fixed'
  }
  if (setting === 'Halloween') {
    return 'bg-cover bg-body-pattern-game-halloween bg-fixed'
  }
  if (setting === 'Christmas') {
    return 'bg-cover bg-body-pattern-game-christmas bg-fixed'
  }
  if (setting === 'Chinese New Year') {
    return 'bg-cover bg-body-pattern-game-chinese-new-year bg-fixed'
  }
  if (setting === 'Summer') {
    return 'bg-cover bg-body-pattern-game-summer bg-fixed'
  }

  return undefined
}

export const PlytimePage: FC<Props> = observer(({ background, roles = [], children }) => {
  const { auth: { user, in_progress, role } } = useStore()
  const { permitted } = roles.length > 0 ? checkPermission(user, roles) : { permitted: true }
  if (!role) {
    return null
  }

  return (
    <div>
      <NavBar pages={getRoleRoutes(role, user)} />

      <ContentContainer background={background}>
        {permitted && children}
        {/*{!permitted && !in_progress && <Unauthorised />}*/}
      </ContentContainer>
      <Toaster />
    </div >
  )
})

