export const PerformanceRowDetail = ({row, subject_score, good, bad, normal, worst_ids}) => {
  if (row.score >= subject_score) {
    return good
  }

  if (worst_ids.includes(row.subject._id)) {
    return bad
  }

  return normal
}
