import Axios from 'axios'
import {setupCache} from 'axios-cache-interceptor'
import {IAnyType, SnapshotIn, SnapshotOut} from 'mobx-state-tree'
import {Auth} from 'aws-amplify'

import {ApiError} from 'types/ApiError'
import {logError} from 'util/helpers/logError'
import {publiclyAvailableRoutes} from 'util/helpers/getRoutePath'

const base_instance = Axios.create({
  baseURL: '/api/',
})
export const instance = setupCache(base_instance, {
  ttl: 1000
})

instance.interceptors.request.use(async (config) => {
  const authSession = await Auth.currentSession().catch(e => {
    console.log('[plytime_auth] error getting auth session:' + e)
    return undefined
  })
  config.headers.Authorization = `Bearer ${authSession?.getIdToken()?.getJwtToken()}`
  return config
})

const Methods = {
  PUT: instance.put,
  GET: instance.get,
  POST: instance.post,
  PATCH: instance.patch,
  DELETE: instance.delete,
}

const validate = (model: IAnyType) => (input: unknown): void => {
  const validation_errors = model.validate(input, [])
  if (validation_errors.length > 0) {
    validation_errors.map((error) => {
      console.error(
        error,
        input,
        model
      )
    })
  }
}

export const api = <Req extends IAnyType, Res extends IAnyType>(
  request_model: Req,
  response_model: Res,
  method: keyof typeof Methods,
  url_template: string
) => {
  const validate_request = validate(request_model)
  const validate_response = validate(response_model)
  return async (request: SnapshotIn<Req> = {} as SnapshotIn<Req>): Promise<SnapshotOut<Res>> => {
    validate_request(request)
    const url = url_template
      .split('/')
      .map(part => request?.params?.[part] || part)
      .join('/')

    try {
      const response = await instance({
        method,
        url,
        data: request.body,
        params: request.query
      })
      validate_response(response.data)
      return response.data
    } catch (e) {
      console.error(e)
      console.error(`[api-error=${url_template}] + ${JSON.stringify(e, null, 2)}`)
      console.error(
        method,
        url,
        request.body,
        request.query
      )

      if (
        e.response?.status === 401 &&
        publiclyAvailableRoutes.includes(window.location.pathname)
      ) {
        return
      }

      if (
        e.response?.status === 401 &&
        !window.location.href.includes('signin')
      ) {
        const existingRole = localStorage.getItem('plytime_role')?.toLowerCase()
        window.location.assign(`/signin/${existingRole ?? 'parent'}`)
        return
      }

      logError(e)

      if (e instanceof ApiError) {
        throw e
      }

      throw new ApiError(
        e.response?.status || 500,
        e.response?.data?.errors || [e.message]
      )
    }
  }
}


export const postEndcodeQuestion = async (endcode_id: string, question: unknown): Promise<unknown> => {
  const { data } = await instance.post(`endcode/${endcode_id}/question`, question)
  return data
}

export interface ICheckAnswer {
  result: string;
  answers: [];
  is_duplicate_answer: boolean;
  endcode_id: string;
}

export const getStudentsUsageStats = async (student_id: string) => {
  const { data } = await instance.get(`student/${student_id}/usage_stats`)
  return data
}

export const getStudentsDifficultyDistribution = async (student_id: string) => {
  const { data } = await instance.get(`student/${student_id}/difficulty_distribution`)
  return data
}

export const getStudentsSubjectDistribution = async (student_id: string) => {
  const { data } = await instance.get(`student/${student_id}/subject_distribution`)
  return data
}

export const getFolder = async (folder?: string) => {
  const { data } = await instance.get(`import/folders${folder ? `?folder=${folder}` : ''}`)
  return data
}

export const importSubjects = async (file_id: string) => {
  const { data } = await instance.post(`import/subjects?file=${file_id}`)
  return data
}

export const importQuestions = async (file_id: string) => {
  const { data } = await instance.post(`import/questions?file=${file_id}`)
  return data
}

export const getActiveImports = async () => {
  const { data } = await instance.get('import')
  return data
}
