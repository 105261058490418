import {Instance, types} from 'mobx-state-tree'

import {Class} from 'common/types/class'

export const GetStudentClassesRequest = types.model({})
export type GetStudentClassesRequest = Instance<typeof GetStudentClassesRequest>

export const GetStudentClassesResponse = types.model({
  success: types.boolean,
  classes: types.array(Class)
})
export type GetStudentClassesResponse = Instance<typeof GetStudentClassesResponse>
