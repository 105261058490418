import {Instance, types} from 'mobx-state-tree'

import {AdminSubject} from 'common/types/subject/subject'
import {Refresh} from 'common/types/refreshes/get_refreshes'
import {Class} from 'common/types/class'

export const Homework = types.model({
  refresh: types.maybeNull(types.late(() => types.reference(Refresh))),
  topic: types.maybeNull(types.late(() => types.reference(AdminSubject))),
  endcode: types.maybeNull(types.late(() => types.reference(AdminSubject))),
  class: types.maybeNull(types.late(() => types.reference(Class))),
}).named('Homework')
export type Homework = Instance<typeof Homework>

export const SetHomeworkPostRequest = types.model({
  body: types.model({
    homework: types.model({
      refresh_id: types.maybe(types.string),
      topic_id: types.maybe(types.string),
      endcode_id: types.maybe(types.string),
      class_id: types.string,
    }),
  })
}).named('SetHomeworkPostRequest')
export type SetHomeworkPostRequest = Instance<typeof SetHomeworkPostRequest>

export const SetHomeworkPostResponse = types.model({
  success: types.boolean
}).named('SetHomeworkPostResponse')
export type SetHomeworkPostResponse = Instance<typeof SetHomeworkPostResponse>

export const GetHomeworkRequest = types.model({
  params: types.model({
    class_id: types.string
  })
}).named('GetHomeworkRequest')
export type GetHomeworkRequest = Instance<typeof GetHomeworkRequest>

export const GetHomeworkResponse = types.model({
  homework: types.maybeNull(Homework),
  success: types.boolean
}).named('GetHomeworkResponse')
export type GetHomeworkResponse = Instance<typeof GetHomeworkResponse>

export const DeleteHomeworkRequest = types.model({
  body: types.model({
    class_id: types.string
  })
}).named('DeleteHomeworkRequest')
export type DeleteHomeworkRequest = Instance<typeof DeleteHomeworkRequest>

export const DeleteHomeworkResponse = types.model({
  success: types.boolean
}).named('DeleteHomeworkResponse')
export type DeleteHomeworkResponse = Instance<typeof DeleteHomeworkResponse>
