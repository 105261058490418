import {api} from 'util/api'
import {ResetStudentScoreRequest, ResetStudentScoreResponse} from 'common/types/student/resetStudentScore'

export const reset_student_score = async(student_id: string, subject_id: string, school_year?: number, score?: number) => {
  return api(ResetStudentScoreRequest, ResetStudentScoreResponse, 'PUT', '/student/reset_student_score')({
    body: {
      student_id,
      subject_id,
      school_year,
      score,
    }
  })
}
