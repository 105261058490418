import {DateTime} from 'luxon'
import {flow, Instance, SnapshotOut, types} from 'mobx-state-tree'

import {HydratedSession} from 'common/types/session'
import {getSession} from 'util/api/sessions'
import {getSessionRoomToken} from 'util/api/sessions/room'
import {putSessionRegister} from 'util/api/sessions/register'
import {SessionEventType} from 'common/types/session/register'
import {logError} from 'util/helpers/logError'

const getIsSessionActive = (session: StudentSession): boolean => {
  const extended_start_time = DateTime
    .fromISO(session.session_date_time)
    .minus({ minutes: 2 })
    .toISOTime()

  const extended_end_time = DateTime
    .fromISO(session.session_date_time_end)
    .plus({ minutes: 30 })
    .toISOTime()

  const now = DateTime.now().toISOTime()

  return (
    now > extended_start_time &&
    now < extended_end_time
  )
}

export const StudentSession = HydratedSession
  .views( self => ({
    get start () {
      return DateTime.fromISO(self.session_date_time)
    },
    get end () {
      return DateTime.fromISO(self.session_date_time).plus({minutes: self.session_length})
    }
  }))
  .volatile( self => ({
    joinable: false
  }))

export type StudentSession = Instance<typeof StudentSession>

export const StudentSessionStore = types
  .model('StudentSessionStore',{
    session: types.maybe(StudentSession),
    room_token: types.maybe(types.string),
    is_session_active: types.boolean
  })
  .volatile(self => ({
    loading: false,
  }))
  .actions(self => ({
    register_event: (type:SnapshotOut<SessionEventType>) => {
      if(self.room_token){
        putSessionRegister(self.session._id, {
          time: DateTime.now().toISO(),
          type
        })
      }
    },
    check_active_session: () => {
      if(self.session) {
        self.is_session_active = getIsSessionActive(self.session)
        return
      }
      self.is_session_active = false
    }
  }))
  .actions(self => ({
    heartbeat() {
      self.register_event('user_still_connected')
    },
    get_session: flow(function*(session_id:string) {
      self.loading = true
      try{
        const session = yield getSession(session_id)
        self.session = {
          ...session,
          student: session.student_id,
          tutor: session.tutor_id
        }
      } catch (e){
        logError(e)
        console.error('Failed to load session', e)
      } finally {
        self.loading = false
      }
    })
  }))
  .actions(self => {
    let heartbeat_timer: NodeJS.Timeout | undefined = undefined
    return ({
      join_session: flow(function*(session_id:string) {
        console.log('join_session')
        self.loading = true
        try{
          yield self.get_session(session_id)
          const {token} = yield getSessionRoomToken(session_id)
          self.room_token = token
          self.register_event('connect')
        }
        catch(e) {
          console.log(e)
        }
        finally {
          self.loading = false
        }
      }),
      disconnect: function () {
        console.log('Left 1-2-1')

        if(heartbeat_timer !== undefined) {
          clearInterval(heartbeat_timer)
          heartbeat_timer = undefined
        }

        if( self.room_token ) {
          self.register_event('disconnect')
          self.room_token = undefined
        }
      }
    })
  })
export type StudentSessionStore = Instance<typeof StudentSessionStore>
