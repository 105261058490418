import {Instance, types} from 'mobx-state-tree'

import {SubjectId, ThemeId} from './id'
import {Topic} from './topic'
import {BaseSubject} from './base'

import {ScoreDistribution} from 'common/types/question/score_distribution'
import {PositiveInteger} from 'common/types/basic/positive_int'
import {Score} from 'common/types/question/score'

export const AdminTheme = BaseSubject
  .named('AdminTheme')
  .props({
    min_score: Score,
    max_score: Score,
    question_distribution: ScoreDistribution,
    question_count: PositiveInteger,
    endcode_count: PositiveInteger,
    topic_count: PositiveInteger,
  })
export type AdminTheme = Instance<typeof AdminTheme>

export const ThemeTree = types
  .model('Theme', {
    _id: ThemeId,
    name: types.string,
    topics: types.array(Topic),
  })
export type ThemeTree = Instance<typeof ThemeTree>

export const ThemePostRequest = types.model(
  'ThemePostRequest',
  {
    params: types.model({
      subject_id: SubjectId
    }),
    body: AdminTheme
  }
)
export type ThemePostRequest = typeof ThemePostRequest

export const GetSubjectThemesRequest = types.model(
  'GetSubjectThemesRequest',
  {
    params: types.model({
      subject_id: SubjectId
    }),
  }
)
export type GetSubjectThemesRequest = typeof GetSubjectThemesRequest

export const GetThemeRequest = types.model(
  'GetThemeRequest',
  {
    params: types.model({
      theme_id: ThemeId
    }),
  }
)
export type GetThemeRequest = typeof GetThemeRequest

export const SubjectThemes = types.array(AdminTheme)
