import {applySnapshot, flow, getRoot, getSnapshot, Instance, SnapshotOut, types} from 'mobx-state-tree'
import {omit} from 'lodash'
import toast from 'react-hot-toast'

import {Parent} from 'common/types/parent'
import {NewStudent} from 'common/types/student'
import {createParentRecord, getParent, updateParent} from 'util/api/parent'
import {Subscription} from 'common/types/subscription'
import {deleteSubscription, listSubscriptions} from 'util/api/subscriptions'
import {postNewStudent} from 'util/api/student'
import {ApiError} from 'types/ApiError'
import {logError} from 'util/helpers/logError'

export const ParentData = types.model(
  'ParentData',
  {
    profile: types.maybe(Parent),
    subscriptions: types.array(Subscription),
    is_normal_sign_in: types.maybe(types.boolean),
  }
).volatile(self => ({
  busy: false
}))
  .actions(self => ({
    set_is_normal_sign_in: (is_normal_sign_in: boolean) => {
      self.is_normal_sign_in = is_normal_sign_in
    },
    get_profile: flow(function* () {
      console.log('getting parent profile...')
      if (self.busy) {
        console.log('Cannot get profile when busy')
        return
      }

      const my_id = (getRoot(self) as any)?.auth?.user?._id
      if (my_id === undefined) {
        console.log('Cannot get profile for unknown user')
        return
      }
      self.busy = true
      try {
        const parent = yield getParent({ query: { _id: my_id } })
        if (self.profile === undefined) {
          self.profile = Parent.create(parent)
        } else {
          applySnapshot(self.profile, parent)
        }
      } catch (error) {
        console.log('error fetching profile...' + error)
        console.error(error.errors || error.message || error)
        throw error
      }
      finally {
        self.busy = false
      }
    }),
    save_profile: flow(function* (parent:SnapshotOut<Parent>) {
      if (self.busy) {
        console.log('Cannot update profile when busy')
        throw new Error('Cannot update profile when busy')
      }
      self.busy = true
      const old = self.profile === undefined ? undefined : getSnapshot(self.profile)
      try {
        yield updateParent({body: omit(parent, '_id','uid','email','students')})
      } catch (error) {
        if (old === undefined) {
          self.profile = undefined
        } else {
          applySnapshot(self.profile, old)
        }
        throw error
      }
      finally {
        self.busy = false
      }
    }),
    set_profile: (profile) => {
      self.profile = profile
    },
    create_profile: flow(function* (parent:SnapshotOut<Parent>) {
      if (self.busy) {
        console.log('Cannot update profile when busy')
        throw new Error('Cannot update profile when busy')
      }
      self.busy = true
      try {
        self.profile = Parent.create(parent)
        const saved = yield createParentRecord({body: omit(parent, '_id','uid','email','students')})
        applySnapshot(self.profile, saved)
        const root: any = getRoot(self)
        root.auth.update_me()
      } catch (error) {
        logError(error)
        self.profile = undefined
        throw error
      }
      finally {
        self.busy = false
      }
    }),
    add_student: flow(function* (student: NewStudent) {
      if (self.busy) {
        console.log('Cannot add student when busy')
        throw new Error('Cannot add student when busy')
      }
      self.busy = true
      try {
        const saved_student = yield postNewStudent({body: student})
        self.profile.students.push(saved_student)

        return saved_student
      } catch (error) {
        if( error instanceof ApiError && error.status === 403 ) {
          throw new ApiError(403, ['Username is already taken'])
        }
        console.error(error)
        throw error
      }
      finally {
        self.busy = false
      }
    }),
    get_subscriptions: flow(function* () {
      try {
        const subs = yield listSubscriptions()
        applySnapshot(self.subscriptions, subs)

      } catch (error) {
        console.error(`get_subscriptions: ${error}`)
        throw error
      }
    }),
    cancel_subscription: flow(function* (id:string) {
      try {
        const sub = self.subscriptions.find(s => s._id === id)
        if( sub === undefined ) {
          throw new Error('No such subscription for parent')
        }
        yield deleteSubscription(id)
        sub.cancelled = true
      } catch (e) {
        logError(e)
        console.error(`cancel_subscription: ${e}`)
        toast.error(`Error: ${e.errors?.join(', ')}`)
      }
    })
  }))

export type ParentData = Instance<typeof ParentData>
