import React, {useCallback, useState} from 'react'
import {Instance} from 'mobx-state-tree'
import {observer} from 'mobx-react-lite'
import {DateTime} from 'luxon'

import {BadDetail, GoodDetail, NormalDetail} from './PerformanceDetail'

import {ResponsiveTable} from 'components/ResponsiveTable'
import {BluePill, GreenPill, RedPill} from 'components/stats/StatsView/TableRecord'
import {Stats, TopicStats} from 'common/types/student/current_stats'
import {PerformanceRowDetail} from 'components/progress/Components/TopicsTable/PerformanceRowDetail'
import {AddToMixButton} from 'components/progress/Components/TopicsTable/AddToMixButton'
import {Mix_Extended} from 'types/student/mixes'
import {PrimaryClickableButton, SecondaryClickableButton} from 'components/ClickableButton'
import {IModalContent, Modal} from 'components/modal/modal'
import {VideoHelp} from 'components/video_help'
import {AnswerDetailView, QuestionReview, QuestionTableHeader} from 'components/question_review'
import {Icon} from 'generic_components/Icon'
import {Roles} from 'util/auth/helper'
import {useUser} from 'context/userContext'
import {sortSubjectsWithProvisionalScores} from 'util/helpers/sortSubjectsWithProvisionalScores'
import {useStore} from 'types/store'

interface ITopicBreakdownProps {
    topic: Instance<typeof TopicStats>,
    subject_score: number,
    isShowingAdvanced: boolean,
    toggleInMix: (subject: Instance<typeof Stats>, already_in_mix: boolean) => void,
    currentMix: Instance<Mix_Extended>,
    student_id: string,
    endcodes_with_watched_videos: string[]
}

export const TopicBreakdown = observer(({
  topic,
  subject_score,
  isShowingAdvanced,
  toggleInMix,
  currentMix,
  student_id,
  endcodes_with_watched_videos
}: ITopicBreakdownProps): React.JSX.Element => {
  const { student_trends_store } = useStore()
  const worst_endcode_ids = topic
    .endcodes
    .slice()
    .filter((row) => Boolean(row.score))
    .filter((row) => row.score < subject_score)
    .filter((row) => row.score < topic.score)
    .sort((a, b) => a.score > b.score ? 1 : -1)
    .map((a) => a.subject._id)

  const [modalContent, setModalContent] = useState<IModalContent>(null)
  const onCloseModalCallback = useCallback(() => setModalContent(null), [])

  return (
    <div>
      {
        modalContent ?
          (
            <Modal
              title={modalContent.title}
              content={modalContent.content}
              onClose={onCloseModalCallback}
            />
          ) :
          null
      }
      <div className="rounded border-2 border-solid border-gray-500 p-4">
        <h1 className="mb-6 text-center text-xl font-bold capitalize">
          Live <span className="underline">{topic.subject.name}</span> Skills
        </h1>
        <ResponsiveTable
          isSortable={false}
          headers={[
            ...(
              isShowingAdvanced ?
                [
                  {
                    label: 'Score',
                    getter: row => {
                      if (row.score)
                        return row.score.toFixed(2)

                      return `(${(subject_score).toFixed(2)})`
                    }
                  },
                  {
                    label: 'Min',
                    key: 'subject.min_score'
                  },
                  {
                    label: 'Standard_level',
                    key: 'subject.standard_level'
                  },
                  {
                    label: 'Max',
                    key: 'subject.max_score'
                  }
                ] :
                []
            ),
            {
              label: 'Name',
              getter: (row) => (
                <PerformanceRowDetail
                  row={row}
                  subject_score={subject_score}
                  worst_ids={worst_endcode_ids}
                  good={<GoodDetail>{row.subject.description}</GoodDetail>}
                  bad={<BadDetail>{row.subject.description}</BadDetail>}
                  normal={<NormalDetail>{row.subject.description}</NormalDetail>}
                />
              )
            },
            {
              getter: (row) => (
                <div className="flex space-x-2">
                  <PerformanceRowDetail
                    row={row}
                    subject_score={subject_score}
                    worst_ids={worst_endcode_ids}
                    good={<GreenPill label={<span><i className="fa-dumbbell fa-solid"/>&nbsp;Strength</span>} />}
                    bad={<RedPill label={<span><i className="fa-magnifying-glass fa-solid"/>&nbsp;Focus</span>} />}
                    normal={null}
                  />

                  {
                    !endcodes_with_watched_videos.includes(row.subject._id) &&
                    row.subject.help_videos.length > 0 &&
                    student_trends_store.new_endcodes_this_week.find(new_endcode => new_endcode._id === row.subject._id)                      ?
                      <BluePill label={<span><i className="fa-video fa-solid"/>&nbsp;New Help Video</span>} /> :
                      null
                  }
                </div>
              )
            },
            {
              getter: (row) => (
                <TopicBreakdownActions
                  row={row}
                  setModalContent={setModalContent}
                  student_id={student_id}
                  toggleInMix={toggleInMix}
                  currentMix={currentMix}
                  endcodes_with_watched_videos={endcodes_with_watched_videos}
                  worst_endcode_ids={worst_endcode_ids}
                />
              )
            },
          ]}
          rows={
            topic
              .endcodes
              .filter((endcode) =>  endcode.subject.status === 'live')
              .slice()
              .sort(sortSubjectsWithProvisionalScores(subject_score))
          }
        />
        <div className="mt-4 max-h-[80vh] overflow-y-auto">
          <h1 className="mb-6 text-center text-xl font-bold capitalize">Previous <span className="underline">{topic.subject.name}</span> Answers</h1>
          <QuestionReview
            student_id={student_id}
            AnswerDetailComponent={AnswerDetailView}
            initialFilters={
              [
                {
                  id: QuestionTableHeader.Topic,
                  value: topic.subject.name
                }
              ]
            }
            fetch_since={DateTime.fromISO(topic.last_timestamp).minus({days: 7}).toISO()}
          />
        </div>
      </div>
    </div>
  )
})

interface ITopicBreakdownActionsProps {
    row: Stats,
    setModalContent: (content: IModalContent) => void,
    student_id: string,
    toggleInMix: (subject: Instance<typeof Stats>, already_in_mix: boolean) => void,
    currentMix: Instance<Mix_Extended>,
    endcodes_with_watched_videos: string[]
    worst_endcode_ids: string[]
}
const TopicBreakdownActions = observer(({
  row,
  setModalContent,
  student_id,
  currentMix,
  toggleInMix,
  endcodes_with_watched_videos,
  worst_endcode_ids
}: ITopicBreakdownActionsProps) => {
  const {user} = useUser()
  const {
    actions_store: {get_actions},
    student_trends_store: {new_endcodes_this_week}
  } = useStore()

  const is_new_video = !endcodes_with_watched_videos.includes(row.subject._id) &&
        row.subject.help_videos.length > 0 &&
      new_endcodes_this_week.find(new_endcode => new_endcode._id === row.subject._id)

  return (
    <div className="flex justify-end space-x-2">
      {
        user.role === Roles.STUDENT ?
          (
            <>
              {
                row.subject.help_videos.length > 0 ?
                  (
                    <SecondaryClickableButton
                      pulse={is_new_video}
                      type="button"
                      onClick={() => {
                        setModalContent({
                          title: `Help for ${row.subject.description}`,
                          content: <VideoHelp subject={row.subject} help_videos={row.subject.help_videos}/>
                        })

                        get_actions('student', student_id, 'watch_skill_videos')
                          .then(() => get_actions('student', student_id))
                      }}
                    >
                  Help &nbsp;
                      <i className="fa-video fa-solid"/>
                    </SecondaryClickableButton>
                  ) :
                  null
              }
              <PrimaryClickableButton
                href={`/game/step1_setup?endcode=${row.subject._id}`}
                pulse={worst_endcode_ids.includes(row.subject._id)}
              >
              Play&nbsp;
                <Icon icon={'fa-gamepad fa-solid'}/>
              </PrimaryClickableButton>
            </>
          ) :
          (
            <AddToMixButton
              currentMix={currentMix}
              row={row}
              toggleInMix={toggleInMix}
            />
          )
      }
    </div>
  )
})
TopicBreakdownActions.displayName = 'TopicBreakdownActions'
