import React, {lazy, Suspense} from 'react'
import {redirect, RouteObject} from 'react-router'

import {queryFromURL} from 'util/helpers/queryFromURL'
import {ProtectedRouteComponent, PublicRouteComponent} from 'AppRoot'
import {PublicPlytimePage} from 'components/public_plytime_page'
import {PlytimePage} from 'components/plytime_page'
import {BlockLoader} from 'generic_components/BlockLoader'
import {TeacherMenuPageWrapper} from 'pages/teacher/components/menu_page_wrapper'
import {MenuPageWrapper} from 'pages/school/components/menu_page_wrapper'
import {PlytimePageStudentBackground} from 'components/plytime_page_student_background'
import {PlytimePageGameBackground} from 'components/plytime_page_game_background'
import {Roles} from 'util/auth/helper'
import {listAllTutors} from 'util/api/tutor'
import {getRootStore} from 'types/store'
import {getPusherOptions} from 'services/pusher'
import {PwaPageWrapper} from 'components/PwaPageWrapper'
import {ErrorPage} from 'pages/404'

export const loadablePage = (factory: Parameters<typeof lazy>[0]) => () => {
  const Component = lazy(factory)

  return (
    <Suspense fallback={<BlockLoader />}>
      <Component />
    </Suspense>
  )
}

const studentProtectedRoutes: RouteObject[] = [
  {
    Component: () => (
      <PlytimePageStudentBackground>
        <ProtectedRouteComponent role={Roles.STUDENT}/>
      </PlytimePageStudentBackground>
    ),
    path: 'student',
    loader: () => {
      const {auth, student_menu_store} = getRootStore()
      if (auth.user.role !== Roles.STUDENT) {
        return redirect('/sign-out')
      }

      student_menu_store.initialise()

      return {
        getPusherOptions: getPusherOptions()
      }
    },
    children: [
      {
        Component: loadablePage(() => import('pages/student/competitions')),
        path: 'competitions',
      },
      {
        Component: loadablePage(() => import('pages/student/progress')),
        path: 'progress',
      },
      {
        Component: loadablePage(() => import('pages/student/refresh')),
        path: 'refresh',
      },
      {
        Component: loadablePage(() => import('pages/student/Answers/answers')),
        path: 'answers',
      },
      {
        Component: loadablePage(() => import('pages/student/sessions')),
        path: 'sessions',
      },
      {
        Component: loadablePage(() => import('pages/student/link_parent')),
        path: 'link-parent',
      },
      {
        Component: loadablePage(() => import('pages/student/tokens')),
        path: 'tokens',
      },
      {
        Component: loadablePage(() => import('pages/student')),
        path: '',
      },
    ]
  },
  {
    Component: () => (
      <PlytimePageStudentBackground fullScreen>
        <ProtectedRouteComponent role={Roles.STUDENT}/>
      </PlytimePageStudentBackground>
    ),
    path: 'student/session/:session_id',
    loader: () => {
      const {auth, student_menu_store} = getRootStore()
      if (auth.user.role !== Roles.STUDENT) {
        return redirect('/sign-out')
      }

      student_menu_store.initialise()

      return null
    },
    children: [
      {
        Component: loadablePage(() => import('pages/student/session/[session_id]')),
        index: true
      },
    ]
  },
  {
    Component: () => (
      <PlytimePageGameBackground>
        <ProtectedRouteComponent role={Roles.STUDENT}/>
      </PlytimePageGameBackground>
    ),
    path: 'game',
    loader: () => {
      const {auth} = getRootStore()
      if (auth.user.role !== Roles.STUDENT) {
        return redirect('/sign-out')
      }
      return null
    },
    children: [
      {
        Component: loadablePage(() => import('pages/game/step1_setup')),
        path: 'step1_setup',
      },
      {
        Component: loadablePage(() => import('pages/game/step2_speed_standard')),
        path: 'step2_speed_standard',
      },
      {
        Component: loadablePage(() => import('pages/game/step3_summary')),
        path: 'step3_summary',
      }
    ]
  }
]

const parentProtectedRoutes: RouteObject[] = [
  {
    Component: () => (
      <PlytimePage>
        <ProtectedRouteComponent role={Roles.PARENT}/>
      </PlytimePage>
    ),
    path: 'parent',
    loader: () => {
      const {auth, parentPageStore, parent_data} = getRootStore()
      if (auth.user.role !== Roles.PARENT) {
        return redirect('/sign-out')
      }

      parentPageStore.initialise()
      parent_data.set_is_normal_sign_in(true)

      return null
    },
    children: [
      {
        Component: loadablePage(() => import('pages/parent/profile')),
        path: 'profile',
      },
      {
        Component: loadablePage(() => import('pages/parent/student_performance')),
        path: 'student_performance',
      },
      {
        Component: loadablePage(() => import('pages/parent/subscriptions/cancel')),
        path: 'subscriptions/cancel',
      },
      {
        Component: loadablePage(() => import('pages/parent/subscriptions/edit')),
        path: 'subscriptions/edit',
      },
      {
        Component: loadablePage(() => import('pages/parent/subscriptions')),
        path: 'subscriptions',
      },
      {
        Component: loadablePage(() => import('pages/parent')),
        path: '',
      },
    ]
  }
]

const tutorProtectedRoutes: RouteObject[] = [
  {
    Component: () => (
      <PlytimePage>
        <ProtectedRouteComponent role={Roles.TUTOR}/>
      </PlytimePage>
    ),
    path: 'tutor',
    loader: () => {
      const {auth} = getRootStore()
      if (auth.user.role !== Roles.TUTOR) {
        return redirect('/sign-out')
      }
      return null
    },
    children: [
      {
        Component: loadablePage(() => import('pages/tutor/availability')),
        path: 'availability',
      },
      {
        Component: loadablePage(() => import('pages/tutor/profile')),
        path: 'profile',
        loader: () => {
          const {tutor_data} = getRootStore()
          tutor_data.get_profile()
          return null
        },
      },
      {
        Component: loadablePage(() => import('pages/tutor/session/[subject_id]')),
        path: 'session/:student_id/:subject_id',
      },
      {
        Component: loadablePage(() => import('pages/tutor/session/[session_id]')),
        path: 'session/:session_id',
        loader: ({ params }) => {
          const {auth, studentsStore, subjectsStore, studentSessionStore} = getRootStore()

          studentsStore.get_students_for_tutor(auth.user._id)
            .then(() => subjectsStore.get_all_subjects())
            .then(() => studentSessionStore.get_session(params.session_id))
            .then(() =>  studentSessionStore.check_active_session())
          return null
        },
      },
      {
        Component: loadablePage(() => import('pages/tutor')),
        path: '',
        loader: () => {
          const {tutorPageStore} = getRootStore()
          tutorPageStore.initialise()
          return null
        },
      },
    ]
  }
]

const schoolProtectedRoutes: RouteObject[] = [
  {
    Component: () => (
      <MenuPageWrapper>
        <ProtectedRouteComponent role={Roles.SCHOOL}/>
      </MenuPageWrapper>
    ),
    path: 'school',
    loader: () => {
      const {auth, schoolStore} = getRootStore()
      if (auth.user.role !== Roles.SCHOOL) {
        return redirect('/sign-out')
      }

      schoolStore.initialise()
      return null
    },
    children: [
      {
        Component: loadablePage(() => import('pages/school/profile')),
        index: true,
      },
      {
        Component: loadablePage(() => import('pages/school/profile')),
        path: 'profile',
      },
      {
        Component: loadablePage(() => import('pages/school/teachers')),
        path: 'teachers',
      },
      {
        Component: loadablePage(() => import('pages/school/students')),
        path: 'students',
      },
      {
        Component: loadablePage(() => import('pages/school/classes')),
        path: 'classes',
      },
    ]
  },

]

const teacherProtectedRoutes: RouteObject[] = [
  {
    Component: () => (
      <TeacherMenuPageWrapper>
        <ProtectedRouteComponent role={Roles.TEACHER}/>
      </TeacherMenuPageWrapper>
    ),
    path: 'teacher',
    loader: ({ params }) => {
      const {auth, teacherPageStore} = getRootStore()
      if (auth.user.role !== Roles.TEACHER) {
        return redirect('/sign-out')
      }

      teacherPageStore.initialise(auth.user.uid, params?.class_id)
      return null
    },
    children: [
      {
        Component: loadablePage(() => import('pages/teacher/edit_student_subscription')),
        path: 'subscription/class/:class_id/student/:student_id',
      },
      {
        Component: loadablePage(() => import('pages/teacher/teams')),
        path: 'teams',
      },
      {
        Component: loadablePage(() => import('pages/teacher/teacher_competitions_detail_page')),
        path: 'competitions/:competition_id',
      },
      {
        Component: loadablePage(() => import('pages/teacher/teacher_competitions_page')),
        path: 'competitions',
      },
      {
        Component: loadablePage(() => import('pages/teacher/performance_detail')),
        path: 'performance/:class_id',
        loader: ({ params }) => {
          const {auth, classPerformanceStore, teacherPageStore} = getRootStore()

          teacherPageStore.initialise(auth.user.uid, params?.class_id)
          classPerformanceStore.set_selected_topic(null)
          classPerformanceStore.set_selected_endcode(null)

          return null
        },
      },
      {
        Component: loadablePage(() => import('pages/teacher/student_performance')),
        path: 'performance/student/:student_id',
      },
      {
        Component: loadablePage(() => import('pages/teacher/performance_summary')),
        path: 'performance',
      },
      {
        Component: loadablePage(() => import('pages/teacher/home')),
        path: '',
      },
    ]
  }
]

const adminProtectedRoutes: RouteObject[] = [
  {
    Component: () => (
      <PlytimePage>
        <ProtectedRouteComponent role={Roles.ADMIN}/>
      </PlytimePage>
    ),
    path: 'admin',
    loader: () => {
      const {auth} = getRootStore()
      if (auth.user.role !== Roles.ADMIN) {
        return redirect('/sign-out')
      }

      return null
    },
    children: [
      {
        Component: loadablePage(() => import('pages/admin/competitions/competitions')),
        path: 'competitions',
      },
      {
        Component: loadablePage(() => import('pages/admin/competitions/details')),
        path: 'competitions/:competition_id',
        loader: ({ params }) => {
          const {admin, subjectsStore} = getRootStore()
          subjectsStore.get_all_subjects()
            .then(() => admin.get_competitions())
            .then(() => admin.get_details(params.competition_id))
            .then(() => admin.get_students())

          return null
        },
      },
      {
        Component: loadablePage(() => import('pages/admin/competitions/add')),
        path: 'competitions/add',
      },
      {
        Component: loadablePage(() => import('pages/admin/competitions/list')),
        path: 'competitions/list',
        loader: () => {
          const {admin, subjectsStore} = getRootStore()
          subjectsStore.get_all_subjects()
            .then(() => admin.get_competitions())

          return null
        },
      },
      {
        Component: loadablePage(() => import('pages/admin/competitions/edit')),
        path: 'competitions/:competition_id/edit',
        loader: () => {
          const {admin} = getRootStore()
          admin.get_competitions()
          return null
        },
      },
      {
        Component: loadablePage(() => import('pages/admin/subjects')),
        path: 'subjects',
        loader: () => {
          const {subjectsStore} = getRootStore()
          subjectsStore.get_all_subjects()
          return null
        },
      },
      {
        Component: loadablePage(() => import('pages/admin/question_manager')),
        path: 'question_manager',
        loader: () => {
          const {admin, subjectsStore, subject_management_store} = getRootStore()
          subjectsStore.get_all_subjects()
          if (subject_management_store.selected_endcode) {
            admin.get_questions_for_endcode(subject_management_store.selected_endcode._id)
          }
          return null
        },
      },
      {
        Component: loadablePage(() => import('pages/admin/subjects_import')),
        path: 'subjects_import',
      },
      {
        Component: loadablePage(() => import('pages/admin/tutors/all_availability')),
        path: 'tutors/all_availability',
        loader: () => {
          const tutors = listAllTutors()
          return tutors
        },
      },
      {
        Component: loadablePage(() => import('pages/admin/tutors/add')),
        path: 'tutors/add',
        loader: () => {
          const {subjectsStore} = getRootStore()
          subjectsStore.get_all_subjects()
          return null
        },
      },
      {
        Component: loadablePage(() => import('pages/admin/tutors/list')),
        path: 'tutors/list',
      },
      {
        Component: loadablePage(() => import('pages/admin/tutors/[_id]')),
        path: 'tutors/:_id',
        loader: ({ params }) => {
          const {admin_tutor_list} = getRootStore()

          if (admin_tutor_list.tutors.length === 0 && !admin_tutor_list.busy) {
            admin_tutor_list.refresh()
          }
          const tutor = admin_tutor_list.get_tutor(params._id)
          return tutor
        },
      },
      {
        Component: loadablePage(() => import('pages/admin/tutors')),
        path: 'tutors',
      },
      {
        Component: loadablePage(() => import('pages/admin/student_subject_scores')),
        path: 'student_scores',
        loader: () => {
          const {admin_student_score_list, refreshesStore} = getRootStore()
          admin_student_score_list.refresh()
          refreshesStore.get_refreshes()
          return null
        },
      },
      {
        Component: loadablePage(() => import('pages/admin')),
        path: '',
      },
    ]
  },
]

const publicRoutes: RouteObject[] = [
  {
    element: (
      <PublicPlytimePage>
        <PublicRouteComponent />
      </PublicPlytimePage>
    ),
    path: 'signin',
    children: [
      {
        Component: loadablePage(() => import('pages/signin')),
        path: 'parent',
      },
      {
        Component: loadablePage(() => import('pages/signin')),
        path: 'student',
      },
      {
        Component: loadablePage(() => import('pages/signin')),
        path: 'tutor',
      },
      {
        Component: loadablePage(() => import('pages/signin')),
        path: 'teacher',
      },
      {
        Component: loadablePage(() => import('pages/signin')),
        path: 'school',
      },
      {
        Component: loadablePage(() => import('pages/signin')),
        path: 'admin',
      },
    ]
  },
  {
    Component: loadablePage(() => import('pages/parent/subscriptions/new_subscription')),
    path: '/parent/new-subscription',
  },
  {
    Component: loadablePage(() => import('pages/home')),
    path: '/',
  },
  {
    Component: loadablePage(() => import('pages/404')),
    path: '*',
  },
]

export const routes: RouteObject[] = [
  {
    path: 'sign-out',
    Component: loadablePage(() => import('pages/signout')),
  },
  {
    loader: ({ request: { url } }) => {
      const {auth, student_subscriptions, promo_codes} = getRootStore()
      const query = queryFromURL(url)
      if (query.get('promo-code')){
        promo_codes.set_promotion_code(query.get('promo-code'))
      }

      auth.initialise().then(() => student_subscriptions.get_subscriptions())

      return null
    },
    element: <PwaPageWrapper />,
    errorElement: <ErrorPage />,
    children: [
      ...publicRoutes,
      ...adminProtectedRoutes,
      ...parentProtectedRoutes,
      ...schoolProtectedRoutes,
      ...studentProtectedRoutes,
      ...teacherProtectedRoutes,
      ...tutorProtectedRoutes,
    ]
  }
]