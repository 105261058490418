export const is_latex_included = (str: string): boolean => (str ?? '').includes('$')

export const is_readable_string = (str: string): boolean => {
  const pattern = /(\S{3,})/
  return pattern.test(str)
}

export const is_maths_only = (str: string): boolean => {
  const pattern = /^([0-9.£$ +-=÷*x?]*)$/
  return pattern.test(str)
}
