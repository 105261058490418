import {Instance, SnapshotOut, types} from 'mobx-state-tree'

import {SubjectId} from 'common/types/subject/id'
import {NonEmptyString} from 'common/types/basic/non_empty_string'
import {NumberFromStringOrNumber} from 'common/types/basic/number_from_string_or_number'
import {StudentId} from 'common/types/student/id'

export const ResetStudentScoreRequest = types.model({
  body: types.model({
    student_id: StudentId,
    subject_id: SubjectId,
    school_year: types.maybe(types.number),
    score: types.maybe(types.number)
  })
})
export type ResetStudentScoreRequest = typeof ResetStudentScoreRequest

export const ResetStudentScoreResponse = types.model({
  student_id: StudentId,
  subject_id: SubjectId,
  score: types.number,
})
export type ResetStudentScoreResponse = typeof ResetStudentScoreResponse

export const ListStudentSubjectScoreItem = types.model(
  'StudentScore',{
    _id: NonEmptyString,
    student_id: StudentId,
    student_first_name: NonEmptyString,
    student_last_name: NonEmptyString,
    score: types.number,
    tutor_first_name: types.maybe(types.string),
    tutor_last_name: types.maybe(types.string),
    parent_email: types.maybe(types.string),
    subject_id: SubjectId,
    subject_name: NonEmptyString,
  }
)
export type ListStudentSubjectScoreItem = Instance<typeof ListStudentSubjectScoreItem>

export const StudentScoreSearchFilter = types.model('StudentScoreSearchFilter', {
  _from: NumberFromStringOrNumber,
  _limit: NumberFromStringOrNumber,
  studentId: types.optional(types.string, ''),
})
export type StudentScoreSearchFilter = SnapshotOut<typeof StudentScoreSearchFilter>


export const StudentScoreListRequest = types.model('StudentScoreListRequest',{
  query: StudentScoreSearchFilter
})
export type StudentScoreListRequest = SnapshotOut<typeof StudentScoreListRequest>

export const StudentScoreListResponse = types.array(ListStudentSubjectScoreItem)
export type StudentScoreListResponse = Instance<typeof StudentScoreListResponse>
