import {Instance, types} from 'mobx-state-tree'

import {DateTimeString} from 'common/types/basic/date_time_string'
import {Score} from 'common/types/question/score'
import {Lines} from 'common/types/question/question'
import {AdminSubject} from 'common/types/subject/subject'
import {Difficulty} from 'common/types/question/difficulty'
import {AllowedAnswers, SubmittedAnswerMap} from 'common/types/question/answer/answer'
import {QuestionId} from 'common/types/question/id'

export const Result = types.enumeration([
  'CORRECT',
  'INCORRECT',
  'PASSED',
])
export const GetStudentAnswersRequest = types.model({
  params: types.model({
    student_id: types.string,
  }),
  query: types.model({
    since_date_iso: DateTimeString,
    to_date_iso: types.maybe(DateTimeString)
  })
})
export type GetStudentAnswersRequest = typeof GetStudentAnswersRequest

export const StudentAnswer = types.model({
  _id: types.identifier,
  reviewed: types.boolean,
  help_requested: types.boolean,
  resolved: types.boolean,
  question: types.maybeNull(types.model({
    _id: QuestionId,
    ref: types.string,
    answers: AllowedAnswers,
    lines: Lines,
    maximum_time: types.number,
    standard_time: types.number
  })),
  answer: SubmittedAnswerMap,
  result: Result,
  score: Score,
  timestamp: DateTimeString,
  subject: types.array(types.safeReference(types.late(() => AdminSubject))),
  response_time: types.number,
  difficulty: Difficulty
})
export type StudentAnswer = Instance<typeof StudentAnswer>

export const GetStudentAnswersResponse = types.array(StudentAnswer)
export type GetStudentAnswersResponse = Instance<typeof GetStudentAnswersResponse>

export const PutReviewStatusRequest = types.model('PutReviewStatusRequest', {
  params: types.model({
    answer_id: types.string,
  }),
  body: types.model({
    reviewed: types.boolean,
    help_requested: types.boolean,
  }),
})
export type PutReviewStatusRequest = Instance<typeof PutReviewStatusRequest>

export const PutReviewStatusResponse = types.model('PutReviewStatusResponse', {
  success: types.boolean,
})
export type PutReviewStatusResponse = Instance<typeof PutReviewStatusResponse>

export const PutResolvedStatusRequest = types.model('PutResolvedStatusRequest', {
  params: types.model({
    answer_id: types.string
  }),
  body: types.model({
    resolved: types.boolean
  })
})
export type PutResolvedStatusRequest = Instance<typeof PutResolvedStatusRequest>

export const PutResolvedStatusResponse = types.model('PutResolvedStatusResponse', {
  success: types.boolean,
})
export type PutResolvedStatusResponse = Instance<typeof PutResolvedStatusResponse>
