import { IFocusCount } from 'types/class_performance'

interface IStudentFocusesByStudent {
    student_id: string,
    subject_ids: string[]
}

export const regroup_scores = (student_strengths: IStudentFocusesByStudent[]): IFocusCount[] => {
  const result = []
  for (const strengths_by_student of student_strengths) {
    for (const subject of strengths_by_student.subject_ids) {
      const foundIndex = result.findIndex(r => r.subject_id === subject)
      if (foundIndex !== -1) {
        result[foundIndex].student_ids.push(strengths_by_student.student_id)
        continue
      }

      result.push(
        {
          subject_id: subject,
          student_ids: [strengths_by_student.student_id]
        }
      )
    }
  }

  return result
}
