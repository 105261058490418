import React, {FC, useCallback} from 'react'
import {observer} from 'mobx-react-lite'

import {useStore} from 'types/store'
import {BorderWrapper, ColumnContainer, NoBorderInput} from 'generic_components/AnswerInputContainers'
import {InputProps} from 'components/question/inputProps'
import {get_formatted_correct_answer} from 'components/question/input'

type MixedFraction = {
  whole: string,
  numerator: string,
  denominator: string,
}

const calculateLength = (x: string): number => x.length <= 3 ? 3 : x.length + 1

const parseWholeNumberInput = (value: string): string => value.replaceAll(/[^0-9-]/g,'')
const parseFractionNumberInput = (value: string): string => value.replaceAll(/[^0-9]/g, '')

const parseWholeNumberValue = (value: string): MixedFraction => ({whole: value, numerator: '', denominator: ''})

const parseFractionValue = (value: string): MixedFraction => {
  const [numerator, denominator] = value.trim().split('/')
  return {whole: '', numerator, denominator}
}
const parseMixedFractionValue = (value: string): MixedFraction => {
  const [whole, fraction] = value !== '' ? value.split(' ') : ['','']
  const [numerator, denominator] = fraction !== '' ? fraction.split('/') : ['','']
  return {whole, numerator, denominator}
}

const parseMixedFraction = (value:string): MixedFraction => {
  if (value.match(/^ *[+-]?[0-9]+ *$/)){
    return parseWholeNumberValue(value)
  } else if(value.match(/^ *[0-9]+\/[0-9]+ *$/)){
    return parseFractionValue(value)
  } else {
    return parseMixedFractionValue(value)
  }
}

const getAnswer = (correct_answers, answer_decision, element, answerIndex) => {
  if (correct_answers.length > 0 && answer_decision !== 'CORRECT') {
    const found_answer = get_formatted_correct_answer(correct_answers, answerIndex, element.ref)
    if (found_answer) {
      return parseMixedFraction(found_answer)
    }
  }

  return {
    whole: '',
    numerator: '',
    denominator: ''
  }
}

const drawLine = (numeratorSize: number, denominatorSize: number) =>
  '\u2501'.repeat(Math.max(numeratorSize, denominatorSize))

export const MixedFractionInputComponent: FC<InputProps> = observer(({answerIndex, element, handleChange, answer_decision, correct_answers, value, element_id}) => {
  const {game, auth: { user }} = useStore()

  const {whole, numerator, denominator} = parseMixedFraction(value)
  const [wholeSize, numeratorSize, denominatorSize] = [whole, numerator, denominator].map(calculateLength)

  const answer : MixedFraction = getAnswer(correct_answers, answer_decision, element, answerIndex)

  const [wholeAnswerSize, numeratorAnswerSize, denominatorAnswerSize] =
    [answer.whole, answer.numerator, answer.denominator].map(calculateLength)

  const inputChangeWhole = useCallback((e) => {
    e.target.value = `${parseWholeNumberInput(e.target.value)} ${numerator}/${denominator}`
    handleChange(e)
  }, [handleChange, numerator, denominator])

  const inputChangeNumerator = useCallback((e) => {
    e.target.value = `${whole} ${parseFractionNumberInput(e.target.value)}/${denominator}`
    handleChange(e)
  }, [handleChange, whole, denominator])

  const inputChangeDenominator = useCallback((e) => {
    e.target.value = `${whole} ${numerator}/${parseFractionNumberInput(e.target.value)}`
    handleChange(e)
  }, [handleChange, whole, numerator])

  const drawInputLine = drawLine(numeratorSize, denominatorSize)
  const drawAnswerLine = drawLine(numeratorAnswerSize, denominatorAnswerSize)

  return (
    <ColumnContainer>
      <BorderWrapper answer_decision={answer_decision}>
        <div className='flex'>
          <NoBorderInput
            autoFocus
            id={`${element_id}_whole`}
            onChange={(e) =>inputChangeWhole(e) }
            readOnly={!!(answer_decision)}
            value={whole}
            size={wholeSize}
            onKeyPress={e => {
              if (e.key === 'Enter' && !game.over) {
                if ( whole !== '' && numerator !== '' && denominator !== '' ) {
                  game.submit_answer(user._id)
                }
              }
            }}
          />
          <ColumnContainer>
            <NoBorderInput
              id={`${element_id}_numerator`}
              onChange={(e) =>inputChangeNumerator(e) }
              readOnly={!!(answer_decision)}
              style={{marginBottom: 0}}
              value={numerator}
              size={numeratorSize}
              onKeyPress={e => {
                if (e.key === 'Enter' && !game.over) {
                  if ( whole !== '' && numerator !== '' && denominator !== '' ) {
                    game.submit_answer(user._id)
                  }
                }
              }}
            />
            <p style={{margin: '0px', fontSize: '0.75em'}}>{drawInputLine}</p>
            <NoBorderInput
              id={`${element_id}_denominator`}
              onChange={(e) => inputChangeDenominator(e)}
              readOnly={!!(answer_decision)}
              value={denominator}
              size={denominatorSize}
              onKeyPress={e => {
                if (e.key === 'Enter' && !game.over) {
                  if ( whole !== '' && numerator !== '' && denominator !== '' ) {
                    game.submit_answer(user._id)
                  }
                }
              }}
            />
          </ColumnContainer>
        </div>
      </BorderWrapper>
      {answer_decision !== 'CORRECT' && correct_answers.length > 0 && (
        <BorderWrapper answer_decision={'CORRECT'}>
          <div className='flex'>
            {answer.whole !== '' && (
              <NoBorderInput
                readOnly={true}
                value={answer.whole}
                size={wholeAnswerSize}
              />
            )}
            {answer.numerator !== '' && answer.denominator !== '' && (
              <ColumnContainer>
                <NoBorderInput
                  readOnly={true}
                  value={answer.numerator}
                  style={{marginBottom: 0}}
                  size={numeratorAnswerSize}
                />
                <p style={{margin: '0px', fontSize: '0.75em'}}>{drawAnswerLine}</p>
                <NoBorderInput
                  readOnly={true}
                  value={answer.denominator}
                  size={denominatorAnswerSize}
                />
              </ColumnContainer>
            )}
          </div>
        </BorderWrapper>
      )}
    </ColumnContainer>
  )
})
