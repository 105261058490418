import {ILanguageOption} from 'components/game/setup/read_out_language_selector'

export const get_language_shortcode = (language_code: string): string => language_code.split('-')[0]

export const sort_options = (arr: ILanguageOption[]) => arr
  .sort((a, b) => {
    if (a.value < b.value) {
      return -1
    }
    if (a.value > b.value) {
      return 1
    }
    return 0
  })
