import {useCallback} from 'react'
import {observer} from 'mobx-react-lite'

import {FieldComponent} from 'generic_components/MstForm'

export const Checkbox: FieldComponent<boolean> = observer(({ id, value, onChange, disabled, custom_props }) => {

  const onChangeCallback = useCallback((e) => {
    onChange({ ...e, target: { value: !value, id } })
  }, [value])

  return (
    <div className='mt-2 flex items-center'>
      <input
        id={id}
        type="checkbox"
        checked={value}
        onChange={onChangeCallback}
        disabled={disabled}
        className="mr-3 size-8 cursor-pointer rounded border leading-tight text-gray-700 shadow focus:outline-none focus:ring"
      />
      {
        custom_props?.text && <span className="text-sm text-gray-100">{custom_props.text}</span>
      }
    </div>
  )
})
