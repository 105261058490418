import classNames from 'classnames'
import React, {AriaRole} from 'react'
import {DateTime} from 'luxon'
import {sortBy} from 'lodash'

import {Days} from 'common/types/basic/day'
import {SelectedSlots} from 'common/types/subscription'

type Status = 'progress'|'success'|'error'

const get_chosen_session_status = (slots_left_to_select: number): Status => {
  if (slots_left_to_select > 0) {
    return 'progress'
  }
  if (slots_left_to_select === 0) {
    return 'success'
  }
  if (slots_left_to_select < 0) {
    return 'error'
  }
}

interface ISession {
    day_of_week: number,
    time: number,
    duration: number,
}

const get_sessions_from_slots = (slots: SelectedSlots): ISession[] => {
  const sortedSlots = sortBy(slots, ['day_of_week', 'time'])
  const sessions = []

  for (const {day_of_week, time} of sortedSlots) {
    const consecutiveSlot = sessions.find(s =>
      s.day_of_week === day_of_week &&
            (
              s.time === time - s.duration ||
                s.time === time + s.duration
            )
    )

    if (consecutiveSlot) {
      consecutiveSlot.time = Math.min(consecutiveSlot.time, time)
      consecutiveSlot.duration = consecutiveSlot.duration + 15
      continue
    }

    sessions.push(
      { day_of_week, time, duration: 15 }
    )
  }

  return sessions
}

const ZeroTime = DateTime.now().startOf('day')
export const slotFormat = (slot_start_minute_of_day: number) => `${ZeroTime.plus({ minutes: slot_start_minute_of_day }).toFormat('HH:mm')}`

interface IProps {
    chosen_session_count: number,
    selected_slots: SelectedSlots
}

export const ChosenSessions = ({ chosen_session_count, selected_slots }: IProps) => {
  const slots_left_to_select = chosen_session_count - selected_slots.length
  const status = get_chosen_session_status(slots_left_to_select)

  return (
    <div
      role={getAriaRole(status)}
      className="flex flex-col justify-center"
    >
      <table
        className={
          classNames(
            'divide-y-2 rounded',
            status === 'progress' ? 'divide-yellow-200 bg-yellow-100' : '',
            status === 'success' ? 'divide-green-200 bg-green-100' : '',
            status === 'error' ? 'divide-red-200 bg-red-100' : '',
          )
        }
      >
        <thead>
          <tr>
            <Header status={status} value="Day"/>
            <Header status={status} value="Start Time"/>
            <Header status={status} value="Duration"/>
          </tr>
        </thead>

        <tbody
          className={
            classNames('divide-y',
              status === 'progress' ? 'divide-yellow-200' : '',
              status === 'success' ? 'divide-green-200' : '',
              status === 'error' ? 'divide-red-200' : '',
            )
          }
        >
          {
            get_sessions_from_slots(selected_slots)
              .map((session, index) =>
                <tr key={`${index}-${session.time}-${session.day_of_week}`}>
                  <Detail status={status} value={Days[session.day_of_week]}/>
                  <Detail status={status} value={slotFormat(session.time)}/>
                  <Detail status={status} value={`${session.duration} Minutes`}/>
                </tr>
              )
          }
        </tbody>
      </table>
    </div>
  )
}

const getAriaRole = (status: Status): AriaRole => {
  if (status === 'error') {
    return 'alert'
  }
  if (status === 'progress') {
    return 'note'
  }

  return 'status'
}

const Header = ({ status, value }) => {
  return (
    <th
      className={
        classNames(
          'whitespace-nowrap px-4 py-2 underline',
          status === 'progress' ? 'text-yellow-900' : '',
          status === 'success' ? 'text-green-900' : '',
          status === 'error' ? 'text-red-900' : '',
        )
      }
    >
      {value}
    </th>
  )
}

const Detail = ({ status, value }) => {
  return (
    <td
      className={
        classNames('px-4 py-2 text-center',
          status === 'progress' ? 'text-yellow-700' : '',
          status === 'success' ? 'text-green-700' : '',
          status === 'error' ? 'text-red-700' : '',

        )
      }
    >
      {value}
    </td>
  )
}
