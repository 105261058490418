import {Instance, types} from 'mobx-state-tree'

import {SessionOptions} from 'common/types/session/options'
import {ReferralCode} from 'common/types/referral_code'
import {Student} from 'common/types/student'
import {Tutor} from 'common/types/tutor/tutor'
import {Class} from 'common/types/class'
import {AdminSubject} from 'common/types/subject/subject'
import {DateTimeString} from 'common/types/basic/date_time_string'
import {SubjectId} from 'common/types/subject/id'
import {StudentId} from 'common/types/student/id'
import {MetaSession} from 'common/types/session'

export const Subscription = types.model('Subscription', {
  _id: types.identifier,
  student: types.safeReference(Student),
  subject: types.safeReference(AdminSubject),
  tutor: types.maybeNull(types.reference(Tutor)),
  class: types.maybeNull(types.safeReference(types.late(() => Class))),
  session_option: SessionOptions,
  original_amount: types.maybeNull(types.number),
  payment_amount: types.maybeNull(types.number),
  discount_amount: types.maybeNull(types.number),
  payment_date: types.maybeNull(DateTimeString),
  start_date: DateTimeString,
  updated_start_date: DateTimeString,
  stripe_subscription_id: types.maybe(types.string),
  assigned_sessions: types.array(MetaSession),
  cancelled: types.optional(types.boolean, false),
  referralCode: types.maybeNull(ReferralCode),
  school_year: types.number,
  slots: types.array(types.string)
}).named('Subscription')
export type Subscription = Instance<typeof Subscription>

export const GetSubscriptionsRequest = types.model({}).named('GetSubscriptionsRequest')
export type GetSubscriptionsRequest = Instance<typeof GetSubscriptionsRequest>

export const GetSubscriptionsResponse = types.array(Subscription)
export type GetSubscriptionsResponse = Instance<typeof GetSubscriptionsResponse>

export const SubscriptionByIdRequest = types.model({
  params: types.model({
    subscription_id: types.string
  })
}).named('SubscriptionByIdRequest')
export type SubscriptionByIdRequest = Instance<typeof SubscriptionByIdRequest>

export const DeleteSubscriptionResponse = types.model({
  subscription_id: types.string
}).named('DeleteSubscriptionResponse')
export type DeleteSubscriptionResponse = Instance<typeof DeleteSubscriptionResponse>

export const SelectedSlot = types.model({
  day_of_week: types.number,
  time: types.number,
}).named('SelectedSlot')
export type SelectedSlot = Instance<typeof SelectedSlot>

export const SelectedSlots = types.array(SelectedSlot)
export type SelectedSlots = Instance<typeof SelectedSlots>

const CreateBasketBody = types.model({
  parent_id: types.maybeNull(types.string),
  school_id: types.maybeNull(types.string),
  class_id: types.maybe(types.string),
  student_id: StudentId,
  subject: SubjectId,
  session_option: SessionOptions,
  slots: SelectedSlots,
  success_url: types.string,
  error_url: types.string,
  promo_code: types.maybeNull(types.string),
  referral_code: types.maybeNull(types.string),
  school_year: types.number,
})
export type CreateBasketBody = Instance<typeof CreateBasketBody>

export const CreateBasketRequest = types.model({
  body: CreateBasketBody
}).named('CreateBasketRequest')
export type CreateBasketRequest = Instance<typeof CreateBasketRequest>

export const Basket = types.model({
  basket_id: types.maybe(types.string),
  redirect_url: types.string,
  total: types.maybe(types.number),
  total_discount: types.maybe(types.number),
  line_items: types.maybe(types.array(
    types.model({
      amount_total: types.number,
      description: types.string,
      quantity: types.number,
      discounts: types.array(
        types.model({
          amount: types.number,
          name: types.string
        })
      )
    })
  ))
}).named('Basket')
export type Basket = Instance<typeof Basket>

export const CreateBasketResponse = Basket
export type CreateBasketResponse = Instance<typeof CreateBasketResponse>

export const RescheduleSubscriptionRequest = types.model({
  params: types.model({
    subscription_id: types.string
  }),
  body: types.model({
    old_day_of_week: types.number,
    old_time: types.number,
    new_day_of_week: types.number,
    new_time: types.number,
    session_length: types.number,
  })
}).named('RescheduleSubscriptionRequest')
export type RescheduleSubscriptionRequest = Instance<typeof RescheduleSubscriptionRequest>

export const SubstituteSubscriptionRequest = types.model({
  params: types.model({
    subscription_id: types.string
  })
}).named('SubstituteSubscriptionRequest')
export type SubstituteSubscriptionRequest = Instance<typeof SubstituteSubscriptionRequest>
