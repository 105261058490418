import React, {FC, useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {sortBy} from 'lodash'
import classNames from 'classnames'

import {useStore} from 'types/store'
import {PrimaryClickableButton, SecondaryClickableButton, WarningClickableButton} from 'components/ClickableButton'
import {ResponsiveTable} from 'components/ResponsiveTable'
import {ModalField} from 'components/subscription/TutoringDetails'
import {Days} from 'common/types/basic/day'
import {SelectedSlot, SelectedSlots} from 'common/types/subscription'
import {BlockLoader} from 'generic_components/BlockLoader'
import {ChosenSessions, slotFormat} from 'components/subscription/ChosenSessions'

interface Props {
  close: () => void,
  shouldShowBack?: boolean,
  onClickConfirm?: () => void,
  isLoading?: boolean;
  isModal: boolean
}

export const SlotSelection: FC<Props> = observer(({
  close,
  shouldShowBack = false,
  onClickConfirm,
  isLoading = false,
  isModal
}) => {
  const {
    subscription_wizard: {
      available_slots,
      back,
      existing_subscription,
      find_availability,
      finding_availability,
      is_invalid_referral_code,
      next,
      query_with_referral_code,
      referral_code,
      selected_slots,
      session_count,
      update_slot_selection
    }
  } = useStore()

  useEffect(() => {
    find_availability()
  }, [])

  return (
    <div>
      <div className={classNames(isModal ? 'overflow-auto max-h-[50vh] md:max-h-[70vh]' : 'py-6')}>
        <SlotsInstructionMessage
          finding_availability={finding_availability}
          referral_code={referral_code}
          all_slots={available_slots(existing_subscription?.tutor?._id)}
          chosen_slots={selected_slots}
          chosen_session_count={session_count()}
          is_invalid_referral_code={is_invalid_referral_code}
          query_with_referral_code={query_with_referral_code}
        />
        {
          selected_slots.length ?
            (
              <ModalField
                title="Your Chosen Sessions"
                instructions={
                  <ChosenSessions selected_slots={selected_slots} chosen_session_count={session_count()}/>
                }
              />
            ) :
            null
        }
        <ModalField
          title="Please select your weekly slot days & times"
          field={(
            isLoading ?
              <BlockLoader message={'Please wait...'} /> :
              (
                <div className="h-[50vh] overflow-auto">
                  <ResponsiveTable
                    isSortable={false}
                    headers={[{
                      label: 'Day',
                      getter: (slot) => {
                        return Days[slot.day_of_week]
                      }
                    },
                    {
                      label: 'Time',
                      getter: (slot) => {
                        return slotFormat(slot.time)
                      }
                    },
                    {
                      getter: (slot) => (
                        <div className="float-right">
                          {
                            !selected_slots.find((s) => (s.day_of_week === slot.day_of_week && s.time === slot.time)) ?
                              (
                                <PrimaryClickableButton
                                  onClick={() => update_slot_selection({ ...slot })}
                                >
                                Add
                                </PrimaryClickableButton>
                              ) :
                              (
                                <WarningClickableButton
                                  onClick={() => update_slot_selection({ ...slot })}
                                >
                                Remove
                                </WarningClickableButton>
                              )
                          }
                        </div>
                      )
                    }
                    ]}
                    rows={sortBy(available_slots(existing_subscription?.tutor?._id), 'day_of_week', 'time') as SelectedSlot[]}
                    chunkSize={available_slots(existing_subscription?.tutor?._id).length}
                  />
                </div>
              )
          )}
        />
      </div>
      <div className="flex h-1/3 justify-around py-6">
        {
          shouldShowBack && (
            <SecondaryClickableButton
              onClick={back}
            >
            Back
            </SecondaryClickableButton>
          )
        }

        <PrimaryClickableButton
          onClick={onClickConfirm ?? next}
          disabled={session_count() !== selected_slots.length}
        >
          {onClickConfirm ? 'Confirm' : 'Next'}
        </PrimaryClickableButton>
      </div>
    </div>
  )
})

interface ISlotsInstructionMessageProps {
  finding_availability: boolean,
  referral_code?: string,
  all_slots: any,
  chosen_slots: SelectedSlots,
  chosen_session_count: number,
  is_invalid_referral_code: boolean,
  query_with_referral_code: boolean,
}

const SlotsInstructionMessage = observer(({ finding_availability, referral_code, all_slots, chosen_slots, chosen_session_count, is_invalid_referral_code, query_with_referral_code }: ISlotsInstructionMessageProps) => {
  if (finding_availability) {
    if (referral_code.length > 0 && query_with_referral_code) {
      return (
        <BlockLoader
          message="Please wait while we find the tutor's slots."
        />
      )
    }

    return (
      <BlockLoader
        message="Please wait while we find the best tutor for you."
      />
    )
  }

  if (all_slots.length === 0) {
    if (referral_code === '') {
      return (
        <div
          className="rounded bg-red-100 px-3 py-1.5 text-center font-medium text-red-700 shadow"
        >
          We&apos;re sorry. There are no tutors with availability matching your requirements.
        </div>
      )
    }
    if (is_invalid_referral_code) {
      return (
        <div
          className="rounded bg-red-100 px-3 py-1.5 text-center font-medium text-red-700 shadow"
        >
          We&apos;re sorry. The code you used was invalid. Please go back and try again with a different code.
        </div>
      )
    }

    return (
      <div
        className="rounded bg-red-100 px-3 py-1.5 text-center font-medium text-red-700 shadow"
      >
        We&apos;re sorry. The tutor had no available slots that matched your requirements. Please untick the checkbox in the previous step if you&apos;d like to find what slots are available for you.
      </div>
    )
  }

  return (
    <div>
      <InstructionMessage chosen_session_count={chosen_session_count} chosen_slots={chosen_slots} />
    </div>
  )
})

const InstructionMessage = ({ chosen_session_count, chosen_slots }) => {
  const slots_left_to_select = chosen_session_count - chosen_slots.length
  if ((slots_left_to_select) > 0) {
    return (
      <div
        className="rounded bg-amber-100 px-3 py-1.5 text-center font-medium text-amber-700 shadow"
      >
          You have {slots_left_to_select} slot{slots_left_to_select >= 2 ? 's' : ''} left to select
      </div>
    )
  }

  if ((chosen_session_count - chosen_slots.length) < 0) {
    return (
      <div
        className="rounded bg-red-100 px-3 py-1.5 text-center font-medium text-red-700 shadow"
      >
          You have chosen {Math.abs(chosen_session_count - chosen_slots.length)} slot too many
      </div>
    )
  }

  return (
    <div
      className="rounded bg-green-100 px-3 py-1.5 text-center font-medium text-green-700 shadow"
    >
        Great! You&apos;re all set!
    </div>
  )
}
