import {observer} from 'mobx-react-lite'

import {FieldComponent} from 'generic_components/MstForm'

export const StringInput: FieldComponent<string> = observer(({ id, value, onChange, disabled, required }) =>
  (
    <input
      id={id}
      type="text"
      value={value ?? ''}
      onChange={onChange}
      disabled={disabled}
      required={required}
      className="h-[36px] w-full appearance-none rounded px-2 leading-tight text-gray-700 shadow focus:outline-none focus:ring disabled:cursor-not-allowed disabled:bg-gray-100"
    />
  )
)
StringInput.displayName = 'StringInput'
