import {flow, Instance, types} from 'mobx-state-tree'
import {DateTime} from 'luxon'

import {StudentAnswer} from 'common/types/student/answers'
import {getStudentAnswers} from 'util/api/student/answers'

export enum WeekIdentifier {
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek'
}

export const FocusStatsStore = types
  .model({
    isLoading: types.boolean,
    answers: types.model({
      thisWeek: types.array(StudentAnswer),
      lastWeek: types.array(StudentAnswer),
    })
  })
  .actions((self) => ({
    getAnswers: flow(function* (student_id) {
      self.isLoading = true

      const thisWeek:DateTime = DateTime.utc().startOf('week')
      const lastWeek:DateTime = thisWeek.minus({ week:1 })

      self.answers[WeekIdentifier.lastWeek] = yield getStudentAnswers(
        student_id,
        lastWeek.toJSDate().toISOString(),
        lastWeek.plus({day: 7}).toJSDate().toISOString()
      )
      self.answers[WeekIdentifier.thisWeek] = yield getStudentAnswers(
        student_id,
        thisWeek.toJSDate().toISOString(),
        thisWeek.plus({day: 7}).toJSDate().toISOString()
      )

      self.isLoading = false
    })
  }))
  .views(self => ({
    minutesSpentOnQuestion: (key: WeekIdentifier) =>
      self.answers[key].reduce((accumulator, current) => accumulator + (current.response_time / 60), 0),
    questionCorrectlyAnswered: (key: WeekIdentifier) =>
      self.answers[key].reduce((accumulator, current) => accumulator + (current.result === 'CORRECT' ? 1 : 0), 0),
  }))

export type FocusStatsStore = Instance<typeof FocusStatsStore>
