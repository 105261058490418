import {observer} from 'mobx-react-lite'
import classNames from 'classnames'
import {get} from 'lodash'

import {FieldSpec} from 'generic_components/MstForm'

interface IMSTFieldProps extends FieldSpec<string>{
    is_modal?: boolean,
    draft: Record<string, unknown>,
    editing: boolean,
    onChange: (e: any) => void,
    errors: Record<string, string[]>
}
export const MstField = observer(({
  is_modal,
  name,
  draft,
  label,
  Component,
  editing,
  disabled,
  custom_props,
  required,
  onChange,
  errors
}: IMSTFieldProps) => {
  const value = get(draft, name)
  const is_error = Boolean(errors[name])
  const is_valid = value && !is_error

  return (
    <div key={name} className="m-4 px-4">
      <label
        htmlFor={name}
        className={classNames(is_modal ? '' : 'text-gray-200', '')}
      >
        {label}
      </label>

      {
        required ?
          (
            <span className={
              classNames(
                'text-xs ml-2',
                errors[name] ? 'text-red-400' : 'text-gray-400',
              )
            }
            >
            (Required)
            </span>
          ) :
          null
      }

      <div className="relative px-4">
        <div className={
          classNames(
            required && is_error && '*:border-2 *:border-red-400 *:rounded',
            required && is_valid && '*:border-2 *:border-green-400 *:rounded',
          )
        }>
          <Component
            id={name}
            value={value}
            onChange={onChange}
            disabled={!editing || disabled}
            required={required}
            custom_props={custom_props}
            {...custom_props}
          />
        </div>

        {
          required &&
          is_valid &&
          typeof value !== 'boolean' &&
            (
              Component.displayName === 'StringInput' ||
                Component.displayName === 'NumberInput'
            ) ?
            (
              <span className="pointer-events-none absolute inset-y-0 end-6 grid place-content-center">
                <i className="fa-solid fa-square-check text-green-500"></i>
              </span>
            ) :
            null
        }
      </div>
      {
        errors[name]?.map((error, i) => {
          return (
            <div className="mx-4 mt-2 rounded bg-red-200 px-4 py-2 text-sm text-red-500" key={error}>
              {error}
            </div>
          )
        })
      }
    </div>
  )
})
MstField.displayName = 'MstField'
