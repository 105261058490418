import {applySnapshot, flow, Instance, types} from 'mobx-state-tree'

import {Subject} from './subject/subject'

import {getAllSubjects} from 'util/api/subject'
import {BaseSubject} from 'common/types/subject/base'
import {logError} from 'util/helpers/logError'

export const QuestionManagementModel = types.model('QuestionManagementStore', {
  subjects: types.array(BaseSubject),
  loading: types.optional(types.boolean, false)
})
  .actions(self => ({
    refresh: flow(function*() {
      self.loading = true
      try {
        const subjects = yield getAllSubjects()
        applySnapshot(self, {
          subjects: subjects.filter((subject) => {
            return subject.type === 'subject'
          })
        })
      } catch (error) {
        logError(error)
        console.error('Failed to fetch subjects', error)
      } finally {
        self.loading = true
      }
    }),
    add_subject (subject:Subject) {
      self.subjects.push(subject)
    }
  }))

export type QuestionManagement = Instance<typeof QuestionManagementModel>
