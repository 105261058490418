import {FC} from 'react'
import {observer} from 'mobx-react-lite'

import {FieldComponentProps} from 'generic_components/MstForm'
import {AdminSubject} from 'common/types/subject/subject'
import {RichSelect} from 'components/RichSelect'

interface ISubjectSelectorProps extends FieldComponentProps<string> {
    subjects: AdminSubject[]
}

export const SubjectSelector: FC<ISubjectSelectorProps> = observer(({ id, value, onChange, disabled, subjects }) => {
  return (
    <RichSelect
      id={id}
      selected={value}
      onChange={(e) => {
        if (e.value?.length > 0) {
          onChange({
            target: {value: e.value, id},
            currentTarget: {value: e.value, id},
          } as any)
        } else {
          onChange({
            target: {value: undefined, id},
            currentTarget: {value: undefined, id}
          } as any)
        }
      }}
      isDisabled={disabled}
      options={
        [
          {
            label: 'Please choose a subject',
            value: undefined,
          },
          ...subjects
            .map(subject => ({
              label: subject.name,
              value: subject._id
            }))
        ]
      }
    />
  )
})
SubjectSelector.displayName = 'SubjectSelector'
