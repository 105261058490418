import { flow, Instance, types } from 'mobx-state-tree'

import { Subscription } from 'common/types/subscription'
import { get_subscriptions_for_teacher } from 'util/api/teacher/get_subscriptions'

export enum TierOptionEnum {
  PlytimeFree = 'Plytime Free',
  PlytimePremium = 'Plytime Premium',
  WithFocusTutoring = 'With Focus Tutoring®',
}

export const SubscriptionsStore = types.model({
  isLoading: types.boolean,
  subscriptions: types.array(Subscription)
})
  .actions(self => ({
    get_subscriptions_for_teacher: flow(function*(teacher_id: string){
      self.isLoading = true
      const { subscriptions } = yield get_subscriptions_for_teacher(teacher_id)
      self.subscriptions = subscriptions
      self.isLoading = false
    })
  }))
  .views(self => ({
    active_for_student(student_id) {
      return self.subscriptions.find(
        (sub) => {
          return !sub.cancelled && student_id === sub.student?._id
        }
      )
    }
  }))
export type SubscriptionsStore = Instance<typeof SubscriptionsStore>
