import {types} from 'mobx-state-tree'

import {AdminSubject} from 'common/types/subject/subject'

export const MixBase = types.model({
  name: types.string,
  subject: types.string,
  subjects: types.array(types.reference(AdminSubject)),
})
export type MixBase = typeof MixBase

const Params = types.model({
  student_id: types.string
})

export const PostStudentMixRequest = types.model({
  params: Params,
  body: MixBase
})
export type PostStudentMixRequest = typeof PostStudentMixRequest

export const Mix = MixBase
  .named('Mix')
  .props({
    _id: types.identifier,
    student: types.string,
  })
export type Mix = typeof Mix

export const GetStudentMixesRequest = types.model({params: Params})
export type GetStudentMixesRequest = typeof GetStudentMixesRequest

export const GetStudentMixesResponse = types.array(Mix)
export type GetStudentMixesResponse = typeof GetStudentMixesResponse

const PutDeleteParams = Params
  .named('PutParams')
  .props({
    mix_id: types.string
  })

export const PutStudentMixRequest = types.model({
  params: PutDeleteParams,
  body: MixBase
})
export type PutStudentMixRequest = typeof PutStudentMixRequest

export const DeleteStudentMixRequest = types.model({
  params: PutDeleteParams
})
export type DeleteStudentMixRequest = typeof DeleteStudentMixRequest

export const DeleteStudentMixResponse = types.model({
  mix_id: types.string
})
export type DeleteStudentMixResponse = typeof DeleteStudentMixResponse
