import {observer} from 'mobx-react-lite'

import {SubscriptionJourneyMessage} from './SubscriptionJourneyMessage'

import {SessionOptionEnum} from 'common/types/session/options'
import {useStore} from 'types/store'
import {UserModel} from 'types/user/model'
import {AuthComponent} from 'components/auth'
import {Roles} from 'util/auth/helper'
import {SubscriptionWizardState} from 'components/subscription/SubscriptionWizardState'
import {TutoringDetails} from 'components/subscription/TutoringDetails'
import {Availability} from 'components/subscription/Availability'
import {SlotSelection} from 'components/subscription/SlotSelection'
import {Payment} from 'components/subscription/Payment'
import {Referral} from 'components/subscription/Referral'
import {AlreadyPlatformOnly} from 'components/subscription/AlreadyPlatformOnly'
import {SubscriptionWizardParentDetails} from 'components/parent/ParentDetails'
import {ProgressStepper} from 'generic_components/ProgressStepper'

export interface ISubscriptionStepsProps {
  isModal: boolean,
  closeModal?: () => void,
  isLoading?: boolean,
  onClickConfirmSubscribe?: () => Promise<void>,
  displayName?: string,
}

export const SubscriptionSteps = observer( ({ closeModal, isModal, isLoading, onClickConfirmSubscribe, displayName }: ISubscriptionStepsProps) => {
  const {
    subscription_wizard: { steps, current_step },
    parent_data: { is_normal_sign_in }
  } = useStore()

  const {PageComponent, HeaderComponent} = steps[current_step]

  return (
    <>
      {!is_normal_sign_in && HeaderComponent && <HeaderComponent displayName={displayName} />}
      {steps.length > 1 && <ProgressStepper steps={steps} current_step={current_step} />}
      <PageComponent
        closeModal={closeModal}
        isModal={isModal}
        isLoading={isLoading}
        onClickConfirmSubscribe={onClickConfirmSubscribe}
      />
    </>
  )
})

export interface IStep {
  label: string,
  showIf?: (
      arg: {
        user?: UserModel,
        selected_session_option: string,
        isPrevPlatformOnly?: boolean,
        is_normal_sign_in: boolean,
      }
  ) => boolean,
  HeaderComponent?: (
    arg: {
      displayName?: string
    }
  ) => JSX.Element,
  PageComponent: (
      arg: {
        subscription_wizard?: SubscriptionWizardState,
        closeModal?: () => void,
        isModal?: boolean,
        isPrevPlatformOnly?: boolean,
        isLoading?: boolean,
        onClickConfirmSubscribe?: () => void
      }
  ) => JSX.Element
}

export const create_subscription_steps: IStep[] = [
  {
    label: 'Account',
    showIf: ({ is_normal_sign_in }) => !is_normal_sign_in,
    PageComponent: () => (
      <AuthComponent
        role={Roles.PARENT}
        initialState='signUp'
      />
    ),
    HeaderComponent: () => (
      <SubscriptionJourneyMessage
        headline='Get started with Plytime Learning®'
        content={(
          <>
            <p className='mb-2'>Create an account to start your subscription.</p>
            <p>Or sign in if you already have an account.</p>
          </>
        )}
      />
    ),
  },
  {
    label: 'Profile',
    showIf: ({ is_normal_sign_in }) => !is_normal_sign_in,
    PageComponent: () => (
      <div className="mx-auto rounded lg:w-2/3">
        <SubscriptionWizardParentDetails />
      </div>
    ),
    HeaderComponent: () => (
      <SubscriptionJourneyMessage
        headline='Almost there! Just a few more details'
        content='Please fill out the form below with your information'
      />
    ),
  },
  {
    label: 'Details',
    PageComponent: ({ closeModal, isModal }) => (
      <TutoringDetails
        close={closeModal}
        isModal={isModal}
      />
    ),
    HeaderComponent: ({ displayName }) => (
      <SubscriptionJourneyMessage
        headline={(
          <>
            Hi {displayName}!
            <span className='block'>Which student and subject would you like to start a new subscription for?</span>
          </>
        )}
        content={(
          <>
            <p className='mb-2'>Add a new student to your account or select from the list of students.</p>
            <p>Choose the subject you&apos;d like to subscribe for.</p>
          </>
        )}
      />
    ),
  },
  {
    label: 'Choose Subscription',
    PageComponent: ({ isModal }) => (
      <Availability
        shouldShowBack
        isModal={isModal}
      />
    ),
    HeaderComponent: () => (
      <SubscriptionJourneyMessage
        headline='Perfect! Now, Choose Your Access'
        content={(
          <>
            <p className='mb-2'>Gain basic access with Plytime Free</p>
            <p className='mb-2'>Or unlock more features with a paid subscription*</p>
          </>
        )}
      />
    ),
  },
  {
    label: 'Referrals',
    PageComponent: ({ closeModal, isModal }) => (
      <Referral
        close={closeModal}
        isModal={isModal}
      />
    ),
    HeaderComponent: () => (
      <SubscriptionJourneyMessage
        headline={(
          <>
            Almost Done!
            <span className='block'>Do you have a Referral code?</span>
          </>
        )}
      />
    ),
  },
  {
    label: 'Select Slots',
    showIf: ({ selected_session_option }) => !(
      selected_session_option === SessionOptionEnum.FreeTrial ||
        selected_session_option === SessionOptionEnum.PlatformOnly
    ),
    PageComponent: ({ closeModal, isModal }) => (
      <SlotSelection
        close={closeModal}
        shouldShowBack
        isModal={isModal}
      />
    ),
    HeaderComponent: () => (
      <SubscriptionJourneyMessage
        headline='Great! Now, Pick Your Preferred Session Times'
        content='Choose from the available session times below.'
      />
    ),
  },
  {
    label: 'Confirm',
    PageComponent: ({ closeModal, isModal }) => (
      <Payment
        on_success={closeModal}
        close={closeModal}
        isModal={isModal}
      />
    ),
    HeaderComponent: () => (
      <SubscriptionJourneyMessage
        headline={(
          <>
            All Set!
            <span className='block'>Confirm Your Subscription</span>
          </>
        )}
      />
    ),
  }
]

export const edit_subscription_steps: IStep[] = [
  {
    label: 'Choose Subscription',
    PageComponent: ({ isModal }) => (
      <Availability
        shouldShowBack={false}
        isModal={isModal}
      />
    )
  },
  {
    label: 'Notice',
    showIf: ({ isPrevPlatformOnly, selected_session_option }) => isPrevPlatformOnly && selected_session_option === SessionOptionEnum.PlatformOnly,
    PageComponent: ({ isModal }) => (
      <AlreadyPlatformOnly
        isModal={isModal}
      />
    )
  },
  {
    label: 'Referrals',
    showIf: ({ isPrevPlatformOnly, selected_session_option }) => (isPrevPlatformOnly && selected_session_option !== SessionOptionEnum.PlatformOnly),
    PageComponent: ({ closeModal, isModal }) => (
      <Referral
        close={closeModal}
        isModal={isModal}
      />
    )
  },
  {
    label: 'Select Slots',
    showIf: ({ selected_session_option }) => !(
      selected_session_option === SessionOptionEnum.FreeTrial ||
        selected_session_option === SessionOptionEnum.PlatformOnly
    ),
    PageComponent: ({ closeModal, isModal }) => (
      <SlotSelection
        close={closeModal}
        shouldShowBack
        isModal={isModal}
      />
    )
  },
  {
    label: 'Confirm',
    showIf: ({ isPrevPlatformOnly, selected_session_option }) => !(isPrevPlatformOnly && selected_session_option === SessionOptionEnum.PlatformOnly),
    PageComponent: ({ closeModal, isModal }) => (
      <Payment
        on_success={closeModal}
        close={closeModal}
        isModal={isModal}
      />
    )
  }
]

export const tutor_reschedule_subscription_steps: IStep[] = [
  {
    label: 'Select Slots',
    PageComponent: ({ closeModal, isModal, isLoading, onClickConfirmSubscribe}) => {
      return (
        <SlotSelection
          close={closeModal}
          shouldShowBack={false}
          onClickConfirm={onClickConfirmSubscribe}
          isLoading={isLoading}
          isModal={isModal}
        />
      )}
  },
]
