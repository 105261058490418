import {Instance, types} from 'mobx-state-tree'

export const CreateFreeTrialRequestBody = types.model({
  student_id: types.string,
  subject_id: types.string,
  parent_id: types.maybeNull(types.string),
  school_id: types.maybeNull(types.string),
  school_year: types.number,
})
export type CreateFreeTrialRequestBody = Instance<typeof CreateFreeTrialRequestBody>

export const CreateFreeTrialRequest = types.model({
  body: CreateFreeTrialRequestBody
})
export type CreateFreeTrialRequest = Instance<typeof CreateFreeTrialRequest>

export const CreateFreeTrialResponse = types.model({
  success: types.boolean
})
export type CreateFreeTrialResponse = Instance<typeof CreateFreeTrialResponse>
