import {flow, getRoot, Instance, resolveIdentifier, types} from 'mobx-state-tree'

import {Store} from 'types/store'
import {Student} from 'common/types/student'
import {Class} from 'common/types/class'

export const TeacherPageStore = types
  .model({
    isLoading: types.boolean,
    currentClass: types.safeReference(Class),
  })
  .views((self) => {
    return ({
      breadcrumbs: (pathPattern: string, params) => {
        const rootStore = getRoot<Instance<typeof Store>>(self)

        const classPerformanceBreadCrumb = {
          label: 'Class Performance',
          href: '/teacher/performance'
        }

        const classDetailBreadCrumb = {
          label: `${self.currentClass?.name} Detail`,
          href: `/teacher/performance/${self.currentClass?._id}`
        }

        if (pathPattern === '/teacher/performance') {
          return [ classPerformanceBreadCrumb ]
        }

        if (pathPattern === '/teacher/performance/:class_id') {
          return [
            classPerformanceBreadCrumb,
            classDetailBreadCrumb,
          ]
        }

        if (pathPattern === '/teacher/subscription/class/:class_id/student/:student_id') {
          const student = resolveIdentifier(Student, rootStore, params.student_id)

          return [
            classPerformanceBreadCrumb,
            classDetailBreadCrumb,
            {
              label: `Add Tutoring for ${student?.first_name}`,
              href: `/teacher/subscription/class/${self.currentClass?._id}/student/${student?._id}`
            },
          ]
        }

        if (pathPattern === '/teacher/performance/student/:student_id') {
          const student = resolveIdentifier(Student, rootStore, params.student_id)

          return [
            classPerformanceBreadCrumb,
            classDetailBreadCrumb,
            {
              label: `Student Performance for ${student?.first_name}`,
              href: `/teacher/performance/student/${student?._id}`
            },
          ]
        }

        return []
      }
    })
  })
  .actions(self => ({
    set_current_class: (currentClass: Class) => {
      self.currentClass = currentClass
    },
  }))
  .actions((self) => ({
    initialise: flow(function* (teacher_id: string, class_id?: string) {
      self.isLoading = true

      const {
        teacherStore,
        subjectsStore,
        admin_tutor_list,
        subscriptionsStore,
        classPerformanceStore,
        studentsStore,
        refreshesStore,
        teacher_teams_list,
      } = getRoot<Instance<typeof Store>>(self)

      yield subjectsStore.get_all_subjects()
      yield studentsStore.get_students_for_teacher(teacher_id)
      yield teacherStore.get_teacher(teacher_id)
      yield Promise.all([
        admin_tutor_list.refresh(),
        subscriptionsStore.get_subscriptions_for_teacher(teacher_id),
      ])

      if (class_id) {
        self.set_current_class(class_id)
        yield Promise.all([
          refreshesStore.get_refreshes(),
          classPerformanceStore.get_class_performance(class_id)
        ])
      }

      yield teacher_teams_list.refresh()

      self.isLoading = false
      return true
    })
  }))
export type TeacherPageStore = Instance<typeof TeacherPageStore>
