import {Instance, types} from 'mobx-state-tree'

import {Student} from 'common/types/student'
import {Class} from 'common/types/class'

export const Team = types.model({
  _id: types.identifier,
  name: types.string,
  students: types.array(types.reference(types.late(() => Student))),
  class: types.reference(types.late(() => Class)),
})
export type Team = Instance<typeof Team>

export const AddNewTeamRequest = types.model({
  body: types.model({
    team_name: types.string,
    class_id: types.string
  })
})
export type AddNewTeamRequest = Instance<typeof AddNewTeamRequest>

export const AddNewTeamResponse = types.model({
  team: types.reference(Team),
  success: types.boolean
})
export type AddNewTeamResponse = Instance<typeof AddNewTeamResponse>


export const GetTeamsRequest = types.model({})
export type GetTeamsRequest = Instance<typeof GetTeamsRequest>
export const GetTeamsResponse = types.model({
  success: types.boolean,
  teams: types.array(Team)
})
export type GetTeamsResponse = Instance<typeof GetTeamsResponse>

export const SaveTeamRequest = types.model({
  body: types.model({
    _id: types.string,
    name: types.string,
    students: types.array(types.string),
  })
})
export type SaveTeamRequest = Instance<typeof SaveTeamRequest>

export const SaveTeamResponse = types.model({
  team: types.reference(Team),
  success: types.boolean,
})
export type SaveTeamResponse = Instance<typeof SaveTeamResponse>

export const DeleteTeamRequest = types.model({
  body: types.model({
    team_id: types.string
  })
})
export type DeleteTeamRequest = Instance<typeof DeleteTeamRequest>

export const DeleteTeamResponse = types.model({
  success: types.boolean,
})
export type DeleteTeamResponse = Instance<typeof DeleteTeamResponse>
