import React from 'react'
import classNames from 'classnames'
import {Link} from 'react-router-dom'

interface IProps {
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
    disabled?: boolean,
    className?: string,
    children?: React.ReactNode,
    href?: string,
    type?: 'button' | 'submit' | 'reset',
    pulse?: boolean,
    ariaLabel?: string
    isLoading?: boolean
}

export const ClickableButton = ({onClick, disabled, className, children, href, type, pulse, isLoading, ariaLabel}: IProps): JSX.Element => {
  const button = (
    <button
      className={
        classNames(
          'text-white font-bold py-2 px-4 rounded cursor-pointer disabled:bg-gray-400 disabled:cursor-not-allowed flex justify-center items-center',
          pulse && 'relative', // Apply dynamically because 'relative' effects the z-index, causing the button to overlay some components.
          className
        )
      }
      disabled={disabled || isLoading}
      onClick={onClick}
      type={type}
      aria-label={ariaLabel}
    >
      {
        pulse ?
          <span
            className={
              classNames(
                'hidden motion-safe:block animate-ping absolute h-full w-full rounded-full opacity-75',
                className
              )
            }
          >

          </span> :
          null
      }

      {
        isLoading ?
          (
            <i className="fa-solid fa-spinner fa-spin"></i>
          ) :
          children
      }
    </button>
  )
  if (href) {
    return (
      <Link to={href} className="cursor-pointer">{button}</Link>
    )
  }

  return button
}

export const PrimaryClickableButton = (props: IProps): JSX.Element => {
  return (
    <ClickableButton
      {...props}
      className={
        classNames(
          'bg-green-500 hover:bg-green-700',
          props.className
        )}
    />
  )
}

export const SecondaryClickableButton = (props: IProps): JSX.Element => {
  return (
    <ClickableButton
      {...props}
      className={
        classNames(
          'bg-blue-500 hover:bg-blue-700',
          props.className
        )}
    />
  )
}

export const WarningClickableButton = (props: IProps): JSX.Element => {
  return (
    <ClickableButton
      {...props}
      className={
        classNames(
          'bg-red-500 hover:bg-red-700',
          props.className
        )}
    />
  )
}
