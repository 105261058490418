// eslint-disable-next-line import-x/no-unresolved
import {VirtualKeyboardInterface} from 'mathlive/dist/types/virtual-keyboard'

// We can add definitions when we pollute the global space here
export interface IWindowConfig extends Window {
    mathVirtualKeyboard: VirtualKeyboardInterface & EventTarget;
    newrelic: {
      noticeError: (error: Error, customAttributes?: object) => void
    }
}

export const $window =
    typeof window !== 'undefined' ?
      (window as unknown as IWindowConfig) :
      ({} as IWindowConfig)
