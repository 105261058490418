import {Instance, types} from 'mobx-state-tree'

import {Student} from 'common/types/student'

export const GetTeacherStudentsRequest = types.model({
  params: types.model({
    teacher_id: types.string
  })
})
export type GetTeacherStudentsRequest = Instance<typeof GetTeacherStudentsRequest>

export const GetTeacherStudentsResponse = types.model({
  success: types.boolean,
  students: types.array(Student)
})
export type GetTeacherStudentsResponse = Instance<typeof GetTeacherStudentsResponse>
