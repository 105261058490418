import {flow, getRoot, Instance, types} from 'mobx-state-tree'
import {DateTime} from 'luxon'
import toast from 'react-hot-toast'
import {sum} from 'lodash'

import {DisplayPrize, DisplayToken} from 'common/types/student/tokens'
import {getPrizes, getTokens, postPrize} from 'util/api/student/tokens'
import {award_tokens} from 'util/api/tokens/award_tokens'
import {IRichStore} from 'types/store'

export const Tokens = types.model(
  'Tokens', {
    isLoading: types.boolean,
    tokens: types.array(DisplayToken),
    prizes: types.array(DisplayPrize),
    previous_token_count: types.maybe(types.number),
    total_token_count: types.number,
    total_pending_token_count: types.number,
    last_updated: types.maybe(types.string),
  }
).actions(self => ({
  fetch_tokens: flow(function* (student_id: string) {
    self.isLoading = true
    const tokenResponse = yield getTokens(student_id)
    self.tokens = tokenResponse.rows
    self.total_pending_token_count = tokenResponse.total_pending_token_count
    self.previous_token_count = self.total_token_count
    self.total_token_count = tokenResponse.total_token_count
    self.last_updated = DateTime.now().toISO()

    self.isLoading = false
  }),
  fetch_prizes: flow(function* () {
    self.isLoading = true
    const prizesResponse = yield getPrizes()
    self.prizes = prizesResponse.results.sort((a, b) => a.amount - b.amount)
    self.isLoading = false
  }),
  redeem_prize: flow(function* ({ prize_id, student_id }) {
    self.isLoading = true
    yield postPrize({prize_id, student_id})
    self.isLoading = false
  }),
}))
  .actions(self => ({
    award_tokens: flow(function* (student_id: string, amount: number) {
      yield toast.promise(
        award_tokens({body: {student_id, amount}}),
        {
          loading: 'Loading...',
          success: `Well done! You've earned ${amount} bonus tokens!`,
          error: 'Error when linking your parent',
        }, {duration: 5000})
      yield self.fetch_tokens(student_id)
    }),
  }))
  .views(self => ({
    get has_increased() {
      return self.total_token_count > self.previous_token_count
    },
    is_redeemable(prize: DisplayPrize) {
      const { student_tokens, student_subscriptions }: IRichStore = getRoot(self)

      const subscription_value = sum(student_subscriptions.current_subscriptions.map(s => s.payment_amount))

      if (student_tokens.total_token_count < prize.amount) {
        return false
      }
      
      if (prize.minimum_subscription_value) {
        return subscription_value >= prize.minimum_subscription_value
      }
      
      return true
    }
  }))

export type Tokens = Instance<typeof Tokens>
