import {ChangeEvent, FC, FormEvent, useEffect, useState} from 'react'
import {getSnapshot, IAnyModelType, Instance, SnapshotIn} from 'mobx-state-tree'
import {observer} from 'mobx-react-lite'
import toast from 'react-hot-toast'
import classNames from 'classnames'

import {logError} from 'util/helpers/logError'
import {IMSTActionButtonsProps, MstActionButtons} from 'generic_components/MstForm/MstActionButtons'
import {MstField} from 'generic_components/MstForm/MstField'

export interface FieldComponentProps<Value> {
    id?: string;
    value: Value,
    onChange: (value: ChangeEvent) => void,
    disabled?: boolean,
    required?: boolean,
    readOnly?: boolean,
    min?: number,
    max?: number,
    placeholder?: string,
    isSearchable?: boolean,
    custom_props?: any
}
export type FieldComponent<Value> = FC<FieldComponentProps<Value>>

interface IValidator {
    validator: (input: string) => boolean
    failMessage: string,
}

export interface FieldSpec<Value> {
    name: string
    label: string
    Component: FieldComponent<Value>
    width_percent?: number
    disabled?: boolean
    required?: boolean
    modifier?: (value: string) => any,
    validators?: IValidator[],
    custom_props?: any
}

interface IProps<M extends IAnyModelType> {
    title?: string
    subtitle?: string
    model: M
    initial: Instance<M>
    submit: (out: SnapshotIn<M>) => Promise<unknown>
    on_reset?: () => void
    fields: FieldSpec<any>[]
    force_edit_mode?: boolean
    submit_text?: string
    cancel_text?: string
    success_text?: string
    hide_cancel?: boolean
    sticky_bottom?: boolean
    is_modal?: boolean,
    ActionButtons?:(props:IMSTActionButtonsProps) => JSX.Element
}

const modifyIfNeeded = (field: string, value: string, fields: FieldSpec<any>[]) => {
  const foundField = fields.find(({name}) => name === field)
  if (foundField.modifier) {
    return foundField.modifier(value)
  }

  return value
}

export const MSTForm: FC<IProps<IAnyModelType>> = observer(({
  initial,
  model,
  fields,
  submit,
  on_reset = () => undefined,
  force_edit_mode = false,
  submit_text = 'Save',
  cancel_text = 'Cancel',
  success_text = 'Saved',
  title,
  subtitle,
  hide_cancel = false,
  sticky_bottom = true,
  is_modal = false,
  ActionButtons = MstActionButtons
}) => {
  const [draft, set_draft] = useState<Record<string, unknown>>(getSnapshot(initial))
  const [errors, set_errors] = useState<Record<string, string[]>>({})
  const [has_errors, set_has_errors] = useState(false)
  const [dirty, set_dirty] = useState(false)
  const [editing, set_editing] = useState(force_edit_mode)
  const [has_submitted, set_has_submitted] = useState(false)

  const reset = () => {
    set_draft(() => getSnapshot(initial))
    set_errors(() => ({}))
    set_has_errors(false)
    set_dirty(false)
    set_editing(force_edit_mode)
  }

  useEffect(reset, [getSnapshot(initial)]) // need to serialise the whole object to update state on field changes

  const onCancel = (e: FormEvent) => {
    e.stopPropagation()
    e.preventDefault()
    reset()
    on_reset()
  }

  const validate = (field?: string, value?: any, onSubmit?: boolean) => {
    const error_entries = fields
      .map(
        ({ name, validators }) =>
          [
            name,
            (validators ?? [])
              .map(
                ({ validator, failMessage }) => {
                  if (name === field) {
                    if (validator(value)) {
                      return null
                    }

                    return failMessage
                  }

                  if (validator(draft[name] as string ?? '')) {
                    return null
                  }

                  return failMessage
                }
              )
              .filter(Boolean)
          ]
      )
      .filter(([_name, value]) => value?.length)

    const errors_per_field = Object.fromEntries(error_entries)
    const has_errors = error_entries.length > 0

    set_errors(errors_per_field)
    set_has_errors(has_errors)

    if (onSubmit){
      if (Object.keys(errors_per_field).length > 0) {
        const firstElementWithError = document.getElementById(Object.keys(errors_per_field)[0])
        if (firstElementWithError && firstElementWithError.scrollIntoView) {
          firstElementWithError.scrollIntoView({ behavior: 'smooth' })
        } else {
          console.error(errors_per_field)
        }
      }
    }

    return !has_errors
  }

  const onClickSubmit = async (e: FormEvent) => {
    e.stopPropagation()
    e.preventDefault()

    set_has_submitted(true)
    if (validate(null, null, true)) {
      try {
        await submit(draft)
        if (success_text !== '') {
          toast.success(success_text, {duration: 5000})
        }
        reset()
      }
      catch (e) {
        logError(e)
        console.log(e)
        toast.error(`Error: ${e.errors?.join(', ')}`)
      }
    }
  }

  const onChange = (event: any) => {
    // if event._id exists, then it's a synthetic 'event' and the object is just the value
    const value = (event._id) ?
      modifyIfNeeded(event.target.id, event, fields) :
      modifyIfNeeded(event.target.id, event.target.value, fields)

    set_draft(draft => ({
      ...draft,
      [event.target.id]: value
    }))
    set_dirty(true)

    // Don't show errors until they have tried submitting once
    if (has_submitted) {
      validate(event.target.id, value)
    }
  }

  return (
    <form onReset={onCancel} className="w-full">
      {title ? <h1 className="p-4 text-center text-xl font-bold capitalize text-gray-200">{title}</h1> : null}
      <div className={classNames(!is_modal ? 'py-2 bg-gray-700/50 rounded shadow' : '')}>
        {subtitle ? <h2 className="p-6">{subtitle}</h2> : null}
        <div>
          <div className="pt-2">
            {
              fields.map((props) =>
                <MstField
                  key={props.name}
                  {...props}
                  is_modal={is_modal}
                  draft={draft}
                  editing={editing}
                  onChange={onChange}
                  errors={errors}
                />
              )
            }
          </div>
        </div>
      </div>
      <ActionButtons
        sticky_bottom={sticky_bottom}
        hide_cancel={hide_cancel}
        editing={editing}
        cancel_text={cancel_text}
        onClickSubmit={onClickSubmit}
        dirty={dirty}
        has_errors={has_errors}
        submit_text={submit_text}
        force_edit_mode={force_edit_mode}
        set_editing={set_editing}
      />
    </form>
  )
})
MSTForm.displayName = 'MSTForm'
