import React, {FC} from 'react'
import {observer} from 'mobx-react-lite'

import {SecondaryClickableButton} from 'components/ClickableButton'
import {ContentCard} from 'generic_components/ContentCard'
import {useStore} from 'types/store'

interface Props {
    isModal: boolean
}

export const AlreadyPlatformOnly:FC<Props> = observer(({ isModal }) => {
  const {subscription_wizard} = useStore()

  return (
    <ContentCard>
      <h1>
        Your subscription is currently platform only, did you mean to select a subscription with direct access
        to a tutor?
      </h1>

      <div className="mt-6">
        <SecondaryClickableButton
          onClick={subscription_wizard.back}
        >
          Back
        </SecondaryClickableButton>
      </div>
    </ContentCard>
  )
})
