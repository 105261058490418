import {flow, Instance, types} from 'mobx-state-tree'
import {DateTime} from 'luxon'

import {StudentAnswer} from 'common/types/student/answers'
import {getStudentAnswers} from 'util/api/student/answers'

export const AnswersStore = types.model({
  answers: types.array(StudentAnswer),
  isLoading: types.boolean,
  fetch_since: types.maybe(types.string),
  student_id: types.maybe(types.string),
})
  .actions(self => ({
    get_answers: flow(function* (student_id: string, fetch_since: string) {
      self.student_id = student_id
      self.fetch_since = fetch_since

      self.isLoading = true
      const answers = yield getStudentAnswers(student_id, fetch_since)

      self.answers = answers
        .sort((a: StudentAnswer, b: StudentAnswer) => a.timestamp <= b.timestamp ? 1 : -1)

      self.isLoading = false
    }),
    update_answer: (update) => {
      const answer = self.answers.find(({_id}) => update._id === _id)

      answer.resolved = update.resolved ?? answer.resolved
      answer.help_requested = update.help_requested ?? answer.help_requested
      answer.reviewed = update.reviewed ?? answer.reviewed
    },
    reset: () => {
      self.answers.clear()
    },
  }))
  .actions(self => ({
    load_next_week: () => {
      self.fetch_since = DateTime
        .fromISO(self.fetch_since)
        .minus({weeks: 1})
        .toISO()

      self.get_answers(self.student_id, self.fetch_since)
    }
  }))

export type AnswersStore = Instance<typeof AnswersStore>
