import {Instance, types} from 'mobx-state-tree'

import {GAME_TYPE} from 'common/types/game/'
import {NumberFromStringOrNumber} from 'common/types/basic/number_from_string_or_number'

export const GhostPlayerGameSummary = types.model('GamePlayerSummary', {
  player: types.string,
  playerType: types.string,
  totalPoints: types.number,
})
export type GhostPlayerGameSummary = Instance<typeof GhostPlayerGameSummary>

export const HumanGamePlayerSummary = GhostPlayerGameSummary
  .named('HumanGamePlayerSummary')
  .props({
    coins: types.number,
    difficultyBonus: types.number,
    streakBonus: types.number,
    streak: types.number,
    highestStreak: types.number,
    questionCnt: types.number,
  })
export type HumanGamePlayerSummary = Instance<typeof HumanGamePlayerSummary>

export const HighScoreGetRequest = types.model('HighScoreGetRequest', {
  query: types.model({
    game_type: types.enumeration(Object.values(GAME_TYPE)),
    duration: NumberFromStringOrNumber
  })
})
export type HighScoreGetRequest = Instance<typeof HighScoreGetRequest>

export const HighScoreGetResponse = types.model('HighScoreGetResponse', {
  score: types.number
})
export type HighScoreGetResponse = Instance<typeof HighScoreGetResponse>


