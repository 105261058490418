import React from 'react'

interface IProps {
  message?: string
}

export const BlockLoader = ({ message }: IProps): JSX.Element => {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="p-4"
    >
      <div className="relative rounded-lg bg-gray-200 px-12 py-6 text-center text-xl text-gray-800 shadow">
        <i className="fa-solid fa-spinner fa-spin"></i> { message ?? 'Loading...' }
      </div>
    </div>
  )
}
