import {FC, useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {IAnyModelType, Instance} from 'mobx-state-tree'

import {PrimaryClickableButton} from 'components/ClickableButton'
import {useStore} from 'types/store'
import {MSTForm} from 'generic_components/MstForm'
import {StringInput} from 'generic_components/MstFormStringInput'
import {Checkbox} from 'generic_components/MstFormCheckbox'
import {Parent} from 'common/types/parent'
import {BlockLoader} from 'generic_components/BlockLoader'
import {IMSTActionButtonsProps} from 'generic_components/MstForm/MstActionButtons'
import {CountrySelector} from 'components/CountrySelector'

interface Props {
  initial_profile: Instance<IAnyModelType>,
  onSave?: () => void,
  ActionButtons?:(props:IMSTActionButtonsProps) => JSX.Element
}

export const ParentDetails: FC<Props> = observer(({ initial_profile, onSave, ActionButtons }) => {
  const { parent_data: { create_profile, profile, save_profile } } = useStore()

  const onSubmit = useCallback(
    async (parent) => {
      if (profile === undefined) {
        await create_profile(parent)
      } else {
        await save_profile(parent)
      }

      if (onSave){
        onSave()
      }
    },
    [
      create_profile,
      profile,
      save_profile,
      onSave,
    ]
  )

  return (
    <MSTForm
      title='Your profile'
      initial={initial_profile}
      model={Parent}
      submit={onSubmit}
      force_edit_mode
      cancel_text={profile === undefined ? 'Clear' : 'Cancel'}
      fields={[
        {
          name: 'first_name',
          label: 'First name',
          Component: StringInput,
          required: true,
          validators: [
            {
              validator: Boolean,
              failMessage: 'Please enter a first name',
            },
          ],
        },
        {
          name: 'last_name',
          label: 'Last name',
          Component: StringInput,
          required: true,
          validators: [
            {
              validator: Boolean,
              failMessage: 'Please enter a last name',
            },
          ],
        },
        {
          name: 'email',
          label: 'Email address',
          Component: StringInput,
          disabled: true,
          required: true,
          validators: [
            {
              validator: Boolean,
              failMessage: 'Please enter an email address',
            },
          ],
        },
        {
          name: 'country',
          label: 'Country',
          Component: CountrySelector,
        },
        {
          name: 'postcode',
          label: 'Postcode',
          Component: StringInput,
        },
        {
          name: 'phone_number',
          label: 'Phone number',
          Component: StringInput,
        },
        {
          name: 'email_opt_in',
          label: 'Email opt in',
          Component: Checkbox,
          custom_props: {
            text: 'Check this box to receive emails with our latest offers and updates'
          }
        }
      ]}
      ActionButtons={ActionButtons}
    />
  )
})

export const SubscriptionWizardParentDetails = observer(() => {
  const {parentPageStore, subscription_wizard, parent_data} = useStore()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    parent_data.get_profile()
      .then(() => {
        if (!parent_data.profile?.first_name) {
          return parentPageStore.create_initial_profile()
        }
      })
      .then(() => setIsLoading(false))
  }, [])

  if(isLoading) {
    return <BlockLoader message='Loading... Please wait' />
  }

  return (
    <ParentDetails
      initial_profile={parent_data.profile ?? parentPageStore.newParent}
      onSave={subscription_wizard.next}
      ActionButtons={ActionButtons}
    />
  )
})

const ActionButtons = ({ onClickSubmit }: IMSTActionButtonsProps) => {
  return (
    <div className="flex h-1/3 justify-around py-6">
      <PrimaryClickableButton
        onClick={onClickSubmit}
      >
        Next
      </PrimaryClickableButton>
    </div>
  )
}
