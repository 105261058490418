import React from 'react'
import classNames from 'classnames'

interface IProps {
    children: React.JSX.Element|string|number,
    selected?: boolean,
    expected?: boolean,
    onClick: (e) => void,
    id: string,
    type: 'button' | 'submit' | 'reset',
    value: any
}
export const McTick = ({type, id, children, selected, expected, onClick, value}: IProps) => {
  return (
    <button
      type={type}
      id={id}
      data-testid={id}
      onClick={onClick}
      value={value}
      className={
        classNames(
          getBorderColour(selected, expected),
          'p-4 m-4 rounded shadow font-bold text-lg focus:outline-0 border-solid border-4 bg-gray-100 w-full lg:w-auto'
        )
      }
    >
      {children}
    </button>
  )
}

const getBorderColour = (selected: boolean, expected: boolean): string => {
  if (expected) {
    return 'border-green-500'
  }
  if (selected) {
    return 'border-blue-500'
  }
  if (selected && !expected) {
    return 'border-red-500'
  }

  return 'border-gray-500'
}
