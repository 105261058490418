import {useEffect} from 'react'

export interface IModalContent {
  title?: string | JSX.Element,
  content: string | JSX.Element,
}

interface IModalProps extends IModalContent{
  onClose: () => void,
  onConfirm?: () => void,
}

export const Modal = ({ onClose, title, onConfirm, content }: IModalProps): JSX.Element => {
  useEffect(() => {
    document.body.classList.add('overflow-hidden')
    return () => {
      document.body.classList.remove('overflow-hidden')
    }
  }, [])

  return (
    <div
      tabIndex={-1}
      className="fixed inset-0 z-50 w-full overflow-hidden bg-gray-900/50 p-4 backdrop-blur md:inset-0"
      onClick={onClose}
    >
      <div
        className="fixed inset-0 mx-auto my-6 h-full lg:w-fit lg:min-w-[500px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative flex flex-col rounded-lg bg-white shadow">
          {
            title ?
              (
                <div className="rounded-t border-b p-4">
                  <h3 className="w-full text-center text-xl font-semibold capitalize text-gray-900">
                    {title}
                  </h3>
                  <button
                    type="button"
                    className="absolute right-5 top-5 bg-transparent text-gray-400 hover:bg-gray-200 hover:text-gray-900"
                    onClick={onClose}
                  >
                    <svg aria-hidden="true" className="size-5" fill="currentColor" viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"></path>
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
              ) :
              null
          }

          <div className="space-y-6">
            {content}
          </div>

          {
            onConfirm ?
              (
                <div
                  className="fixed bottom-0 flex items-center space-x-2 rounded-b border-t border-gray-200 p-6"
                >
                  <button
                    data-modal-hide="defaultModal"
                    type="button"
                    className="rounded-lg bg-blue-700 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus:ring-4 focus:ring-blue-300">I
                    accept
                  </button>
                  <button
                    data-modal-hide="defaultModal"
                    type="button"
                    className="rounded-lg border border-gray-200 bg-white px-5 py-2.5 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-900 focus:z-10 focus:outline-none focus:ring-4 focus:ring-blue-300">Decline
                  </button>
                </div>
              ) :
              null
          }

        </div>
      </div>
    </div>
  )
}
