import {getRoot, types} from 'mobx-state-tree'

import {AdminSubject} from 'common/types/subject/subject'
import {Refresh} from 'common/types/refreshes/get_refreshes'
import {IRichStore} from 'types/store'
import {Mix_Extended} from 'types/student/mixes'

export const Selected_subjects = types.model({
  selected_refresh: types.maybe(types.safeReference(Refresh)),
  selected_topic: types.maybe(types.safeReference(AdminSubject)),
  selected_endcode: types.maybe(types.safeReference(AdminSubject)),
  selected_mix: types.maybe(types.safeReference(Mix_Extended)),
  selected_game_type: types.maybe(types.string), // todo enum
})
  .actions(self => {
    const set_selected_refresh = (refresh: Refresh | string) => {
      self.selected_refresh = refresh as any
      self.selected_topic = undefined
      self.selected_endcode = undefined
    }

    const set_selected_topic = (topic: AdminSubject | string) => {
      self.selected_topic = topic as any
      self.selected_endcode = undefined
    }

    const set_selected_endcode = (endcode: AdminSubject | string) => {
      self.selected_endcode = endcode as any
    }

    const set_selected_game_type = (game_type: string) => {
      self.selected_game_type = game_type as any
      self.selected_mix = undefined
      self.selected_refresh = undefined
      self.selected_topic = undefined
      self.selected_endcode = undefined
    }

    const set_selected_mix = (mix_id: string) => {
      const {student_mixes_list: {mixes}} = getRoot<IRichStore>(self)

      const found_mix = mixes.find(mix => mix._id === mix_id)
      if (found_mix) {
        self.selected_mix = found_mix
        return
      }
    }

    return {
      set_selected_refresh,
      set_selected_topic,
      set_selected_endcode,
      set_selected_game_type,
      set_selected_mix,
    }
  }
  )
  .views(self => ({
    get subjects(): AdminSubject[] {
      if (self.selected_mix){
        return self.selected_mix.subjects
      }
      if (self.selected_refresh) {
        if (self.selected_endcode) {
          return self.selected_refresh.subjects.filter(s => s._id === self.selected_endcode._id)
        }
        if (self.selected_topic) {
          return self.selected_refresh.subjects.filter(s => s.parent._id === self.selected_topic._id)
        }

        return self.selected_refresh.subjects
      }
      if (self.selected_endcode) {
        return [self.selected_endcode]
      }
      if (self.selected_topic) {
        return [self.selected_topic]
      }

      const {student_subscriptions}: IRichStore = getRoot(self)
      return [student_subscriptions.subscription_subject]
    }
  }))
export type Selected_subjects = typeof Selected_subjects
