import {Instance, types} from 'mobx-state-tree'

import {StudentId} from './student/id'

import {Difficulty} from 'common/types/question/difficulty'

export const Ask = types.model({
  body: types.model({
    student: StudentId,
    difficulty: Difficulty,
    focus: types.array(types.string),
    endcodes_to_exclude: types.optional(types.array(types.string), [])
  })
})
export type Ask = Instance<typeof Ask>
