import React from 'react'
import {observer} from 'mobx-react-lite'


import {Menu} from 'generic_components/MenuWrapper/Menu'
import {useUser} from 'context/userContext'
import {getMenuItemsForRole} from 'util/helpers/getMenuItemsForRole'
import {IMenuButtonProps} from 'generic_components/MenuWrapper/MenuButton'

interface IProps {
    children,
    items?: IMenuButtonProps[]
}

export const MenuWrapper = observer(({ items, children }: IProps): React.JSX.Element => {
  const {user} = useUser()

  const menu_items = (items ?? []).length > 0 ? items : getMenuItemsForRole(user)

  if (menu_items.length === 0) {
    return children
  }

  return (
    <div className="2xl:grid 2xl:grid-cols-6">
      <Menu items={menu_items}/>
      <div className="col-span-5 p-4">
        {children}
      </div>
    </div>
  )
})
MenuWrapper.displayName = 'MenuWrapper'
