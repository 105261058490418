import {observer} from 'mobx-react-lite'

import {FieldComponent} from 'generic_components/MstForm'

export const DateInput:FieldComponent<string> = observer(({id, value, onChange, disabled}): JSX.Element => {
  return (
    <input
      id={id}
      type='date'
      value={value}
      onChange={onChange}
      disabled={disabled}
      className="h-[36px] w-full appearance-none rounded border px-2 leading-tight text-gray-700 shadow focus:outline-none focus:ring"
    />
  )
})
DateInput.displayName = 'DateInput'
