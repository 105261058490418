import {flow, Instance, types} from 'mobx-state-tree'

import {StudentStats} from 'common/types/student/current_stats'
import {getStudentCurrentStats} from 'util/api/student/current_stats'

export const StudentPerformanceStatsStore = types.model({
  hierarchy: StudentStats,
  is_loading: types.boolean
})
  .actions(self => ({
    get_performance_stats: flow(function* (student_id: string) {
      self.is_loading = true
      const perf = yield getStudentCurrentStats(student_id)
      self.hierarchy = perf
      self.is_loading = false
    })
  }))
export type StudentPerformanceStatsStore = Instance<typeof StudentPerformanceStatsStore>
