import {FC, useEffect} from 'react'
import ReactPlayer from 'react-player/vimeo'
import {useTimeout} from 'usehooks-ts'

import {useStore} from 'types/store'
import {AdminSubject} from 'common/types/subject/subject'
import {useUser} from 'context/userContext'
import {Roles} from 'util/auth/helper'


interface Props {
  subject: AdminSubject
  help_videos: string[]
}

export const VideoHelp: FC<Props> = ({ subject, help_videos }) => {
  const {user} = useUser()
  const {
    actions_store: {record_action},
    student_tokens: {award_tokens, fetch_tokens}
  } = useStore()

  useEffect(() => {
    record_action('watch_skill_videos', subject._id)
  }, [])

  useTimeout(() => {
    if (user.role === Roles.STUDENT) {
      award_tokens(user._id, 20)
        .then(() => fetch_tokens(user._id))
    }
  }, 30_000)

  return (
    <>
      {help_videos.map(v => {
        return (
          <div key={v} className="mx-auto w-fit">
            <ReactPlayer
              url={v}
              controls={true}
            />
          </div>
        )
      })}
    </>
  )
}
