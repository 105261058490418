import {flow, getRoot, Instance, types} from 'mobx-state-tree'
import {differenceWith} from 'lodash'

import {getStudentTrendStats} from 'util/api/student/trend_stats'
import {StudentSubjectTrend} from 'common/types/student/trend_stats'
import {Store} from 'types/store'
import {AdminSubject} from 'common/types/subject/subject'


export const StudentTrendsStore = types.model({
  trends: types.array(StudentSubjectTrend),
  is_loading: types.maybe(types.boolean),
}).actions(
  self => ({
    get_trend_stats: flow(function* (student_id: string) {
      self.is_loading = true
      self.trends = yield getStudentTrendStats(student_id)
      self.is_loading = false
    })
  })
)
  .views(self => ({
    get new_endcodes_this_week(): AdminSubject[] {
      const { subjectsStore } = getRoot<Instance<typeof Store>>(self)

      const current_score = self.trends.at(-1)
      const first_score = self.trends.at(0)

      if (current_score && first_score) {
        const this_week_live = subjectsStore
          .endcodes()
          .filter(e => current_score.score > e.min_score && current_score.score < e.max_score)

        const first_week_of_scores_live = subjectsStore
          .endcodes()
          .filter(e => first_score.score > e.min_score && first_score.score < e.max_score)

        return differenceWith(
          this_week_live,
          first_week_of_scores_live
        )
      }

      return []
    }
  }))
export type StudentTrendsStore = Instance<typeof StudentTrendsStore>
