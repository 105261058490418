import {FC, useCallback} from 'react'
import {observer} from 'mobx-react-lite'

import {useStore} from 'types/store'
import {Container, Input} from 'generic_components/AnswerInputContainers'
import {InputProps} from 'components/question/inputProps'
import {get_formatted_correct_answer} from 'components/question/input'

const calculateLength = (x: string): number => x.length <= 3 ? 3 : x.length + 1

export const NumberComponent: FC<InputProps> = observer(({ answerIndex, element, handleChange, answer_decision, correct_answers, value, element_id }) => {
  const { game, auth: { user } } = useStore()

  const answer = correct_answers.length > 0 && answer_decision != 'CORRECT' ?
    get_formatted_correct_answer(correct_answers, answerIndex, element.ref) :
    ''

  const size = calculateLength(value)
  const answerSize = calculateLength(answer)

  const handleInputChange = useCallback((e) => {
    e.target.value = e.target.value.replaceAll(/[^0-9.-]/g, '')
    handleChange(e)
  }, [handleChange])

  return (
    <Container>
      <Input
        id={element_id}
        onChange={handleInputChange}
        answer_decision={answer_decision}
        readOnly={!!(answer_decision)}
        value={value}
        size={size}
        onKeyPress={e => {
          if (e.key === 'Enter' && !game.over) {
            game.submit_answer(user._id)
          }
        }}
        autoFocus={element.autoFocus}
        placeholder={element.placeholder}
      />
      {answer_decision !== 'CORRECT' && correct_answers.length > 0 &&
        <Input
          value={correct_answers[0].find(answerPart => answerPart.ref === element.ref)?.values[0] || 'error'}
          answer_decision="CORRECT"
          readOnly={true}
          size={answerSize}
          placeholder={element.placeholder}
        />
      }
    </Container>
  )
})
