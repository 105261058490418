import {Instance, types} from 'mobx-state-tree'

import {AdminSubject} from 'common/types/subject/subject'

export const Stats = types.model({
  subject: types.late(() => types.safeReference(AdminSubject)),
  parent_subject: types.late(() => types.maybeNull(types.safeReference(AdminSubject))),
  score: types.maybe(types.number),
  previous_score: types.maybe(types.maybeNull(types.number)),
  recent_answer_count: types.maybe(types.number),
  count: types.maybe(types.maybeNull(types.number)),
  last_timestamp: types.maybe(types.string),
  is_ahead: types.maybe(types.boolean),
}).named('Stats')
export type Stats = Instance<typeof Stats>

export const TopicStats = Stats.props({
  endcodes: types.array(Stats)
}).named('TopicStats')

export type TopicStats = Instance<typeof TopicStats>

export const ThemeStats = Stats.props({
  topics: types.array(TopicStats)
}).named('ThemeStats')
export type ThemeStats = Instance<typeof ThemeStats>

export const SubjectStats = Stats.props({
  themes: types.array(ThemeStats)
}).named('SubjectStats')
export type SubjectStats = Instance<typeof SubjectStats>

export const StudentStats = types.array(SubjectStats)
export type StudentStats = Instance<typeof StudentStats>

export const StudentStatsRequest = types.model({
  params: types.model({
    student_id: types.string
  }),
}).named('StudentStatsRequest')
export type StudentStatsRequest = typeof StudentStatsRequest
