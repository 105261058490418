interface IContentCardProps {
    title?: string,
    children: JSX.Element|JSX.Element[]|string
}

export const ContentCard = ({ title, children }: IContentCardProps): JSX.Element => {
  return (
    <div className="whitespace-normal p-6">
      {
        title ?
          (
            <h2 className="mb-6 text-center text-xl font-bold capitalize">
              { title }
            </h2>
          ) :
          null
      }
      <div className="rounded bg-amber-200 p-6 shadow">
        { children }
      </div>
    </div>
  )
}
