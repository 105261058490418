import {applySnapshot, flow, getRoot, Instance, SnapshotOut, types} from 'mobx-state-tree'
import {groupBy, isEqual, uniqWith} from 'lodash'

import {ApproxAvailabilities, TutorAvailabilitySlot} from 'common/types/tutor/availability_search'
import {ApproxTime, SessionOptionEnum, SessionOptions} from 'common/types/session/options'
import {Day} from 'common/types/basic/day'
import {search_tutor_availabilities} from 'util/api/tutor/availability_search'
import {createBasket, createFreeTrial, editSubscription} from 'util/api/subscriptions'
import {Basket, SelectedSlot, SelectedSlots, Subscription} from 'common/types/subscription'
import {getTutorAvailability} from 'util/api/tutor/availabilty'
import {getSubscriptionProducts} from 'util/api/subscription_products'
import {SubscriptionProduct} from 'common/types/subscription_products/subscription_products'
import {IRichStore, Store} from 'types/store'
import {AdminSubject} from 'common/types/subject/subject'
import {Student} from 'common/types/student'
import {Roles} from 'util/auth/helper'
import {logError} from 'util/helpers/logError'
import {ITier, tiers} from 'components/subscription_tiers/helpers'
import {
  create_subscription_steps,
  edit_subscription_steps,
  IStep,
  tutor_reschedule_subscription_steps,
} from 'generic_components/SubscriptionSteps'

const get_current_subscription_availability = async (existing_subscription) => {
  const tutor_id = existing_subscription?.tutor?._id
  if (tutor_id) {
    const tutorAvailability = await getTutorAvailability(tutor_id)

    return existing_subscription?.slots.map((slot_id) => {
      const slot = Object
        .entries(tutorAvailability.availability)
        .map(([_, value]) => value.find(({ _id }) => _id === slot_id))
        .filter(Boolean)[0]

      return {
        _id: slot_id,
        day_of_week: slot.day_of_week,
        time: slot.time,
        tutor_id,
      }
    })
  }
  return []
}

const get_customer_details = ({ user, school_id }) => {
  if (user.role === Roles.SCHOOL) {
    return {
      parent_id: null,
      school_id: user._id
    }
  }

  if (user.role === Roles.TEACHER) {
    return {
      parent_id: null,
      school_id
    }
  }

  return {
    parent_id: user._id,
    school_id: null
  }
}

export enum SubscriptionJourneyTypes {
    CreateSubscription = 'CreateSubscription',
    EditSubscription = 'EditSubscription',
    TutorRescheduleSubscription = 'TutorRescheduleSubscription',
}

export const SubscriptionWizardState = types
  .model({
    subscription_journey_type: types.enumeration(Object.values(SubscriptionJourneyTypes)),
    current_step: types.optional(types.number, 0),
    student: types.safeReference(Student),
    subject: types.safeReference(AdminSubject),
    selected_session_option: types.optional(types.string, SessionOptionEnum.PlatformOnly),
    availability: ApproxAvailabilities,
    finding_availability: types.optional(types.boolean, false),
    tutor_availabilities: types.array(types.compose(TutorAvailabilitySlot, types.model({identifier: types.identifier}))),
    existing_subscription: types.maybe(types.reference(Subscription)),
    selected_slots: SelectedSlots,
    referral_code: types.optional(types.string, ''),
    query_with_referral_code: types.optional(types.boolean, false),
    is_invalid_referral_code: types.optional(types.boolean, false),
    products: types.array(SubscriptionProduct),
    basket: types.maybe(Basket),
    school_year: types.maybe(types.number),
    isLoading: types.boolean,
  })
  .views(self => ({
    get steps () {
      const {auth, parent_data: { is_normal_sign_in }}: IRichStore = getRoot(self)

      const showIf = (step: IStep) => {
        if (step.showIf) {
          return step.showIf({
            user: auth.user,
            selected_session_option: self.selected_session_option,
            isPrevPlatformOnly: self.existing_subscription?.session_option === SessionOptionEnum.PlatformOnly,
            is_normal_sign_in
          })
        }
        return true
      }

      if (self.subscription_journey_type === SubscriptionJourneyTypes.CreateSubscription) {
        return create_subscription_steps.filter(showIf)
      }

      if (self.subscription_journey_type === SubscriptionJourneyTypes.EditSubscription) {
        return edit_subscription_steps.filter(showIf)
      }

      if (self.subscription_journey_type === SubscriptionJourneyTypes.TutorRescheduleSubscription) {
        return tutor_reschedule_subscription_steps.filter(showIf)
      }
    },
    get platform_only_cost () {
      const platform_only = self.products.find((product) => product.label === SessionOptionEnum.PlatformOnly)
      return platform_only?.weekly_cost_in_pence / 100
    },
    get has_school_subscription () {
      return Boolean(((self.student?.subscriptions as any) ?? []).find(s => !s.cancelled && s.school))
    },
  }))
  .views(self => ({
    get subscription_tiers () {
      return tiers.filter((tier: ITier) => {
        if (tier.showIf) {
          return tier.showIf({
            shouldShow: self.subscription_journey_type === SubscriptionJourneyTypes.CreateSubscription && !self.has_school_subscription
          })
        }
        return true
      })
    },
  }))
  .views(self => ({
    session_count: () => {
      const selected_product = self.products.find((product) => product.label === self.selected_session_option)
      return selected_product?.sessions
    },
    sessions_time_total: () => {
      switch(self.selected_session_option) {
        case SessionOptionEnum.FourFifteenMin:
          return '1 Hour'
        case SessionOptionEnum.FiveFifteenMin:
          return '1 Hour and 15 Minutes'
        case SessionOptionEnum.SixFifteenMin:
          return '1 Hour and 30 Minutes'
        case SessionOptionEnum.SevenFifteenMin:
          return '1 Hour and 45 Minutes'
        default:
          return `${Number(self.selected_session_option.slice(0,1)) * 15} Minutes`
      }
    },
    cost: () => {
      const selected_product = self.products.find((product) => product.label === self.selected_session_option)
      return selected_product?.weekly_cost_in_pence / 100
    },
    product_cost: (label: SessionOptionEnum) => {
      const selected_product = self.products.find((product) => product.label === label)
      const cost = selected_product?.weekly_cost_in_pence / 100

      if(self.has_school_subscription && label !== SessionOptionEnum.PlatformOnly) {
        return (cost - self.platform_only_cost).toFixed(2)
      }

      if (Number.isInteger(cost)) {
        return cost
      }

      return cost.toFixed(2)
    },
    is_available: (day: string, time: string) => self.availability.findIndex(a => a.day === day && a.approx_time === time) !== -1,
    available_slots: (tutor_id: string) => {
      if (!tutor_id) {
        const possible_tutors = Object.entries(groupBy(self.tutor_availabilities, 'tutor_id'))
          .filter(([_, slots]: any) =>
            self.selected_slots.every((selectedSlot) => slots.find((s) =>
              selectedSlot.day_of_week === s.day_of_week &&
                    selectedSlot.time === s.time
            )))
          .map(([key]) => key)

        const all_possible_slots = self
          .tutor_availabilities
          .filter((slot) => possible_tutors.includes(slot.tutor_id))
          .map(({time, day_of_week}) => ({
            time,
            day_of_week,
          }))

        return uniqWith(all_possible_slots, isEqual)
      }

      return self.tutor_availabilities
    },
  }))
  .actions(self => {
    const {auth, promo_codes, actions_store: {record_action}} = getRoot<Instance<typeof Store>>(self)

    return ({
      next: flow(function* () {
        yield record_action('subscription_progress', `finished:${self.steps[self.current_step].label}`)
        self.current_step = self.current_step + 1
        yield record_action('subscription_progress', `start:${self.steps[self.current_step].label}`)
      }),
      back: flow(function* () {
        yield record_action('subscription_progress', `back-from:${self.steps[self.current_step].label}`)
        self.current_step = self.current_step - 1
        yield record_action('subscription_progress', `back-to:${self.steps[self.current_step].label}`)
      }),
      set_step: flow(function* (new_step: number) {
        if (self.current_step === new_step) {
          yield record_action('subscription_progress', `jump:${self.steps[self.current_step].label}`)
          return
        }

        yield record_action('subscription_progress', `jump-from:${self.steps[self.current_step].label}`)
        self.current_step = new_step
        yield record_action('subscription_progress', `jump-to:${self.steps[self.current_step].label}`)
      }),
      update_student: (student) => {
        self.student = student
        self.basket = undefined
      },
      update_subject: (subject) => {
        self.subject = subject
        self.basket = undefined
      },
      update_session_count: (option: SnapshotOut<SessionOptions>) => {
        self.selected_session_option = option

        if (self.selected_session_option === SessionOptionEnum.PlatformOnly) {
          self.selected_slots.replace([])
        }
      },
      update_availability: (day: SnapshotOut<Day>, approx_time: SnapshotOut<ApproxTime>, available: boolean) => {
        if (available) {
          self.availability = self.availability.filter(a => !(a.day === day && a.approx_time === approx_time)) as any
        } else {
          self.availability.push({day, approx_time})
        }

        self.selected_slots.replace([])
      },
      replace_selected_slots: (slots: SelectedSlot[]) => {
        self.selected_slots.replace(slots)
      },
      update_slot_selection: (slot: SelectedSlot) => {
        const isAlreadySelected = self.selected_slots.some(({day_of_week, time}) =>
          day_of_week === slot.day_of_week &&
                  time === slot.time
        )

        if (!isAlreadySelected) {
          self.selected_slots.push(slot)
        } else {
          self.selected_slots.replace(
            self.selected_slots.filter(
              ({day_of_week, time}) => !(slot.day_of_week === day_of_week && slot.time === time)
            )
          )
        }
      },
      update_existing_subscription: (existing_subscription) => {
        self.existing_subscription = existing_subscription
      },
      find_availability: flow(function* () {
        if (self.finding_availability || !self.subject) {
          return
        }
        self.finding_availability = true

        try {
          const availability = yield search_tutor_availabilities({
            body: {
              student: self.student._id,
              subject: self.subject._id,
              session_option: self.selected_session_option as SessionOptionEnum,
              // If we have a tutor, and therefore an existing subscription. We just want all that tutors availability.
              // Even slots which are busy (on the current subscription).
              availability: self.existing_subscription?.tutor?._id ?
                undefined :
                self.availability,
              tutor_id: self.existing_subscription?.tutor?._id,
              referral_code: self.query_with_referral_code ?
                self.referral_code :
                undefined,
            }
          })

          applySnapshot(
            self.tutor_availabilities,
            uniqWith(
              [
                ...availability,
                ...yield get_current_subscription_availability(self.existing_subscription)
              ].map((slot) => ({
                ...slot,
                identifier: `${slot.day_of_week} - ${slot.time}`
              })
              ),
              isEqual
            ),
          )
        } catch (e) {
          logError(e)
          console.error(e)
        } finally {
          self.finding_availability = false
        }
      }),
      update_referral_code: (code: string) => {
        self.referral_code = code
      },
      update_query_with_referral_code: (value: boolean) => {
        self.query_with_referral_code = value
      },
      checkout: flow(function* () {
        self.isLoading = true

        if(self.selected_session_option === SessionOptionEnum.FreeTrial) {
          yield createFreeTrial({
            body: {
              student_id: self.student._id,
              subject_id: self.subject._id,
              parent_id: auth.user._id,
              school_year: self.school_year,
              school_id: null,
            }
          })
          self.isLoading = false
          return `${location.protocol}//${location.host}/parent/subscriptions`
        }

        if(
          self.subscription_journey_type === SubscriptionJourneyTypes.CreateSubscription ||
          self.existing_subscription?.session_option === SessionOptionEnum.FreeTrial
        ) {
          const {redirect_url} = yield createBasket({
            body: {
              student_id: self.student._id,
              subject: self.subject._id,
              session_option: self.selected_session_option as SessionOptionEnum,
              slots: self.selected_slots,
              success_url: `${location.protocol}//${location.host}/parent/subscriptions`,
              error_url: `${location.protocol}//${location.host}/parent/subscriptions`,
              promo_code: promo_codes.promotion_information?.promo_id,
              school_year: self.school_year ?? self.existing_subscription?.school_year,
              ...self.referral_code ? {referral_code: self.referral_code} : {},
              ...get_customer_details({
                user: auth.user,
                school_id: self.student.school
              })
            }
          })
          self.isLoading = false
          return redirect_url
        }

        const success_error_url =
          auth.user.role === Roles.TEACHER ?
            `${location.protocol}//${location.host}/teacher/performance/${self.existing_subscription.class._id}` :
            `${location.protocol}//${location.host}/parent/subscriptions`

        const {redirect_url} = yield editSubscription({
          body: {
            student_id: self.student._id,
            subject: self.subject._id,
            session_option: self.selected_session_option as SessionOptionEnum,
            slots: self.selected_slots,
            success_url: success_error_url,
            error_url: success_error_url,
            tutor_id: self.existing_subscription?.tutor?._id,
            class_id: self.existing_subscription?.class?._id,
            promo_code: promo_codes.promotion_information?.promo_id,
            ...self.referral_code ? {referral_code: self.referral_code} : {},
            is_confirmed_edit: true,
            ...get_customer_details({
              user: auth.user,
              school_id: self.student.school
            })
          }
        })
        self.isLoading = false
        return redirect_url
      }),
      get_products: flow(function* () {
        self.products = (yield getSubscriptionProducts()).results
      }),
      set_school_year: (school_year) => {
        self.school_year = school_year
      }
    })
  })

export type SubscriptionWizardState = Instance<typeof SubscriptionWizardState>
