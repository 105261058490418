import {flow, Instance, types} from 'mobx-state-tree'
import {DateTime} from 'luxon'

import {UserModel} from 'types/user/model'
import {HydratedSession} from 'common/types/session'
import {getPastSessions} from 'util/api/sessions'
import {Roles} from 'util/auth/helper'

export const PastSessionsStore = types.model({
  sessions: types.array(HydratedSession),
  busy: types.boolean,
  weeks: types.number
}).volatile((self) => ({
  user: null,
  tutor: null,
})).actions(self => {
  const get_sessions = async () => {
    const selector = get_selector(self.user, self.tutor)
    const sessions = await Promise.all(Array.from({length: self.weeks}).map((_, week) =>
      getPastSessions(selector, DateTime.now().minus({week}).toISO())
    ))

    return sessions.flat()
  }

  return {
    refresh: flow(function* (user?: UserModel, tutor?: string) {
      self.busy = true
      // todo refactor out this volatile state
      self.user = user
      self.tutor = tutor

      self.sessions = yield get_sessions()
      self.busy = false
    }),
    load_next_week: flow(function* () {
      self.busy = true
      self.weeks = self.weeks + 1
      self.sessions = yield get_sessions()
      self.busy = false
    })
  }
})

export type PastSessionsStore = Instance<typeof PastSessionsStore>

export const get_selector = (user, tutor?: string) => {
  if (!user) {
    return {}
  }
  if (user.role === Roles.STUDENT) {
    return { student_id: user._id }
  }
  if (user.role === Roles.PARENT) {
    return { parent_id: user._id }
  }
  if (user.role === Roles.TEACHER) {
    return { teacher_id: user._id }
  }
  if (user.role === Roles.ADMIN) {
    return { tutor_id: tutor }
  }

  return { tutor_id: user._id }
}
