import {flow, getRoot, Instance, types} from 'mobx-state-tree'
import {isNull, mean, uniqBy} from 'lodash'

import {Refresh} from 'common/types/refreshes/get_refreshes'
import {AdminSubject} from 'common/types/subject/subject'
import {getRefreshes} from 'util/api/refreshes/get_refreshes'
import {IRichStore} from 'types/store'
import {Roles} from 'util/auth/helper'

export interface IRefreshOption {
    key: string,
    value: string,
}

interface IDefaultRefresh {
    initialRefresh: IRefreshOption,
    refreshIndex: number|null
}

export const RefreshesStore = types.model({
  refreshes: types.array(Refresh),
  is_loading: types.boolean,
})
  .views((self) => ({
    refreshes_options(parent_subject: AdminSubject|string): IRefreshOption[] {
      const subject_id = typeof parent_subject === 'string' ?
        parent_subject :
        parent_subject._id

      return self.refreshes
        .filter(refresh => refresh.parent_subject._id === subject_id)
        .map((r: Refresh) => ({
          key: r.label,
          value: r._id,
        }))
        .sort((a, b) => (a.key > b.key ? 1 : -1))
    },
    get_topics_for_refresh: (selected_refresh: Refresh) => {
      return uniqBy(
        (selected_refresh?.subjects ?? [])
          .map((subject) => subject.parent)
          .slice(0)
          .sort((a, b) => a.name > b.name ? 1 : -1),
        (s) => s.name
      )
    },
    get_refresh_topic_performance_stats: (topic: AdminSubject, refresh_id: string) => {
      const { subjectsStore, answerStatsStore }: IRichStore = getRoot(self)

      const selected_refresh = self.refreshes
        .find((refresh: Refresh) => refresh._id === refresh_id)

      const topicEndcodeIds = subjectsStore.subjects
        .filter((subject) => subject.parent?._id === topic._id)
        .filter((subject) =>
          selected_refresh.subjects.map((s) => s._id).includes(subject._id)
        )
        .map((subject) => subject._id)

      const stats_to_count = answerStatsStore.answer_stats
        .filter((answer_stat) =>
          topicEndcodeIds.includes(answer_stat.subject._id)
        )
        .filter((answer_stat) => answer_stat.recent_correct_percentage !== null)

      const avg_performance = mean(
        stats_to_count.map((answer_stat) => answer_stat.recent_correct_percentage)
      )
      return {
        avg_performance,
        stats_to_count
      }
    },
    endcodes_for_topic: (selected_refresh: Refresh, topic_id: string) => {
      return (
        (selected_refresh?.subjects ?? [])
          .filter((subject) => subject.parent._id === topic_id)
          .filter((subject: AdminSubject) => subject.status === 'live')
          .sort((a, b) => a.min_score > b.min_score ? 1 : -1)
      )
    },
    get_endcode_performance: (endcode_id: string) => {
      const { answerStatsStore }: IRichStore = getRoot(self)

      return answerStatsStore.answer_stats.find(
        (answer_stat) => answer_stat.subject._id === endcode_id
      )?.recent_correct_percentage
    }
  }))
  .views((self) => ({
    find_student_default_refresh(parent_subject: AdminSubject, subject_score: number): IDefaultRefresh {
      const {auth, student_performance_stats_store: {hierarchy}}: IRichStore = getRoot(self)

      if (
        auth.user.role === Roles.STUDENT &&
        !hierarchy.length
      ) {
        return {
          initialRefresh: self.refreshes_options(parent_subject)[0],
          refreshIndex: 0,
        }
      }

      const current_refresh = self.refreshes
        .filter(refresh => refresh.reference_score < subject_score)
        .filter(refresh => refresh.parent_subject._id === parent_subject._id)
        .sort((a, b) => (a.reference_score > b.reference_score ? 1 : -1))
        .pop()

      if (!current_refresh) {
        return {
          initialRefresh: self.refreshes_options(parent_subject)[0],
          refreshIndex: 0,
        }
      }
      
      return {
        initialRefresh: self.refreshes_options(parent_subject).find(refresh => refresh.value === current_refresh._id),
        refreshIndex: self.refreshes_options(parent_subject).findIndex(refresh => refresh.value === current_refresh._id)
      }
    },
    endcodes_stats: (selectedRefresh: Refresh, selectedTopicId: string) => {
      return self.endcodes_for_topic(selectedRefresh, selectedTopicId).reduce(
        (previous_totals, current_endcode) => {
          const endcode_performance = self.get_endcode_performance(current_endcode._id)

          if (isNull(endcode_performance)) {
            return {
              ...previous_totals,
              unanswered: previous_totals.unanswered + 1
            }
          }
          if (endcode_performance >= 70) {
            return {
              ...previous_totals,
              greenTopics: previous_totals.greenTopics + 1
            }
          }
          if (endcode_performance < 50) {
            return {
              ...previous_totals,
              redTopics: previous_totals.redTopics + 1
            }
          }

          return {
            ...previous_totals,
            yellowTopics: previous_totals.yellowTopics + 1
          }
        },
        {
          greenTopics: 0,
          yellowTopics: 0,
          redTopics: 0,
          unanswered: 0
        }
      )
    },
    topics_stats: (selectedRefresh: Refresh, selectedRefreshId: string) => {
      return self.get_topics_for_refresh(selectedRefresh).reduce(
        (previous_totals, current_topic) => {
          const {avg_performance} = self.get_refresh_topic_performance_stats(current_topic, selectedRefreshId)

          if (Number.isNaN(avg_performance)) {
            return {
              ...previous_totals,
              unanswered: previous_totals.unanswered + 1
            }
          }
          if (avg_performance >= 70) {
            return {
              ...previous_totals,
              greenTopics: previous_totals.greenTopics + 1
            }
          }
          if (avg_performance < 50) {
            return {
              ...previous_totals,
              redTopics: previous_totals.redTopics + 1
            }
          }

          return {
            ...previous_totals,
            yellowTopics: previous_totals.yellowTopics + 1
          }
        },
        {
          greenTopics: 0,
          yellowTopics: 0,
          redTopics: 0,
          unanswered: 0
        }
      )
    }
  }))
  .views((self) => ({
    get_year_refresh_stats: (selectedRefreshId: string, selectedTopicId?: string) => {
      const selected_refresh = self.refreshes.find((refresh) => refresh._id === selectedRefreshId)

      if(selectedTopicId && selectedTopicId !== 'Please choose a topic') {
        return self.endcodes_stats(selected_refresh, selectedTopicId)
      }
      return self.topics_stats(selected_refresh, selectedRefreshId)
    },
  }))
  .actions(self => ({
    get_refreshes: flow(function* () {
      self.is_loading = true
      const {refreshes} = yield getRefreshes()
      self.refreshes = refreshes
      self.is_loading = false
    })
  }))

export type RefreshesStore = Instance<typeof RefreshesStore>
