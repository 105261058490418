import {types} from 'mobx-state-tree'

import {SubjectId} from 'common/types/subject/id'
import {ApproxTime, SessionOptions} from 'common/types/session/options'
import {Day} from 'common/types/basic/day'
import {AvailabilityId, TutorId} from 'common/types/tutor/id'

export const ApproxAvailability = types.model('ApproxAvailability',{
  day: Day,
  approx_time: ApproxTime
})
export type ApproxAvailability = typeof ApproxAvailability

export const ApproxAvailabilities = types.array(ApproxAvailability)
export type ApproxAvailabilities = typeof ApproxAvailabilities

export const SearchAvailabilityRequest = types.model({
  body: types.model({
    student: types.string,
    subject: SubjectId,
    session_option: SessionOptions,
    availability: types.maybe(ApproxAvailabilities),
    tutor_id: types.maybe(types.string),
    referral_code: types.maybe(types.string)
  }),
})
export type SearchAvailabilityRequest = typeof SearchAvailabilityRequest

export const TutorAvailabilitySlot = types.model('TutorAvailabilitySlot',{
  _id: AvailabilityId,
  tutor_id: TutorId,
  day_of_week: types.number,
  time: types.number,
})

export const SearchAvailabilityResponse = types.array(TutorAvailabilitySlot)
export type SearchAvailabilityResponse = typeof SearchAvailabilityResponse

export const SearchAvailabilityWithReferralCodeRequest = types.model({
  body: types.model({
    referral_code: types.string,
    subject: SubjectId,
    session_option: SessionOptions,
    availability: ApproxAvailabilities
  })
})
export type SearchAvailabilityWithReferralCodeRequest = typeof SearchAvailabilityWithReferralCodeRequest
