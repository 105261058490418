import {FC} from 'react'

import {Element} from 'common/types/question/question'

interface Props {
  element: Element,
  element_id: string
}

export const QuestionText: FC<Props> = ({ element, element_id }) => {
  return (
    <p
      id={element_id}
      className="min-w-fit whitespace-pre-wrap bg-amber-200 p-2 text-center text-lg text-gray-700"
    >
      {
        // Filter out just £'s because it's part of the GBP input component
        element.text?.trim() !== '£' ?
          element.text :
          null
      }
    </p>
  )
}
