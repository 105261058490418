import {flow, Instance, types} from 'mobx-state-tree'

import {GetHomeworkResponse, Homework} from 'common/types/homework'
import {getHomework} from 'util/api/homework/get_homework'

export const StudentHomeworkStore = types.model({
  homework: types.maybeNull(Homework),
  isLoading: types.boolean
})
  .actions(self => ({
    get_homework: flow(function* (class_id: string) {
      self.isLoading = true
      const response: GetHomeworkResponse = yield getHomework({params: {class_id}})
      self.homework = response.homework

      self.isLoading = false
      return response.homework
    }),
  }))
export type StudentHomeworkStore = Instance<typeof StudentHomeworkStore>
