import {FC} from 'react'
import {observer} from 'mobx-react-lite'

import {useStore} from 'types/store'
import {ColumnContainer, Container, Input} from 'generic_components/AnswerInputContainers'
import {InputProps} from 'components/question/inputProps'
import {get_formatted_correct_answer} from 'components/question/input'

const calculateLength = (x: string): number => x.length <= 3 ? 3 : x.length + 1

const parseNumber = (value: string): string => value.replaceAll(/[^0-9]/g, '')

const parseFraction = (fraction: string): [string, string] => {
  const [numerator, denominator] = (fraction !== '') ? fraction.replaceAll(/[ ]/g, '').split('/') : ['','']
  return [numerator, denominator]
}

const getAnswer = (correct_answers, answer_decision, element, answerIndex) => {
  if (correct_answers.length > 0 && answer_decision !== 'CORRECT') {
    const foundAnswer = get_formatted_correct_answer(correct_answers, answerIndex, element.ref)
    if (foundAnswer) {
      return parseFraction(foundAnswer)
    }
  }

  return ['', '']
}

const drawLine = (numeratorSize: number, denominatorSize: number) =>
  '\u2501'.repeat(Math.max(numeratorSize, denominatorSize))

export const FractionInputComponent: FC<InputProps> = observer(({answerIndex, element, handleChange, answer_decision, correct_answers, value, element_id}) => {
  const {game, auth: { user }} = useStore()

  const [numerator, denominator] = parseFraction(value)
  const [numeratorSize, denominatorSize] = [calculateLength(numerator), calculateLength(denominator)]

  const [numeratorAnswer, denominatorAnswer] = getAnswer(correct_answers, answer_decision, element, answerIndex)

  const [numeratorAnswerSize, denominatorAnswerSize] =
    [numeratorAnswer, denominatorAnswer].map(calculateLength)

  const inputChangeNumerator = (e) => {
    e.target.value = `${parseNumber(e.target.value)}/${denominator}`
    handleChange(e)
  }

  const inputChangeDenominator = (e) => {
    e.target.value = `${numerator}/${parseNumber(e.target.value)}`
    handleChange(e)
  }

  const drawInputLine = drawLine(numeratorSize, denominatorSize)
  const drawAnswerLine = drawLine(numeratorAnswerSize, denominatorAnswerSize)

  return (
    <Container>
      <ColumnContainer>
        <Input
          autoFocus
          id={`${element_id}_numerator`}
          onChange={(e) =>inputChangeNumerator(e) }
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          value={numerator}
          size={numeratorSize}
          style={{marginBottom: 0}}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              if ( numerator !== '' && denominator !== '' ) {
                game.submit_answer(user._id)
              }
            }
          }}
        />
        <p style={{margin: '0px', fontSize: '0.75em'}}>{drawInputLine}</p>
        <Input
          id={`${element_id}_denominator`}
          onChange={(e) => inputChangeDenominator(e)}
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          value={denominator}
          size={denominatorSize}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              if ( numerator !== '' && denominator !== '' ) {
                game.submit_answer(user._id)
              }
            }
          }}
        />
      </ColumnContainer>
      {answer_decision !== 'CORRECT' && correct_answers.length > 0 && (
        <ColumnContainer>
          <Input
            answer_decision={'CORRECT'}
            readOnly={true}
            style={{marginBottom: 0}}
            value={numeratorAnswer}
            size={numeratorAnswerSize}
          />
          <p style={{margin: '0px', fontSize: '0.75em'}}>{drawAnswerLine}</p>
          <Input
            answer_decision={'CORRECT'}
            readOnly={true}
            value={denominatorAnswer}
            size={denominatorAnswerSize}
          />
        </ColumnContainer>
      )}
    </Container>
  )
})
