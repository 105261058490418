import React, {useCallback} from 'react'
import {NavLink, useLocation} from 'react-router-dom'

import {useUser} from 'context/userContext'

export interface IMenuButtonProps {
    label: string,
    href: string,
    icon: JSX.Element,
    sub_items?: IMenuButtonProps[],
    shouldPing?: boolean
}

const active_styles = 'text-gray-100 hover:bg-gray-500 bg-gray-700'
const inactive_styles = 'text-gray-900 hover:bg-gray-100 bg-gray-200'

export const MenuButton = ({ icon, label, href, shouldPing, sub_items = [] }: IMenuButtonProps) => {
  const { user } = useUser()
  const location = useLocation()

  const is_active = location.pathname === href
  const is_active_in_submenu = Boolean(sub_items.find(({href}) => location.pathname === href))
  const is_home_active = location.pathname === `/${user?.role.toLowerCase()}`

  return (
    <li>
      <NavItem icon={icon} label={label} href={href} shouldPing={shouldPing} />
      {
        sub_items.length > 0 && (is_active || is_active_in_submenu || is_home_active ) ?
          (
            <ul className="ml-4 mt-2 space-y-2">
              {
                sub_items.map((item, key) => (
                  <li key={key}><NavItem {...item}/></li>
                ))
              }
            </ul>
          ) :
          null
      }
    </li>
  )
}

const NavItem = ({ icon, label, href, shouldPing }: IMenuButtonProps) => {
  const classNameCallback = useCallback(({isActive}) => {
    return `flex items-center rounded-lg p-2 relative ${isActive ? active_styles : inactive_styles}`
  }, [])

  return (
    <NavLink
      to={href}
      end
      className={classNameCallback}
      target={
        href.includes('http') ?
          '_blank' :
          undefined
      }
    >
      {({ isActive }) => (
        <>
          {icon}
          <span className="ml-3">{label}</span>

          {
            shouldPing && !isActive ?
              (
                <span className="relative ml-auto">
                  <span
                    className="absolute inline-flex size-full animate-ping rounded-full bg-green-400 opacity-75"></span>
                  <span className="relative inline-flex size-3 rounded-full bg-green-500"></span>
                </span>
              ) :
              null
          }
        </>
      )}
    </NavLink>
  )
}
