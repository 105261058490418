import {flow, Instance, types} from 'mobx-state-tree'

import {Teacher} from 'common/types/teacher'
import {get_teacher_request} from 'util/api/teacher/get_teacher_request'
import {Competition} from 'common/types/competition'

export const TeacherStore = types.model({
  isLoading: types.boolean,
  teacher: types.maybe(Teacher),
}).actions(self => ({
  get_teacher: flow(function*(teacher_id: string){
    self.isLoading = true
    const { teacher } = yield get_teacher_request(teacher_id)
    self.teacher = teacher

    self.isLoading = false
  }),
}))
  .views(self => ({
    get all_competitions() {
      return (
          self
            .teacher
            ?.classes ?? []
      )
        .map(c => c.competitions)
        .flat() as Competition[]
    }
  }))
  .views(self => {
    return ({
      get upcoming_competitions() {
        return self.all_competitions
          .filter((c): boolean => c?.status === 'Upcoming')
      },
      get ongoing_competitions() {
        return self.all_competitions
          .filter((c): boolean => c?.status === 'Ongoing')
      },
      get finished_competitions() {
        return self.all_competitions
          .filter((c): boolean => c?.status === 'Finished')
      },
    })
  })

export type TeacherStore = Instance<typeof TeacherStore>
