import {FC, ReactElement, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import {PusherProvider} from '@harelpls/use-pusher'

import {SubscriptionLevelTooLow} from './SubscriptionLevelTooLow'

import {get_background_for_setting, PlytimePage} from 'components/plytime_page'
import {useStore} from 'types/store'
import {getPusherOptions} from 'services/pusher'
import {PageLoader} from 'generic_components/PageLoader'
import {MenuWrapper} from 'generic_components/MenuWrapper'

interface Props {
  children: ReactElement|ReactElement[],
}

export const PlytimePageGameBackground: FC<Props> = observer(({ children }) => {
  const {game, student_subscriptions} = useStore()
  const [pusherOptions, setPusherOptions] = useState(null)

  useEffect(() => {
    getPusherOptions().then((options) => setPusherOptions(options))
  }, [])

  if (!pusherOptions) {
    return <PageLoader />
  }

  return (
    <PlytimePage
      background={get_background_for_setting(game.setting)}
    >
      <PusherProvider {...pusherOptions as any}>
        {
          student_subscriptions.subscriptions.length === 0 ?
            (
              <MenuWrapper>
                <div className="mx-auto max-w-6xl">
                  <SubscriptionLevelTooLow
                    message='Get a Plytime Free or Premium subscription to unlock access to play games.'
                  />
                </div>
              </MenuWrapper>
            ) :
            children
        }
      </PusherProvider>
    </PlytimePage>
  )
})
