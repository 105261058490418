import {flow, getRoot, Instance, types} from 'mobx-state-tree'
import {isEqual, uniqWith} from 'lodash'

import {Store} from 'types/store'
import {HydratedSession} from 'common/types/session'

export const TutorPageStore = types
  .model({
    isLoading: types.boolean,
    hasInitialised: types.boolean
  })
  .actions((self) => ({
    initialise: flow(function* () {
      self.isLoading = true

      const {
        auth,
        tutor_data,
        subjectsStore,
        studentsStore,
        upcomingSessionsStore,
        pastSessionsStore,
        messagesStore,
      } = getRoot<Instance<typeof Store>>(self)

      yield tutor_data.get_profile()
      yield studentsStore.get_students_for_tutor(auth.user._id)
      yield subjectsStore.get_all_subjects()
      yield tutor_data.get_subscriptions()
      yield upcomingSessionsStore.refresh(auth.user)
      yield pastSessionsStore.refresh(auth.user)

      const uniqueSessions = uniqWith(
        upcomingSessionsStore.sessions.map((session: HydratedSession) => ({
          student_id: session.student?._id,
          subject_id: session.subject?._id
        })),
        isEqual
      )

      for (const {student_id, subject_id} of uniqueSessions) {
        yield messagesStore.get_unread_notes(subject_id, student_id)
        yield messagesStore.register_websocket_listener(subject_id, student_id)
      }

      // todo replace isLoading flipping in all the stores with a middleware.
      self.isLoading = false
    })
  }))

export type TutorPageStore = Instance<typeof TutorPageStore>
