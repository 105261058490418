import {Instance, types} from 'mobx-state-tree'

import {MyProfilePicturePostRequest, ProfilePictureResponse} from 'common/types/profile/picture'
import {PlytimeUser} from 'common/types/profile/user'
import {api} from 'util/api'

const MeGetRequest = types.model({
})
export type MeGetRequest = Instance<typeof MeGetRequest>

export const getMe = api(MeGetRequest, PlytimeUser, 'GET', 'profile/me')

export const updateMyProfilePicture = api(MyProfilePicturePostRequest, ProfilePictureResponse, 'POST', 'profile/me/picture')

