import {flow, getRoot, getSnapshot, Instance, types} from 'mobx-state-tree'

import {IRichStore} from 'types/store'
import {AllowedAnswers, SubmittedAnswerMap} from 'common/types/question/answer/answer'
import {postEndcodeQuestion} from 'util/api'
import {checkAnswer} from 'util/api/question/checkAnswer'

export const AdminQuestionAnswersStore = types.model({
  student_answer: SubmittedAnswerMap,
  show_result: types.boolean,
  answer_decision: types.maybe(types.string),
  correct_answers: AllowedAnswers,
}).actions(self => ({
  clear: () => {
    self.student_answer.clear()
    self.correct_answers.clear()
    self.answer_decision = undefined
  },
  update_answer(line_element: string, ref: string, value: string, selected: boolean) {
    self.correct_answers.clear()
    self.answer_decision = undefined

    if (value === '' || selected) {
      self.student_answer.delete(line_element)
    } else {
      self.student_answer.set(line_element, {ref, value})
    }
    self.show_result = false
  },
  check_admin_answer: flow(function* () {
    const {admin: {add_question}, subject_management_store} = getRoot<IRichStore>(self)

    if (subject_management_store.selected_question.has_changed) {
      const newQuestion = yield postEndcodeQuestion(
        subject_management_store.selected_endcode._id,
        getSnapshot(subject_management_store.selected_question)
      )
      subject_management_store.selected_question.set_has_changed(false)
      add_question(newQuestion)
      subject_management_store.set_selected_question(newQuestion._id)
    }

    const {result, answers} = yield checkAnswer({
      params: {
        question_id: subject_management_store.selected_question._id
      },
      body: {
        answer: getSnapshot(self.student_answer) as unknown as SubmittedAnswerMap,
        student_id: undefined,
        time: undefined,
        difficulty: undefined,
        game_summary_id: undefined,
        game_id: undefined,
      }
    })
    self.show_result = true
    self.answer_decision = result
    self.correct_answers = answers
  }),
  save_question: flow(function* () {
    const {admin: {add_question}, subject_management_store} = getRoot<IRichStore>(self)

    self.show_result = false
    if (subject_management_store.selected_question.has_changed) {
      const newQuestion = yield postEndcodeQuestion(
        subject_management_store.selected_endcode._id,
        getSnapshot(subject_management_store.selected_question)
      )
      subject_management_store.selected_question.set_has_changed(false)
      add_question(newQuestion)
      subject_management_store.set_selected_question(newQuestion._id)
      self.show_result = true
      self.answer_decision = 'Question Saved'
    }
  })
}))


export type AdminQuestionAnswersStore = Instance<typeof AdminQuestionAnswersStore>

