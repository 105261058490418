import React, { useCallback } from 'react'
import {observer} from 'mobx-react-lite'
import classNames from 'classnames'

import {ITier} from './helpers'

import {SubscriptionJourneyTypes} from 'components/subscription/SubscriptionWizardState'
import {useStore} from 'types/store'


interface ITierCardprops {
  tier: ITier
  onSelectTier: (args) => void
  selectedTier: string
  preferredWindowRef: React.RefObject<HTMLDivElement>
}

export const TierCard = observer(({
  tier: {name, value, BadgeComponent, PricingComponent, FeaturesComponent, TierCardActions},
  onSelectTier,
  selectedTier,
  preferredWindowRef
}: ITierCardprops): React.JSX.Element => {
  const {
    subscription_wizard: {
      update_session_count,
      subscription_journey_type,
      has_school_subscription,
      next,
      product_cost
    }
  } = useStore()


  const onClickOption = useCallback((name, value) => () => {
    update_session_count(value)
    onSelectTier(name)
    next()
  }, [name, value])

  const scrollToWindow = useCallback(() => {
    preferredWindowRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [preferredWindowRef])

  return (
    <div
      className={
        classNames(
          selectedTier === name ? 'border-4 border-green-500' : 'border border-gray-700/70',
          'rounded bg-gray-700/70 p-6 shadow-xl text-white flex-1 min-w-[250px]'
        )}
    >
      {
        subscription_journey_type === SubscriptionJourneyTypes.CreateSubscription && (
          <BadgeComponent
            hasSchoolSubscription={has_school_subscription}
          />
        )
      }
      <h1 className="text-3xl font-bold ">
        {name}
      </h1>
      <PricingComponent cost={product_cost(value)} />
      <hr />
      <FeaturesComponent />
      <hr />
      <div className='mt-5'>
        <TierCardActions
          onClick={onClickOption(name, value)}
          onSelect={onSelectTier}
          onScrollBtnClick={scrollToWindow}
          hasSchoolSubscription={has_school_subscription}
        />
      </div>   
    </div>
  )
})
