import {Instance, SnapshotOut, types} from 'mobx-state-tree'

import {NonEmptyString} from 'common/types/basic/non_empty_string'
import {Gender} from 'common/types/basic/gender'
import {DateString} from 'common/types/basic/date_string'

const StudentPassword = types.refinement(
  'StudentPassword',
  types.string,
  (snapshot: string) => snapshot.length >= 6,
  () => 'Passwords must be at least 6 characters in length.'
)

export const NewStudent = types.model('Student',{
  username: NonEmptyString,
  password: types.maybeNull(StudentPassword),
  first_name: NonEmptyString,
  last_name: NonEmptyString,
  dob: DateString,
  gender: types.maybeNull(Gender),
  school: types.string,
})
export type NewStudent = Instance<typeof NewStudent>

export const StudentPostRequest = types.model(
  'Student',
  {
    body: NewStudent
  }
)
export type StudentPostRequest = SnapshotOut<typeof StudentPostRequest>

export const Student = NewStudent
  .named('Student')
  .props({
    _id: types.identifier,
    uid: NonEmptyString,
    parent_id: types.maybeNull(types.string),
    password: types.undefined,
    subscriptions: types.maybe(
      types.array(
        types.model({
          school: types.maybeNull(types.string),
          cancelled: types.boolean,
        })
      )
    )
  })

export type Student = Instance<typeof Student>
