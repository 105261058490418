import {FC, useEffect, useState} from 'react'
import {observer} from 'mobx-react-lite'
import toast from 'react-hot-toast'
import classNames from 'classnames'

import {SubscriptionJourneyTypes} from './SubscriptionWizardState'

import {PrimaryClickableButton, SecondaryClickableButton} from 'components/ClickableButton'
import {ModalField} from 'components/subscription/TutoringDetails'
import {freeWeekCode} from 'components/subscription_tiers/helpers'
import {ApiError} from 'types/ApiError'
import {useStore} from 'types/store'
import {TierOptionEnum} from 'types/subscriptions'
import {reset_student_score} from 'util/api/admin/resetStudentScore'
import {Location} from 'util/helpers/location'
import {logError} from 'util/helpers/logError'
import {SessionOptionEnum} from 'common/types/session/options'
import {BlockLoader} from 'generic_components/BlockLoader'

interface Props {
  on_success: () => void
  close: () => void,
  isModal: boolean
}

export const Payment: FC<Props> = observer(({ on_success, close, isModal }) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    parent_data: {subscriptions},
    promo_codes,
    subscription_wizard: {
      back,
      product_cost,
      checkout,
      has_school_subscription,
      selected_session_option,
      subscription_tiers,
      subscription_journey_type,
      sessions_time_total,
      student,
      subject,
      school_year
    }
  } = useStore()

  const selected_tier = subscription_tiers.find((tier) => tier.value === selected_session_option)?.name

  useEffect(() => {
    const is_new_student_and_subscription = !subscriptions.some(s => s.student._id === student._id)

    if(
      is_new_student_and_subscription &&
      !has_school_subscription &&
      selected_session_option !== SessionOptionEnum.FreeTrial &&
      subscription_journey_type === SubscriptionJourneyTypes.CreateSubscription
    ) {
      setIsLoading(true)
      promo_codes.set_promotion_code(freeWeekCode)
      promo_codes.get_promotion_information()
        .finally(() => setIsLoading(false))
      return
    }

    promo_codes.set_promotion_code(undefined)
    setIsLoading(false)
  }, [student])

  const onCheckout = async (event) => {
    event.preventDefault()
    event.stopPropagation()

    try {
      const redirect_url = await checkout()

      await promo_codes.set_promotion_code(undefined)

      if(
        subscription_journey_type === SubscriptionJourneyTypes.CreateSubscription &&
        !has_school_subscription
      ) {
        await reset_student_score(
          student._id,
          subject._id,
          school_year - 1,
          undefined
        )
      }

      Location.assign(redirect_url)

    } catch (err) {
      logError(err)
      console.error(err)
      if ( err instanceof ApiError ) {
        toast.error(err.errors.join('\n'))
      } else {
        toast.error('Oops! something went wrong. Please check your bank statement and contact support if you have been charged')
      }
    }
  }

  if(isLoading) {
    return <BlockLoader message='Please wait...' />
  }

  return (
    <div>
      <div className={classNames(isModal ? 'overflow-auto max-h-[50vh] md:max-h-[70vh]' : 'py-6')}>
        <ModalField
          title="Please double check your subscription details"
          instructions={(
            <>
              <p className='mb-2'>
                You are subscribing <b><span className='text-blue-300'>{student.first_name}</span></b> to
                {' '}
                <b><span className='text-green-300'>
                  {
                    selected_session_option !== SessionOptionEnum.FreeTrial &&
                    selected_session_option !== SessionOptionEnum.PlatformOnly ?
                      (
                        'Plytime Premium with Focus Tutoring®'
                      ) :
                      selected_tier
                  }
                </span></b> for <b><span className='text-yellow-300'> {subject.name}</span></b>
              </p>

              {
                selected_session_option !== SessionOptionEnum.FreeTrial &&
                selected_session_option !== SessionOptionEnum.PlatformOnly &&
                <p>You have added <b><span className='text-green-300'>{sessions_time_total()}</span></b> of Focus 15® sessions per week</p>
              }

              {
                selected_session_option === SessionOptionEnum.FreeTrial ?
                  (
                    <p className='mb-2'>Includes 1 Week Free Trial of <b>
                      <span className='text-green-300'>
                        {TierOptionEnum.PlytimePremium}
                      </span></b>
                    </p>
                  ) :
                  (
                    <>
                      <p>Normally costing <b>
                        <span className='text-green-300'>
                        £{product_cost(selected_session_option as SessionOptionEnum)}
                        </span></b> per week
                      </p>
                      {
                        promo_codes.promotion_information && (
                          <p>Total Due Today (Free first week of Plytime Premium): <b>
                            <span className='text-green-300'>
                            £{(Number(product_cost(selected_session_option as SessionOptionEnum)) - (promo_codes.promotion_information.amount_off / 100)).toFixed(2)}
                            </span></b>
                          </p>
                        )}
                      <p className='mt-2'>Payments are taken in advance of your sessions. You can cancel at any time.</p>
                    </>
                  )
              }
              <p>By subscribing, you agree to the</p>
              <ul className="list-inside list-disc text-blue-200">
                <li><a href='https://plytime.com/terms-and-conditions/' target='_blank' rel='noreferrer'>Terms and Conditions</a></li>
                <li><a href='https://plytime.com/privacy-policy/' target='_blank' rel='noreferrer'>Privacy policy</a></li>
                <li><a href='https://plytime.com/safeguarding-policy/' target='_blank' rel='noreferrer'>Safeguarding policy</a></li>
              </ul>
            </>
          )}
        />

        {
          selected_session_option !== SessionOptionEnum.FreeTrial ?
            (
              <p className={classNames(isModal ? 'text-gray-900' : 'text-gray-100', 'mt-6 text-xl text-center')}>
                Your payment will be processed securely, by <a href='https://stripe.com' target='_blank' rel='noreferrer'>Stripe</a>
              </p>
            ) :
            null
        }
      </div>

      <div className="flex h-1/3 justify-around py-6">
        <SecondaryClickableButton
          onClick={back}
        >
          Back
        </SecondaryClickableButton>

        <PrimaryClickableButton
          onClick={onCheckout}
        >
          Confirm {selected_session_option !== SessionOptionEnum.FreeTrial ? 'and Pay' : ''}
        </PrimaryClickableButton>
      </div>
    </div>
  )
})
