import {observable} from 'mobx'
import {getRoot, Instance, types} from 'mobx-state-tree'

import {IRichStore} from 'types/store'
import {Student} from 'common/types/student'
import {regroup_scores} from 'util/helpers/regroup_scores'
import {Score} from 'common/types/class'
import {Refresh} from 'common/types/refreshes/get_refreshes'

interface IFocus {
  subject_id: string
}

export const TeacherStrengthsAndFocusesStore = types.model({})
  .views(self => ({
    get_focus_for_student(
      student: Student,
      type: 'endcode' | 'topic'
    ): IFocus[] {
      const {classPerformanceStore, subjectsStore}: IRichStore = getRoot(self)
      const { scores_by_student } = classPerformanceStore.classPerformance
      const student_scores = scores_by_student.find(score_by_student => score_by_student.student._id === student._id)

      if (student_scores) {
        const subject_score = student_scores.scores.find(s => s.subject?.type === 'subject')
        if (type === 'topic') {
          return subjectsStore
            .topics()
            .filter(topic => classPerformanceStore.is_subject_live(student, topic))
            .map((topic) => {
              const real_topic_score = student_scores.scores.find(s => s.subject._id === topic._id)
              const nominal_score = (topic.min_score + topic.max_score) / 2

              return {
                subject_id: topic._id,
                score: real_topic_score?.score ?? nominal_score,
                min_score: topic.min_score
              }
            })
            .sort((a, b) => {
              if (a.score === b.score) {
                return a.min_score > b.min_score ? 1 : -1
              }

              return a.score > b.score ? 1 : -1
            })
            .slice(0, 3)

        }
        if (type === 'endcode') {
          return student_scores
            .scores
            .filter(s => s.subject?.type === type)
            .filter(s => s.score)
            .filter(s => classPerformanceStore.is_subject_live(student, s.subject))
            .filter(s => s.score < subject_score.score)
            .filter(endcode_score => {
              const found_topic_score = student_scores
                .scores
                .filter(s => s.subject?.type === 'topic')
                .find(topic_score => {
                  return topic_score.subject._id === endcode_score.subject.parent._id
                })

              if (found_topic_score) {
                return endcode_score.score < found_topic_score.score
              }

              return false
            })
            .sort((a, b) => a.score > b.score ? 1 : -1)
            .map(s => ({
              subject_id: s.subject._id,
              score: s.score
            }))
        }
      }

      return []
    },
    get_strength_for_student(
      student: Student,
      type: 'endcode' | 'topic'
    ): IFocus[] {
      const {classPerformanceStore, teacherPageStore}: IRichStore = getRoot(self)
      const student_scores = classPerformanceStore
        .classPerformance
        .scores_by_student
        .find(score_by_student => score_by_student.student._id === student._id)

      if (student_scores) {
        const subject_score = student_scores.scores.find(s => teacherPageStore.currentClass.subject._id)
        const scores_for_type = student_scores.scores.filter((s) => s.subject.type === type)

        return scores_for_type
          .filter(s => classPerformanceStore.is_subject_live(student, s.subject))
          .filter(({ score }: Score) => {
            return score > subject_score.score
          })
          .map(s => ({
            subject_id: s.subject._id,
            score: s.score
          }))
      }

      return []
    }
  }))
  .views(self => ({
    focuses(type: 'endcode' | 'topic', students: Student[] = [], selectedRefresh?: Refresh) {
      if (selectedRefresh) {
        return observable([])
      }

      const student_focuses = students
        .map((student: Student) => ({
          student_id: student._id,
          subject_ids: self.get_focus_for_student(student, type).map(s => s.subject_id)
        }))

      return observable(regroup_scores(student_focuses))
    },
    strengths(type: 'endcode' | 'topic', students: Student[] = [], selectedRefresh?: Refresh) {
      if (selectedRefresh) {
        return observable([])
      }

      const student_strengths = students
        .map((student: Student) => ({
          student_id: student._id,
          subject_ids: self.get_strength_for_student(student, type).map(s => s.subject_id)
        }))

      return observable(regroup_scores(student_strengths))
    },
  }))

export type TeacherStrengthsAndFocusesStore = Instance<typeof TeacherStrengthsAndFocusesStore>
