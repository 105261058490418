import {Instance, types} from 'mobx-state-tree'

import {DateTimeString} from 'common/types/basic/date_time_string'
import {StudentId} from 'common/types/student/id'

export const GetStudentTrendStatsRequest = types.model(
  'GetStudentTrendStatsRequest',
  {
    params: types.model({
      student_id: StudentId,
    })
  }
)
export type GetStudentTrendStatsRequest = Instance<typeof GetStudentTrendStatsRequest>

export const StudentSubjectTrend = types.model(
  'StudentSubjectTrend',
  {
    timestamp: DateTimeString,
    score: types.number,
    subject: types.string,
  }
)
export type StudentSubjectTrend = Instance<typeof StudentSubjectTrend>

export const GetStudentTrendStatsResponse = types.array(StudentSubjectTrend)
export type GetStudentTrendStatsResponse = Instance<typeof GetStudentTrendStatsResponse>
