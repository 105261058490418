import React from 'react'
import {Navigate, Outlet, RouterProvider} from 'react-router'
import {observer} from 'mobx-react-lite'

import {useUser} from 'context/userContext'
import {Roles} from 'util/auth/helper'
import {getRootStore, StoreProvider} from 'types/store'
import 'css/fireworks.css'
import 'css/tailwind.css'
import 'core-js/actual'

interface IProtectedRouteComponentProps {
  role: Roles
}
export const ProtectedRouteComponent = observer(({ role }: IProtectedRouteComponentProps) => {
  const {user} = useUser()
  if (user && role === user.role) {
    return <Outlet />
  }

  return <Navigate to="/"/>
})

export const PublicRouteComponent = observer(() => {
  const {user} = useUser()
  const page_role = window.location.pathname.split('/').slice(1)[0].toUpperCase()

  if (
    user && (
      user.role !== page_role || window.location.href.includes('signin')
    )
  ) {
    return <Navigate to={`/${user.role.toLowerCase()}`}/>
  }

  return <Outlet />
})

export const AppRoot = ({ router }) => {
  return (
    <StoreProvider value={getRootStore()}>
      <RouterProvider
        router={router}
      />
    </StoreProvider>
  )
}
