import {flow, getRoot, Instance, types} from 'mobx-state-tree'

import {getAllSubjects} from 'util/api/subject'
import {AdminSubject} from 'common/types/subject/subject'
import {isEndcodeLive} from 'util/helpers/isEndcodeLive'
import {Stats} from 'common/types/student/current_stats'
import {IRichStore} from 'types/store'

export const SubjectsStore = types.model({
  subjects: types.array(AdminSubject),
  isLoading: types.boolean
})
  .actions(self => ({
    get_all_subjects: flow(function* () {
      self.isLoading = true
      self.subjects = yield getAllSubjects()
      self.isLoading = false
    }),
    add_local_subject: flow(function*(subject: AdminSubject){
      self.subjects.push(subject)
    })
  }))
  .views((self) => ({
    top_level_subjects(): AdminSubject[] {
      return self
        .subjects
        .filter(s => s.type === 'subject')
    },
    themes(): AdminSubject[] {
      const rootStore:IRichStore = getRoot(self)
      const parent_subject = rootStore.student_subscriptions.subscription_subject

      return self
        .subjects
        .filter(s => s.type === 'theme')
        .filter(s => {
          if (parent_subject) {
            return s.parent._id === parent_subject._id
          }

          return true
        })
    },
    topics(): AdminSubject[] {
      const rootStore:IRichStore = getRoot(self)
      const parent_subject = rootStore.student_subscriptions.subscription_subject

      return self
        .subjects
        .filter(s => s.type === 'topic')
        .filter(s => {
          if (parent_subject) {
            return s.parent.parent._id === parent_subject._id
          }

          return true
        })
    },
    endcodes(): AdminSubject[] {
      const rootStore:IRichStore = getRoot(self)
      const parent_subject = rootStore.student_subscriptions.subscription_subject

      return self
        .subjects
        .filter(s => s.type === 'endcode')
        .filter(s => {
          if (parent_subject) {
            return s.parent.parent.parent._id === parent_subject._id
          }

          return true
        })
    },
  }))
  .views(self => ({
    live_endcodes_for_student(is_ahead: boolean, endcode_stats: Stats[], subject_score: number) {
      return self.endcodes().filter((endcode) => {
        const endcode_score = endcode_stats.find(e => e.subject._id === endcode._id)

        return isEndcodeLive(
          is_ahead,
          endcode_score?.score,
          subject_score,
          endcode,
        )
      })
    }
  }))
  .views(self => ({
    parent_subjects_for_type(type: string, top_level_subject_id: string) {
      if (type === 'endcode') {
        return self.topics().filter(topic => topic.parent.parent._id === top_level_subject_id)
      }
      if (type === 'topic') {
        return self.themes().filter(theme => theme.parent._id === top_level_subject_id)
      }
      if (type === 'theme') {
        return self.top_level_subjects()
      }

      return []
    }
  }))

export type SubjectsStore = Instance<typeof SubjectsStore>
