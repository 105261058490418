import {FC} from 'react'

import {PrimaryClickableButton} from 'components/ClickableButton'

interface IProps {
  message?: string
}

export const SubscriptionLevelTooLow: FC<IProps> = ({message}) => {
  return (
    <section className="mx-auto my-6 border-t-8 border-red-600 bg-white p-6 text-center lg:p-12 lg:text-left lg:shadow first-line:lg:rounded">
      <h1 className="mb-6 flex items-center justify-center text-center text-xl font-bold text-red-700 md:text-2xl lg:mt-2 lg:text-3xl">
        <i className="fa-solid fa-triangle-exclamation mr-1"></i>Uh Oh! You Need A Plytime Learning Subscription
      </h1>
      <ul className="mx-auto mb-6 text-center text-gray-700 md:text-lg">
        <li className="pb-1 pl-2">
          {
            message ? 
              message : 
              'To unlock additional access, upgrade your subscription to Plytime Premium or Plytime Premium With Focus Tutoring®'
          }
        </li>
        <li className="pb-1 pl-2">
          Your parent or teacher can set this up.
        </li>
      </ul>
      <div className="flex justify-center">
        <PrimaryClickableButton
          href="/student"
          className='mx-auto h-12 w-full text-lg'
        >
          Go Back
        </PrimaryClickableButton>
      </div>
    </section>
  )
}
