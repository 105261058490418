import {ReactNode, useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {Toaster} from 'react-hot-toast'

import {IPage, NavBar} from './NavBar'
import {ContentContainer} from './ContentContainer'

import {useUser} from 'context/userContext'


const PAGES: IPage[] = [
  {
    href: '/signin/parent',
    name: 'Parent Login'
  },
  {
    href: '/signin/student',
    name: 'Student Login'
  },
  {
    href: '/signin/tutor',
    name: 'Tutor Login'
  },
  {
    href: '/signin/teacher',
    name: 'Teacher Login'
  },
  {
    href: '/signin/school',
    name: 'School Login'
  },
  {
    href: 'https://plytime.com/faqs/',
    name: 'FAQ/Help',
    target: '_blank'
  },
  {
    href: 'https://plytime.com/contact-us/',
    name: 'Contact Us',
    target: '_blank'
  },
]

interface IPublicPlytimePageProps {
  children?: ReactNode,
}

export const PublicPlytimePage = observer(({ children }: IPublicPlytimePageProps) => {
  const { user } = useUser()

  useEffect(() => {
    console.log('reload public page plytime for user:' + JSON.stringify(user))
  }, [])


  return (
    <div className="size-full">
      <NavBar pages={PAGES} />

      <ContentContainer>
        {children}
      </ContentContainer>
      <Toaster />
    </div>
  )
})
