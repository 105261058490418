import { Instance, types } from 'mobx-state-tree'

import { SessionOptions } from 'common/types/session/options'

export const GetSubscriptionProductsRequest = types.model({})
export type GetSubscriptionProductsRequest = Instance<typeof GetSubscriptionProductsRequest>

export const SubscriptionProduct = types.model({
  label: SessionOptions,
  sessions: types.number,
  weekly_cost_in_pence: types.number
})
export const GetSubscriptionProductsResponse = types.model({
  results: types.array(SubscriptionProduct)
})
export type GetSubscriptionProductsResponse = Instance<typeof GetSubscriptionProductsResponse>
