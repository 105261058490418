import React from 'react'

import {PrimaryClickableButton} from 'components/ClickableButton'

export const NotFoundPage = () => {
  return (
    <div className='mx-auto mb-20 mt-16 flex h-full flex-col items-center justify-center md:mt-32 lg:p-2'>
      <h1 className="mb-10 text-center text-2xl font-semibold capitalize">Page Not Found</h1>
      <div>
        <PrimaryClickableButton
          href='/'
        >
            Go to Home
        </PrimaryClickableButton>
      </div>
    </div>
  )
}
export default NotFoundPage

export const ErrorPage = () => {
  const role = localStorage.getItem('plytime_role').toLowerCase() || ''

  return (
    <div className='mx-auto mb-20 mt-16 flex h-full flex-col items-center justify-center md:mt-32 lg:p-2'>
      <h1 className="mb-10 text-center text-2xl font-semibold capitalize">An Error has Occurred. Please refresh or try again later.</h1>
      <div>
        <PrimaryClickableButton
          href={`/${role}`}
        >
            Go to Home
        </PrimaryClickableButton>
      </div>
    </div>
  )
}
