import {Instance, types} from 'mobx-state-tree'

import {StudentId} from 'common/types/student/id'
import {SubjectId} from 'common/types/subject/id'
import {SessionOptions} from 'common/types/session/options'
import {SelectedSlots } from 'common/types/subscription'

export const EditSubscriptionRequest = types.model({
  body: types.model({
    parent_id: types.maybeNull(types.string),
    school_id: types.maybeNull(types.string),
    class_id: types.maybe(types.string),
    student_id: StudentId,
    subject: SubjectId,
    session_option: SessionOptions,
    slots: SelectedSlots,
    success_url: types.string,
    error_url: types.string,
    promo_code: types.maybeNull(types.string),
    referral_code: types.maybeNull(types.string),
    tutor_id: types.maybe(types.string),
    is_confirmed_edit: types.maybe(types.boolean)
  })
})
export type EditSubscriptionRequest = Instance<typeof EditSubscriptionRequest>

export const EditSubscriptionResponse = types.model({
  redirect_url: types.string
})
export type EditSubscriptionResponse = Instance<typeof EditSubscriptionResponse>
