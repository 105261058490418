import React from 'react'

import {IMenuButtonProps, MenuButton} from './MenuButton'

interface IProps {
  items: IMenuButtonProps[]
}

export const Menu = ({ items }: IProps): JSX.Element => {
  return (
    <aside aria-label="Sidebar">
      <div className="h-full overflow-y-auto py-4">
        <ul className="space-y-2 font-medium">
          {items.map((props, i) => <MenuButton {...props} key={i}/>)}
        </ul>
      </div>
    </aside>
  )
}

