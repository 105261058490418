import {DateTime} from 'luxon'

const SEPTEMBER = 9
export const get_school_year = (current_date: DateTime): number => {
  const is_before_september = current_date.month < SEPTEMBER
  if (is_before_september) {
    return current_date.year - 1
  }
  return current_date.year
}
