import {Instance, types} from 'mobx-state-tree'

import {Student} from 'common/types/student'

export const GetTutorStudentsRequest = types.model({
  params: types.model({
    tutor_id: types.string
  })
})
export type GetTutorStudentsRequest = Instance<typeof GetTutorStudentsRequest>

export const GetTutorStudentsResponse = types.model({
  success: types.boolean,
  students: types.array(Student)
})
export type GetTutorStudentsResponse = Instance<typeof GetTutorStudentsResponse>
