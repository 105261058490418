import React, {FC, useCallback, useEffect, useState} from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react-lite'

import {useStore} from 'types/store'
import {$window} from 'services/window'
import {TranslationStore} from 'types/translation'
import {get_language_shortcode} from 'util/helpers/voice'

interface Props {
  text: string;
}

const defaultSpeed = 0.8
const DEFAULT_LANGUAGE = 'en-GB'

const get_selected_voice = (synth: SpeechSynthesis, voice: string): SpeechSynthesisVoice => {
  const selectedVoice = synth.getVoices().find(v => v.name === voice)

  return selectedVoice ?? synth.getVoices()[0]
}

const getTextToRead = async (text: string, translation: TranslationStore): Promise<string> => {
  if (translation.language === DEFAULT_LANGUAGE) {
    return text
  }

  const translationDetails = {
    content: text,
    input_language: get_language_shortcode(DEFAULT_LANGUAGE),
    output_language: get_language_shortcode(translation.language),
  }

  return await translation.get_translation(translationDetails)
}

const TextToSpeechButton: FC<Props> = observer(({ text }) => {
  const { translation } = useStore()
  const synth = $window.speechSynthesis
  const [isReading, setIsReading] = useState(false)
  const [translatedText, setTranslatedText] = useState('')

  useEffect(() => {
    return () => {
      synth.cancel()
      setTranslatedText('')
    }
  }, [text])

  const readSection = useCallback(() => {
    if (isReading) {
      synth.cancel()
      setIsReading(false)
      return
    }

    const selectedVoice = get_selected_voice(synth, translation.voice)

    getTextToRead(text, translation).then((translated_text) => {
      const utterance = new SpeechSynthesisUtterance(translated_text)
      utterance.voice = selectedVoice
      utterance.lang = selectedVoice.lang
      utterance.rate = defaultSpeed

      synth.cancel()
      synth.speak(utterance)
      utterance.onend = () => {
        setIsReading(false)
      }
      setIsReading(true)
      setTranslatedText(translated_text)
    })
  }, [text, isReading, translation.voice])

  if (translation.voice) {
    return (
      <>
        <button
          className="h-fit cursor-pointer rounded-full bg-white py-1"
          onClick={readSection}
          type="button"
        >
          <i
            className={
              classNames(
                'px-2 fa-solid fa-volume-high text-sm',
                isReading && 'text-red-500'
              )
            }
          />
        </button>
        {
          translation.language !== DEFAULT_LANGUAGE &&
          translatedText.length > 0 ?
            (
              <div className="w-full text-center">
                <img
                  className="mr-1 inline w-5"
                  src={`/country_flags/${get_language_shortcode(translation.language)}.png`}
                  alt="flag"
                />
                <span className="text-gray-500">
                  {translatedText}
                </span>
              </div>

            ) :
            null
        }
      </>
    )
  }
})

export default TextToSpeechButton
