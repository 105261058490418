import React, {HTMLAttributeAnchorTarget, useCallback, useEffect, useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {observer} from 'mobx-react-lite'
import classNames from 'classnames'
import {useInterval} from 'usehooks-ts'
import {DateTime} from 'luxon'

import {useUser} from 'context/userContext'
import {useStore} from 'types/store'
import {ChatButton} from 'components/ChatButton'
import {Student} from 'common/types/student'
import {InlineLoader} from 'generic_components/InlineLoader'
import {Roles} from 'util/auth/helper'
import {publiclyAvailableRoutes} from 'util/helpers/getRoutePath'

export interface IPage {
    href?: string,
    target?: HTMLAttributeAnchorTarget,
    name: string|React.JSX.Element,
    onClick?: () => void
}
interface IProps {
    pages: IPage[]
}

const getLinkStyle = (isActive: boolean): string => {
  if (isActive) {
    return 'text-white bg-teal-500 lg:text-teal-700 underline'
  }

  return 'text-gray-600 lg:hover:text-gray-900'
}

export const NavBar = observer(({pages}: IProps): React.JSX.Element => {
  const [isHamburgerMenuVisible, setIsHamburgerMenuVisible] = useState(false)
  const toggleMenuCallback = useCallback(() => setIsHamburgerMenuVisible(!isHamburgerMenuVisible), [isHamburgerMenuVisible])

  return (
    <nav
      className="bg-gray-200 px-2 py-2.5 md:px-4">
      <div className="container flex flex-wrap items-center justify-between">
        <a href="/" className="flex items-center no-underline">
          <img src="/PlytimeNewLogo.svg" className="inline h-[64px] w-[320px] p-4" alt="Plytime Logo"/>
        </a>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="ml-3 inline-flex items-center rounded-lg p-2 text-sm text-gray-400 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600 lg:hidden"
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={toggleMenuCallback}
        >
          <span className="sr-only">Open main menu</span>
          <svg className="size-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"></path>
          </svg>
        </button>
        <div className={
          classNames([
            !isHamburgerMenuVisible ? 'hidden' : '',
            'w-full lg:block lg:w-auto'
          ])
        }>
          <ul className="mt-4 list-none items-baseline rounded-lg p-4 lg:mt-0 lg:flex lg:space-x-8 lg:text-sm lg:font-medium">
            <NavbarAvatar />
            {pages.map((page) => {
              const LinkComponent = page.href.includes('http') ?
                BasicLink :
                NavLink

              return (
                <li key={page.name.toString()}>
                  <LinkComponent
                    to={page.href}
                    target={page.target ?? '_self'}
                    end="true"
                    className={
                      ({isActive}) =>
                        classNames(
                          'block py-2 pl-3 pr-4 rounded lg:bg-transparent lg:border-0 lg:p-0 cursor-pointer',
                          getLinkStyle(isActive),
                        )
                    }
                    onClick={page.onClick}
                  >
                    {page.name}
                  </LinkComponent>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </nav>
  )
})

const BasicLink = (props): React.JSX.Element => (
  <a
    {...props}
    href={props.to as string}
    className="block cursor-pointer rounded py-2 pl-3 pr-4 lg:border-0 lg:bg-transparent lg:p-0"
  >
    {props.children}
  </a>
)


const StudentNavBarSection = observer(() => {
  const { user } = useUser()
  const { student_tokens, student_subscriptions, subjectsStore } = useStore()
  const [isLoading, setIsLoading]  = useState(true)

  useEffect(() => {
    subjectsStore.get_all_subjects()
      .then(() => student_subscriptions.get_subscriptions())
      .then(() => setIsLoading(false))
  }, [])

  useInterval(() => {
    const five_seconds_after_updating = DateTime.fromISO(student_tokens.last_updated).plus({seconds: 5}).toISO()
    const now = DateTime.now().toISO()

    setRecentlyIncreasedTokens(now < five_seconds_after_updating && student_tokens.has_increased)
  }, 1000)

  useEffect(() => {
    student_tokens.fetch_tokens(user._id)
  }, [])

  const [hasRecentlyIncreasedTokens, setRecentlyIncreasedTokens] = useState<boolean>(false)

  return (
    <div className="flex">
      <span
        className={
          classNames(
            hasRecentlyIncreasedTokens && 'fa-bounce',
            'm-2 p-2 bg-gray-400 rounded shadow w-[80px] h-[36px] inline-flex items-center justify-center'
          )
        }
      >
        {
          student_tokens.isLoading ?
            <i className="fa-solid fa-spinner fa-spin-pulse"></i> :
            <>
              <i className="fa-solid fa-coins pr-2 text-yellow-300"></i>
              <span className="text-yellow-300">
                {student_tokens.total_token_count}
              </span>
            </>
        }
      </span>
      {
        isLoading ?
          (
            <div className="mt-[15px]">
              <InlineLoader />
            </div>
          ):
          (
            <ChatButton
              subject={student_subscriptions.subscription_subject}
              student={user as unknown as Student} // todo - probably want the logged in Student available, rather than using the User
            />
          )
      }
      <LoginStreak />
    </div>
  )
})
StudentNavBarSection.displayName = 'StudentNavBarSection'

const LoginStreak = observer(() => {
  const {user} = useUser()
  const {attendance_streak_store} = useStore()
  useEffect(() => {
    attendance_streak_store.add_streak(user._id)
  }, [])

  if (!attendance_streak_store.has_loaded){
    return (
      <div className="mt-[15px]">
        <InlineLoader />
      </div>
    )
  }

  return (
    <div className="p-2" title="You will get bonus tokens if you complete a streak">
      <span>Login Streak:</span>

      <div>
        {
          Array(attendance_streak_store.streak)
            .fill(null)
            .map((_, i) => <i key={i} className="fa-solid fa-star text-yellow-500"></i>)

        }
        {
          Array(5 - attendance_streak_store.streak)
            .fill(null)
            .map((_, i) => <i key={i} className="fa-regular fa-star text-yellow-500"></i>)
        }
      </div>
    </div>
  )
})
LoginStreak.displayName = 'LoginStreak'

const NavbarAvatar = observer(() => {
  const { user } = useUser()
  const page_url = useLocation().pathname

  if(user || publiclyAvailableRoutes.includes(page_url)) {
    return (
      <li className="flex items-center p-2">
        <div className="flex flex-col">
          {
            user?.displayName ?
              (
                <div>
                  Logged in as
                  <i className="fa-solid fa-user mx-2 text-lg"></i>
                  <span className="font-bold">
                    {user?.displayName}
                  </span>
                </div>
              ) :
              'Guest'
          }

          {
            user?.role === Roles.STUDENT && <StudentNavBarSection/>
          }
        </div>
      </li>
    )
  }
})
