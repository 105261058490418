import React from 'react'

export const InlineLoader = (): React.JSX.Element => {
  return (
    <div
      className="inline"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative inline rounded-lg bg-gray-100 p-2 text-center text-gray-800 shadow">
        <i className="fa-solid fa-spinner fa-spin"></i>
      </div>
    </div>
  )
}
