import 'mathlive'
import React, {KeyboardEventHandler, useCallback, useEffect, useRef} from 'react'
import {observer} from 'mobx-react-lite'
// eslint-disable-next-line import-x/no-unresolved
import {MathfieldElement} from 'mathlive/dist/types/mathlive'

import {get_formatted_correct_answer} from 'components/question/input'
import {Roles} from 'util/auth/helper'
import {TextWithMaths} from 'components/text_with_maths'
import {$window} from 'services/window'
import {useUser} from 'context/userContext'
import {useStore} from 'types/store'
import {InputProps} from 'components/question/inputProps'
import {StyledLink} from 'components/StyledLink'

declare global {
    // eslint-disable-next-line @typescript-eslint/no-namespace
    namespace JSX {
        interface IntrinsicElements {
            'math-field': React.DetailedHTMLProps<React.HTMLAttributes<MathfieldElement>, MathfieldElement>;
        }
    }
}

export const AdvancedMathsInputComponent = observer(({
  answerIndex,
  element,
  value,
  handleChange,
  answer_decision,
  correct_answers
}: InputProps) => {
  const {user} = useUser()
  const {game} = useStore()
  const mathFieldRef = useRef<MathfieldElement>(null)

  const onFocus = useCallback(() => {
    $window.mathVirtualKeyboard.show()
    mathFieldRef.current.scrollIntoView()
  }, [mathFieldRef])

  const onKeyDownCapture = useCallback(((event) => {
    if (event.key === 'Enter') {
      return game.submit_answer(user._id)
    }
  }) as KeyboardEventHandler, [user])

  useEffect(() => {
    $window.mathVirtualKeyboard.show()
    mathFieldRef.current.focus()
    mathFieldRef.current.scrollIntoView()

    return () => {
      $window.mathVirtualKeyboard.hide()
    }
  }, [])

  return (
    <div className="pt-6">
      <div>
        {
          element.placeholder ?
            (
              <div className="text-center">
                <TextWithMaths text={element.placeholder}/>
              </div>
            ) :
            null
        }

        {
          user.role === Roles.ADMIN ?
            (
              <div>
                            Real Value: {value}
              </div>
            ) :
            null
        }

        <math-field
          data-testid="math-field"
          onFocus={onFocus}
          ref={mathFieldRef}
          onKeyDownCapture={onKeyDownCapture}
          onInput={handleChange as any}
          style={{
            fontSize: '2rem',
            minWidth: '400px',
            border: '4px solid',
            borderColor: getBorderColour(answer_decision),
            borderRadius: '0.25rem',
            display: 'block',
          }}
          {
            ...(answer_decision ?
              {
                // https://github.com/arnog/mathlive/blob/cd9fc2df05b36a85b5ab21bb6d00320ea8a10e5d/src/public/mathfield-element.ts#L2075
                'read-only': '',
              } :
              {}
            )
          }
        >
          {value}
        </math-field>

        <StyledLink
          to="https://plytime.com/wp-content/uploads/2024/08/Maths-Keyboard-tips.pdf"
          target="_blank"
        >
          <div className="mt-2 text-center text-sm">
            <i className="fa-solid fa-circle-info mr-2"></i>
                    How to use the Maths Keyboard
          </div>
        </StyledLink>
      </div>

      {answer_decision !== 'CORRECT' && correct_answers.length > 0 &&
        <math-field
          style={{
            marginTop: '10px',
            fontSize: '2rem',
            minWidth: '400px',
            border: '4px solid',
            borderColor: '#22c55e',
            borderRadius: '0.25rem'
          }}
          read-only=""
        >
          {get_formatted_correct_answer(correct_answers, answerIndex, element.ref)}
        </math-field>
      }
    </div>
  )
})

const getBorderColour = (answer_decision: string) => {
  if (answer_decision === 'INCORRECT') {
    return '#ef4444'
  }

  if (answer_decision === 'CORRECT') {
    return '#22c55e'
  }
}
