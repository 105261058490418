import {FC, useCallback} from 'react'
import {observer} from 'mobx-react-lite'

import {useStore} from 'types/store'
import {ColumnContainer, Container, Input} from 'generic_components/AnswerInputContainers'
import {InputProps} from 'components/question/inputProps'
import {get_formatted_correct_answer} from 'components/question/input'

export const TimeInputComponent: FC<InputProps> = observer(({ answerIndex, element, handleChange, answer_decision, correct_answers, value, element_id }) => {
  const { game, auth: { user } } = useStore()

  const size = 5

  const handleInputChange = useCallback((e) => {
    if(e.target.value.length <= 5){
      const input = e.target.value.replaceAll(/[^0-9:]/g, '')
      const numbers = input.replaceAll(':','')
      const reformatted = numbers.length <= 2 ?
        input :
        `${numbers.slice(0,-2)}:${numbers.slice(-2)}`
      e.target.value = reformatted
      handleChange(e)
    }
  }, [handleChange])

  return (
    <Container>
      <ColumnContainer>
        <Input
          autoFocus
          id={element_id}
          onInput={(e) => handleInputChange(e)}
          answer_decision={answer_decision}
          placeholder={'__:__'}
          readOnly={!!(answer_decision)}
          value={value}
          size={size}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              game.submit_answer(user._id)
            }
          }}
        />
        {answer_decision !== 'CORRECT' && correct_answers.length > 0 &&
          <Input
            value={get_formatted_correct_answer(correct_answers, answerIndex, element.ref) || 'error'}
            answer_decision="CORRECT"
            readOnly={true}
            size={size}
          />
        }
      </ColumnContainer>

    </Container>
  )
})
