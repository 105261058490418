import {FC, useCallback} from 'react'
import {observer} from 'mobx-react-lite'

import {useStore} from 'types/store'
import {ColumnContainer, Input} from 'generic_components/AnswerInputContainers'
import {InputProps} from 'components/question/inputProps'
import {get_formatted_correct_answer} from 'components/question/input'
import {is_maths_only} from 'util/helpers/stringFormatting'

const calculateLength = (x: string): number => x.length <= 3 ? 3 : x.length + 1

export const GBPComponent: FC<InputProps> = observer(({ answerIndex, element, handleChange, answer_decision, correct_answers, value, element_id }) => {
  const { game, auth: { user } } = useStore()

  const answer = correct_answers.length > 0 && answer_decision != 'CORRECT' ?
    get_formatted_correct_answer(correct_answers, answerIndex, element.ref) :
    ''

  const size = calculateLength(value)
  const answerSize = calculateLength(answer)

  const handleInputChange = useCallback((e) => {
    e.target.value = e.target.value.replaceAll(/[^0-9.-]/g, '')
    handleChange(e)
  }, [handleChange])

  const handleBlur = useCallback((e) => {
    if (is_maths_only(e.target.value)) {
      e.target.value = Number.parseFloat(e.target.value).toFixed(2)
      return handleChange(e)
    }

    e.target.value = ''
    handleChange(e)
  }, [value])

  return (
    <ColumnContainer>
      <div>
        <i className="fa-solid fa-sterling-sign"></i>
        <Input
          id={element_id}
          onBlur={handleBlur}
          onChange={handleInputChange}
          answer_decision={answer_decision}
          readOnly={!!(answer_decision)}
          value={value}
          size={size}
          onKeyPress={e => {
            if (e.key === 'Enter' && !game.over) {
              game.submit_answer(user._id)
            }
          }}
          autoFocus={element.autoFocus}
          placeholder="__.__"
          type="number"
          className="[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        />
      </div>
      {answer_decision !== 'CORRECT' && correct_answers.length > 0 &&
              <div>
                <i className="fa-solid fa-sterling-sign"></i>
                <Input
                  value={correct_answers[0].find(answerPart => answerPart.ref === element.ref)?.values[0] || 'error'}
                  answer_decision="CORRECT"
                  readOnly={true}
                  size={answerSize}
                  placeholder={element.placeholder}
                />
              </div>
      }
    </ColumnContainer>
  )
})
