import {FormEvent, useCallback} from 'react'
import {observer} from 'mobx-react-lite'
import classNames from 'classnames'

import {PrimaryClickableButton, SecondaryClickableButton} from 'components/ClickableButton'

export interface IMSTActionButtonsProps {
    sticky_bottom?: boolean,
    hide_cancel?: boolean,
    editing: boolean,
    cancel_text?: string,
    onClickSubmit: (e: FormEvent) => Promise<void>,
    dirty: boolean,
    has_errors: boolean,
    submit_text?: string,
    force_edit_mode?: boolean,
    set_editing: (editing: boolean) => void,
}
export const MstActionButtons = observer(({
  sticky_bottom,
  hide_cancel,
  editing,
  cancel_text,
  onClickSubmit,
  dirty,
  has_errors,
  submit_text,
  force_edit_mode,
  set_editing,
}: IMSTActionButtonsProps) => {
  const onClickEdit = useCallback(() => set_editing(true), [])

  return (
    <div className={classNames(sticky_bottom ? 'sticky bottom-0 bg-white' : '', 'flex justify-evenly py-6 border border-0 border-t-2 border-gray-200')}>
      {!hide_cancel &&
        <SecondaryClickableButton
          type="reset"
          disabled={!editing}
        >
          {cancel_text}
        </SecondaryClickableButton>
      }
      <PrimaryClickableButton
        type="button"
        onClick={onClickSubmit}
        disabled={!dirty || !editing || has_errors}
      >
        {submit_text}
      </PrimaryClickableButton>
      {!force_edit_mode &&
        <SecondaryClickableButton
          type="button"
          onClick={onClickEdit}
        >
            Edit
        </SecondaryClickableButton>
      }
    </div>
  )
})
MstActionButtons.displayName = 'MstActionButtons'
