import {flow, getRoot, Instance, types} from 'mobx-state-tree'

import {postAction} from 'util/api/action/postAction'
import {Store} from 'types/store'
import {Action} from 'common/types/actions'
import {getActions} from 'util/api/action/getActions'
import {Roles} from 'util/auth/helper'

export const ActionsStore = types.model({
  actions: types.map(types.array(Action)),
  is_loading: types.boolean
}).actions(self => ({
  record_action: flow(function* (label: string, value: string) {
    const { auth } = getRoot<Instance<typeof Store>>(self)
    if (auth.user?.role === Roles.STUDENT || auth.user?.role === Roles.PARENT) {
      yield postAction({
        body: {
          type: auth.user.role.toLowerCase(),
          user_id: auth.user.uid,
          label,
          value,
        }
      })
    }
  }),
  get_actions: flow(function* (type: string, user_id: string, label?: string) {
    self.is_loading = true

    const { actions } = yield getActions({
      params: {
        type,
        user_id,
      },
      query: {
        label
      }
    })
    self.actions.set(user_id, actions)

    self.is_loading = false
  })
}))

export type ActionsStore = Instance<typeof ActionsStore>
