import toast from 'react-hot-toast'
import React, {useCallback} from 'react'
import classNames from 'classnames'

export const ActionToast = ({ toastInstance, onDismiss, title, subTitle, actionLabel }) => {
  const onClickDismiss = useCallback(() => {
    onDismiss()
    toast.dismiss(toastInstance.id)
  }, [toastInstance, onDismiss])

  return (
    <div
      className={
        classNames(
          toastInstance.visible ?
            'animate-enter' :
            'animate-leave',
          'max-w-md w-full shadow rounded pointer-events-auto flex ring-1 ring-black ring-opacity-5 relative bg-gray-100'
        )
      }
    >
      <i className="fa-4x fa-solid fa-wrench absolute left-6 top-6 text-xl"></i>
      <div className="w-0 flex-1 p-4">
        <div className="flex items-start">
          <div className="ml-3 flex-1 text-center">
            <p className="font-medium text-gray-900">
              {title}
            </p>
            <p className="mt-1 text-sm text-gray-500">
              {subTitle}
            </p>
          </div>
        </div>
      </div>
      <div className="flex border-l border-gray-200">
        <button
          onClick={onClickDismiss}
          className="flex w-full items-center justify-center rounded-none rounded-r border border-transparent p-4 text-sm font-medium text-indigo-600 hover:bg-indigo-100 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          {actionLabel}
        </button>
      </div>
    </div>
  )
}