import {FC, FormEvent, useCallback} from 'react'
import {observer} from 'mobx-react-lite'

import {LineComponent} from 'components/question/line'
import {AskedQuestion, Lines} from 'common/types/question/question'
import {AllowedAnswers, SubmittedAnswerMap} from 'common/types/question/answer/answer'

interface Props {
  question: AskedQuestion,
  student_answer: SubmittedAnswerMap,
  correct_answers: AllowedAnswers,
  answer_decision?: string,
  update_answer: (line_elem_key: string, ref: string, value: string, selected: boolean) => void
}

export const Question: FC<Props> = observer(({ question: { lines }, student_answer, correct_answers, answer_decision, update_answer }) => {
  const handleInputChange = useCallback((line_element: string, ref: string, selected = false) => (e) => {
    e.preventDefault()
    const value = e.currentTarget.value
    update_answer(line_element, ref ?? '', value, selected)
  }, [update_answer])

  return (
    <div>
      <form
        autoComplete='off'
        onSubmit={override_submit}
        className="mb-5 overflow-auto bg-amber-200 p-6 shadow"
      >
        {
          horizontaliseLines(lines).map((line, line_index) => (
            <LineComponent
              lines={horizontaliseLines(lines)}
              key={line_index}
              line_index={line_index}
              line={line}
              handleInputChange={handleInputChange}
              correct_answers={correct_answers}
              student_answer={student_answer}
              answer_decision={answer_decision}
            />
          ))
        }
      </form>
      {answer_decision && <p id="message" className="rounded bg-gray-100 p-2 text-center">{answerDecisionMessage[answer_decision]}</p>}
    </div >
  )
})
Question.displayName = 'Question'

const override_submit = (e: FormEvent) => {
  e.preventDefault()
  console.error('Question form submit blocked')
}

export const answerDecisionMessage = {
  'INCORRECT': <p className="font-bold text-red-500">Sorry, that is not the correct answer. The correct answer is shown above.</p>,
  'PASSED': <p className="font-bold text-blue-500">You passed. The correct answer is shown above.</p>,
  'CORRECT': <p className="font-bold text-green-500">Correct, well done!</p>,
}

const horizontaliseLines = (lines: Lines): Lines => {
  const output = []
  const input_lines = []
  for (const line of lines) {
    if (line[0] && (
      line[0].type === 'MC input' ||
      line[0].type === 'Text input' ||
      line[0].type === 'Whole Number'
    )) {
      input_lines.push(...line)
    } else if(input_lines.length > 0) {
      output.push(input_lines)
      input_lines.splice(0, input_lines.length)
    } else {
      output.push(line)
    }
  }

  if (input_lines.length > 0) {
    output.push([
      {
        ...input_lines[0],
        autoFocus: true
      },
      ...input_lines.slice(1)
    ])
  }

  return output as Lines
}
