import {FC, useEffect, useState} from 'react'

import {Element} from 'common/types/question/question'
import {$window} from 'services/window'

interface Props {
  image: Element
}

export const QuestionImage: FC<Props> = ({ image }) => {
  const [imgUrl, setImgUrl] = useState(null)

  useEffect( () => {
    if (image.image_id) {
      $window
        .fetch(`/static-data/attachments/${image.image_id}`)
        .then(res => res.blob())
        .then(blob => setImgUrl(URL.createObjectURL(blob)))
    }
  }, [image.image_id])

  if (!imgUrl) {
    return null
  }

  return (
    <div className="m-1 mx-auto w-full max-w-[500px] rounded bg-white p-2 shadow">
      <img src={imgUrl} alt={image.alt}/>
    </div>
  )
}
