import {flow, getRoot, Instance, types} from 'mobx-state-tree'
import toast from 'react-hot-toast'

import {Store} from 'types/store'
import {postTeam} from 'util/api/teacher/teams/add_team'
import {getTeams} from 'util/api/teacher/teams/get_teams'
import {Team} from 'common/types/teacher/teams'
import {Class} from 'common/types/class'
import {saveTeam} from 'util/api/teacher/teams/save_team'
import {deleteTeam} from 'util/api/teacher/teams/delete_team'
import {postSetCompetitionTeams} from 'util/api/competitions/set_competition_teams'
import {Competition} from 'common/types/competition'

export const TeacherTeamsList = types.model({
  teams: types.array(Team),
  current_team: types.maybe(types.safeReference(Team)),
  selected_class: types.maybe(types.safeReference(Class)),
}).actions(self => {
  const refresh = flow(function*(){
    const { teams } = yield getTeams()
    self.teams = teams
  })

  const save_current_team = flow(function*(){
    const result = yield saveTeam({
      body: {
        _id: self.current_team._id,
        name: self.current_team.name,
        students: self.current_team.students.map(s => s._id)
      }
    })
    if (result.success) {
      toast.success('Saved team')
    } else {
      toast.error('Failed saving team')
    }
  })

  return ({
    update_current_team_name: flow(function* (name) {
      self.current_team.name = name
    }),
    toggle_in_team: flow(function* (student) {
      const is_in_current_team = self.current_team.students.find(s => s._id === student)
      if (is_in_current_team) {
        self.current_team.students.remove(is_in_current_team)
      } else {
        self.current_team.students.push(student)
      }

      yield save_current_team()
    }),
    refresh,
    set_selected_class: flow(function* (class_model) {
      self.selected_class = class_model
    }),
    set_current_team: flow(function* (team) {
      self.current_team = team
    }),
    delete_team: flow(function* (team) {
      yield deleteTeam({body: { team_id: team._id}})
      toast.success('Team deleted')

      yield refresh()
    }),
    save_current_team,
    add_team: flow(function* (team_name: string) {
      const { team } = yield postTeam({
        body: {
          team_name,
          class_id: self.selected_class?._id
        }
      })

      yield refresh()
      self.current_team = team
    }),
    set_competition_teams: flow(function*(competition: Competition, teams: string[]) {
      const { teacherStore } = getRoot<Instance<typeof Store>>(self)

      const result = yield postSetCompetitionTeams({
        body: {
          competition_id: competition._id,
          team_ids: teams
        }
      })
      yield refresh()
      yield teacherStore.get_teacher(teacherStore.teacher._id)

      if (result.success) {
        toast.success('Competition teams saved.')
      }
    })
  })
})
export type TeacherTeamsList = Instance<typeof TeacherTeamsList>
