import {getRoot, Instance, types} from 'mobx-state-tree'
import {max, mean, sum} from 'lodash'

import {IRichStore, Store} from 'types/store'
import {Score, ScoreByStudent} from 'common/types/class'
import {Student} from 'common/types/student'
import {days_from_now} from 'util/helpers/days_from_now'

export const TeacherStudentPerformanceScores = types
  .model({})
  .views(self => {
    const performance_messages = (student: Student, selected_focus: Score | null): number => {
      const {classPerformanceStore} = getRoot<Instance<typeof Store>>(self)

      const is_refresh_year = classPerformanceStore.selectedRefresh &&
          !classPerformanceStore.selectedTopic &&
          !classPerformanceStore.selectedEndcode

      if (is_refresh_year) {
        const scores_for_year = classPerformanceStore
          .all_scores(student._id)
          .filter(s => classPerformanceStore.selectedRefresh.subjects.map(s => s._id).includes(s.subject._id))

        const total_answers = sum(scores_for_year.map(score => score.total_answers))

        if (total_answers > 0) {
          return mean(scores_for_year.map(score => score.recent_correct_percentage))
        }

        return null
      }

      const is_refresh_topic_or_endcode = classPerformanceStore.selectedRefresh &&
          classPerformanceStore.selectedTopic

      if (is_refresh_topic_or_endcode) {
        if (selected_focus.total_answers > 0) {
          return selected_focus.recent_correct_percentage
        }

        return null
      }

      if (classPerformanceStore.is_subject_live(student as Student, selected_focus.subject)) {
        if (selected_focus && classPerformanceStore.selectedEndcode && selected_focus.score) {
          return selected_focus.score
        }

        return selected_focus.score
      }

      return null
    }

    return {
      performance_score(student: Student, scores: Score[]): number {
        const {classPerformanceStore, teacherPageStore} = getRoot<Instance<typeof Store>>(self)

        if (
          classPerformanceStore.selectedTopic ||
          classPerformanceStore.selectedEndcode
        ) {
          const selected_focus = classPerformanceStore.selected_focus(scores as Score[])
          if (selected_focus) {
            return performance_messages(
              student,
              selected_focus
            )
          }

          return null
        }

        const subject_score = (scores as Score[]).find(s => s.subject._id === teacherPageStore.currentClass.subject._id)
        if (subject_score && !classPerformanceStore.selectedTopic) {
          return performance_messages(
            student,
            subject_score, // todo try refactor + extract with selected_focus above
          )
        }

        return null
      }
    }
  })
  .views(self => ({
    last_answered: ({ scores, student }: ScoreByStudent): number => {
      const {classPerformanceStore, teacherPageStore}: IRichStore = getRoot(self)

      if (classPerformanceStore.selectedRefresh && !classPerformanceStore.selectedTopic && !classPerformanceStore.selectedEndcode) {
        const scores_for_refresh = classPerformanceStore.scores_for_refresh(classPerformanceStore.selectedRefresh, student as Student)
        const max_last_answered = max(scores_for_refresh.map((s) => s.last_answered))

        if (!max_last_answered) {
          return null
        }
        return days_from_now(max_last_answered)
      }

      if (classPerformanceStore.selectedRefresh && classPerformanceStore.selectedTopic && !classPerformanceStore.selectedEndcode) {
        const refresh_subjects_for_topic = classPerformanceStore.refresh_subjects_for_topic(classPerformanceStore.selectedTopic._id)
        const scores_for_subject = (scores as Score[])
          .filter(score =>
            refresh_subjects_for_topic.map(j => j._id).includes(score.subject._id)
          )
        const max_last_answered = max(max(scores_for_subject.map((s) => s.last_answered)))

        if (!max_last_answered) {
          return null
        }
        return days_from_now(max_last_answered)
      }

      if (!classPerformanceStore.selectedTopic) {
        const subject_score = (scores as Score[] ?? []).find(s => s.subject._id === teacherPageStore.currentClass.subject._id)
        if (!subject_score || !subject_score.last_answered) {
          return null
        }

        return days_from_now(subject_score.last_answered)
      }

      const selected_focus = classPerformanceStore.selected_focus(scores as Score[])

      if (!selected_focus || !selected_focus.last_answered) {
        return null
      }

      return days_from_now(selected_focus.last_answered)
    }
  }))

export type TeacherStudentPerformanceScores = Instance<typeof TeacherStudentPerformanceScores>
