import {StringSelector} from 'generic_components/MstFormStringSelector'
import {Country} from 'common/types/basic/country'

export const CountrySelector = StringSelector([
  {
    key: 'Please choose a country',
    value: '',
  },
  ...(Country as any)._types
])
