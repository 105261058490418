import {applySnapshot, flow, getRoot, getSnapshot, Instance, types} from 'mobx-state-tree'
import {DateTime} from 'luxon'
import {v4 as uuid} from 'uuid'

import {ListMetadata, QuestionSearchFilter} from 'common/types/question/search'
import {question_search} from 'util/api/admin/question/search'
import {Competition, PostNewCompetitionResponse, PutCompetitionResponse} from 'common/types/competition'
import {postNewCompetition} from 'util/api/competitions/add_competition'
import {getAdminCompetitions} from 'util/api/competitions/get_admin_competitions'
import {getCompetitionDetails} from 'util/api/competitions/get_competition_details'
import {DetailResult} from 'common/types/competition/details'
import {Student} from 'common/types/student'
import {getAllStudents} from 'util/api/admin/getAllStudents'
import {updateCompetition} from 'util/api/competitions/update_competition'
import {Question} from 'types/question_management/question/question'
import {Store} from 'types/store'
import {DisplayType} from 'common/types/question/question'

export const AdminStore = types
  .model('AdminStore',{
    questions: types.array(Question),
    questions_filter: types.optional(QuestionSearchFilter, {
      status: ['live'],
      _from: 0,
      _limit: 50,
    }),
    metadata: types.maybe(ListMetadata),
    competitions: types.array(Competition),
    is_loading: types.boolean,
    competition_results: types.array(DetailResult),
    competition_last_updated: types.maybe(types.string),
    students: types.array(Student)
  })
  .volatile(() => ({
    busy: false,
    changed: true
  }))
  .actions(self => ({
    add_question: (newQuestion: Question) => {
      const index = self.questions.findIndex(q => q.ref === newQuestion.ref)

      const updated_questions =   [
        ...self.questions.slice(0, index),
        newQuestion,
        ...self.questions.slice(index + 1)
      ]
      self.questions.replace(updated_questions)
    },
    reset_questions: () => {
      self.questions = [] as any
    },
    get_questions_for_endcode: flow(function* (endcode_id: string) {
      self.is_loading = true
      const response = yield question_search({ query: {
        endcode_id,
        _from: 0,
        _limit: 1000,
        status: ['live', 'draft', 'review'],
      }})

      self.questions = response.data
      self.metadata = response.metadata

      for (const question of self.questions) {
        question.set_has_changed(false)
      }

      self.is_loading = false
    }),
    get_more_questions: flow(function* () {
      self.is_loading = true
      const response = yield question_search({ query: {
        endcode_id: self.questions[0].subject[3]._id,
        _from: self.questions.length,
        _limit: 1000,
        status: ['live', 'draft', 'review'],
      }})

      self.questions.push(...response.data)
      self.metadata = response.metadata

      for (const question of self.questions) {
        question.set_has_changed(false)
      }

      self.is_loading = false
    }),
    set_filter_status: (statuses:string[]) => {
      applySnapshot(self.questions_filter.status, statuses)
      self.changed = true
    },
    set_filter_ref: (ref:string) => {
      self.questions_filter.ref = ref
      self.changed = true
    },
    get_competitions: flow(function* () {
      self.is_loading = true
      const result = yield getAdminCompetitions()
      self.competitions = result.competitions
      self.is_loading = false
    }),
    add_competition: flow(function* (competition) {
      self.is_loading = true

      const result = yield postNewCompetition({
        body: competition
      })
      self.is_loading = false

      return result as PostNewCompetitionResponse
    }),
    update_competition: flow(function* (competition) {
      self.is_loading = true

      const result = yield updateCompetition({
        body: competition
      })
      self.is_loading = false

      return result as PutCompetitionResponse
    }),
    get_details: flow(function*(competition_id: string) {
      self.is_loading = true
      const { results } =  yield getCompetitionDetails({ params: { competition_id } })
      self.competition_results = results
      self.competition_last_updated = DateTime.now().toISO()


      self.is_loading = false
    }),
    get_students: flow(function*(){
      self.is_loading = true
      const { students } =  yield getAllStudents()
      self.students = students

      self.is_loading = false
    }),
    clear_previous: () => {
      self.competition_last_updated = undefined
      self.is_loading = false
      self.competition_results.clear()
    },
    set_is_loading: (isLoading: boolean) => {
      self.is_loading = isLoading
    },
    clone_question: (question: Question) => {
      const cloned_question = Question.create({
        ...getSnapshot(question),
        _id : 'NEW',
        ref: uuid(),
        status: 'draft',
        updated_at: undefined
      })
      cloned_question.set_has_changed(true)
      self.questions.unshift(cloned_question)
    },
    new_question: () => {
      const { subject_management_store } = getRoot<Instance<typeof Store>>(self)
      const new_question = Question.create({
        _id : 'NEW',
        ref: uuid(),
        status: 'draft',
        maximum_time: 30,
        standard_time: 30,
        score: 30,
        lines: [[{
          type: DisplayType.Text,
          text: 'New Question'
        }]],
        answers: [
          [{
            'ref': '',
            'values': ['a']
          }]
        ],
        subject: [
          subject_management_store.selected_endcode.parent.parent.parent._id, // subject
          subject_management_store.selected_endcode.parent.parent._id, // theme
          subject_management_store.selected_endcode.parent._id, // topic
          subject_management_store.selected_endcode._id // endcode
        ]
      })
      new_question.set_has_changed(true)
      self.questions.unshift(new_question)
    }
  }))
export type AdminStore = Instance<typeof AdminStore>
