import {ReactNode} from 'react'
import classNames from 'classnames'
import {observer} from 'mobx-react-lite'
import {useLocation} from 'react-router-dom'

import {useUser} from 'context/userContext'
import {Roles} from 'util/auth/helper'

const getRoleFromUrl = (url: string): Roles|undefined => {
  if (url.includes('parent')) {
    return Roles.PARENT
  }

  if (url.includes('student')) {
    return Roles.STUDENT
  }

  if (url.includes('tutor')) {
    return Roles.TUTOR
  }

  if (url.includes('teacher')) {
    return Roles.TEACHER
  }

  if (url.includes('school')) {
    return Roles.SCHOOL
  }
}

const getBackground = (role: Roles): string => {
  if (role === Roles.ADMIN) {
    return 'bg-body-pattern-admin bg-fixed'
  }
  if (role === Roles.PARENT) {
    return 'bg-body-pattern-parent bg-fixed'
  }
  if (role === Roles.STUDENT) {
    return 'bg-body-pattern-student bg-fixed'
  }
  if (role === Roles.TUTOR) {
    return 'bg-body-pattern-tutor bg-fixed'
  }
  if (role === Roles.TEACHER) {
    return 'bg-body-pattern-teacher bg-fixed'
  }
  if (role === Roles.SCHOOL) {
    return 'bg-body-pattern-school bg-fixed'
  }

  return ''
}

interface IProps {
  children: ReactNode,
  background?: string
}

export const ContentContainer = observer(({ background, children }: IProps) => {
  const location = useLocation()
  const { user } = useUser()
  const roleBackground = getBackground(user?.role ?? getRoleFromUrl(location.pathname))

  return (
    <div
      className={
        classNames(
          //  Min height is screen height minus navbar width.
          user?.role === Roles.STUDENT ? 'min-h-[calc(100vh-144px)]' : 'min-h-[calc(100vh-76px)]',
          'h-full bg-gray-700 lg:p-2 bg-repeat relative',
          background ?? roleBackground
        )
      }
    >
      {children}
    </div>
  )
})
