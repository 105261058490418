import {flow, getRoot, Instance, types} from 'mobx-state-tree'
import {DateTime} from 'luxon'
import toast from 'react-hot-toast'
import {IObservableArray} from 'mobx'

import {ApiError} from 'types/ApiError'
import {IRichStore} from 'types/store'
import {School} from 'common/types/school'
import {GenderEnum} from 'common/types/basic/gender'
import {get_school_request} from 'util/api/school/get_school_request'
import {add_teacher} from 'util/api/teacher/update_school'
import {update_school} from 'util/api/school/update_school'
import {postNewStudent} from 'util/api/student'
import {add_class} from 'util/api/class/add_class'
import {update_class} from 'util/api/class/update_class'
import {remove_class} from 'util/api/class/remove_class'
import {CreateBasketResponse} from 'common/types/subscription'
import {create_class_subscription} from 'util/api/class/create_class_subscription'
import {edit_class_subscription} from 'util/api/class/edit_class_subscription'
import {cancel_class_subscription} from 'util/api/class/cancel_class_subscription'
import {createFreeTrial} from 'util/api/subscriptions'
import {NewStudent, Student} from 'common/types/student'
import {Class} from 'common/types/class'
import {Teacher} from 'common/types/teacher'
import {ISelectable} from 'pages/school/components/multi_assign/multi_assign'
import {logError} from 'util/helpers/logError'
import {reset_student_score} from 'util/api/admin/resetStudentScore'

export interface ISpreadSheetStudent {
  'Date of Birth (dd/mm/yyyy)': string,
  'First Name': string,
  'Initial Password': string
  'Parent Email': string,
  'Second Name': string,
  Username: string,
  'School Year (1-11)': string,
  'Gender (Male, Female, Other)': string,
  'Subject (Primary Maths, KS3 Maths, GCSE Maths)': string,
}

const validateGender = (gender: string): GenderEnum | undefined => {
  if (gender.toLowerCase() === GenderEnum.Male.toLowerCase()) {
    return GenderEnum.Male
  }
  if (gender.toLowerCase() === GenderEnum.Female.toLowerCase()) {
    return GenderEnum.Female
  }
  if (gender.toLowerCase() === GenderEnum.Other.toLowerCase()) {
    return GenderEnum.Other
  }

  return undefined
}

export const NewSchoolStudent = NewStudent
  .named('Student')
  .props({
    subject_id: types.string,
    school_year: types.number
  })
export type NewSchoolStudent = Instance<typeof NewSchoolStudent>

export const SchoolStore = types.model({
  school: types.maybe(School),
  isLoading: types.boolean,
  stage: types.number,
  currentClass: types.safeReference(Class),
})
  .actions(self => {
    const get_school = flow(function* (school_id?: string) {
      self.isLoading = true
      self.school = yield get_school_request(school_id ?? self.school?._id)
      self.isLoading = false
    })

    return {
      get_school,
      update_school: flow(function* (school: School) {
        yield update_school({
          name: school.name,
          address: school.address,
          postcode: school.postcode,
          type: school.type,
          phone_number: school.phone_number,
        })
        yield get_school()

        self.stage = self.stage + 1
      }),
      add_teacher: flow(function* (name: string, email: string) {
        yield add_teacher({body: {name, email}})
        yield get_school()
      }),
      add_students: flow(function* (students: ISpreadSheetStudent[]) {
        let success = true
        let index = 0
        let loadingToast: string
        const {subjectsStore}: IRichStore = getRoot(self)

        for (const spreadsheetStudent of students) {
          index++
          loadingToast = toast.loading(`Uploading students: ${index} of ${students.length}`, { id: loadingToast })

          if (spreadsheetStudent.Username) {
            try {
              const subject = 
                subjectsStore.top_level_subjects()
                  .filter(subject => subject.status === 'live')
                  .find(subject => subject.name === spreadsheetStudent['Subject (Primary Maths, KS3 Maths, GCSE Maths)'].trim())
              
              if(!subject) {
                throw new Error(`Invalid Subject: ${spreadsheetStudent['Subject (Primary Maths, KS3 Maths, GCSE Maths)'].trim()}`)
              }

              const newStudent = yield postNewStudent({
                body: {
                  username: spreadsheetStudent.Username.trim(),
                  password: spreadsheetStudent['Initial Password'].trim(),
                  first_name: spreadsheetStudent['First Name'].trim(),
                  last_name: spreadsheetStudent['Second Name'].trim(),
                  dob: DateTime.fromFormat(spreadsheetStudent['Date of Birth (dd/mm/yyyy)'].trim(), 'dd/MM/yyyy').toISODate(),
                  gender: validateGender(spreadsheetStudent['Gender (Male, Female, Other)'].trim()),
                  school: self.school._id,
                }
              })

              const school_year = Number.parseInt(spreadsheetStudent['School Year (1-11)'].trim())
              yield createFreeTrial({
                body: {
                  student_id: newStudent._id,
                  subject_id: subject._id,
                  parent_id: null,
                  school_year,
                  school_id: self.school._id
                }
              })

              yield reset_student_score(
                newStudent._id,
                subject._id,
                school_year - 1
              )
            } catch (error) {
              logError(error)
              success = false
              console.error('Failed to create student', spreadsheetStudent)

              console.error(error)
              if (error instanceof ApiError) {
                toast.error(`${error.errors.join('\n')} (${spreadsheetStudent.Username})`)
              } else {
                toast.error('Something went wrong when uploading students, please contact support.')
              }

              break
            }
          }
        }

        toast.remove(loadingToast)
        if (success) {
          toast.success(`${students.length} Students added successfully`)
        }
        yield get_school()

      }),
      add_student: flow(function* (student: NewSchoolStudent) {
        self.isLoading = true
        const newStudent = yield postNewStudent({
          body: {
            dob: student.dob,
            first_name: student.first_name,
            last_name: student.last_name,
            gender: student.gender,
            password: student.password,
            username: student.username,
            school: self.school._id
          }
        })
        yield createFreeTrial({
          body: {
            student_id: newStudent._id,
            subject_id: student.subject_id,
            parent_id: null,
            school_year: student.school_year,
            school_id: self.school._id,
          }
        })
        yield reset_student_score(
          newStudent._id,
          student.subject_id,
          student.school_year - 1
        )
        yield get_school()
        self.isLoading = false

        return newStudent
      }),
      set_current_class: (currentClass) => {
        self.currentClass = currentClass
      },
      add_pending_class: (pending_class) => {
        self.school.classes.push(pending_class)
      },
      remove_pending_class: (pending_class) => {
        self.school.classes.remove(pending_class)
      },
      save_class: flow(function* () {
        const {class_id} = yield add_class({
          body: {
            school_id: self.school._id,
            teacher_ids: self.currentClass.teachers.map(t => t?._id).filter(Boolean),
            class_name: self.currentClass.name,
            school_year: self.currentClass.school_year,
          }
        })

        const {success} = yield update_class({
          body: {
            class_id,
            student_ids: self.currentClass.students.map(s => s?._id).filter(Boolean),
            teacher_ids: self.currentClass.teachers.map(t => t?._id).filter(Boolean),
            class_name: self.currentClass.name,
            subject_id: self.currentClass.subject._id,
            school_year: self.currentClass.school_year,
          }
        })

        yield get_school()

        if (success) {
          toast.success('Class created successfully')
        }
        return success
      }),
      update_class: flow(function* () {
        const {success} = yield update_class({
          body: {
            class_id: self.currentClass._id,
            student_ids: self.currentClass.students.map(s => s?._id).filter(Boolean),
            teacher_ids: self.currentClass.teachers.map(t => t?._id).filter(Boolean),
            class_name: self.currentClass.name,
            subject_id: self.currentClass.subject._id,
            school_year: self.currentClass.school_year,
          }
        })

        yield get_school()

        if (success) {
          toast.success('Class updated successfully')
        }
      }),
      update_class_subscription: flow(function* () {
        self.isLoading = true
        const update_class_result = yield update_class({
          body: {
            class_id: self.currentClass._id,
            student_ids: self.currentClass.students.map(s => s?._id).filter(Boolean),
            teacher_ids: self.currentClass.teachers.map(t => t?._id).filter(Boolean),
            class_name: self.currentClass.name,
            subject_id: self.currentClass.subject._id,
            school_year: self.currentClass.school_year,
          }
        })

        const edit_subscription_result = yield edit_class_subscription({
          body: {
            school_id: self.school._id,
            class_id: self.currentClass._id,
            student_ids: self.currentClass.students.map(s => s?._id).filter(Boolean),
            subject: self.currentClass.subject._id
          }
        })

        self.isLoading = false

        if (update_class_result.success && edit_subscription_result.success) {
          toast.success('Subscription updated successfully')
        }
      }),
      remove_class: flow(function* (class_id) {
        const {success} = yield remove_class({
          body: {
            class_id,
            school_id: self.school._id
          }
        })

        yield get_school()

        if (success) {
          toast.success('Class deleted successfully')
        }
      }),
      cancel_class_subscription: flow(function* () {
        const {success} = yield cancel_class_subscription({
          body: {
            class_id: self.currentClass._id,
            school_id: self.school._id,
          }
        })

        yield get_school()

        if (success) {
          toast.success('Subscription cancelled successfully')
        }
      }),
      subscribe_class: flow(function* (complete_url: string, promo_code?: string) {
        const result: Instance<typeof CreateBasketResponse> = yield create_class_subscription({
          body: {
            school_id: self.school._id,
            student_ids: self.currentClass.students.map(s => s?._id).filter(Boolean),
            class_id: self.currentClass._id,
            school_year: self.currentClass.school_year,
            subject: self.currentClass.subject._id,
            success_url: complete_url,
            error_url: complete_url,
            promo_code,
            referral_code: undefined,
          }
        })

        return result
      }),
      set_current_class_teacher: (position: number, teacher: string) => {
        if (self.currentClass) {
          self.currentClass.teachers[position] = teacher
        }
      },
      set_current_class_teachers: (teachers: ISelectable[]) => {
        if (self.currentClass) {
          self.currentClass.teachers = teachers.map(t => t?.value)
        }
      },
      remove_current_class_teacher: (teacher: string) => {
        if (self.currentClass) {
          const foundTeacher = self.currentClass.teachers.findIndex(t => t?._id === teacher);
          (self.currentClass.teachers as IObservableArray<Teacher>)[foundTeacher] = null
        }
      },
      set_current_class_name: (name) => {
        if (self.currentClass) {
          self.currentClass.name = name
        }
      },
      set_current_class_student: (position: number, student: string) => {
        if (self.currentClass) {
          self.currentClass.students[position] = student
        }
      },
      remove_current_class_student: (student: string) => {
        if (self.currentClass) {
          const foundStudent = self.currentClass.students.findIndex(s => s?._id === student);
          (self.currentClass.students as IObservableArray<Student>)[foundStudent] = null
        }
      },
      set_current_class_students: (students: ISelectable[]) => {
        if (self.currentClass) {
          self.currentClass.students = students.map(s => s?.value)
        }
      },
      set_current_class_subject: (subject) => {
        if (self.currentClass) {
          if (self.currentClass.subject !== subject) {
            self.currentClass.students = self.currentClass.students.map(() => null)
          }
          self.currentClass.subject = subject
        }
      },
      set_current_class_school_year: (school_year) => {
        if (self.currentClass) {
          self.currentClass.school_year = school_year
        }
      }
    }
  })
  .actions(self => ({
    initialise: flow(function* () {
      const {auth, admin_tutor_list, subjectsStore}: IRichStore = getRoot(self)

      self.isLoading = true

      yield admin_tutor_list.refresh()
      yield subjectsStore.get_all_subjects()
      yield self.get_school(auth.user.uid)

      self.isLoading = false
    })
  }))

export type SchoolStore = Instance<typeof SchoolStore>
