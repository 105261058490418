import {Instance, types} from 'mobx-state-tree'

export const TranslationRequest = types.model({
  content: types.string,
  input_language: types.string,
  output_language: types.string
})
export type TranslationRequest = Instance<typeof TranslationRequest>

export const PostTranslationRequest = types.model({
  body: TranslationRequest
})
export type PostTranslationRequest = Instance<typeof PostTranslationRequest>

export const PostTranslationResponse = types.model({
  body: types.model({
    translated: types.string
  })
})
export type PostTranslationResponse = Instance<typeof PostTranslationResponse>
