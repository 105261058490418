import {flow, getRoot, Instance, types} from 'mobx-state-tree'
import {DateTime} from 'luxon'
import {sum} from 'lodash'

import {Store} from 'types/store'
import {Competition} from 'common/types/competition'
import {getStudentCompetitions} from 'util/api/student/competitions'
import {getCompetitionDetails} from 'util/api/competitions/get_competition_details'
import {DetailResult} from 'common/types/competition/details'
import {getAllStudents} from 'util/api/admin/getAllStudents'
import {Student} from 'common/types/student'
import {getStudentTeams} from 'util/api/student/teams'
import {Team} from 'common/types/teacher/teams'
import {Class} from 'common/types/class'
import {getStudentClasses} from 'util/api/student/classes'

export interface ILeaderboardItem {
    competitor: string,
    total_tokens: number,
    is_you: boolean,
    position: number,
    student_id?: string
}

export const GameCompetitionsStore = types.model({
  isLoading: types.boolean,
  teams: types.array(Team),
  classes: types.array(Class),
  competitions: types.array(Competition),
  students: types.array(Student),
  selected_competition: types.maybe(types.safeReference(Competition)),
  results: types.map(types.array(DetailResult)),
  last_updated: types.maybe(types.string),
  original_position: types.maybeNull(types.number),
  original_tokens: types.maybeNull(types.number)
})
  .actions(self => ({
    get_competitions: flow(function* () {
      self.isLoading = true
      const { competitions } = yield getStudentCompetitions()
      self.competitions = competitions
      self.isLoading = false
    }),
    get_details: flow(function*(competition) {
      if (competition){
        self.isLoading = true
        const { results } =  yield getCompetitionDetails({ params: { competition_id: competition._id } })
        self.results.set(competition._id, results)

        self.last_updated = DateTime.now().toISO()
        self.isLoading = false
      }
    }),
    get_students: flow(function*(){
      self.isLoading = true
      const { students } =  yield getAllStudents()
      self.students = students

      self.isLoading = false
    }),
    get_classes: flow(function*() {
      self.isLoading = true

      const { classes } = yield getStudentClasses()
      self.classes = classes

      self.isLoading = false
    }),
    get_teams: flow(function*() {
      self.isLoading = true

      const { teams } = yield getStudentTeams()
      self.teams = teams

      self.isLoading = false
    }),
    set_selected_competition: (selected_competition) => {
      self.selected_competition = selected_competition
    },
    set_original_position: (position) => {
      self.original_position = position
    },
    set_original_tokens: (tokens) => {
      self.original_tokens = tokens
    }
  }))
  .views(self => {
    const rootStore = getRoot<Instance<typeof Store>>(self)

    const get_individual_leaderboard = (competition) => {
      return (
        competition
          ?.class
          ?.students ?? self.students
      )
        .slice()
        .map((student) => {
          const foundScore = self.results.get(competition._id)?.find(r => r.student._id === student._id)
          const is_you = student._id === rootStore.auth.user._id

          return ({
            competitor: `${student.first_name} ${is_you ? '(You)' : ''}`,
            total_tokens: foundScore?.total_tokens ?? 0,
            is_you,
            student_id: student._id
          })
        })
        .filter(x => x.total_tokens !== 0 || x.is_you)
        .sort((a, b) => a.total_tokens <= b.total_tokens ? 1 : -1)
        .map((x, i) => ({ ...x, position: i + 1 }))
    }

    const get_team_leaderboard = (competition) => {
      return competition
        .teams
        .map(team => {
          const team_score = sum(
            team
              .students
              .map(s => self.results.get(competition._id)?.find(r => r.student._id === s._id)?.total_tokens)
              .filter(Boolean)
          )

          return ({
            competitor: team.name,
            total_tokens: team_score,
            is_you: Boolean(team.students.find(s => s._id === rootStore.auth.user._id)),
          })
        })
        .sort((a, b) => a.total_tokens <= b.total_tokens ? 1 : -1)
        .map((x, i) => ({ ...x, position: i + 1 }))
    }

    return ({
      full_leaderboard: (competition: Competition|null): ILeaderboardItem[]|null => {
        if (!competition) {
          const rootStore = getRoot<Instance<typeof Store>>(self)

          return [{
            competitor: 'You',
            total_tokens: rootStore.student_tokens.total_token_count,
            is_you: true,
            position: null,
            student_id: 'string'
          }]
        }
        return competition.type === 'individual' ?
          get_individual_leaderboard(competition) :
          get_team_leaderboard(competition)
      }
    })
  })
  .views(self => {
    return ({
      partial_leaderboard: (competition): ILeaderboardItem[] => {
        if (!competition) {
          return self.full_leaderboard(null)
        }

        const myLeaderboardItem = self.full_leaderboard(competition).find(l => l.is_you)
        if (!myLeaderboardItem) {
          return self.full_leaderboard(competition).slice(0, 5)
        }

        if (myLeaderboardItem.position >= 5) {
          return [
            ...self.full_leaderboard(competition).slice(0, 2),
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position - 2),
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position - 1),
            myLeaderboardItem
          ]
        }

        if (myLeaderboardItem.position === 4) {
          return [
            ...self.full_leaderboard(competition).slice(0, 2),
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position - 1),
            myLeaderboardItem,
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position + 1),
          ]
        }

        if (myLeaderboardItem.position === 3) {
          return [
            ...self.full_leaderboard(competition).slice(0, 2),
            myLeaderboardItem,
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position + 1),
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position + 2),
          ]
        }

        if (myLeaderboardItem.position === 2) {
          return [
            self.full_leaderboard(competition).find(l => l.position === 1),
            myLeaderboardItem,
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position + 1),
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position + 2),
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position + 3),
          ]
        }

        if (myLeaderboardItem.position === 1) {
          return [
            myLeaderboardItem,
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position + 1),
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position + 2),
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position + 3),
            self.full_leaderboard(competition).find(l => l.position === myLeaderboardItem.position + 4),
          ]
        }

        return self.full_leaderboard(competition).slice(0, 5)
      },
      current_position: (competition: Competition|null): ILeaderboardItem|null => {
        const myLeaderboardItem = self.full_leaderboard(competition).find(l => l.is_you)
        if (myLeaderboardItem) {
          return myLeaderboardItem
        }
      }
    })
  })
export type GameCompetitionsStore = Instance<typeof GameCompetitionsStore>
